import { media } from 'media';
import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div`
  display: inline-flex;
  padding: 4px;
  gap: 4px;

  border-radius: 30px;
  background: ${COLORS.Background100_70};

  ${media.mobile`
    gap: 2px;
  `}
`;
