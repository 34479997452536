import {
  ADD_FOLLOWING_LIST,
  UPDATE_FOLLOWING_LIST,
  DELETE_FOLLOWING_LIST,
  UPDATE_SELECTED_LIST,
  DELETE_SELECTED_LIST,
  RESET_SELECTED_LIST,
  ADD_SELECTED_LIST,
  UPDATE_DATA_REFERENCE,
  ADD_SPRAYCHART,
  UPDATE_SPRAYCHART,
  DELETE_SPRAYCHART,
} from './ActionTypes';

const initialState = {
  followingList: [],
  selectedList: [],
  dataPreference: "sasb",
  sprayChartList: [],
};

export default function mylist(state = initialState, action = {}) {
  switch (action.type) {
    case ADD_FOLLOWING_LIST:
      return {
        ...state,
        followingList: [...action.payload],
      };
    case UPDATE_FOLLOWING_LIST:
      return {
        ...state,
        followingList: [...state.followingList, action.payload],
      };
    case DELETE_FOLLOWING_LIST:
      return {
        ...state,
        followingList: [
          ...state.followingList.filter((el) => el.corp_code !== action.payload.corp_code),
        ],
      };
    case ADD_SELECTED_LIST:
      return {
        ...state,
        selectedList: [...action.payload],
      }
    case UPDATE_SELECTED_LIST:
      return {
        ...state,
        selectedList: [...state.selectedList, ...action.payload],
      };
    case DELETE_SELECTED_LIST:
      return {
        ...state,
        selectedList: [
          ...state.selectedList.filter((el) => el.corp_code !== action.payload.corp_code),
        ]
      }
    case RESET_SELECTED_LIST:
      return {
        ...state,
        selectedList: initialState.selectedList,
      }
    case UPDATE_DATA_REFERENCE:
      return {
        ...state,
        dataPreference: action.payload
      }
    case ADD_SPRAYCHART:
      return {
        ...state,
        sprayChartList: [
          action.payload, ...state.sprayChartList,
        ]
      }
    case UPDATE_SPRAYCHART:
      return {
        ...state,
        sprayChartList: action.payload
      }
    case DELETE_SPRAYCHART:
      return {
        ...state,
        sprayChartList: [
          ...state.sprayChartList.filter((el) => el.corp_code !== action.payload.corp_code)
        ]
      }
    default:
      return state;
  }
}
