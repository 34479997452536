import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
`;

export const RadioWrapper = styled.label`
  display: inline-block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const HiddenRadio = styled.input`
  opacity: 0;
  height: 0;
  width: 0;
  position: absolute;
`;

export const Radio = styled.span`
  position: absolute;
  top: calc(50% - 10px);
  left: 0;
  width: 16px;
  height: 16px;
  border: ${(props) =>
    props.disabled
      ? 'solid 2px #BAC9D4'
      : props.checked
      ? `solid 2px ${COLORS.Primary500}`
      : 'solid 2px #515562'};
  border-radius: 50%;

  :hover {
    background: ${(props) => (props.disabled ? '' : props.checked ? `` : '#BAC9D450')};
  }
`;

export const InnerCircle = styled.span`
  position: absolute;
  top: calc(50% - 6px);
  left: 2px;
  width: 12px;
  height: 12px;
  background-color: ${(props) => (props.disabled ? '' : props.checked ? COLORS.Primary500 : '')};
  border-radius: 50%;
  transition: all 150ms;
  background-repeat: no-repeat;
  background-position: center center;
`;
