import { ListWrapper, ItemWrapper, IndustryRankWrapper } from '../ESGIndustryRiskStyles';
import IndustryRank from 'ui/listItem/industryRank/IndustryRank';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Nodata from 'ui/errorSigns/noData/Nodata';
import { useTranslation } from 'react-i18next';

const CompanyList = ({ data, addFollowing, cancelFollowing, followingList }) => {
  const navigate = useNavigate();
  const language = useSelector((state) => state.user.language);
  const [t] = useTranslation('noData');

  return (
    <ListWrapper>
      {data.length > 0 ? (
        <>
          {data.map((el, i) => (
            <ItemWrapper key={i}>
              <IndustryRankWrapper>
                <IndustryRank
                  type="pulse"
                  companyName={el[`comp_name_${language}`]}
                  score={el.score.toFixed(2)}
                  onClick={() => navigate(`company/${el.corp_code}`)}
                  rank={i + 1}
                  onAdd={() => addFollowing(el.corp_code)}
                  onCancel={() => cancelFollowing(el.corp_code)}
                  isSelected={followingList.find((company) => company.corp_code === el.corp_code)}
                  key={i}
                />
              </IndustryRankWrapper>
            </ItemWrapper>
          ))}
        </>
      ) : (
        <Nodata desc={t('No data.')} />
      )}
    </ListWrapper>
  );
};

export default CompanyList;
