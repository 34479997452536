import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div`
  display: ${(props) => (props.isOpen ? 'inline-block' : 'none')};
  padding: 4px;
  border-radius: 4px;
  background: ${COLORS.Text400};
  position: fixed;
  top: ${(props) => props.y + 'px'};
  left: ${(props) => props.x + 'px'};
`;
