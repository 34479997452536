import CardTitle from 'ui/title/Title';
import Card from 'ui/card/defaultCard/Card';
import ESGTopicTrendCompanyLists from './ESGTopicTrendLists/ESGTopicTrendLists';
import { CompanyListsWrapper } from './ESGTopicTrendStyles';
import Preloaer from 'ui/preloaders/componentPreloader/ComponentPreloader';
import { useTranslation } from 'react-i18next';
import Nodata from 'ui/errorSigns/noData/Nodata';

const ESGTopicTrend = ({ data, isLoading, sx }) => {
  const [t] = useTranslation('home');
  const [dT] = useTranslation('noData');

  return (
    <Card sx={{ ...sx }}>
      <CardTitle
        title={t('ESG Topic Trend')}
        desc={[
          t(
            'We provide company-specific exposures to sustainability-related ESG risks over the past seven days.',
          ),
          t(
            'ESG topics are automatically classified and updated daily in alignment with the 26 SASB standards under IFRS.',
          ),
        ]}
      />
      <CompanyListsWrapper>
        {isLoading ? (
          <Preloaer width="1174px" height="568px" />
        ) : (
          <>
            {data.length > 0 ? (
              <ESGTopicTrendCompanyLists trends={data} />
            ) : (
              <Nodata desc={dT('No data.')} sx={{ height: '150px' }} />
            )}
          </>
        )}
      </CompanyListsWrapper>
    </Card>
  );
};

export default ESGTopicTrend;
