import { COLORS } from 'styles/colorTheme';
import { Container, Wrapper } from './PrimaryTapStyles';
import Typo from 'ui/typo/Typo';
import { useRef, useEffect, useState } from 'react';

const PrimaryTap = ({
  children,
  isActive = false,
  color = COLORS.Text400,
  maxWidth,
  margin = '0px',
  onClick,
  sx,
}) => {
  const refEL = useRef();
  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    const instance = refEL.current;

    instance.addEventListener('mouseover', () => {
      setIsHover(true);
    });

    instance.addEventListener('mouseleave', () => {
      setIsHover(false);
    });

    return () => {
      instance.removeEventListener('mouseover', () => {
        setIsHover(true);
      });

      instance.removeEventListener('mouseleave', () => {
        setIsHover(false);
      });
    };
  }, []);

  return (
    <Container ref={refEL} isActive={isActive} style={{ ...sx }} onClick={onClick} margin={margin}>
      <Wrapper maxWidth={maxWidth}>
        <Typo variant="h3" color={isActive || isHover ? COLORS.Primary500 : color}>
          {children}
        </Typo>
      </Wrapper>
    </Container>
  );
};

export default PrimaryTap;
