import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { COLORS } from 'styles/colorTheme';
import { SASB_TAG_ID_ICON_MAP } from 'data/sasbTagIconMap';
import { useEffect } from 'react';
import _ from 'lodash';

export default function TimelineChart({ data, selectedTags, onClick = () => {} }) {
  const [options, setOptions] = useState({});

  const [tChart] = useTranslation('chart');
  const language = useSelector((state) => state.user.language);

  let nfrMin = Number.MAX_SAFE_INTEGER;
  let nfrMax = Number.MIN_SAFE_INTEGER;

  data?.nfrData?.forEach((el, i) => {
    if (el[1] < nfrMin) nfrMin = el[1];
    if (el[1] > nfrMax) nfrMax = el[1] + 1;
  });
  const _options = {
    chart: {
      type: 'areaspline',
      zoomType: 'x',
      resetZoomButton: {
        theme: {
          fill: '#f1f3f4',
          stroke: 'none',
          r: 12,
          states: {
            hover: {
              fill: '#e4f2fd',
              style: {
                color: 'white',
              },
            },
          },
        },
      },
    },
    title: {
      text: '',
      align: 'center',
    },
    xAxis: {
      type: 'datetime',
      lineWidth: 1,
      lineColor: '#C5CFDD',
      tickWidth: 0,
      labels: {
        style: {
          fontSize: '12px',
          color: '#515562',
        },
        formatter: function () {
          return moment(this.value).format('YY/MM/DD');
        },
      },
      crosshair: {
        color: '#78899F',
        dashStyle: 'LongDash',
      },
    },
    yAxis: {
      title: '',
      labels: {
        style: {
          fontSize: '12px',
          color: '#646876',
        },
        formatter: function () {
          return this.value.toFixed(2);
        },
      },
      opposite: true,
      min: nfrMin,
      max: nfrMax,
    },
    legend: { enabled: false },
    tooltip: {
      enabled: true,
      crosshair: true,
      shared: true,
      useHTML: true,
      borderRadius: 4,
      padding: 12,
      shadow: {
        offsetX: 0,
        offsetY: 3,
        opacity: 0.03,
      },
    },

    plotOptions: {
      series: {
        states: {
          hover: {
            halo: null,
          },
        },
      },
      areaspline: {
        lineWidth: 2.0,
        animation: false,
        marker: {
          enabled: false,
          radius: 5,
          symbol: 'circle',
          states: {
            hover: {
              enabled: true,
              lineWidth: 0,
            },
          },
        },
      },
    },
    credits: {
      enabled: false,
    },
  };

  const series = [
    {
      id: 'nfr',
      name: 'Nfr',
      data: data.nfrData,
      color: COLORS.Secondary500,
      tooltip: {
        followPointer: true,
        xDateFormat: '%Y.%m.%d',
        headerFormat: `{point.key}`,
        pointFormatter: function () {
          return `
            <div style="margin-top:10px;display:flex;align-items: center;gap: 4px;">
              <div style="width: 8px;height: 8px;background-color:${
                COLORS.Secondary500
              }; border-radius: 50%;"></div>
              <div>${tChart('ESG risk')}: <span style="margin-left: 8px;">${this.y.toFixed(
            2,
          )}</span></div>
            </div>
          `;
        },
      },
      fillColor: {
        linearGradient: [0, 0, 0, 300],
        stops: [
          [0, 'rgba(254, 156, 8, 0.2)'],
          [1, 'rgba(254, 156, 8, 0.0)'],
        ],
      },
    },
  ];

  const getOption = (options, series) => {
    const newOption = _.cloneDeep(options);
    const arr = _.cloneDeep(series);

    data.flagGroups.forEach((flags) => {
      if (!selectedTags.has(flags[0].sasb_tag_id)) return;
      arr.push({
        type: 'flags',
        name: 'events',
        y: -22,
        shape: `url(${SASB_TAG_ID_ICON_MAP[flags[0]['sasb_tag_id']].iconUri})`,
        data: flags,
        onSeries: 'nfr',
        cursor: 'pointer',
        events: {
          click: function (event) {
            onClick(event.point);
          },
        },
        tooltip: {
          headerFormat: '',
          distance: 18,
          pointFormatter: function () {
            return `
                <div>
                  <div style="font-size:14px;color: ${COLORS.Text300}; margin-bottom: 6px;">
                    ${this[`sasb_tag_label_${language}`]}
                  </div>
                  <div style="font-size:10px;color: ${COLORS.Text200}; margin-bottom: 2px;">
                    ${this.sasb_tag_id}
                  </div>
                  <div style="font-size:10px;color: ${COLORS.Text200}">
                    ${this.title_}
                  </div>
                </div>
              `;
          },
        },
      });
    });

    newOption.series = arr;

    return newOption;
  };

  useEffect(() => {
    const newOption = getOption(_options, series);
    setOptions(newOption);
  }, [selectedTags, language]);

  if (!data.nfrData || data.nfrData.length <= 0) return null;

  return <HighchartsReact highcharts={Highcharts} options={options} immutable={true} />;
}
