import Typo from 'ui/typo/Typo';
import { Container, Dot, LabelWrapper } from './LegendItemStyles';
import LegendCheckbox from 'ui/checkbox/legendCheckbox/LegendCheckbox';
import { COLORS } from 'styles/colorTheme';

const LegendItem = ({ color, children, onClick, isSelected = false, sx }) => {
  return (
    <Container style={{ ...sx }} onClick={onClick}>
      <LabelWrapper>
        <Dot color={color} />
        <Typo variant="b1" color={COLORS.Text400}>
          {children}
        </Typo>
      </LabelWrapper>
      <LegendCheckbox isSelected={isSelected} />
    </Container>
  );
};

export default LegendItem;
