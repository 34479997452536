import { Container, Icon } from './PlusStyles';
import check from 'assets/check_green.svg';

const Plus = ({ onClick, isSelected = false, sx }) => {
  const handleClick = (e) => {
    onClick();
    e.stopPropagation();
  };

  return (
    <Container style={sx} onClick={handleClick}>
      {isSelected ? (
        <Icon src={check} alt="selected" />
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            className="path"
            d="M19 12H5"
            stroke="#8C909F"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            className="path"
            d="M12 19L12 5"
            stroke="#8C909F"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      )}
    </Container>
  );
};

export default Plus;
