import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.button`
  border: none;
  cursor: pointer;
  text-align: center;

  padding-top: ${(props) => props.py};
  padding-bottom: ${(props) => props.py};
  padding-left: ${(props) => props.px};
  padding-right: ${(props) => props.px};
  border-radius: 30px;
  display: inline-block;
  width: 'fit-content';
  background: ${(props) => (props.selected ? props.color : '#ffffff')};

  :hover {
    background: ${(props) => props.color};
  }
  :active {
    background: ${COLORS.Background300};
  }
`;

export const TextWrapper = styled.div`
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : 'none')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
