import { useEffect } from 'react';
import Tabs from 'components/analyticsPage/tabs';
import Wrapper from 'ui/wrapper';
import SubCard from 'ui/card/subCard';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import TabPanels from 'components/analyticsPage/tabPanels/TabPanels';
import { getAnalyticsTabList } from 'api/analytics';
import { saveAnalyticsTabList } from 'services/analytics/Action';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTabs } from 'components/analyticsPage/tabs/components/tabItems/hooks/useTabs';
import useAmplitude from 'hooks/useAmplitude';

const Analytics = () => {
  useAmplitude('analytics');
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const fetchTabList = async () => {
    const results = await getAnalyticsTabList(user.userId);
    dispatch(saveAnalyticsTabList(results.data));
  };

  const location = useLocation();
  const [selectTab] = useTabs();
  const { tabList } = useSelector((state) => state.analytics);

  useEffect(() => {
    fetchTabList();
    if (location?.state) {
      selectTab(tabList.length);
    }
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      <Wrapper className="content-wrapper">
        <SubCard
          sx={{
            px: 8,
            py: 1,
            minHeight: '86vh',
            '@media(max-width: 768px)': { padding: '30px 0px' },
          }}
        >
          <Tabs />
          <TabPanels />
        </SubCard>
      </Wrapper>
    </DndProvider>
  );
};

export default Analytics;
