export const ADD_FOLLOWING_LIST = 'ADD_FOLLOWING_LIST';
export const UPDATE_FOLLOWING_LIST = 'UPDATE_FOLLOWING_LIST';
export const DELETE_FOLLOWING_LIST = 'DELETE_FOLLOWING_LIST';
export const UPDATE_SELECTED_LIST = 'UPDATE_SELECTED_LIST';
export const DELETE_SELECTED_LIST = 'DELETE_SELECTED_LIST';
export const RESET_SELECTED_LIST = 'RESET_SELECTED_LIST';
export const ADD_SELECTED_LIST = 'ADD_SELECTED_LIST';
export const UPDATE_DATA_REFERENCE = 'UPDATE_DATA_REFERENCE';
export const ADD_SPRAYCHART = 'ADD_SPRAYCHART';
export const UPDATE_SPRAYCHART = 'UPDATE_SPRAYCHART';
export const DELETE_SPRAYCHART = 'DELETE_SPRAYCHART';