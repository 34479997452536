import { Component } from 'react';
import { Card, CardHeader, Typography, CardContent, Divider } from '@mui/material';
import { COLORS } from 'styles/colorTheme';

class SubCard extends Component {
  render() {
    const {
      children,
      content,
      contentClass,
      darkTitle,
      secondary,
      sx = {},
      contentSX = {},
      title,
      ref,
      ...others
    } = this.props;

    return (
      <Card
        ref={ref}
        sx={{
          border: `1px solid`,
          borderColor: COLORS.Background100,
          margin: '20px',
          boxShadow: 'none',
          borderRadius: '12px',
          ...sx,
        }}
        {...others}
      >
        {/* card header and action */}
        {!darkTitle && title && (
          <CardHeader
            sx={{ p: 2.5 }}
            title={<Typography variant="subtitle1">{title}</Typography>}
            action={secondary}
          />
        )}
        {darkTitle && title && (
          <CardHeader
            sx={{ p: 2.5 }}
            title={<Typography variant="subtitle1">{title}</Typography>}
            action={secondary}
          />
        )}

        {/* content & header divider */}
        {title && <Divider />}
        {/* card content */}
        {content && (
          <CardContent sx={{ p: 2.5, ...contentSX }} className={contentClass || ''}>
            {children}
          </CardContent>
        )}
        {!content && children}
      </Card>
    );
  }
}

export default SubCard;
