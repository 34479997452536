import {
  Container,
  TitleWrapper,
  TagWrapper,
  Title,
  CompanyTagWrapper,
  TopicTagWrapper,
  TitleImgContainer,
  Img,
  FeedbacWrapper,
} from './NewsCardSmall.styles';
import Typo from 'ui/typo/Typo';
import { COLORS } from 'styles/colorTheme';
import Tag from 'ui/tag/Tag';
import { useSelector } from 'react-redux';
import FeedbackButton from 'ui/buttons/feedbackButton/FeedbackButton';
import useFeedback from 'hooks/useFeedback';
import { useTranslation } from 'react-i18next';
import { Snackbar, Alert } from '@mui/material';
import { useState } from 'react';

const NewsCardSmall = ({ article, sx }) => {
  const handleClick = () => window.open(link, '_blank');
  const language = useSelector((state) => state.user.language);
  const {
    image_: img,
    title: title,
    press: src,
    time: date,
    original_link: link,
    companies: companies,
    esg_topics: topics,
    count_of_cluster: cluster,
  } = article;
  const user = useSelector((state) => state.user.info);
  const [snackbar, setSnackbar] = useState(false);
  const [up, down] = useFeedback(user, article, () => setSnackbar(true));
  const [t] = useTranslation('news');

  return (
    <Container style={sx}>
      <TitleImgContainer>
        <TitleWrapper>
          <Title onClick={handleClick}>
            <Typo variant="h3" color={COLORS.Text500}>
              {title}
            </Typo>
          </Title>

          <Typo variant="overline1" color={COLORS.Text100}>
            {src} | {date.slice(0, 10)} | {t('Related')} ({cluster})
          </Typo>
        </TitleWrapper>

        <Img src={img} alt="img" />
      </TitleImgContainer>

      <TagWrapper>
        <CompanyTagWrapper>
          {companies.map((company, i) => (
            <Tag variant="company" key={i}>
              {company[`comp_name_${language}`]}
            </Tag>
          ))}
        </CompanyTagWrapper>

        <TopicTagWrapper>
          {topics.map((topic, i) => (
            <Tag key={i}>{topic[`general_issue_name_${language}`]}</Tag>
          ))}
        </TopicTagWrapper>
      </TagWrapper>
      <FeedbacWrapper className="feedback">
        <FeedbackButton onClickThumbsDown={down} onClickThumbsUp={up} />
      </FeedbacWrapper>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={() => setSnackbar(false)}
        open={snackbar}
      >
        <Alert onClose={() => setSnackbar(false)} severity="success" sx={{ width: '100%' }}>
          {t('Thank you for your feedback.')}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default NewsCardSmall;
