import NewsBlockTitle from 'ui/listItem/newsBlockTitle/NewsBlockTitle';
import { Container, Head, Img, Body } from './VerticalNewsFrame.styles';
import NewsCardText from '../text/NewsCardText';

const VerticalNewsFrame = ({ title, articles = [], onClick }) => {
  return (
    <Container>
      <Head>
        <NewsBlockTitle title={title} onClick={onClick} />
        <Img src={articles[0]?.image_} alt="img" />
      </Head>
      <Body>
        {articles.map((article, i) => (
          <NewsCardText key={i} article={article} />
        ))}
      </Body>
    </Container>
  );
};

export default VerticalNewsFrame;
