import { Stack, Checkbox } from '@mui/material';
import { useFilters } from 'components/analyticsPage/tabPanels/components/drawers/hooks/useFilters';
import _ from 'lodash';

const Filters = ({ filters, setQuery, originalFilter = [] }) => {
  const [getFilters] = useFilters();

  const handleClick = (el) => {
    const result = getFilters(originalFilter, el);
    setQuery({ filters: result });
  };

  return (
    <>
      {filters?.map((el, i) => (
        <div key={`filters_${i}`}>
          <Checkbox checked={el.check} onClick={() => handleClick(el)} />
          {el.category}
          <Stack ml={4}>
            {el.subCategory ? (
              <Filters
                filters={el.subCategory}
                setQuery={setQuery}
                originalFilter={originalFilter}
              />
            ) : null}
          </Stack>
        </div>
      ))}
    </>
  );
};

export default Filters;
