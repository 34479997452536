import { media } from 'media';
import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div`
  display: flex;
  width: 575px;
  padding: 10px;
  flex-direction: column;
  gap: 8px;
  box-sizing: border-box;
  position: relative;
  border-radius: 5px;

  :hover {
    background: ${COLORS.Background50};
    & .feedback {
      visibility: visible;
      opacity: 1;
    }
  }

  ${media.mobile`
    width: 100%;
  `}
`;

export const TitleWrapper = styled.div`
  width: 401px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-self: stretch;
`;

export const Title = styled.div`
  word-break: break-all;
  cursor: pointer;
  :hover {
    text-decoration-line: underline;
  }
`;

export const TagWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const CompanyTagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const TopicTagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const TitleImgContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: stretch;
`;

export const Img = styled.img`
  object-fit: cover;
  border-radius: 2px;
  width: 142px;
  height: 80px;
`;

export const FeedbacWrapper = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
  visibility: hidden;
  opacity: 0;

  transition: all 0.3s;
`;
