import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ComponentPreloader from 'ui/preloaders/componentPreloader/ComponentPreloader';
import IndustryModal from 'ui/modals/industryModal/IndustryModal';
import ModalContainer from 'ui/modals/modalContainer/ModalContainer';
import NewsCardSmall from 'ui/card/newsCards/image/newsCardSmall/NewsCardSmall';
import PrimaryMenu from 'ui/menus/primaryMenu/PrimaryMenu';
import Pagination from 'ui/pagination/Pagination';
import SelectModalButton from 'ui/buttons/selectModalButton/SelectModalButton';
import {
  CardWrapper,
  Container,
  SelectIndustryWrapper,
  MenuWrapper,
  NewsWrapper,
} from './IndustryNewsStyles';
import Nodata from 'ui/errorSigns/noData/Nodata';
import gsap from 'gsap';

const IndustryNews = ({
  industries,
  industryIdx,
  articles,
  totalN,
  page,
  handlePage,
  handleMenu,
  isLoading,
  sx,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [t] = useTranslation('news');
  const [dT] = useTranslation('noData');
  const language = useSelector((state) => state.user.language);

  const [menuOffsetList, setMenuOffsetList] = useState(0);

  useEffect(() => {
    if (openModal) {
      const body = document.querySelector('body');
      body.style.overflow = 'hidden';
    } else {
      const body = document.querySelector('body');
      body.style.overflow = null;
    }
  }, [openModal]);

  const ref = useRef(null);
  const menuRef = useRef(null);

  useEffect(() => {
    if (!menuRef) return;
    const children = menuRef.current?.children;
    const list = [];
    let left = 0;
    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      list.push({ offsetLeft: left, offsetWidth: child.offsetWidth });
      left += child.offsetWidth;
    }
    if (ref) {
      gsap.to(ref.current, {
        scrollLeft:
          list[industryIdx].offsetLeft -
          ref.current.offsetWidth / 2 +
          list[industryIdx].offsetWidth / 2,
      });
    }
    setMenuOffsetList(list);
  }, [menuRef, language]);

  const handleApply = (industryIdx) => {
    if (ref) {
      gsap.to(ref.current, {
        scrollLeft:
          menuOffsetList[industryIdx].offsetLeft -
          ref.current.offsetWidth / 2 +
          menuOffsetList[industryIdx].offsetWidth / 2,
      });
    }
    handleMenu(industryIdx);
  };

  return (
    <Container style={{ ...sx }}>
      <MenuWrapper ref={ref}>
        <PrimaryMenu
          ref={menuRef}
          sx={{ flexShrink: 0 }}
          itemMaxWidth={'250px'}
          menuList={industries.map((el) => el[`IndustryName_${language}`])}
          activeIndex={industryIdx}
          onClick={(index) => handleMenu(index)}
        />
      </MenuWrapper>

      <SelectIndustryWrapper>
        <SelectModalButton onClick={() => setOpenModal(!openModal)} title={t('Select Industry')} />
      </SelectIndustryWrapper>

      {!isLoading ? (
        <>
          <NewsWrapper>
            {articles.map((article, i) => (
              <CardWrapper key={i}>
                <NewsCardSmall article={article} />
              </CardWrapper>
            ))}
          </NewsWrapper>
          {articles.length === 0 ? <Nodata desc={dT('No ESG news.')} /> : null}
        </>
      ) : (
        <ComponentPreloader width="1206px" height="300px" />
      )}

      <ModalContainer visible={openModal} onClose={() => setOpenModal(false)}>
        <IndustryModal
          current={industryIdx}
          apply={handleApply}
          close={() => setOpenModal(!openModal)}
        />
      </ModalContainer>

      {!isLoading && (
        <Pagination
          totalPage={Math.ceil(totalN / 20)}
          currentPage={page}
          step={5}
          onClick={handlePage}
        />
      )}
    </Container>
  );
};

export default IndustryNews;
