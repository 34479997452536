import styled from 'styled-components';

export const MainCardStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '12px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '@media(max-width: 768px)': {
    width: '90%',
    overflow: 'auto',
  },
};

export const ContentsWrapper = styled.section`
  padding: 24px 24px 12px 24px;
`;

export const ButtonContainer = styled.section`
  display: flex;
  padding: 12px 24px;
  justify-content: flex-end;
`;

export const CloseButtonWrapper = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
`;
