import Typo from 'ui/typo/Typo';
import {
  Container,
  TitleWrapper,
  CardsWrapper,
  CardWrapper,
  NoData,
} from './SimilarityCardsStyles';
import { COLORS } from 'styles/colorTheme';
import QuestionIcon from 'ui/icon/questionIcon/QuestionIcon';
import ThirdCard from 'ui/card/thirdCard/ThirdCard';
import { useSelector } from 'react-redux';

const SimilarityCards = ({ title, desc, cards = [], addCompanyList, sx }) => {
  const language = useSelector((state) => state.user.language);

  const handleClick = (card) => {
    addCompanyList({
      corp_code: card.corp_code,
      comp_name_ko: card[`corp_name_ko`],
      comp_name_en: card[`corp_name_en`],
      esg_risk: card.esg_risk,
      esg_risk_change_ratio: card.esg_risk_change_ratio,
    });
  };

  return (
    <Container style={{ ...sx }}>
      <TitleWrapper>
        <Typo variant="h2" color={COLORS.Text500}>
          {title}
        </Typo>
        {/* <QuestionIcon desc={desc} /> */}
      </TitleWrapper>
      {cards.length > 0 ? (
        <CardsWrapper>
          {cards?.map((card, i) => (
            <CardWrapper key={i}>
              <ThirdCard
                companyId={card.stock_code}
                marketName={card[`stock_market_${language}`]}
                companyName={card[`corp_name_${language}`]}
                change={card.esg_risk_change_ratio}
                score={card.esg_risk}
                onClick={() => handleClick(card)}
              />
            </CardWrapper>
          ))}
        </CardsWrapper>
      ) : (
        <NoData>
          <Typo variant="h2" color={COLORS.Text300}>
            No Data
          </Typo>
        </NoData>
      )}
    </Container>
  );
};

export default SimilarityCards;
