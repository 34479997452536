import { COLORS } from 'styles/colorTheme';
import { Container } from './Regular.styles';
import Typo from 'ui/typo/Typo';
import { useRef, useEffect, useState } from 'react';

const SecondaryTap = ({ isActive = false, children, sx, color = COLORS.Text400, onClick }) => {
  const refEL = useRef();
  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    const instance = refEL.current;

    instance.addEventListener('mouseover', () => {
      setIsHover(true);
    });

    instance.addEventListener('mouseleave', () => {
      setIsHover(false);
    });

    return () => {
      instance.removeEventListener('mouseover', () => {
        setIsHover(true);
      });

      instance.removeEventListener('mouseleave', () => {
        setIsHover(false);
      });
    };
  }, []);

  return (
    <Container ref={refEL} isActive={isActive} style={{ ...sx }} onClick={onClick}>
      <Typo variant="h3" color={isHover ? color : isActive ? '#fff' : color}>
        {children}
      </Typo>
    </Container>
  );
};

export default SecondaryTap;
