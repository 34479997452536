import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import TabItem from 'components/analyticsPage/tabs/components/tabItems/components/TabItem';
import RemoveModal from 'components/analyticsPage/tabs/components/RemoveModal';
import TabItems from 'components/analyticsPage/tabs/components/tabItems/TabItems';
import { useSelector } from 'react-redux';
import { useCreateTab } from 'components/analyticsPage/tabs/hooks/useCreateTab';
import { useRemoveModal } from 'components/analyticsPage/tabs/hooks/useRemoveModal';
import { useTranslation } from 'react-i18next';

const CustomTabs = styled(Tabs)({
  marginTop: '10px',
  '& .MuiTabs-indicator': {
    backgroundColor: '#ffffff',
  },
});

const TabsComponent = () => {
  const [t] = useTranslation('analytics');
  const { tabList } = useSelector((state) => state.analytics);
  const [createTab] = useCreateTab();
  const [modalState, handleModal] = useRemoveModal();

  return (
    <>
      <CustomTabs value={false} variant="scrollable" scrollButtons allowScrollButtonsMobile>
        <TabItems handleModal={handleModal} />
        <TabItem
          icon={<ControlPointIcon />}
          iconPosition="start"
          label={t('Create')}
          idx={tabList.length}
          display="none"
          onClick={createTab}
        />
      </CustomTabs>
      <RemoveModal modalState={modalState} handleModal={handleModal} />
    </>
  );
};

export default TabsComponent;
