import CompanyItem from '../../../listItem/companyItem/CompanyItem';
import { Container } from './CompanyListStyles';

const CompanyList = ({ data }) => {
  return (
    <Container>
      {data.map((el, i) => (
        <CompanyItem data={el} key={i} />
      ))}
    </Container>
  );
};

export default CompanyList;
