import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div``;

export const PopupWrapper = styled.div`
  position: absolute;
`;

export const CompanyListWrapper = styled.div`
  cursor: pointer;
  border-radius: ${(props) => (props.isLast ? '0px 0px 20px 20px' : '0px')};
  display: flex;
  gap: 12px;
  padding: 12px 20px;
  align-items: center;
  :hover {
    background-color: ${COLORS.Background50};
  }
  &.focus {
    background-color: ${COLORS.Background50};
  }
`;
export const CompanyNameWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
