import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { COLORS, GRAPH1 } from 'styles/colorTheme';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Nodata from 'ui/errorSigns/noData/Nodata';
import { useTranslation } from 'react-i18next';

const PieChart = ({ title, width = 900, height = 280, data }) => {
  const isAllNull = (valueArr) => {
    if (!valueArr) return true;
    for (let i = 0; i < valueArr.length; i++) {
      const element = valueArr[i];
      if (element || element !== 0) return false;
    }
    return true;
  };

  const [idx, setIndex] = useState(-1);
  const language = useSelector((state) => state.user.language);
  const [isData, setIsData] = useState(false);
  const [t] = useTranslation('noData');

  useEffect(() => {
    const allNull = isAllNull(data?.values);
    setIsData(allNull);
  }, [data]);

  const data_ = data?.ratios?.map((el, i) => {
    return [data[`dimension_names_${language}`][i], el];
  });

  const selectIdx = (event) => {
    setIndex(event.point.index);
  };

  const options = {
    chart: {
      type: 'pie',
      height: height,
      width: width,
    },
    title: {
      text: title,
      align: 'center',
    },
    subtitle: {
      useHTML: true,
      text:
        idx >= 0
          ? `<div style="width:120px;text-align:center;">${data.ratios[idx]?.toFixed(2)}%</div>`
          : null,
      align: 'left',
      verticalAlign: 'middle',
      x: width / 2.8 - 60,
      textAnchor: 'middle',
      style: {
        fontSize: '1.8em',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: 'normal',
        color: COLORS.Text300,
      },
    },
    legend: {
      layout: 'vertical',
      verticalAlign: 'middle',
      itemMarginTop: 4,
      itemMarginBottom: 4,
      x: width * 0.25,
      symbolHeight: 12,
      symbolWidth: 12,
      itemStyle: {
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        color: COLORS.Text400,
      },
    },
    tooltip: { enabled: false },
    plotOptions: {
      pie: {
        center: ['35%', '50%'],
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: { enabled: false },
        showInLegend: true,
        size: '90%',
        innerSize: '70%',
        borderWidth: 3,
        borderRadius: '10%',
        states: {
          hover: {
            enabled: true,
            brightness: 0,
            halo: null,
          },
        },
        events: {
          click: selectIdx,
        },
      },
    },
    colors: GRAPH1,
    series: [
      {
        name: 'PieChart',
        data: data_,
      },
    ],
    credits: {
      enabled: false,
    },
  };

  return (
    <>
      {isData ? (
        <Nodata desc={t('No data.')} sx={{ height: '280px', width: '100%' }} />
      ) : (
        <HighchartsReact highcharts={Highcharts} options={options} />
      )}
    </>
  );
};

export default PieChart;
