import axiosInstance from 'api/apiInterceptor';

export const getBasicDataAnalytics = async (data) => {
  return await axiosInstance.get(`/analytics/${data.corp_code}/data?${data.filters}`);
};

export const getAdvancedDataAnalytics = async (data) => {
  return await axiosInstance.get(`/analytics/${data.corp_code}/data-timeseries?${data.filters}`);
};

export const getStockDataAnalytics = async (data) => {
  return await axiosInstance.get(`/analytics/${data.corp_code}/stock-data?${data.filters}`);
};

export const getMultiIndexAnalytics = async (data) => {
  return await axiosInstance.get(`/analytics/${data.corp_code}/index-multiple?${data.filters}`);
};

export const getSingleIndexAnalytics = async (data) => {
  return await axiosInstance.get(`/analytics/${data.corp_code}/index-single?${data.filters}`);
};

export const getSingleAnalyticsTabInfo = async (filters) => {
  return await axiosInstance.get(
    `/tabs/read?id=${filters.tabId}&tab_name=${filters.tabName}&user_id=${filters.userId}`,
  );
};

export const createAnalytics = async (filters) => {
  const body = {
    tab_name: filters.tabName,
    user_id: filters.userId,
    corp_code: filters.corpCode,
    tab_type: filters.tabType,
    filters: filters.filters,
  };
  return await axiosInstance.post(`/tabs/create`, body);
};

export const updateAnalytics = async (filters) => {
  const body = {
    tab_name: filters.tabName,
    user_id: filters.userId,
    corp_code: filters.corpCode,
    tab_type: filters.tabType,
    filters: filters.filters,
  };
  return await axiosInstance.put(`/tabs/update`, body, {
    params: {
      id: filters.tabId,
      tab_name: filters.prevName,
      user_id: filters.userId,
    },
  });
};

export const deleteAnalytics = async (filters) => {
  return await axiosInstance.delete(`/tabs/delete`, {
    params: {
      id: filters.tabId,
      user_id: filters.userId,
    },
  });
};

export const changeAnalyticsTabOrder = async (filters) => {
  const body = {
    tab_order: filters.tabOrder,
  };

  return await axiosInstance.put(`/tabs/update-order?user_id=${filters.userId}`, body);
};

export const getAnalyticsTabList = async (userId) => {
  return await axiosInstance.get(`/tabs/list?&user_id=${userId}`);
};
