import Typo from 'ui/typo/Typo';
import {
  Container,
  Wrapper,
  NameWrapper,
  DataWrapper,
  RankContainer,
  NameContainer,
  Data,
  Img,
} from './IndustryRankStyles';
import Plus from 'ui/icon/plus/Plus';
import Score2 from 'ui/caption/score2/Score2';
import pulseImg from 'assets/pulse.svg';
import vectorImg from 'assets/vector.svg';
import exposureImg from 'assets/exposure.svg';
import { COLORS } from 'styles/colorTheme';

const IndustryRank = ({
  type,
  rank,
  companyName,
  score,
  scoreChange,
  onClick,
  onAdd,
  onCancel,
  isSelected,
  sx,
}) => {
  return (
    <Container style={sx}>
      <Wrapper onClick={onClick}>
        <NameContainer>
          <RankContainer>
            <Typo variant="d3" color={COLORS.Primary500}>
              {rank}
            </Typo>
          </RankContainer>
          <NameWrapper>
            <Typo variant="b1" color={COLORS.Text400}>
              {companyName}
            </Typo>
          </NameWrapper>
        </NameContainer>

        <DataWrapper>
          <Data>
            {type === 'pulse' ? <Img src={pulseImg} alt="pulse" /> : null}
            {type === 'exposure' ? <Img src={exposureImg} alt="pulse" /> : null}
            {type === 'vector' ? <Img src={vectorImg} alt="pulse" /> : null}
            <Typo variant="caption1" color={COLORS.Text300}>
              {score}
            </Typo>
          </Data>
          <Score2 change={scoreChange} />
          <Plus onClick={isSelected ? onCancel : onAdd} isSelected={isSelected} />
        </DataWrapper>
      </Wrapper>
    </Container>
  );
};

export default IndustryRank;
