import SecondaryTap from 'ui/taps/secondary/regular/Regular';
import { Container, Wrapper } from './RegularMenu.styles';

const RegularMenu = ({ menuList = [], activeIndex = 0, onClick, sx }) => {
  const handleClick = (index) => {
    onClick(index);
  };

  return (
    <Container style={{ ...sx }}>
      <Wrapper>
        {menuList.map((menu, i) => (
          <SecondaryTap isActive={i === activeIndex} key={i} onClick={() => handleClick(i)}>
            {menu}
          </SecondaryTap>
        ))}
      </Wrapper>
    </Container>
  );
};

export default RegularMenu;
