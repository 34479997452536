import { Container } from './TopStyles';
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

const Top = ({ variant = 'white' }) => {
  const handleClick = () => {
    gsap.to(window, {
      scrollTo: { y: 0, autoKill: false },
      duration: 1,
      ease: 'power2.inOut',
    });
  };

  return (
    <Container variant={variant} onClick={handleClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          className="path"
          d="M6 15L11.4697 9.53033C11.7626 9.23744 12.2374 9.23744 12.5303 9.53033L18 15"
          stroke="#131722"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </Container>
  );
};

export default Top;
