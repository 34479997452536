import { useEffect, useState } from 'react';

const useTags = (events) => {
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [flagGroups, setFlagGroups] = useState([]);
  const [eventMap, setEventMap] = useState({});

  useEffect(() => {
    if (events) {
      const [flagGroups, tagIdSet, eventMap] = mapFlagGroups(events);
      const sortedTags = sortTagsbyId(tagIdSet);
      setFlagGroups(flagGroups);
      setTags(new Set(sortedTags));
      setSelectedTags(new Set(sortedTags));
      setEventMap(eventMap);
    }
  }, [events]);

  const selectTag = (tag) => {
    if (selectedTags.has(tag)) {
      selectedTags.delete(tag);
    } else {
      selectedTags.add(tag);
    }
    setSelectedTags(new Set(selectedTags));
  };

  return [tags, selectedTags, flagGroups, selectTag, eventMap];
};

const mapFlagGroups = (events) => {
  const eventMap = {};
  const tagIdSet = new Set();

  events.forEach((event, i) => {
    event.title = ' ';
    if (tagIdSet.has(event.sasb_tag_id)) {
      eventMap[event.sasb_tag_id].push(event);
    } else {
      tagIdSet.add(event.sasb_tag_id);
      eventMap[event.sasb_tag_id] = [event];
    }
  });

  const flagGroups = [];
  for (let i in eventMap) {
    flagGroups.push(eventMap[i]);
  }

  return [flagGroups, tagIdSet, eventMap];
};

const sortTagsbyId = (tags) => {
  const arr = [...tags];
  arr.sort((a, b) => Number(a.slice(-3)) - Number(b.slice(-3)));
  return new Set(arr);
};

export default useTags;
