import { media } from 'media';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 4px;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  ${media.mobile`
    //had to decrease the width because radio button did not take spaces
    width: 95%;
  `}
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
