import AnalyticsModal from 'components/analyticsPage/analyticsModal';
import { Stack, Typography, Button } from '@mui/material';
import icon01 from 'assets/icon_01.svg';
import icon11 from 'assets/icon_11.svg';
import icon15 from 'assets/icon_15.svg';
import AnalyticsCard from 'components/analyticsPage/tabPanels/components/initiateCTA/components/generatorModal/components/AnalyticsCard';
import { useTranslation } from 'react-i18next';
import * as amplitude from '@amplitude/analytics-browser';

const buttonStyle = {
  width: 'auto',
  backgroundColor: '#267CB2',
  boxShadow: 'none',
  color: '#fff',
  borderRadius: '4px',
  ':hover': {
    backgroundColor: '#267CB2',
    boxShadow: 'none',
  },
};

const GeneratorModal = ({
  open,
  closeModal,
  handleTutorialModal,
  handleDataDrawer,
  handleIndexDrawer,
}) => {
  const handleData = () => {
    amplitude.track('analytics_analysis_create_start', { category: 'data' });
    handleDataDrawer();
    closeModal();
  };

  const handleIndex = () => {
    amplitude.track('analytics_analysis_create_start', { category: 'index' });
    handleIndexDrawer();
    closeModal();
  };

  const [t] = useTranslation('analytics');

  return (
    <AnalyticsModal open={open} handleClose={closeModal}>
      <Stack spacing={2}>
        <Typography textAlign="center" fontWeight="500" fontSize="32px">
          {t('Select an analytics type')}
        </Typography>
        <Stack direction="row" justifyContent="center" flexWrap="wrap">
          <AnalyticsCard
            img={icon01}
            title={t('Create a data table')}
            description="Data analytics provide source data so that users can directly analyze corporate financial and non-financial data in the form of cross-sections and time series after sophisticated normalization and standardization."
            openAnalyticsDrawer={handleData}
          />
          <AnalyticsCard
            img={icon11}
            title={t('Create a chart')}
            description="Index Analytics provide an in-depth analysis function that can analyze companies by ESG topics, and provide long-term and short-term ESG risk indicators."
            openAnalyticsDrawer={handleIndex}
          />
          {/* <AnalyticsCard 
            img={icon15}
            title='Event Analytics'
            description='Event Analytics provide event monitoring functions to analyze ESG-related events and effectively identify ESG topics and risks that specific companies have been exposed to over a specific period of time.'
            openAnalyticsDrawer={}
          /> */}
        </Stack>
        {/* <Stack alignItems='flex-end'>
          <Button onClick={handleTutorialModal} sx={buttonStyle}>Preview the experience</Button>
        </Stack> */}
      </Stack>
    </AnalyticsModal>
  );
};

export default GeneratorModal;
