import { useEffect, useState } from 'react';
import { getCompanyTreemapData } from 'api/dashboard';

const useEsgTree = (corpCodes) => {
  const [esgTreemapData, setEsgTreemapData] = useState();

  useEffect(() => {
    if (!corpCodes) return;
    getDate();
  }, [corpCodes]);

  const getDate = async () => {
    const res = await getCompanyTreemapData(corpCodes);
    if (!res) return undefined;

    const _data = res.data.filter((el) => el.esg_risk_change_ratio);
    setEsgTreemapData(_data);
  };

  return [esgTreemapData];
};

export default useEsgTree;
