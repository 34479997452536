import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import HighchartsTreemap from 'highcharts/modules/treemap';
import { useSelector } from 'react-redux';
HighchartsTreemap(Highcharts);

const TreemapChart = ({ title, data }) => {
  if (!data) return;

  const language = useSelector((state) => state.user.language);

  const getColorValue = (ratio) => {
    if (ratio <= -0.3) return 'rgba(67, 129, 222, 1)';
    else if (ratio <= -0.1) return 'rgba(133, 173, 233, 1)';
    else if (ratio <= -0.03) return 'rgba(205, 222, 249, 1)';
    else if (ratio <= 0) return 'rgba(241, 243, 246, 1)';
    else if (ratio < 0.1) return 'rgba(253, 227, 225, 1)';
    else if (ratio < 0.3) return 'rgba(228, 144, 134, 1)';
    else return 'rgba(222, 111, 98, 1)';
  };

  let series = [];
  data.forEach((el, i) => {
    series.push({
      name: el[`corp_name_${language}`],
      value: Number(el.esg_risk.toFixed(2)),
      color: getColorValue(Number(el.esg_risk_change_ratio.toFixed(2))),
    });
  });

  const options = {
    series: [
      {
        type: 'treemap',
        layoutAlgorithm: 'squarified',
        data: series,
      },
    ],
    plotOptions: {
      treemap: {
        borderWidth: 6,
        borderColor: '#ffffff',
        borderRadius: 10,
        states: {
          hover: {
            borderColor: '#ffffff',
            brightness: 0.03,
          },
        },
        dataLabels: {
          useHTML: true,
          formatter: function (e) {
            const changeRatio = Number(data[this.x].esg_risk_change_ratio.toFixed(2));
            const fontSizeW = Math.min(this.point.shapeArgs.width * (1 / this.key.length), 50);
            return `<div>
                <div 
                  style=
                  "font-size: ${fontSizeW}px;
                  text-align:center;
                  color: #333743;
                  font-family: Mulish;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                  margin-bottom:4px;"
                >
                  ${this.key}
                </div>
                <div style="
                  font-size:clamp(12px, 1rem, 12px);
                  text-align:center;
                  color: #515562;
                  text-align: center;
                  font-family: Mulish;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                  "
                >
                  ${changeRatio > 0 ? '+' : ''}${changeRatio}%
                </div>
              </div>`;
          },
        },
      },
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      backgroundColor: 'none',
      followPointer: true,
      formatter: function () {
        return (
          `
            <p style="
              color:#9ab;
              font-family:Mulish;
              text-align:center;
              margin:0;
              padding: 12px;
              border-radius: 4px;
              border: none;
              background-color: white"
            >` +
          '<strong style="color:#000;font-weight:normal;font-size:16px;">' +
          this.point.name +
          ': ' +
          this.point.value.toFixed(2) +
          '</strong></p>'
        );
      },
    },
    title: { text: title },
    credits: { enabled: false },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default TreemapChart;
