import { media } from 'media';
import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div`
  display: flex;
  justify-content: ${(props) => (props.mode === 'fill' ? 'space-around' : 'none')};
  border-bottom: 1px solid ${COLORS.Background100};

  ${media.mobile`
    overflow: auto;
    justify-content: start;
  `}
`;
