import { useEffect, useState } from 'react';
import { getMainNews } from 'api/news';

const useNews = () => {
  const [articles, setArticles] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    const res = await getMainNews();
    if (!res) {
      setArticles([]);
    } else {
      const mainNews = res.data.main_news;
      setArticles(mainNews);
    }
    setIsLoading(false);
  };

  return [articles, isLoading];
};

export default useNews;
