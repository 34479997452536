import { useEffect } from 'react';
import { convertIndexUritoQueryObj } from 'components/analyticsPage/tabPanels/components/indexAnalysis/hooks/useIndexAnalysis';
import { useSelector } from 'react-redux';
import { useIndexQuery } from 'components/analyticsPage/tabPanels/components/drawers/indexDrawer/hooks/useIndexQuery';

export const useResetIndexDrawer = (trigger) => {
  const analytics = useSelector((state) => state.analytics);
  const data = analytics.tabList[analytics.selectedTab];
  const [setQuery] = useIndexQuery();

  useEffect(() => {
    const getQuery = async () => {
      const query = await convertIndexUritoQueryObj(data, analytics.indexQuery);
      setQuery(query);
    };
    getQuery();
  }, [...trigger]);

  return;
};
