// import { createFollow, deleteFollow } from 'api/company';
import { followCompany, unFollow } from 'api/followings';
import { getFollowList } from 'api/followings';
import { addFollowingList } from 'services/mylist/Actions';
import { useDispatch, useSelector } from 'react-redux';
import * as amplitude from '@amplitude/analytics-browser';

const useFollowCompany = (companyId) => {
  const userId = useSelector((state) => state.user.userId);
  const followingList = useSelector((state) => state.mylist.followingList);
  const dispatch = useDispatch();

  const addFollowing = async (corpCode) => {
    if (followingList && followingList.length > 9) {
      return window.alert('Cannot follow more than 10 companies.');
    }
    const companyId_ = companyId || corpCode;
    const res = await followCompany(companyId_);
    if (!res) return;
    const newFollowing = await getFollowList(userId);
    if (companyId) {
      amplitude.track('companydetail_follow_click', { action: 'follow' });
    } else {
      amplitude.track('home_follow_click', { action: 'follow' });
    }
    dispatch(addFollowingList(newFollowing.data));
  };

  const cancelFollowing = async (corpCode) => {
    const companyId_ = companyId || corpCode;
    const res = await unFollow(companyId_);
    if (!res) return;
    const newFollowing = await getFollowList(userId);
    if (companyId) {
      amplitude.track('companydetail_follow_click', { action: 'unfollow' });
    } else {
      amplitude.track('home_follow_click', { action: 'unfollow' });
    }
    dispatch(addFollowingList(newFollowing.data));
  };

  return [addFollowing, cancelFollowing];
};

export default useFollowCompany;
