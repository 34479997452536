import axiosInstance from './apiInterceptor';

export const getESGTopictTrend = async (limit = 8) => {
  return axiosInstance({
    method: 'GET',
    url: `/home/esg-topic-trend`,
    params: { limit: limit },
  });
};

export const getESGMarketTrend = async (category = 'neg_exposure_raw', limit = 20) => {
  return axiosInstance({
    method: 'GET',
    url: `/home/esg-market-trend`,
    params: { limit, category },
  });
};

export const getESGRiskRankByIndustry = async (industry_code, gic, limit = 10) => {
  if (!industry_code || !gic) return;
  return axiosInstance({
    method: 'GET',
    url: `/home/esg-risk-rank-by-industry`,
    params: { limit, industry_code, gic },
  });
};
