import {
  ADD_FOLLOWING_LIST,
  UPDATE_FOLLOWING_LIST,
  DELETE_FOLLOWING_LIST,
  UPDATE_SELECTED_LIST,
  DELETE_SELECTED_LIST,
  RESET_SELECTED_LIST,
  ADD_SELECTED_LIST,
  UPDATE_DATA_REFERENCE,
  ADD_SPRAYCHART,
  UPDATE_SPRAYCHART,
  DELETE_SPRAYCHART,
} from './ActionTypes';

export const addFollowingList = (list) => ({
  type: ADD_FOLLOWING_LIST,
  payload: list,
});

export const updateFollowingList = (element) => ({
  type: UPDATE_FOLLOWING_LIST,
  payload: element,
});

export const deleteFollowingList = (element) => ({
  type: DELETE_FOLLOWING_LIST,
  payload: element,
});

export const addSelectedList = (element) => ({
  type: ADD_SELECTED_LIST,
  payload: element,
})

export const updateSelectedList = (element) => ({
  type: UPDATE_SELECTED_LIST,
  payload: element,
})

export const deleteSelectedList = (element) => ({
  type: DELETE_SELECTED_LIST,
  payload: element,
})

export const resetSelectedList = () => ({
  type: RESET_SELECTED_LIST,
  payload: null,
})

export const updateDataPreference = (element) => ({
  type: UPDATE_DATA_REFERENCE,
  payload: element,
})

export const addSprayChart = (element) => ({
  type: ADD_SPRAYCHART,
  payload: element,
})

export const updateSprayChart = (element) => ({
  type: UPDATE_SPRAYCHART,
  payload: element
})

export const deleteSprayChart = (element) => ({
  type: DELETE_SPRAYCHART,
  payload: element
})