import { media } from 'media';
import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.button`
  position: fixed;
  bottom: 90px;
  right: 90px;
  width: 48px;
  height: 48px;
  border-radius: 50%;

  background: ${(props) => (props.variant === 'white' ? COLORS.Background100 : '#2F353F')};

  display: flex;
  justify-content: center;
  align-items: center;

  & .path {
    stroke: ${(props) => (props.variant === 'white' ? '#131722' : 'white')};
  }

  ${media.mobile`
    display: none;
  `}
`;
