import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  border-radius: 4px;

  :hover {
    background-color: ${COLORS.Background50};
  }
`;
