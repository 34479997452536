import { SAVE_COMPANY_ID } from './ActionTypes';

const initialState = {
  companyId: -1,
};

export default function company(state = initialState, action = {}) {
  switch (action.type) {
    case SAVE_COMPANY_ID:
      return {
        ...state,
        companyId: action.payload,
      };
    default:
      return state;
  }
}
