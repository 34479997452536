import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.button`
  cursor: pointer;
  border: none;
  background-color: unset;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    .path {
      stroke: ${COLORS.Primary500};
    }
    background-color: rgba(16, 163, 127, 0.05);
  }

  :active {
    background-color: rgba(16, 163, 127, 0.2);
  }
`;

export const Icon = styled.img``;
