import { media } from 'media';
import styled from 'styled-components';

export const ChipListWrapper = styled.div`
  display: flex;
  padding: 0px 10px 0px 8px;
  justify-content: space-between;
  align-items: center;
`;

export const ChipList = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  flex-wrap: wrap;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 1206px;
  width: 100%;
`;

export const TopicTrendWrapper = styled.div``;

export const Stock_MarketTrendWrapper = styled.div``;

export const IndustryRiskWrapper = styled.div``;

export const NewsWrapper = styled.div``;
