import styled from "styled-components"

export const Container = styled.div`
  display: inline-block;
  padding: 4px 8px;
  background-color: ${props => props.background};
  border-radius: 4px;
  border: 1px solid ${props => props.borderColor};
  width: ${props => props.width ? props.width : 'fit-content'};
`

export const Wrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;  
  text-transform: ${props => props.uppercase ? 'uppercase': 'none'};
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`