import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div`
  padding: 16px;
  min-width: 280px;
`;

export const MenuItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 32px;
  padding-left: 16px;
  border-radius: 10px;
  cursor: pointer;
  background-color: ${(props) => (props.isSelected ? COLORS.Background100 : '')};
`;
