import MenuSVG from 'assets/menu.svg';
import { Container } from './SelectModalButton.styles';
import Typo from 'ui/typo/Typo';
import { COLORS } from 'styles/colorTheme';

const SelectModalButton = ({ onClick, title }) => {
  return (
    <Container onClick={onClick}>
      <img alt="menu" src={MenuSVG} />
      <Typo variant="btn2" color={COLORS.Text400}>
        {title}
      </Typo>
    </Container>
  );
};

export default SelectModalButton;
