import { saveIndexQuery } from 'services/analytics/Action';
import { useDispatch, useSelector } from 'react-redux';

export const useMultiIndexQuery = () => {

  const dispatch = useDispatch();
  const indexQuery = useSelector((state) => state.analytics.indexQuery);

  const setQuery = (obj) => {
    dispatch(
      saveIndexQuery({
        ...indexQuery,
        index: {
          ...indexQuery.index,
          multiIndex: {
            ...indexQuery.index.multiIndex,
            ...obj,
          },
        }
      })
    )
  }

  return [setQuery];
};