import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';

const Background = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100vw;
  height: 100vh;
  z-index: 9999;

  display: ${(props) => (props.activate ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;

const ScreenPreloader = ({ activate = true }) => {
  return (
    <Background activate={activate}>
      <Wrapper>
        <CircularProgress size={60} />
      </Wrapper>
    </Background>
  );
};

export default ScreenPreloader;
