import { media } from 'media';
import styled from 'styled-components';

export const TitleWrapper = styled.div`
  padding: 0px 6px;

  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
