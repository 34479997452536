import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

export const InputContainer = styled.div`
  width: 100%;
  max-width: 580px;
  display: flex;
  flex-direction: column;
`;

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ErrorMessage = styled.div`
  font-size: 12px;
  color: ${COLORS.Red200};
  display: flex;
  justify-content: flex-end;
  padding: 4px 0;
`;
