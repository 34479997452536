import { useEffect, useState } from 'react';
import { getCompanyHeatmapData } from 'api/dashboard';

const useEsgRisk = (corpCodes) => {
  const [esgRiskSignalData, setEsgRiskSignalData] = useState();
  const [isRiskSignalLoading, setIsRiskSignalLoading] = useState(true);

  useEffect(() => {
    if (!corpCodes) return;
    getDate();
  }, [corpCodes]);

  const getDate = async () => {
    setIsRiskSignalLoading(true);
    const res = await getCompanyHeatmapData(corpCodes);
    setIsRiskSignalLoading(false);
    if (!res) return undefined;
    setEsgRiskSignalData(res.data);
  };

  return [esgRiskSignalData, isRiskSignalLoading];
};

export default useEsgRisk;
