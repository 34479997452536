import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { resetDataQuery, resetIndexQuery, resetDataQueryFilters, resetIndexQueryFilters } from 'services/analytics/Action';

export const useResetDataDrawer = (trigger) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetDataQuery());
  }, [...trigger])

  return;
}

export const useResetAllDataDrawer = () => {
  const dispatch = useDispatch();

  const resetAllDataDrawer = () => {
    dispatch(resetDataQuery());
  }

  return [resetAllDataDrawer];
}

export const useResetDataFilters = () => {
  const dispatch = useDispatch();

  const resetDataFilters = () => {
    dispatch(resetDataQueryFilters());
  }

  return [resetDataFilters];
}

export const useResetIndexDrawer = (trigger) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetIndexQuery());
  }, [...trigger])
  
  return;
}

export const useResetAllIndexDrawer = () => {
  const dispatch = useDispatch();

  const resetAllIndexDrawer = () => {
    dispatch(resetIndexQuery());
  }

  return [resetAllIndexDrawer];
}

export const useResetIndexFilters = () => {
  const dispatch = useDispatch();

  const resetIndexFilters = () => {
    dispatch(resetIndexQueryFilters());
  }

  return [resetIndexFilters];
}