import { useLocation } from 'react-router-dom';
import { useCompanyName } from 'hooks/useCompanyName';
import { useDispatch } from 'react-redux';
import { saveInputInfo } from 'services/analytics/Action';

export const useInitDrawer = () => {

  const location = useLocation();
  const [getCompanyName] = useCompanyName();
  const dispatch = useDispatch();

  const updateDrawer = async () => {
    if (location?.state?.companyId) {
      const name = await getCompanyName(location.state.companyId);
      dispatch(saveInputInfo({
        companyId: location.state.companyId, 
        companyName: name, 
        tabName: name
      }))
    }
  }

  return [updateDrawer];
}