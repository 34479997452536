import { COLORS } from 'styles/colorTheme';

const DashboardIcon = ({ state = 'mouseup', isSelected = false }) => {
  let color = COLORS.Text400;
  if (state === 'mousedown') color = '#fff';

  return (
    <>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.14209 5C2.14209 4.46957 2.3528 3.96086 2.72788 3.58579C3.10295 3.21071 3.61166 3 4.14209 3H10.1421V21H4.14209C3.61166 21 3.10295 20.7893 2.72788 20.4142C2.3528 20.0391 2.14209 19.5304 2.14209 19V5ZM14.1421 3H20.1421C20.6725 3 21.1812 3.21071 21.5563 3.58579C21.9314 3.96086 22.1421 4.46957 22.1421 5V10H14.1421V3ZM14.1421 14H22.1421V19C22.1421 19.5304 21.9314 20.0391 21.5563 20.4142C21.1812 20.7893 20.6725 21 20.1421 21H14.1421V14Z"
          stroke={color}
          fill={isSelected ? color : ''}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default DashboardIcon;
