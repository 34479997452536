import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Stack } from '@mui/material';
import Tab from '@mui/material/Tab';
import ClearIcon from '@mui/icons-material/Clear';
import { useSelector, useDispatch } from 'react-redux';
import { changeAnalyticsTabOrder } from 'api/analytics';
import { saveAnalyticsTabList } from 'services/analytics/Action';
import _ from 'lodash';
import { COLORS } from 'styles/colorTheme';
import * as amplitude from '@amplitude/analytics-browser';

export const TabItem = ({ icon, iconPosition, label, idx, onClick, openModal }) => {
  const { tabList, selectedTab } = useSelector((state) => state.analytics);
  const { userId } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const ref = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    type: 'tab',
    item: { from: idx, to: null },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: async (item, monitor) => {
      if (item.from === item.to) return;
      if (item.from !== item.to) {
        const arr = _.cloneDeep(tabList);
        let remove = arr.splice(item.from, 1);
        let add = arr.splice(item.to, 0, remove[0]);
        let tabOrder = arr.map((el) => (el = el.id));
        let filters = {
          tabOrder: `[${tabOrder}]`,
          userId: userId,
        };
        const result = await changeAnalyticsTabOrder(filters);
        dispatch(saveAnalyticsTabList(result.data));
        amplitude.track('analytics_tab_order_change');
      }
    },
  });

  const [{ isOver }, drop] = useDrop({
    accept: 'tab',
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
    hover(item, monitor) {
      if (!ref.current) return;
      if (idx === item.index) return;
      if (idx !== item.index) {
        item.to = idx;
      }
    },
  });

  drag(drop(ref));

  return (
    <Stack
      direction="row"
      justifyContent="space-evenly"
      alignItems="center"
      position="relative"
      sx={{
        margin: '0 4px',
        padding: '0 8px',
        textTransform: 'none',
        minHeight: '40px',
        border: selectedTab === idx ? `1px solid ${COLORS.Primary500}` : '1px solid #ffffff',
        borderRadius: selectedTab === idx ? '8px 8px 0 0' : '8px',
        color: selectedTab === idx ? COLORS.Primary500 : 'rgba(0, 0, 0, 0.85)',
        borderBottom: selectedTab === idx ? '1px solid #ffffff' : '',
        backgroundColor:
          selectedTab === idx
            ? isOver
              ? '#F5F5F5'
              : '#ffffff'
            : isOver
            ? COLORS.Background100
            : COLORS.Background50,
        '&:hover': {
          backgroundColor: selectedTab === idx ? '' : COLORS.Background100,
        },
      }}
    >
      <Tab
        ref={label === 'New' ? () => {} : ref}
        disableRipple
        icon={icon}
        iconPosition={iconPosition}
        label={label}
        key={idx}
        onClick={onClick}
        sx={{
          textTransform: 'none',
          minHeight: '40px',
          opacity: selectedTab === idx ? 1 : 0.5,
          '&:hover': {
            color: COLORS.Primary500,
            opacity: 1,
          },
          '&.Mui-focusVisible': {
            backgroundColor: COLORS.Background50,
          },
        }}
      />
      <ClearIcon
        fontSize="4px"
        sx={{
          display: idx === tabList.length ? 'none' : '',
          cursor: 'pointer',
          color: COLORS.Background500,
        }}
        onClick={() => openModal()}
      />
    </Stack>
  );
};

export default TabItem;
