import { Container, ItemWrapper, CompanyList, ChipListWrapper } from './ESGRiskInsights.styles';
import Chip from 'ui/chips/chip/Chip';
import { ChipList } from 'components/homePage/HomeStyles';
import Score from 'ui/listItem/score/Score';
import Preloader from 'ui/preloaders/componentPreloader/ComponentPreloader';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CardTitle from 'ui/title/Title';
import Typo from 'ui/typo/Typo';
import { COLORS } from 'styles/colorTheme';
import Nodata from 'ui/errorSigns/noData/Nodata';

const ESGRiskInsights = ({
  data,
  category,
  handler,
  addFollowing,
  cancelFollowing,
  followingList = [],
  isLoading,
}) => {
  const navigate = useNavigate();
  const [t] = useTranslation('home');
  const [dT] = useTranslation('noData');
  const language = useSelector((state) => state.user.language);
  let type = 'pulse';
  let text = 'One Month';
  if (category === 'neg_exposure_raw') {
    type = 'exposure';
    text = 'One Day';
  }
  if (category === 'nfr_raw_score') {
    type = 'vector';
    text = 'One Year';
  }

  return (
    <Container>
      <CardTitle
        title={t('ESG Risk Insights')}
        desc={[
          t(
            'ESG Exposure quantifies the proportion of entities facing ESG-related controversies within the last day. Pulse offers a short-term risk assessment, quantifying thematic ESG risks over a 30-day period through time-series analysis.',
          ),
          t(
            "Insight aggregates a company's ESG risks over the last 12 months, providing a signal for evaluating longer-term ESG risk trends.",
          ),
        ]}
      />
      {isLoading ? (
        <Preloader width="1174px" height="540px" />
      ) : (
        <>
          <ChipListWrapper>
            <ChipList>
              <Chip
                selected={category === 'neg_exposure_raw'}
                onClick={() => handler('neg_exposure_raw')}
              >
                {t('High Exposures')}
              </Chip>
              <Chip
                selected={category === 'nfr_raw_pulse'}
                onClick={() => handler('nfr_raw_pulse')}
              >
                {t('High Pulse')}
              </Chip>
              <Chip
                selected={category === 'nfr_raw_score'}
                onClick={() => handler('nfr_raw_score')}
              >
                {t('High Score')}
              </Chip>
            </ChipList>
            <div style={{ marginLeft: '10px' }}>
              <Typo variant="b2" color={COLORS.Text100}>
                {t(text)}
              </Typo>
            </div>
          </ChipListWrapper>
          <>
            {data.length > 0 ? (
              <CompanyList>
                <div style={{ width: '100%' }}>
                  {data.slice(0, data.length / 2)?.map((el, i) => (
                    <ItemWrapper key={i}>
                      <Score
                        type={type}
                        companyName={el[`comp_name_${language}`]}
                        lightLineChartData={el.chart_data}
                        score={el.value.toFixed(2)}
                        scoreChange={el.change.toFixed(2)}
                        onClick={() => navigate(`company/${el.corp_code}`)}
                        onAdd={() => addFollowing(el.corp_code)}
                        onCancel={() => cancelFollowing(el.corp_code)}
                        isSelected={followingList.find(
                          (company) => company.corp_code === el.corp_code,
                        )}
                        key={i}
                      />
                    </ItemWrapper>
                  ))}
                </div>
                <div style={{ width: '100%' }}>
                  {data.slice(data.length / 2, data.length)?.map((el, i) => (
                    <ItemWrapper key={i}>
                      <Score
                        type={type}
                        companyName={el[`comp_name_${language}`]}
                        lightLineChartData={el.chart_data}
                        score={el.value.toFixed(2)}
                        scoreChange={el.change.toFixed(2)}
                        onClick={() => navigate(`company/${el.corp_code}`)}
                        onAdd={() => addFollowing(el.corp_code)}
                        onCancel={() => cancelFollowing(el.corp_code)}
                        isSelected={followingList.find(
                          (company) => company.corp_code === el.corp_code,
                        )}
                        key={i}
                      />
                    </ItemWrapper>
                  ))}
                </div>
              </CompanyList>
            ) : (
              <Nodata desc={dT('No data.')} sx={{ height: '150px' }} />
            )}
          </>
        </>
      )}
    </Container>
  );
};

export default ESGRiskInsights;
