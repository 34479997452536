import React, { useState } from 'react';
import PrimaryModalFrame from '../primaryModalFrame/PrimaryModalFrame';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Typo from 'ui/typo/Typo';
import { COLORS } from 'styles/colorTheme';
import CheckBoxList from 'ui/listItem/checkBoxList/CheckBoxList';
import { TitleWrapper, Wrapper } from './FollowingListModal.styles';

export default function FollowingListModal({ folloiwngList = [], onApply, onClose, sx }) {
  const { t } = useTranslation('dashboard');
  const language = useSelector((state) => state.user.language);

  const [list, setList] = useState(new Set(folloiwngList));

  const handleClick = (company) => {
    if (list.has(company)) {
      list.delete(company);
    } else {
      list.add(company);
    }
    setList(new Set(list));
  };

  const handleApply = () => {
    onApply(list);
    onClose();
  };

  return (
    <PrimaryModalFrame header={t('My Watchlist')} onClose={onClose} onApply={handleApply} sx={sx}>
      <TitleWrapper>
        <Typo variant="h3" color={COLORS.Text500}>
          {t('My Watchlist')} ({folloiwngList.length})
        </Typo>
      </TitleWrapper>

      <Wrapper>
        <div style={{ marginTop: '12px' }}>
          {folloiwngList.map((company, i) => (
            <div key={i}>
              <CheckBoxList
                label={company[`corp_name_${language}`]}
                isSelected={list.has(company)}
                onClick={() => handleClick(company)}
              />
            </div>
          ))}
        </div>
      </Wrapper>
    </PrimaryModalFrame>
  );
}
