export const COLORS = {
  Primary500: 'rgba(16, 163, 127, 1)',
  Primary400: 'rgba(18, 186, 144, 1)',
  Primary300: 'rgba(20, 204, 158, 1)',
  Primary200: 'rgba(22, 223, 173, 1)',
  Primary100: 'rgba(51, 235, 189, 1)',
  Primary50: 'rgba(124, 243, 213, 1)',
  Secondary500: 'rgba(254, 156, 8, 1)',
  Secondary300: 'rgba(255, 194, 101, 1)',
  Secondary100: 'rgba(255, 224, 176, 1)',
  Text500: 'rgba(19, 23, 34, 1)',
  Text400: 'rgba(51, 55, 67, 1)',
  Text300: 'rgba(81, 85, 98, 1)',
  Text200: 'rgba(100, 104, 118, 1)',
  Text100: 'rgba(140, 144, 159, 1)',
  Text50: 'rgba(172, 176, 192, 1)',
  Background900: 'rgba(47, 53, 63, 1)',
  Background800: 'rgba(66, 74, 87, 1)',
  Background700: 'rgba(97, 117, 142, 1)',
  Background500: 'rgba(120, 137, 159, 1)',
  Background400: 'rgba(154, 169, 191, 1)',
  Background300: 'rgba(197, 207, 221, 1)',
  Background200: 'rgba(218, 226, 236, 1)',
  Background100: 'rgba(240, 243, 250, 1)',
  Background100_70: 'rgba(240, 243, 250, 0.7)',
  Background50: 'rgba(248, 249, 252, 1)',
  Red300: 'rgba(213, 33, 37, 1)',
  Red200: 'rgba(242, 54, 69, 1)',
  Red100: 'rgba(255, 238, 240, 1)',
  Blue300: 'rgba(29, 77, 225, 1)',
  Blue200: 'rgba(41, 98, 255, 1)',
  Blue100: 'rgba(233, 244, 255, 1)',
  //-------------- legacy colors ----------------
  TagBlue300: 'rgba(25, 118, 210, 1)',
  TagBlue200: 'rgba(25, 118, 210, 0.5)',
  TagBlue100: 'rgba(25, 118, 210, 0.04)',
  TagViolet300: 'rgba(156, 39, 176, 1)',
  TagViolet200: 'rgba(156, 39, 176, 0.5)',
  TagViolet100: 'rgba(156, 39, 176, 0.04)',
  TagRed300: 'rgba(211, 47, 47, 1)',
  TagRed200: 'rgba(211, 47, 47, 0.5)',
  TagRed100: 'rgba(211, 47, 47, 0.04)',
  TagOragne300: 'rgba(237, 108, 2, 1)',
  TagOragne200: 'rgba(237, 108, 2, 0.5)',
  TagOragne100: 'rgba(237, 108, 2, 0.04)',
  TagGreen300: 'rgba(46, 125, 50, 1)',
  TagGreen200: 'rgba(46, 125, 50, 0.5)',
  TagGreen100: 'rgba(46, 125, 50, 0.04)',
};

export const REDCOLORSET = [
  'rgba(241, 236, 236, 1)',
  'rgba(255, 235, 238, 1)',
  'rgba(255, 205, 210, 1)',
  'rgba(229, 115, 115, 1)',
  'rgba(178, 71, 71, 1)',
];

export const GRAPH1 = [
  'rgba(192, 232, 151, 1)',
  'rgba(255, 216, 134, 1)',
  'rgba(205, 206, 209, 1)',
  'rgba(228, 195, 255, 1)',
  'rgba(119, 164, 252, 1)',
];

export const GRAPH2 = [
  'rgba(97, 196, 143, 1)',
  'rgba(128, 160, 232, 1)',
  'rgba(156, 156, 156, 1)',
  'rgba(187, 157, 219, 1)',
  'rgba(222, 248, 169, 1)',
  'rgba(160, 222, 196, 1)',
  'rgba(177, 227, 255, 1)',
  'rgba(214, 196, 233, 1)',
];

export const BENCHMARKCOLORS = [
  'rgba(217, 117, 93, 1)',
  'rgba(226, 168, 94, 1)',
  'rgba(250, 216, 141, 1)',
  'rgba(147, 176, 133, 1)',
  'rgba(79, 153, 143, 1)',
  'rgba(87, 147, 178, 1)',
];

export const STAGE = [
  'rgba(67, 129, 222, 1)',
  'rgba(133, 173, 233, 1)',
  'rgba(218, 232, 255, 1)',
  'rgba(241, 243, 246, 1)',
  'rgba(253, 227, 225, 1)',
  'rgba(249, 185, 177, 1)',
  'rgba(222, 111, 98, 1)',
];

export const PATENTCOLORS = [
  'rgba(106, 161, 125, 1)',
  'rgba(137, 196, 148, 1)',
  'rgba(176, 213, 108, 1)',
  'rgba(244, 240, 193, 1)',
  'rgba(239, 235, 228, 1)',
  'rgba(218, 196, 160, 1)',
  'rgba(131, 156, 156, 1)',
];
