import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Snackbar, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { COLORS } from 'styles/colorTheme';
import {
  Container,
  TitleWrapper,
  NewPasswordContainer,
  SectionContainer,
} from './AccountSettings.styles';
import Typo from 'ui/typo/Typo';
import Button from 'ui/buttons/button/Button';
import LanguageSettings from './components/languageSettings/LanguageSettings';
import SettingsSection from './components/settingsSection/SettingsSection';
import SettingsItem from './components/settingsItem/SettingsItem';
import { changePassword, deleteAccount, getUserData, updateUserData } from 'api/user';
import { saveInfo } from 'services/user/Actions';
import ModalComponent from 'ui/modals/defaultModal/Modal';

function AccountSettings() {
  const user = useSelector((state) => state.user?.info);
  const dispatch = useDispatch();
  const [t] = useTranslation('settings');

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openAccountModal, setOpenAccountModal] = useState(false);

  const initialValues = {
    name: user?.fullname,
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .max(255, t(`Name must be less than 255 characters.`))
      .required(t(`Name cannot be empty.`)),
  });

  const onSubmit = async (values, { setErrors }) => {
    const updateName = await updateUserData(user.id, { fullname: values.name });
    if (updateName) {
      const updatedUserInfo = await getUserData(user.id);
      dispatch(saveInfo(updatedUserInfo.data));
      setOpenSnackbar(true);
    } else {
      setErrors({ name: t(`Error!`) });
    }
  };

  const passwordValues = {
    password: '',
    newPassword: '',
    newPasswordCheck: '',
  };

  const passwordValidationSchem = Yup.object().shape({
    newPassword: Yup.string()
      .min(8, t('Password must be at least 8 characters.'))
      .max(16, t('Password must be less than 17 characters.'))
      .matches(/[a-zA-z]/, t('Password must contain at least one letter.'))
      .matches(/[0-9]/, t('Password must contain at least one number.'))
      .required(t('Password cannot be empty.')),
    newPasswordCheck: Yup.string()
      .min(8, t('Password must be at least 8 characters.'))
      .max(16, t('Password must be less than 17 characters.'))
      .matches(/[a-zA-z]/, t('Password must contain at least one letter.'))
      .matches(/[0-9]/, t('Password must contain at least one number.'))
      .required(t('Password cannot be empty.')),
  });

  const onPasswordSubmit = async (values, { setErrors }) => {
    if (values.newPassword !== values.newPasswordCheck)
      return setErrors({ newPasswordCheck: t('Passwords do not match.') });

    const res = await changePassword(values.password, values.newPassword, values.newPasswordCheck);
    if (!res) return setErrors({ newPasswordCheck: t('Error! Please check your password.') });
    setOpenSnackbar(true);
  };

  const handleAccountDelete = async () => {
    await deleteAccount(user.id, { options: null, details: null });
    logout();
  };

  const logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('state');
    window.location.href = '/signin';
  };

  return (
    <Container>
      {/* Profile Settings */}
      <SettingsSection title={t('Profile')}>
        <SectionContainer>
          <SettingsItem title={t('Email')} value={user?.email} readOnly={true} />
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ values, errors, handleChange, handleSubmit, touched }) => (
              <SettingsItem
                errors={errors.name}
                title={t('Name')}
                touched={touched.name}
                name="name"
                value={values.name}
                onClick={handleSubmit}
                onChange={handleChange}
              />
            )}
          </Formik>
          <Formik
            initialValues={passwordValues}
            validationSchema={passwordValidationSchem}
            onSubmit={onPasswordSubmit}
          >
            {({ values, errors, handleChange, handleSubmit, touched }) => (
              <>
                <SettingsItem
                  errors={errors.password}
                  title={t('Password')}
                  touched={touched.password}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  type="password"
                />
                <NewPasswordContainer>
                  <SettingsItem
                    errors={errors.newPassword}
                    title={t('New Password')}
                    touched={touched.newPassword}
                    name="newPassword"
                    value={values.newPassword}
                    onChange={handleChange}
                    type="password"
                  />
                  <SettingsItem
                    errors={errors.newPasswordCheck}
                    title={t('New Password Check')}
                    touched={touched.newPasswordCheck}
                    name="newPasswordCheck"
                    value={values.newPasswordCheck}
                    type="password"
                    onChange={handleChange}
                    onClick={handleSubmit}
                  />
                </NewPasswordContainer>
              </>
            )}
          </Formik>
        </SectionContainer>
      </SettingsSection>
      {/* Language Setttings */}
      <SettingsSection title={t('Language')}>
        <SectionContainer>
          <LanguageSettings />
        </SectionContainer>
      </SettingsSection>
      {/* Delete Account */}
      <Button
        color={COLORS.Red200}
        onClick={() => setOpenAccountModal(true)}
        sx={{ marginTop: '24px' }}
      >
        <Typo variant="btn1">{t('Delete Account')}</Typo>
      </Button>
      {/* Delete Account Modal */}
      <ModalComponent
        onClose={() => setOpenAccountModal(false)}
        onSubmit={handleAccountDelete}
        open={openAccountModal}
      >
        <TitleWrapper>
          <Typo variant="h2" color={COLORS.Text400} className="medium">
            {t('Attention')}
          </Typo>
        </TitleWrapper>
        <div>
          <Typo variant="b2" color={COLORS.Text300}>
            {t('Are you sure you want to delete account?')}
          </Typo>
        </div>
        <div>
          <Typo variant="b2" color={COLORS.Text300}>
            {t('All the settings and information will be removed.')}
          </Typo>
        </div>
      </ModalComponent>
      {/* Snackbar after updates */}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        open={openSnackbar}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
          {t('Saved successfully!')}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default AccountSettings;
