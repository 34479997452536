import { COLORS } from 'styles/colorTheme';
import gear from 'assets/gear.svg';
import styled from 'styled-components';

const Img = styled.img`
  width: 24px;

  filter: ${(props) => (props.convert ? 'invert(100%)' : '')};
`;

function SettingsIcon({ state = 'mouseup', isSelected = false }) {
  let color = COLORS.Text400;
  if (state === 'mousedown') color = '#fff';
  return <Img src={gear} alt="gear" convert={state === 'mousedown'} />;
}

export default SettingsIcon;
