import React from 'react';
import {
  Container,
  BlurContainer,
  ContentsContainer,
  TitleWrapper,
  DescWrapper,
  ButtonWrapper,
  Li,
} from './BlurScreen.styles';
import Typo from 'ui/typo/Typo';
import { COLORS } from 'styles/colorTheme';
import greenRight from 'assets/green_right.svg';

export default function BlurScreen({ title, descriptions, onClick, open }) {
  return (
    <Container open={open}>
      <BlurContainer />
      <ContentsContainer>
        <TitleWrapper>
          <Typo variant="h2" color={COLORS.Text300}>
            {title}
          </Typo>
        </TitleWrapper>
        <DescWrapper>
          {descriptions?.map((desc, i) => (
            <Li key={i}>
              <Typo variant="b1" color={COLORS.Text300}>
                {desc}
              </Typo>
            </Li>
          ))}
        </DescWrapper>

        <ButtonWrapper onClick={onClick}>
          <Typo variant="b1" color={COLORS.Primary500}>
            Continue
          </Typo>
          <img src={greenRight} />
        </ButtonWrapper>
      </ContentsContainer>
    </Container>
  );
}
