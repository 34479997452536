import { media } from 'media';
import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div`
  display: flex;
  margin-left: 80px;

  gap: 12px;
`;

export const Circle = styled.span`
  display: inline-block;
  border-radius: 50%;
  background-color: ${COLORS.Background400};
  padding: 3px;
`;

export const BottomLine = styled.div`
  min-height: 20px;
  height: 100%;
  width: 1px;
  padding-bottom: 36px;
  background-color: ${COLORS.Background400};
`;

export const TopLine = styled.div`
  min-height: 20px;
  width: 1px;
  padding-top: 12px;
  background-color: ${COLORS.Background400};
`;

export const LineContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ContentsContainer = styled.div`
  width: 100%;
  padding-top: 12px;
`;
