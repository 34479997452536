import SecondaryMenu from 'ui/menus/secondary/regular/RegularMenu';
import {
  Container,
  MenuContainer,
  MenuWrapper,
  ThirdMenuWrapper,
  LegendWrapper,
  ItemWrapper,
  SecondaryMenuWrapper,
  ChartContainer,
  ChartWrapper,
} from './BenchmarkLineChartStyles';
import ThirdMenu from 'ui/menus/thirdMenu/ThirdMenu';
import { useEffect, useState } from 'react';
import BenchmarkChart from 'ui/charts/benchmarkChart/BenchmarkChart';
import BenchmarkGroupChart from 'ui/charts/benchmarkGroupChart/BenchmarkGroupChart';
import BenchmarkGroupChart_SASB from 'ui/charts/benchmarkGroupChart/BenchmarkGroupChart_SASB';
import LegendRadioItem from 'ui/listItem/legendRadioItem/LegendRadioItem';
import Preloader from 'ui/preloaders/componentPreloader/ComponentPreloader';
import Nodata from 'ui/errorSigns/noData/Nodata';
import { useTranslation } from 'react-i18next';

const BenchmarkLineChart = ({
  riskData,
  groupRiskData,
  esgFactorData,
  dataDrivenData,
  timeWindow,
  esgMenu,
  setTimeWindow,
  setEsgMenu,
  isLoading,
  setIsLoading,
  sx,
}) => {
  const [t] = useTranslation('chart');
  const [dT] = useTranslation('noData');

  const [legendInfo_groupRisk, setLegendInfo_groupRisk] = useState([]);
  const [legendInfo_esgFactor, setLegendInfo_esgFactor] = useState([]);
  const [legendInfo_dataDriven, setLegendInfo_dataDriven] = useState([]);
  const [activeLegendIdx_groupRisk, setActiveLegendIndex_groupRisk] = useState(0);
  const [activeLegendIdx_esgFactor, setActiveLegendIndex_esgFactor] = useState(0);
  const [activeLegendIdx_dataDriven, setActiveLegendIndex_dataDriven] = useState(0);
  const [arrangedGroupRiskData, setArrangedGroupRiskData] = useState();
  const [arrangedEsgFactorData, setArrangedEsgFactorData] = useState();
  const [arrangedDataDrivenData, setArrangedDataDrivenData] = useState();

  let activeIndex = 0;
  if (timeWindow === '1m') activeIndex = 0;
  if (timeWindow === '3m') activeIndex = 1;
  if (timeWindow === '6m') activeIndex = 2;
  if (timeWindow === '1y') activeIndex = 3;

  let esgMenuIndex = 0;
  if (esgMenu === 'nfr_raw_pulse') esgMenuIndex = 0;
  if (esgMenu === 'nfr_score_by_dimension') esgMenuIndex = 1;
  if (esgMenu === 'risk_score_by_material_codes') esgMenuIndex = 2;
  if (esgMenu === 'risk_score_by_material_data_codes') esgMenuIndex = 3;

  const handleTimeWindow = (i) => {
    if (i === 0) setTimeWindow('1m');
    if (i === 1) setTimeWindow('3m');
    if (i === 2) setTimeWindow('6m');
    if (i === 3) setTimeWindow('1y');
  };

  const handleEsgMenu = (i) => {
    if (i === 0) setEsgMenu('nfr_raw_pulse');
    if (i === 1) setEsgMenu('nfr_score_by_dimension');
    if (i === 2) setEsgMenu('risk_score_by_material_codes');
    if (i === 3) setEsgMenu('risk_score_by_material_data_codes');
  };

  useEffect(() => {
    if (esgMenu === 'nfr_raw_pulse') {
      setIsLoading(false);
    }
    if (esgMenu === 'nfr_score_by_dimension') {
      const overallLegends = getLegendArr(groupRiskData);
      setLegendInfo_groupRisk(overallLegends);
      const res = arrangeDataToLegends(groupRiskData, overallLegends);
      setArrangedGroupRiskData(res);
      setIsLoading(false);
    }
    if (esgMenu === 'risk_score_by_material_codes') {
      const overallLegends = getLegendArr(esgFactorData);
      setLegendInfo_esgFactor(overallLegends);
      const res = arrangeDataToLegends(esgFactorData, overallLegends);
      setArrangedEsgFactorData(res);
      setIsLoading(false);
    }
    if (esgMenu === 'risk_score_by_material_data_codes') {
      const overallLegends = getLegendArr(dataDrivenData);
      setLegendInfo_dataDriven(overallLegends);
      const res = arrangeDataToLegends(dataDrivenData, overallLegends);
      setArrangedDataDrivenData(res);
      setIsLoading(false);
    }
  }, [riskData, esgMenu, groupRiskData, esgFactorData, dataDrivenData]);

  const getLegendArr = (data) => {
    let overallLegends = [];
    for (let i = 0; i < data.length; i++) {
      const legend = data[i].legend;
      for (let j = 0; j < legend.length; j++) {
        const legendName = legend[j];
        if (overallLegends.includes(legendName)) continue;
        overallLegends.push(legendName);
      }
    }
    return overallLegends;
  };

  const arrangeDataToLegends = (data, legends) => {
    let result = [];
    let arr = new Array(data.length).fill().map(() => new Array().fill());

    for (let i = 0; i < legends.length; i++) {
      const legend = legends[i];

      for (let j = 0; j < data.length; j++) {
        const company = data[j];
        const hasLegend = company.legend.findIndex((el) => el === legend);
        if (hasLegend === -1) arr[j].push(undefined);
        else arr[j].push(company.data[hasLegend]);
      }
    }

    data.forEach((el, i) => {
      result.push({
        ...el,
        data: arr[i],
      });
    });

    return result;
  };

  const isDataDrivenDataEmpty = (data) => {
    let result = true;
    data.forEach((el) => {
      if (el.data.length > 0) result = false;
    });

    return result;
  };

  return (
    <Container style={{ ...sx }}>
      <MenuContainer>
        <MenuWrapper>
          <SecondaryMenuWrapper>
            <SecondaryMenu
              menuList={[
                t('ESG risk'),
                t('ESG group risk'),
                t('SASB ESG factor'),
                t('Data-driven ESG factor'),
              ]}
              activeIndex={esgMenuIndex}
              onClick={handleEsgMenu}
            />
          </SecondaryMenuWrapper>
          <ThirdMenuWrapper>
            <ThirdMenu
              menuList={[t('1m'), t('3m'), t('6m'), t('1y')]}
              activeIndex={activeIndex}
              onClick={handleTimeWindow}
            />
          </ThirdMenuWrapper>
        </MenuWrapper>
      </MenuContainer>

      {esgMenu === 'nfr_raw_pulse' && !isLoading ? (
        <ChartContainer>
          <ChartWrapper>
            <BenchmarkChart nfrData={riskData} />
          </ChartWrapper>
        </ChartContainer>
      ) : null}

      {esgMenu === 'nfr_score_by_dimension' && !isLoading ? (
        <ChartContainer>
          <ChartWrapper>
            <BenchmarkGroupChart
              data={arrangedGroupRiskData}
              legendInfo={legendInfo_groupRisk}
              activeLegend={activeLegendIdx_groupRisk}
            />
          </ChartWrapper>
        </ChartContainer>
      ) : null}

      {esgMenu === 'risk_score_by_material_codes' && !isLoading ? (
        <ChartContainer>
          <ChartWrapper>
            <BenchmarkGroupChart_SASB
              data={arrangedEsgFactorData}
              legendInfo={legendInfo_esgFactor}
              activeLegend={activeLegendIdx_esgFactor}
            />
          </ChartWrapper>
        </ChartContainer>
      ) : null}

      {esgMenu === 'risk_score_by_material_data_codes' && !isLoading ? (
        <>
          {dataDrivenData.length > 0 && !isDataDrivenDataEmpty(dataDrivenData) ? (
            <ChartContainer>
              <ChartWrapper>
                <BenchmarkGroupChart_SASB
                  data={arrangedDataDrivenData}
                  legendInfo={legendInfo_dataDriven}
                  activeLegend={activeLegendIdx_dataDriven}
                />
              </ChartWrapper>
            </ChartContainer>
          ) : (
            <Nodata desc={dT('No data.')} />
          )}
        </>
      ) : null}

      <LegendWrapper>
        {esgMenu === 'nfr_score_by_dimension' && !isLoading ? (
          <ItemWrapper>
            {legendInfo_groupRisk.map((el, i) => (
              <LegendRadioItem
                isSelected={i === activeLegendIdx_groupRisk}
                name="nfr_raw_pulse"
                onClick={() => setActiveLegendIndex_groupRisk(i)}
                key={i}
              >
                {el}
              </LegendRadioItem>
            ))}
          </ItemWrapper>
        ) : null}
        {esgMenu === 'risk_score_by_material_codes' && !isLoading ? (
          <ItemWrapper>
            {legendInfo_esgFactor.map((el, i) => (
              <LegendRadioItem
                isSelected={i === activeLegendIdx_esgFactor}
                name="risk_score_by_material_codes"
                onClick={() => setActiveLegendIndex_esgFactor(i)}
                key={i}
              >
                {el}
              </LegendRadioItem>
            ))}
          </ItemWrapper>
        ) : null}
        {esgMenu === 'risk_score_by_material_data_codes' && !isLoading ? (
          <ItemWrapper>
            {legendInfo_dataDriven.map((el, i) => (
              <LegendRadioItem
                isSelected={i === activeLegendIdx_dataDriven}
                name="risk_score_by_material_data_codes"
                onClick={() => setActiveLegendIndex_dataDriven(i)}
                key={i}
              >
                {el}
              </LegendRadioItem>
            ))}
          </ItemWrapper>
        ) : null}
      </LegendWrapper>

      {isLoading ? <Preloader width="1200px" height="432px" /> : null}
    </Container>
  );
};

export default BenchmarkLineChart;
