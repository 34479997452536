import { media } from 'media';
import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div`
  display: flex;
  width: 550px;
  padding: 10px;
  gap: 16px;
  position: relative;
  border-radius: 5px;

  :hover {
    background: ${COLORS.Background50};
    & .feedback {
      visibility: visible;
      opacity: 1;
    }
  }

  ${media.mobile`
    width: 100%;
    box-sizing: border-box;
    gap: 0px;
  `}
`;

export const NumberWrapper = styled.div`
  opacity: 0.800000011920929;
  width: 26px;
  display: flex;
  justify-content: center;

  ${media.mobile`
    padding-right: 10px;
    width: fit-content;
  `}
`;

export const ContentsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${media.mobile`
    overflow: hidden;
  `}
`;

export const TitleWrapper = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${media.mobile`
    width: 100%;
  `}
`;

export const Title = styled.div`
  overflow: ${(props) => (props.variant === 'medium' ? '' : 'hidden')};
  text-overflow: ${(props) => (props.variant === 'medium' ? '' : 'ellipsis')};
  white-space: ${(props) => (props.variant === 'medium' ? '' : 'nowrap')};
  cursor: pointer;

  :hover {
    text-decoration-line: underline;
  }

  ${media.mobile`
    width: 100%;
  `}
`;

export const TagWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const CompanyTagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const TopicTagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const TitleImgContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const FeedbacWrapper = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
  visibility: hidden;
  opacity: 0;

  transition: all 0.3s;
`;
