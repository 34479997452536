import { Provider } from 'react-redux';
import ReactGA from 'react-ga';
import { ReactChannelIO } from 'react-channel-plugin';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as amplitude from '@amplitude/analytics-browser';

import App from './App';
import { store } from 'services/ConfigureStore';
import { COLORS } from 'styles/colorTheme';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
ReactGA.pageview(window.location.pathname + window.location.search);
amplitude.init(process.env.REACT_APP_AMPLITUDE_KEY);

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.Primary500,
      light: COLORS.Primary500,
      dark: COLORS.Primary500,
      contrastText: '#ffffff',
    },
  },
});

const Root = () => {
  return (
    <Provider store={store}>
      <ReactChannelIO pluginKey={process.env.REACT_APP_CHANNEL_ID_PLUGIN_KEY} locale="ko" autoBoot>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </ReactChannelIO>
    </Provider>
  );
};

export default Root;
