import { useSelector } from 'react-redux';
import { useSingleIndexQuery } from 'components/analyticsPage/tabPanels/components/drawers/indexDrawer/components/components/singleIndex/hooks/useSingleIndexQuery';
import _ from 'lodash';

export const useIndex = () => {
  const filters = useSelector((state) => state.analytics.indexQuery.index.singleIndex.filters);
  const [setQuery] = useSingleIndexQuery();

  const getIndex = () => {
    for (let i = 0; i < filters.length; i++) {
      if (filters[i].check === true) {
        return i;
      }
    }
    return 0;
  };

  const setIndex = (event) => {
    const arr = _.cloneDeep(filters);

    arr.map((el, i) => {
      if (i === Number(event.target.value)) el.check = true;
      else el.check = false;
    });

    setQuery({
      filters: arr,
    });
  };

  return [getIndex, setIndex];
};
