import { useEffect, useState } from 'react';
import { getDashboardNews } from 'api/dashboard';

const useNews = (corpCodes) => {
  const [dashboardNewsData, setDashboardNewsData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!corpCodes) return;
    getData();
  }, [corpCodes]);

  const getData = async () => {
    setIsLoading(true);
    const res = await getDashboardNews(corpCodes);
    setIsLoading(false);
    if (!res) return;
    setDashboardNewsData(res.data);
  };

  return [dashboardNewsData, isLoading];
};

export default useNews;
