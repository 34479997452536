import { media } from 'media';
import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div`
  width: fit-content;
  box-sizing: border-box;
  display: flex;
  padding: 8px 80px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 30px;
  height: 44px;
  background-color: ${(props) => (props.isActive ? '#fff' : 'none')};
  color: ${(props) => (props.isActive ? COLORS.Primary500 : COLORS.Text300)};

  :hover {
    color: ${COLORS.Primary500};
  }

  ${media.mobile`
    padding: 0px 12px;
  `}
`;
