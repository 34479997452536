import { useState } from 'react';
import { Stack } from '@mui/material';
import ModalComponent from 'ui/modals/defaultModal';
import * as authAPI from 'api/auth';
import { useTranslation } from 'react-i18next';
import Typo from 'ui/typo/Typo';
import Input from 'ui/inputs/Input';
import * as amplitude from '@amplitude/analytics-browser';

const Reverify = ({ open, handleClose }) => {
  const [t] = useTranslation('signIn');
  const [isSent, setIsSent] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const handleEnter = (e) => {
    if (e.keyCode === 13) {
      handleClick();
    }
  };

  const handleError = (e, message) => {
    setError(e);
    setErrorMessage(message);
  };

  const handleClick = async () => {
    const emailRule =
      /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

    try {
      if (!emailRule.test(email)) return handleError(true, t('Incorrect Email address.'));

      const result = await authAPI.resendVerifyEmail(email);
      if (result && result.status === 200) {
        setIsSent(!isSent);
      }
    } catch (e) {
      if (e.response.status === 400) {
        amplitude.track('email_verification_start');
        handleError(true, t('This account is already verified.'));
      } else {
        handleError(true, t('Incorrect Email address.'));
      }
    }
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const closeModal = () => {
    setIsSent(false);
    setErrorMessage('');
    handleClose();
  };

  return (
    <>
      {!isSent && (
        <ModalComponent open={open} onClose={closeModal} onSubmit={handleClick}>
          <Stack spacing={3}>
            <Typo variant="h1">{t('Request Email Verification')}</Typo>
            <Typo>{t('Please enter your email address.')}</Typo>
            <Input
              sx={{ height: '48px' }}
              onChange={(e) => handleChange(e)}
              onKeyDown={(e) => handleEnter(e)}
              placeHolder={'Email'}
              helperText={error && errorMessage}
            />
          </Stack>
        </ModalComponent>
      )}

      {isSent && (
        <ModalComponent open={open} onClose={closeModal}>
          <Stack spacing={3}>
            <Typo variant="h1">{t('Request Email Verification')}</Typo>
            <Stack>
              <Stack>
                <Typo>{t('Please check the new password seny by email.')}</Typo>
              </Stack>
            </Stack>
          </Stack>
        </ModalComponent>
      )}
    </>
  );
};

export default Reverify;
