import MainNews from './components/mainNews/MainNews';
import { Container, TapWrapper } from './NewsStyles';
import EsgNews from './components/esgNews/EsgNews';
import { useState } from 'react';
import IndustryNews from './components/industryNews/IndustryNews';
import useEsgNews from './hooks/useEsgNews';
import useIndustryNews from './hooks/useIndustryNews';
import useMainNews from './hooks/useMainNews';
import { useTranslation } from 'react-i18next';
import SmallMenu from 'ui/menus/secondary/small/SmallMenu';
import Top from 'ui/transition/top/Top';
import * as amplitude from '@amplitude/analytics-browser';

const News = () => {
  const [t] = useTranslation('news');

  const [index, setIndex] = useState(0);
  const [
    dimensions,
    esgNewsMenu,
    esgNewsData,
    totalN_esg,
    page_esg,
    setPage_esg,
    handleMenu_esg,
    isLoadingEsg,
  ] = useEsgNews();
  const [
    industries,
    industryIdx,
    industryNewsData,
    totalN_ind,
    page_ind,
    setPage_ind,
    handleMenu_ind,
    isLoadingIndustry,
  ] = useIndustryNews();
  const [mainNews, esgTopicNews] = useMainNews();

  const handleTapChange = (i) => {
    if (i === 0) amplitude.track('news_main_news_view');
    if (i === 1) amplitude.track('news_esg_topics_view');
    if (i === 2) amplitude.track('news_industry_news_view');
    setIndex(i);
  };

  return (
    <Container>
      <TapWrapper>
        <SmallMenu
          menuList={[t('Main News'), t('ESG Topics'), t('Industry')]}
          activeIndex={index}
          onClick={(i) => handleTapChange(i)}
        />
      </TapWrapper>

      {index === 0 ? (
        <MainNews
          mainNews={mainNews}
          esgTopicNews={esgTopicNews}
          navigateToESG={() => {
            window.scroll(0, 0);
            setIndex(1);
          }}
        />
      ) : null}

      {index === 1 ? (
        <EsgNews
          dimensions={dimensions}
          esgNewsMenu={esgNewsMenu}
          articles={esgNewsData}
          totalN={totalN_esg}
          page={page_esg}
          handlePage={(i) => setPage_esg(i)}
          handleMenu={handleMenu_esg}
          isLoading={isLoadingEsg}
        />
      ) : null}

      {index === 2 ? (
        <IndustryNews
          industries={industries}
          industryIdx={industryIdx}
          articles={industryNewsData}
          totalN={totalN_ind}
          page={page_ind}
          handlePage={(i) => setPage_ind(i)}
          handleMenu={handleMenu_ind}
          isLoading={isLoadingIndustry}
        />
      ) : null}

      <Top />
    </Container>
  );
};

export default News;
