import Typo from 'ui/typo/Typo';
import { Container, BarWrapper, Bar } from './RangeBarStyles';
import { COLORS } from 'styles/colorTheme';

const RangeBar = ({ ranges = [], sx }) => {
  return (
    <Container style={{ ...sx }}>
      {ranges.map((range, i) => (
        <BarWrapper key={i}>
          <Bar color={range.color} />
          <Typo variant="overline2" color={COLORS.Text300}>
            {range.value}
          </Typo>
        </BarWrapper>
      ))}
    </Container>
  );
};

export default RangeBar;
