import Typo from 'ui/typo/Typo';
import { Container, LegendWrapper, SquareWrapper, CompanyNameWrapper } from './HeatmapChartStyles';
import { COLORS } from 'styles/colorTheme';
import Square from './components/Square';
import { useEffect, useState } from 'react';
import ToolTip from 'ui/tooltip/ToolTip';
import Preloaer from 'ui/preloaders/componentPreloader/ComponentPreloader';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const HeatmapChart = ({ data, isLoading = false, sx }) => {
  const [isProcessDone, setIsProcessDone] = useState(false);
  const [mat, setMat] = useState();
  const language = useSelector((state) => state.user.language);
  const navigate = useNavigate();

  const getStage = (degree) => {
    if (degree === '매우 낮음') return 0;
    if (degree === '낮음') return 1;
    if (degree === '보통') return 2;
    if (degree === '높음') return 3;
    if (degree === '매우 높음') return 4;
  };

  useEffect(() => {
    if (!data) return;

    setIsProcessDone(false);
    const _mat = new Array(data.length + 1).fill(null).map(() => new Array(27).fill(null));
    data.forEach((company, i) => {
      if (i === 0) {
        _mat[0][1] = company['110'][`general_issue_name_${language}`];
        _mat[0][2] = company['120'][`general_issue_name_${language}`];
        _mat[0][3] = company['130'][`general_issue_name_${language}`];
        _mat[0][4] = company['140'][`general_issue_name_${language}`];
        _mat[0][5] = company['150'][`general_issue_name_${language}`];
        _mat[0][6] = company['160'][`general_issue_name_${language}`];
        _mat[0][7] = company['210'][`general_issue_name_${language}`];
        _mat[0][8] = company['220'][`general_issue_name_${language}`];
        _mat[0][9] = company['230'][`general_issue_name_${language}`];
        _mat[0][10] = company['240'][`general_issue_name_${language}`];
        _mat[0][11] = company['250'][`general_issue_name_${language}`];
        _mat[0][12] = company['260'][`general_issue_name_${language}`];
        _mat[0][13] = company['270'][`general_issue_name_${language}`];
        _mat[0][14] = company['310'][`general_issue_name_${language}`];
        _mat[0][15] = company['320'][`general_issue_name_${language}`];
        _mat[0][16] = company['330'][`general_issue_name_${language}`];
        _mat[0][17] = company['410'][`general_issue_name_${language}`];
        _mat[0][18] = company['420'][`general_issue_name_${language}`];
        _mat[0][19] = company['430'][`general_issue_name_${language}`];
        _mat[0][20] = company['440'][`general_issue_name_${language}`];
        _mat[0][21] = company['450'][`general_issue_name_${language}`];
        _mat[0][22] = company['510'][`general_issue_name_${language}`];
        _mat[0][23] = company['520'][`general_issue_name_${language}`];
        _mat[0][24] = company['530'][`general_issue_name_${language}`];
        _mat[0][25] = company['540'][`general_issue_name_${language}`];
        _mat[0][26] = company['550'][`general_issue_name_${language}`];
      }
      _mat[i + 1][0] = {
        corp_code: company.corp_code,
        corp_name_en: company.corp_name_en,
        corp_name_ko: company.corp_name_ko,
        stock_code: company.stock_code,
      };
      _mat[i + 1][1] = company['110'];
      _mat[i + 1][2] = company['120'];
      _mat[i + 1][3] = company['130'];
      _mat[i + 1][4] = company['140'];
      _mat[i + 1][5] = company['150'];
      _mat[i + 1][6] = company['160'];
      _mat[i + 1][7] = company['210'];
      _mat[i + 1][8] = company['220'];
      _mat[i + 1][9] = company['230'];
      _mat[i + 1][10] = company['240'];
      _mat[i + 1][11] = company['250'];
      _mat[i + 1][12] = company['260'];
      _mat[i + 1][13] = company['270'];
      _mat[i + 1][14] = company['310'];
      _mat[i + 1][15] = company['320'];
      _mat[i + 1][16] = company['330'];
      _mat[i + 1][17] = company['410'];
      _mat[i + 1][18] = company['420'];
      _mat[i + 1][19] = company['430'];
      _mat[i + 1][20] = company['440'];
      _mat[i + 1][21] = company['450'];
      _mat[i + 1][22] = company['510'];
      _mat[i + 1][23] = company['520'];
      _mat[i + 1][24] = company['530'];
      _mat[i + 1][25] = company['540'];
      _mat[i + 1][26] = company['550'];
    });
    setMat(_mat);
    setIsProcessDone(true);
  }, [data, language]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [label, setLabel] = useState(undefined);

  const handlePopoverOpen = (event, contents) => {
    setLabel(contents);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);

  return (
    <>
      {!isLoading && isProcessDone && data ? (
        <Container style={{ ...sx }}>
          {mat.map((row, i) => {
            if (i === 0) {
              return row.map((el, i) => (
                <LegendWrapper
                  key={i}
                  onMouseEnter={(e) => handlePopoverOpen(e, el)}
                  onMouseLeave={handlePopoverClose}
                >
                  <Typo
                    color={COLORS.Text400}
                    sx={{
                      fontSize: '8px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: 'normal',
                      letterSpacing: '0.32px',
                    }}
                  >
                    {el}
                  </Typo>
                </LegendWrapper>
              ));
            } else {
              return row.map((el, i) => {
                if (i === 0)
                  return (
                    <CompanyNameWrapper
                      key={i}
                      onMouseEnter={(e) => handlePopoverOpen(e, el[`corp_name_${language}`])}
                      onMouseLeave={handlePopoverClose}
                      onClick={() => navigate(`/company/${el.corp_code}`)}
                    >
                      <Typo variant="overline2" color={COLORS.Text500}>
                        {el[`corp_name_${language}`]}
                      </Typo>
                    </CompanyNameWrapper>
                  );
                else
                  return (
                    <SquareWrapper
                      key={i}
                      onMouseEnter={(e) =>
                        handlePopoverOpen(e, el[`general_issue_name_${language}`])
                      }
                      onMouseLeave={handlePopoverClose}
                    >
                      <Square stage={getStage(el.pulse_degree_ko)} />
                    </SquareWrapper>
                  );
              });
            }
          })}
          <ToolTip label={label} isOpen={label && isOpen} anchorEl={anchorEl} />
        </Container>
      ) : (
        <Preloaer width="1100px" height="220px" />
      )}
    </>
  );
};

export default HeatmapChart;
