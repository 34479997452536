import Typo from 'ui/typo/Typo';
import { Container, Title, Contents, More } from './SecondaryCardStyles';
import { COLORS } from 'styles/colorTheme';

const SecondaryCard = ({ title, contents, btnLable, onClick, sx }) => {
  return (
    <Container style={{ ...sx }}>
      <Title>
        <Typo variant="h3" color={COLORS.Text500}>
          {title}
        </Typo>
      </Title>
      <Contents>
        <Typo variant="b2" color={COLORS.Text500}>
          {contents}
        </Typo>
      </Contents>
      {btnLable ? (
        <More onClick={onClick}>
          <Typo variant="b2" color={COLORS.Primary500}>
            {btnLable}
          </Typo>
        </More>
      ) : null}
    </Container>
  );
};

export default SecondaryCard;
