import { getCompanyEsgProfile } from 'api/companyDetail';

export const useCompanyName = () => {
  const getCompanyName = async (companyId) => {
    const company = await getCompanyEsgProfile(companyId);
    return company.data.name;
  };

  return [getCompanyName];
};
