import { Stack, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import NoResults from 'ui/errorSigns/noResults';
import { useSelector } from 'react-redux';

const FindCompanySearch = ({ data, amount, handleClick }) => {
  const language = useSelector((state) => state.user.language);

  return (
    <div>
      {data.length !== 0 ? (
        <Stack>
          {data.map((el, i) => {
            if (i < amount) {
              return (
                <Stack
                  key={i}
                  sx={{
                    '&:hover': { backgroundColor: '#e0e0e0' },
                    cursor: 'pointer',
                    color: '#6f7070',
                    p: 1,
                    borderRadius: '12px',
                  }}
                  onClick={() => {
                    handleClick(el);
                  }}
                >
                  <Stack direction="row" alignItems="center" spacing={1.5}>
                    <SearchIcon />
                    <Stack>
                      <Typography variant="subtitle1">{el.name}</Typography>
                      <Typography variant="body2">
                        {el.stock_code} | {el[`corp_name_${language}`]}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              );
            }
          })}
        </Stack>
      ) : (
        <Stack sx={{ p: 1, color: '#6f7070', borderRadius: '12px', alignItems: 'center' }}>
          <NoResults />
        </Stack>
      )}
    </div>
  );
};

export default FindCompanySearch;
