import { media } from 'media';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 351px;
  flex-direction: column;
  gap: 24px;

  ${media.mobile`
    overflow: hidden;
    width: 100%;
    margin-bottom: 14px;
  `}
`;

export const Head = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;

  ${media.mobile`
    gap: 4px;
  `}
`;

export const Img = styled.img`
  width: 100%;
  height: 160px;
  object-fit: cover;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-self: stretch;

  ${media.mobile`
    display: block;
  `}
`;
