import Typo from 'ui/typo/Typo';
import CheckBoxList from '../../listItem/checkBoxList/CheckBoxList';
import { Container, Wrapper, TitleWrapper } from './Industry.styles';
import { COLORS } from 'styles/colorTheme';
import { useSelector } from 'react-redux';

const Industry = ({ title, idx, industryList = [], selectedIndex, onClick }) => {
  const language = useSelector((state) => state.user.language);

  return (
    <Container>
      <TitleWrapper>
        <Typo variant="h3" color={COLORS.Text500}>
          {title}
        </Typo>
      </TitleWrapper>
      <Wrapper>
        {industryList.map((industry, i) => (
          <CheckBoxList
            key={i}
            label={industry[`IndustryName_${language}`]}
            isSelected={idx + i === selectedIndex}
            isDisabled={false}
            fill={true}
            onClick={() => onClick(idx + i)}
          />
        ))}
      </Wrapper>
    </Container>
  );
};

export default Industry;
