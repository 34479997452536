import { media } from 'media';
import styled from 'styled-components';

export const CardWrapper = styled.div`
  width: auto;
`;

export const Container = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
`;

export const SelectIndustryWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const MenuWrapper = styled.div`
  display: flex;

  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const NewsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 576px);
  justify-content: space-between;
  row-gap: 24px;

  ${media.mobile`
    display: block;
  `}
`;
