import { useState } from 'react';
import { Stack, Typography, TextField, InputAdornment, Popper, Chip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FindCompanyBox from 'components/analyticsPage/findCompanyBox/FindCompanyBox';
import { useHotSearch } from 'hooks/useHotSearch';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const DrawerInput = ({ data, setQuery }) => {
  const [t] = useTranslation('analytics');
  const language = useSelector((state) => state.user.language);

  const [companyName, setCompanyName] = useState('');
  const [hotsearchCompanyList, inputAnchorEl, initHotsearch, getCompanyList, finHotSerach] =
    useHotSearch();

  const selectCompany = async (e) => {
    setQuery({
      companyId: e.corp_code,
      companyName_ko: e[`corp_name_ko`],
      companyName_en: e[`corp_name_en`],
    });
    finHotSerach(e);
  };

  const handleChange = (e) => {
    setCompanyName(e.target.value);
    getCompanyList(e.target.value);
  };

  return (
    <Stack spacing={3}>
      <Stack width="100%" spacing={1}>
        <Typography fontWeight="500">{t('Select a company')}</Typography>
        <TextField
          size="small"
          variant="outlined"
          onChange={handleChange}
          value={companyName}
          onFocus={initHotsearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Popper
          open={Boolean(inputAnchorEl)}
          anchorEl={inputAnchorEl}
          placement="bottom-end"
          sx={{
            zIndex: '1500',
            marginLeft: '-46px !important',
            minWidth: '350px',
            maxWidth: '475px',
            width: '100%',
          }}
        >
          <FindCompanyBox
            data={hotsearchCompanyList}
            keyword={companyName}
            handleClick={selectCompany}
            handleClose={finHotSerach}
            sx={{ width: '100%' }}
            amount={5}
          />
        </Popper>
      </Stack>
      {data?.companyId && (
        <Stack width="100%" spacing={1}>
          <Typography fontWeight="500">{t('Selected company')}</Typography>
          <Chip label={data[`companyName_${language}`]} variant="outlined" />
        </Stack>
      )}
      <Stack width="100%" spacing={1}>
        <Typography fontWeight="500">{t('Tap name')}</Typography>
        <TextField
          size="small"
          variant="outlined"
          value={data?.tabName}
          onChange={(e) => setQuery({ tabName: e.target.value })}
          sx={{
            '::after': {
              content: `"${data?.tabName?.length} / 35"`,
              textAlign: 'end',
              position: 'absolute',
              bottom: '-15px',
              right: '0px',
              color: 'rgba(0, 0, 0, 0.55)',
              fontSize: '12px',
            },
          }}
        />
      </Stack>
    </Stack>
  );
};

export default DrawerInput;
