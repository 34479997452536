import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import Preloaer from 'ui/preloaders/componentPreloader/ComponentPreloader';
import { useEffect, useState } from 'react';
import Nodata from 'ui/errorSigns/noData/Nodata';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const ControversyBarChart = ({ title, data, isLoading = false }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [options, setOptions] = useState();
  const language = useSelector((state) => state.user.language);
  const [t] = useTranslation('noData');

  useEffect(() => {
    if (!data) return;

    setIsProcessing(true);

    const series = [
      {
        name: 'Negative',
        color: 'rgba(254, 156, 8, 0.80)',
        borderRadiusBottomLeft: '50%',
        data: data.ratios.map((data) => Number(data.toFixed(2))),
      },
    ];

    const _options = {
      chart: {
        type: 'bar',
        height: data.ratios.length * 40,
        margin: [0, 0, 0, 190],
      },
      title: { text: title },
      xAxis: {
        categories: data[`general_issue_names_${language}`],
        gridLineWidth: 0,
        lineWidth: 0,
        labels: {
          useHTML: true,
          formatter: function () {
            const index = data[`general_issue_names_${language}`].findIndex(
              (el) => el === this.value,
            );
            const ratio = Number(data.ratios[index].toFixed(2));
            return `
              <div style="display:flex;width:170px;justify-content:space-between;">
                <div style="width:100px;overflow:hidden;text-overflow: ellipsis;">
                  ${this.value} 
                </div>
                <div>
                  ${ratio.toFixed(2)}%
                </div>
              </div>
            `;
          },
        },
      },
      yAxis: { visible: false },
      plotOptions: {
        bar: {
          pointWidth: 12,
          borderRadius: {
            radius: '30%',
            scope: 'stack',
            where: 'all',
          },
          dataLabels: { enabled: false },
          stacking: 'normal',
        },
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: true,
        outside: true,
        crosshair: true,
        shared: true,
        useHTML: true,
        borderRadius: 4,
        padding: 12,
        shadow: {
          offsetX: 0,
          offsetY: 3,
          opacity: 0.03,
        },
        format:
          '<span style="font-size: 0.8em">ESG topic</span><br/>' +
          '{#each points}' +
          '<br/><span style="color:{color};">\u25CF  </span>' +
          '{key}: <b>{y}%</b><br/>' +
          '{/each}',
      },
      credits: { enabled: false },
      series: series,
    };

    setOptions(_options);
    setIsProcessing(false);
  }, [data, language]);

  return (
    <>
      {isLoading || isProcessing ? (
        <Preloaer width="100%" height="420px" />
      ) : (
        <>
          {data ? (
            <HighchartsReact highcharts={Highcharts} options={options} />
          ) : (
            <Nodata desc={t('No data.')} />
          )}
        </>
      )}
    </>
  );
};

export default ControversyBarChart;
