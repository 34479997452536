import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  min-width: 300px;
  width: fit-content;
  position: relative;
  flex-direction: column;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 4px 12px 0px #c5cfdd;
  z-index: 2;
`;

export const UserWrapper = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 4.5px;
`;

export const NameWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const Name = styled.div`
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Item = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 12px 24px;
  cursor: pointer;
  :hover {
    background-color: ${COLORS.Background100};
  }
  z-index: 2;
  background-color: #fff;
`;

export const Itme_2 = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 12px 24px;
  z-index: 2;
  background-color: #fff;
`;

export const ItemImg = styled.img`
  width: 24px;
`;

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
`;
