import {
  CT
} from './PopupContainerStyles'

const PopupContainer = ({
  isOpen=false,
  children,
  width,
  sx,
}) => {
  return (
    <CT
      isOpen={isOpen}
      style={{...sx}}
      width={width}
    >
      {children}
    </CT>
  )
}

export default PopupContainer