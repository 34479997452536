import { media } from 'media';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${media.mobile`
    margin: 0px 12px;
  `}
`;
export const TitleWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const CardsWrapper = styled.div`
  display: flex;
  gap: 16px;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const CardWrapper = styled.div`
  width: fit-content;
`;

export const NoData = styled.div`
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
