import {
  Container,
  Input
} from './SecondarySearchBarStyles'
import { forwardRef } from 'react'

const SecondarySearchBar = ({
  value = '',
  onChange,
  placeholder,
  onKeyDown,
  maxWidth = '600px',
  py = '16px',
  px = '16px',
  sx,
}, ref) => {

  return (
    <Container
      maxWidth={maxWidth}
      py={py}
      px={px}
      style={{...sx}}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M14.4121 14.4121L20 20" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10 16C13.3137 16 16 13.3137 16 10C16 6.68629 13.3137 4 10 4C6.68629 4 4 6.68629 4 10C4 13.3137 6.68629 16 10 16Z" stroke="black" strokeWidth="1.5"/>
      </svg>
      <Input
        ref={ref} 
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
      />
    </Container>
  )
}

export default forwardRef(SecondarySearchBar)