import styled from 'styled-components';

export const Container = styled.div`
  padding: ${(props) => props.py} ${(props) => props.py};
  max-width: ${(props) => props.maxWidth};
  display: flex;
  align-items: center;
  gap: 4px;
  background: #fff;
`;

export const Input = styled.input`
  all: unset;
  width: 90%;

  &::placeholder {
    font-size: 16px;
    font-weight: 400;
    color: rgba(140, 144, 159, 1);
  }
`;
