export const basicDataAnalyticMap = {
  general_issue_code: 'GIC Code',
  general_issue_name_en: 'GIC Name EN',
  general_issue_name_ko: 'GIC Name Kr',
  data: 'Data-driven Materiality',
  sasb: 'SASB Materiality',
  last_update: 'Last Updated Date',
  sector_rank: 'Sector Rank',
  sector_pct: 'Sector Percentile',
  industry_rank: 'Industry Rank',
  industry_pct: 'Industry Percentile',
  pulse: 'ESG Pulse',
  score: 'ESG Score',
  momentum: 'ESG Momentum',
  neg_exposure: 'ESG Exposure',
  score_change: 'ESG Score Change',
  score_change_ratio: 'ESG Score Change Ratio',
};

export const advancedDataAnalyticsMap = {
  category_name: 'Category Name',
  date: 'Date',
  data: 'Data-driven Materiality',
  sasb: 'SASB Materiality',
  last_update: 'Last Updated Date',
  sector_rank: 'Sector Rank',
  sector_pct: 'Sector Percentile',
  industry_rank: 'Industry Rank',
  industry_pct: 'Industry Percentile',
  pulse: 'ESG Pulse',
  score: 'ESG Score',
  momentum: 'ESG Momentum',
  neg_exposure: 'ESG Exposure',
  score_change: 'ESG Score Change',
  score_change_ratio: 'ESG Score Change Ratio',
};

export const stockDataAnalyticsMap = {
  corp_name_ko: 'Stock Name Ko',
  corp_name_en: 'Stock Name En',
  stock_code: 'Stock Code',
  date: 'Date',
  close: 'Price',
  change: 'Change',
  change_ratio: '% Change',
  day_range: 'Day Range',
  marcap: 'Market Cap',
  avg_volume_30: 'Avg. Volume',
  performance_1month: '1 Month Performance',
  performance_3month: '3 Month Performance',
  performance_6month: '6 Month Performance',
  performance_1year: '1 Year Performance',
  performance_ytd: 'YTD Performance',
};

export const multiIndexAnalyticsMap = {
  nfr_110_pulse: 'GHG Emissions pulse',
  nfr_110_score: 'GHG Emissions score',
  nfr_110_pulse_rsi: 'GHG Emissions momentum',
  neg_exposure_110: 'GHG Emissions exposure',
  nfr_120_pulse: 'Air Quality pulse',
  nfr_120_score: 'Air Quality score',
  nfr_120_pulse_rsi: 'Air Quality momentum',
  neg_exposure_120: 'Air Quality exposure',
  nfr_130_pulse: 'Energy Management pulse',
  nfr_130_score: 'Energy Management score',
  nfr_130_pulse_rsi: 'Energy Management momentum',
  neg_exposure_130: 'Energy Management exposure',
  nfr_140_pulse: 'Water & Wastewater Management pulse',
  nfr_140_score: 'Water & Wastewater Management score',
  nfr_140_pulse_rsi: 'Water & Wastewater Management momentum',
  neg_exposure_140: 'Water & Wastewater Management exposure',
  nfr_150_pulse: 'Waste & Hazardous Materials Management pulse',
  nfr_150_score: 'Waste & Hazardous Materials Management score',
  nfr_150_pulse_rsi: 'Waste & Hazardous Materials Management momentum',
  neg_exposure_150: 'Waste & Hazardous Materials Management exposure',
  nfr_160_pulse: 'Ecological Impacts pulse',
  nfr_160_score: 'Ecological Impacts score',
  nfr_160_pulse_rsi: 'Ecological Impacts momentum',
  neg_exposure_160: 'Ecological Impacts exposure',
  nfr_210_pulse: 'Human Rights & Community Relations pulse',
  nfr_210_score: 'Human Rights & Community Relations score',
  nfr_210_pulse_rsi: 'Human Rights & Community Relations momentum',
  neg_exposure_210: 'Human Rights & Community Relations exposure',
  nfr_220_pulse: 'Customer Privacy pulse',
  nfr_220_score: 'Customer Privacy score',
  nfr_220_pulse_rsi: 'Customer Privacy momentum',
  neg_exposure_220: 'Customer Privacy exposure',
  nfr_230_pulse: 'Data Security pulse',
  nfr_230_score: 'Data Security score',
  nfr_230_pulse_rsi: 'Data Security momentum',
  neg_exposure_230: 'Data Security exposure',
  nfr_240_pulse: 'Access & Affordability pulse',
  nfr_240_score: 'Access & Affordability score',
  nfr_240_pulse_rsi: 'Access & Affordability momentum',
  neg_exposure_240: 'Access & Affordability exposure',
  nfr_250_pulse: 'Product Quality & Safety pulse',
  nfr_250_score: 'Product Quality & Safety score',
  nfr_250_pulse_rsi: 'Product Quality & Safety momentum',
  neg_exposure_250: 'Product Quality & Safety exposure',
  nfr_260_pulse: 'Customer Welfare pulse',
  nfr_260_score: 'Customer Welfare score',
  nfr_260_pulse_rsi: 'Customer Welfare momentum',
  neg_exposure_260: 'Customer Welfare exposure',
  nfr_270_pulse: 'Selling Practices & Product Labeling pulse',
  nfr_270_score: 'Selling Practices & Product Labeling score',
  nfr_270_pulse_rsi: 'Selling Practices & Product Labeling momentum',
  neg_exposure_270: 'Selling Practices & Product Labeling exposure',
  nfr_310_pulse: 'Labor Practices pulse',
  nfr_310_score: 'Labor Practices score',
  nfr_310_pulse_rsi: 'Labor Practices momentum',
  neg_exposure_310: 'Labor Practices exposure',
  nfr_320_pulse: 'Employee Health & Safety pulse',
  nfr_320_score: 'Employee Health & Safety score',
  nfr_320_pulse_rsi: 'Employee Health & Safety momentum',
  neg_exposure_320: 'Employee Health & Safety exposure',
  nfr_330_pulse: 'Employee Engagement, Diversity & Inclusion pulse',
  nfr_330_score: 'Employee Engagement, Diversity & Inclusion score',
  nfr_330_pulse_rsi: 'Employee Engagement, Diversity & Inclusion momentum',
  neg_exposure_330: 'Employee Engagement, Diversity & Inclusion exposure',
  nfr_410_pulse: 'Product Design & Lifecycle Management pulse',
  nfr_410_score: 'Product Design & Lifecycle Management score',
  nfr_410_pulse_rsi: 'Product Design & Lifecycle Management momentum',
  neg_exposure_410: 'Product Design & Lifecycle Management exposure',
  nfr_420_pulse: 'Business Model Resilience pulse',
  nfr_420_score: 'Business Model Resilience score',
  nfr_420_pulse_rsi: 'Business Model Resilience momentum',
  neg_exposure_420: 'Business Model Resilience exposure',
  nfr_430_pulse: 'Supply Chain Management pulse',
  nfr_430_score: 'Supply Chain Management score',
  nfr_430_pulse_rsi: 'Supply Chain Management momentum',
  neg_exposure_430: 'Supply Chain Management exposure',
  nfr_440_pulse: 'Materials Sourcing & Efficiency pulse',
  nfr_440_score: 'Materials Sourcing & Efficiency score',
  nfr_440_pulse_rsi: 'Materials Sourcing & Efficiency momentum',
  neg_exposure_440: 'Materials Sourcing & Efficiency exposure',
  nfr_450_pulse: 'Physical Impacts of Climate Change pulse',
  nfr_450_score: 'Physical Impacts of Climate Change score',
  nfr_450_pulse_rsi: 'Physical Impacts of Climate Change momentum',
  neg_exposure_450: 'Physical Impacts of Climate Change exposure',
  nfr_510_pulse: 'Business Ethics pulse',
  nfr_510_score: 'Business Ethics score',
  nfr_510_pulse_rsi: 'Business Ethics momentum',
  neg_exposure_510: 'Business Ethics exposure',
  nfr_520_pulse: 'Competitive Behavior pulse',
  nfr_520_score: 'Competitive Behavior score',
  nfr_520_pulse_rsi: 'Competitive Behavior momentum',
  neg_exposure_520: 'Competitive Behavior exposure',
  nfr_530_pulse: 'Management of the Legal & Regulatory Environment pulse',
  nfr_530_score: 'Management of the Legal & Regulatory Environment score',
  nfr_530_pulse_rsi: 'Management of the Legal & Regulatory Environment momentum',
  neg_exposure_530: 'Management of the Legal & Regulatory Environment exposure',
  nfr_540_pulse: 'Critical Incident Risk Management pulse',
  nfr_540_score: 'Critical Incident Risk Management score',
  nfr_540_pulse_rsi: 'Critical Incident Risk Management momentum',
  neg_exposure_540: 'Critical Incident Risk Management exposure',
  nfr_550_pulse: 'Systemic Risk Management pulse',
  nfr_550_score: 'Systemic Risk Management score',
  nfr_550_pulse_rsi: 'Systemic Risk Management momentum',
  neg_exposure_550: 'Systemic Risk Management exposure',
  nfr_data_pulse: 'Data-driven Materiality pulse',
  nfr_data_score: 'Data-driven Materiality score',
  nfr_data_pulse_rsi: 'Data-driven Materiality momentum',
  neg_exposure_data: 'Data-driven Materiality exposure',
  nfr_sasb_pulse: 'SASB Materiality pulse',
  nfr_sasb_score: 'SASB Materiality score',
  nfr_sasb_pulse_rsi: 'SASB Materiality momentum',
  neg_exposure_sasb: 'SASB Materiality exposure',
};

export const singleIndexAnalyticsMap = {
  nfr_110_pulse: 'GHG Emissions',
  nfr_110_score: 'GHG Emissions',
  nfr_110_pulse_rsi: 'GHG Emissions',
  neg_exposure_110: 'GHG Emissions',
  nfr_120_pulse: 'Air Quality',
  nfr_120_score: 'Air Quality',
  nfr_120_pulse_rsi: 'Air Quality',
  neg_exposure_120: 'Air Quality',
  nfr_130_pulse: 'Energy Management',
  nfr_130_score: 'Energy Management',
  nfr_130_pulse_rsi: 'Energy Management',
  neg_exposure_130: 'Energy Management',
  nfr_140_pulse: 'Water & Wastewater Management',
  nfr_140_score: 'Water & Wastewater Management',
  nfr_140_pulse_rsi: 'Water & Wastewater Management',
  neg_exposure_140: 'Water & Wastewater Management',
  nfr_150_pulse: 'Waste & Hazardous Materials Management',
  nfr_150_score: 'Waste & Hazardous Materials Management',
  nfr_150_pulse_rsi: 'Waste & Hazardous Materials Management',
  neg_exposure_150: 'Waste & Hazardous Materials Management',
  nfr_160_pulse: 'Ecological Impacts',
  nfr_160_score: 'Ecological Impacts',
  nfr_160_pulse_rsi: 'Ecological Impacts',
  neg_exposure_160: 'Ecological Impacts',
  nfr_210_pulse: 'Human Rights & Community Relations',
  nfr_210_score: 'Human Rights & Community Relations',
  nfr_210_pulse_rsi: 'Human Rights & Community Relations',
  neg_exposure_210: 'Human Rights & Community Relations',
  nfr_220_pulse: 'Customer Privacy',
  nfr_220_score: 'Customer Privacy',
  nfr_220_pulse_rsi: 'Customer Privacy',
  neg_exposure_220: 'Customer Privacy',
  nfr_230_pulse: 'Data Security',
  nfr_230_score: 'Data Security',
  nfr_230_pulse_rsi: 'Data Security',
  neg_exposure_230: 'Data Security',
  nfr_240_pulse: 'Access & Affordability',
  nfr_240_score: 'Access & Affordability',
  nfr_240_pulse_rsi: 'Access & Affordability',
  neg_exposure_240: 'Access & Affordability',
  nfr_250_pulse: 'Product Quality & Safety',
  nfr_250_score: 'Product Quality & Safety',
  nfr_250_pulse_rsi: 'Product Quality & Safety',
  neg_exposure_250: 'Product Quality & Safety',
  nfr_260_pulse: 'Customer Welfare',
  nfr_260_score: 'Customer Welfare',
  nfr_260_pulse_rsi: 'Customer Welfare',
  neg_exposure_260: 'Customer Welfare',
  nfr_270_pulse: 'Selling Practices & Product Labeling',
  nfr_270_score: 'Selling Practices & Product Labeling',
  nfr_270_pulse_rsi: 'Selling Practices & Product Labeling',
  neg_exposure_270: 'Selling Practices & Product Labeling',
  nfr_310_pulse: 'Labor Practices',
  nfr_310_score: 'Labor Practices',
  nfr_310_pulse_rsi: 'Labor Practices',
  neg_exposure_310: 'Labor Practices',
  nfr_320_pulse: 'Employee Health & Safety',
  nfr_320_score: 'Employee Health & Safety',
  nfr_320_pulse_rsi: 'Employee Health & Safety',
  neg_exposure_320: 'Employee Health & Safety',
  nfr_330_pulse: 'Employee Engagement, Diversity & Inclusion',
  nfr_330_score: 'Employee Engagement, Diversity & Inclusion',
  nfr_330_pulse_rsi: 'Employee Engagement, Diversity & Inclusion',
  neg_exposure_330: 'Employee Engagement, Diversity & Inclusion',
  nfr_410_pulse: 'Product Design & Lifecycle Management',
  nfr_410_score: 'Product Design & Lifecycle Management',
  nfr_410_pulse_rsi: 'Product Design & Lifecycle Management',
  neg_exposure_410: 'Product Design & Lifecycle Management',
  nfr_420_pulse: 'Business Model Resilience',
  nfr_420_score: 'Business Model Resilience',
  nfr_420_pulse_rsi: 'Business Model Resilience',
  neg_exposure_420: 'Business Model Resilience',
  nfr_430_pulse: 'Supply Chain Management',
  nfr_430_score: 'Supply Chain Management',
  nfr_430_pulse_rsi: 'Supply Chain Management',
  neg_exposure_430: 'Supply Chain Management',
  nfr_440_pulse: 'Materials Sourcing & Efficiency',
  nfr_440_score: 'Materials Sourcing & Efficiency',
  nfr_440_pulse_rsi: 'Materials Sourcing & Efficiency',
  neg_exposure_440: 'Materials Sourcing & Efficiency',
  nfr_450_pulse: 'Physical Impacts of Climate Change',
  nfr_450_score: 'Physical Impacts of Climate Change',
  nfr_450_pulse_rsi: 'Physical Impacts of Climate Change',
  neg_exposure_450: 'Physical Impacts of Climate Change',
  nfr_510_pulse: 'Business Ethics',
  nfr_510_score: 'Business Ethics',
  nfr_510_pulse_rsi: 'Business Ethics',
  neg_exposure_510: 'Business Ethics',
  nfr_520_pulse: 'Competitive Behavior',
  nfr_520_score: 'Competitive Behavior',
  nfr_520_pulse_rsi: 'Competitive Behavior',
  neg_exposure_520: 'Competitive Behavior',
  nfr_530_pulse: 'Management of the Legal & Regulatory Environment',
  nfr_530_score: 'Management of the Legal & Regulatory Environment',
  nfr_530_pulse_rsi: 'Management of the Legal & Regulatory Environment',
  neg_exposure_530: 'Management of the Legal & Regulatory Environment',
  nfr_540_pulse: 'Critical Incident Risk Management',
  nfr_540_score: 'Critical Incident Risk Management',
  nfr_540_pulse_rsi: 'Critical Incident Risk Management',
  neg_exposure_540: 'Critical Incident Risk Management',
  nfr_550_pulse: 'Systemic Risk Management',
  nfr_550_score: 'Systemic Risk Management',
  nfr_550_pulse_rsi: 'Systemic Risk Management',
  neg_exposure_550: 'Systemic Risk Management',
  nfr_data_pulse: 'Data-driven Materiality',
  nfr_sasb_pulse: 'SASB Materiality',
  nfr_data_score: 'Data-driven Materiality',
  nfr_sasb_score: 'SASB Materiality',
  nfr_data_pulse_rsi: 'Data-driven Materiality',
  nfr_sasb_pulse_rsi: 'SASB Materiality',
  neg_exposure_data: 'Data-driven Materiality',
  neg_exposure_sasb: 'SASB Materiality',
};
