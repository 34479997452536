import Typo from 'ui/typo/Typo';
import { Container, TextWrapper } from './SecondaryChip.styles';
import { COLORS } from 'styles/colorTheme';

const SecondaryChip = ({
  children,
  color = COLORS.Background100,
  icon,
  maxWidth,
  py = '8px',
  px = '12px',
  selected = false,
  onClick,
  sx = {},
  fontSx = {},
}) => {
  return (
    <Container
      color={color}
      selected={selected}
      py={py}
      px={px}
      style={{ ...sx }}
      onClick={onClick}
    >
      {icon && <img src={icon} width={12} />}
      <TextWrapper maxWidth={maxWidth}>
        <Typo variant="btn1" color={COLORS.Text500} sx={{ ...fontSx }}>
          {children}
        </Typo>
      </TextWrapper>
    </Container>
  );
};

export default SecondaryChip;
