import { media } from 'media';
import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 255px);
  column-gap: 40px;
  row-gap: 32px;

  ${media.mobile`
    display: block;
  `}
`;
