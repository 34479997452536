import { Container } from "./LogoStyles"

const Logo = ({onClick}) => {
  return (
    <Container onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
        <path d="M33.5659 33.1274C35.7146 30.9787 35.7146 27.4951 33.5659 25.3465C31.4173 23.1979 27.9337 23.1979 25.7851 25.3465C23.6365 27.4951 23.6365 30.9787 25.7851 33.1274C27.9337 35.276 31.4173 35.276 33.5659 33.1274Z" fill="#10A37F"/>
        <path d="M26.9085 14.6352C27.9278 15.2305 29.1508 15.5141 30.4537 15.3326C32.9218 14.9886 34.8879 12.956 35.1466 10.4771C35.4913 7.17313 32.909 4.38318 29.6756 4.38318C28.6656 4.38318 27.7222 4.66002 26.9085 5.13509V5.12905C25.4598 6.05231 24.095 6.86336 22.9668 7.50977C21.127 8.5634 18.8726 8.5634 17.0335 7.50977C15.9053 6.86336 14.5399 6.05298 13.0918 5.12905V5.13509C12.9937 5.07798 12.8936 5.02355 12.7921 4.97248C12.7767 4.96442 12.7612 4.9577 12.7457 4.95031C12.5804 4.869 12.4104 4.79576 12.2357 4.73125C12.1847 4.71244 12.1343 4.69295 12.0825 4.67548C12.0046 4.64927 11.926 4.62441 11.846 4.60156C11.7492 4.57334 11.6505 4.54848 11.5517 4.52563C11.4858 4.51085 11.4193 4.49539 11.3528 4.48263C11.2238 4.45777 11.0927 4.43828 10.9604 4.42282C10.9133 4.41745 10.8656 4.41274 10.8179 4.40804C10.6553 4.39326 10.4913 4.38318 10.3247 4.38318C7.28613 4.38318 4.82275 6.84656 4.82275 9.88513C4.82275 10.8951 5.09959 11.8385 5.57467 12.6522H5.56862C6.49188 14.0996 7.30226 15.4643 7.948 16.5926C9.0023 18.433 9.0023 20.6888 7.948 22.5299C7.30158 23.6581 6.49121 25.0222 5.56862 26.4703H5.57467C4.97931 27.4896 4.69508 28.7132 4.87718 30.0155C5.22189 32.4836 7.25455 34.4497 9.73338 34.7084C13.0374 35.0531 15.8273 32.4708 15.8273 29.2374C15.8273 28.2274 15.5505 27.284 15.0754 26.4703H15.0815C14.1582 25.0229 13.3478 23.6581 12.7021 22.5299C11.6478 20.6895 11.6478 18.4337 12.7021 16.5926C12.7068 16.5845 12.7115 16.5764 12.7162 16.5684C13.7396 14.783 15.2226 13.3 17.0086 12.2759C17.0167 12.2712 17.0247 12.2665 17.0328 12.2618C18.8733 11.2075 21.129 11.2075 22.9702 12.2618C24.0984 12.9082 25.4624 13.7186 26.9105 14.6412V14.6352H26.9085Z" fill="#10A37F"/>
      </svg>
    </Container>
  )
}

export default Logo