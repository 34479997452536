import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const CheckBoxContainer = styled.div`
  width: 24px;
  height: 24px;
  box-sizing: border-box;

  display: inline-block;

  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const CheckBox = styled.span`
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  box-sizing: border-box;

  border: ${(props) =>
    props.disabled ? `solid 1px ${COLORS.Text50}` : `solid 1px ${COLORS.Primary500}`};
  background: ${(props) =>
    props.disabled ? '' : props.checked ? `${COLORS.Primary500}` : 'white'};
  border-radius: 4px;
  transition: all 150ms;
  background-image: ${(props) => (props.icon && props.checked ? `url(${props.icon})` : '')};
  background-repeat: no-repeat;
  background-position: center center;
`;

export const CheckBox_unfilled = styled.span`
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  box-sizing: border-box;

  border: ${(props) =>
    props.disabled ? `solid 1px ${COLORS.Text50}` : `solid 1px ${COLORS.Primary500}`};
  border-radius: 4px;
  transition: all 150ms;
  background-image: ${(props) => (props.icon && props.checked ? `url(${props.icon})` : '')};
  background-repeat: no-repeat;
  background-position: center center;
`;

export const HiddenCheckBox = styled.input`
  opacity: 0;
  height: 0;
  width: 0;
  position: absolute;
`;
