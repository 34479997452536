import { Container } from './ScoreBoardStyles';
import IndexCard from 'ui/card/indexCard/IndexCard';
import exposureImg from 'assets/exposure.svg';
import articleImg from 'assets/article.svg';
import momentumImg from 'assets/momentum.svg';
import rankImg from 'assets/company_rank.svg';
import ScoreCardStacked from 'ui/card/scoreCardStacked/ScoreCardStacked';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Nodata from 'ui/errorSigns/noData/Nodata';
import Typo from 'ui/typo/Typo';
import { COLORS } from 'styles/colorTheme';

const ScoreBoard = ({ data, isLoading, sx }) => {
  const [t] = useTranslation('companyDetails');
  const [dT] = useTranslation('noData');
  const language = useSelector((state) => state.user.language);

  const [esgRisk, viral, articleCounts, momentum, rank, dimensions] = data;
  if (!isLoading && data.length === 0)
    return <Nodata desc={dT('No data.')} sx={{ height: '210px' }} />;

  return (
    <Container style={{ ...sx }}>
      <ScoreCardStacked
        title={t('SASB risk')}
        score={esgRisk?.esg_risk.toFixed(0)}
        desc={esgRisk?.[`esg_risk_degree_${language}`]}
        maxScore={100}
      />
      <IndexCard
        title={t('Exposure')}
        icon={exposureImg}
        score={
          <Typo variant="h1" color={COLORS.Text400}>
            {viral?.viral_rank}
            <Typo variant="caption2" color={COLORS.Text100}>
              /{viral?.total_comp_count}
            </Typo>
          </Typo>
        }
        desc={
          viral
            ? `${language === 'ko' ? '노출상위' : 'Top'} ${viral?.viral_top_percentage.toFixed(0)}%`
            : undefined
        }
      />
      <IndexCard
        title={t('Article counts')}
        icon={articleImg}
        desc={
          articleCounts
            ? `${articleCounts?.article_counts} ${language === 'ko' ? '개' : ''}`
            : undefined
        }
      />
      <IndexCard
        title={t('Momentum')}
        icon={momentumImg}
        desc={momentum?.[`momentum_degree_${language}`]}
      />
      <IndexCard
        title={t('Industry Rank')}
        icon={rankImg}
        desc={rank ? rank?.industry_rank + ' / ' + rank?.industry_count + t(' ') : undefined}
      />
      <ScoreCardStacked
        title={t('Environment risk')}
        score={dimensions?.dimension_nfr_pulses[0].toFixed(0)}
        desc={dimensions?.[`dimension_degrees_${language}`][0]}
        maxScore={100}
      />
      <ScoreCardStacked
        title={t('Social capital risk')}
        score={dimensions?.dimension_nfr_pulses[1].toFixed(0)}
        desc={dimensions?.[`dimension_degrees_${language}`][1]}
        maxScore={100}
      />
      <ScoreCardStacked
        title={t('Human capital risk')}
        score={dimensions?.dimension_nfr_pulses[2].toFixed(0)}
        desc={dimensions?.[`dimension_degrees_${language}`][2]}
        maxScore={100}
      />
      <ScoreCardStacked
        title={t('Bus Mod & Innov risk')}
        score={dimensions?.dimension_nfr_pulses[3].toFixed(0)}
        desc={dimensions?.[`dimension_degrees_${language}`][3]}
        maxScore={100}
      />
      <ScoreCardStacked
        title={t('Leader & Govern risk')}
        score={dimensions?.dimension_nfr_pulses[4].toFixed(0)}
        desc={dimensions?.[`dimension_degrees_${language}`][4]}
        maxScore={100}
      />
    </Container>
  );
};

export default ScoreBoard;
