import { useEffect, useState } from 'react';
import { getCompanyEsgViralData } from 'api/dashboard';

const useEsgViral = (corpCodes) => {
  const [isViralLoading, setIsViralLoading] = useState(true);
  const [esgRiskExposureData, setEsgRiskExposureData] = useState();
  const [esgControversyData, setEsgControversyData] = useState();

  useEffect(() => {
    if (!corpCodes) return;
    getData();
  }, [corpCodes]);

  const getData = async () => {
    setIsViralLoading(true);
    const res = await getCompanyEsgViralData(corpCodes);
    setIsViralLoading(false);
    if (!res) return;

    setEsgRiskExposureData(res.data.comp_bar_chart);
    setEsgControversyData(res.data.sasb_bar_chart);
  };

  return [esgRiskExposureData, esgControversyData, isViralLoading];
};

export default useEsgViral;
