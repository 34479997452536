import Typo from 'ui/typo/Typo';
import { Container, TitleWrapper, CardsWrapper, CardWrapper } from './EsgCardsStyles';
import { COLORS } from 'styles/colorTheme';
import QuestionIcon from 'ui/icon/questionIcon/QuestionIcon';
import SecondaryCard from 'ui/card/secondaryCard/SecondaryCard';
import EsgTopicPopup from 'ui/popup/esgTopicPopup/EsgTopicPopup';
import { useState } from 'react';
import Slider from 'ui/slider/Slider';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const EsgCards = ({ title, desc, cards, category, sx }) => {
  const [t] = useTranslation('common');
  const language = useSelector((state) => state.user.language);

  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const handleClick = (i) => {
    setIsOpen(true);
    setIndex(i);
  };

  if (cards.length === 0) return;

  return (
    <Container visible={cards.length > 0} style={{ ...sx }}>
      <TitleWrapper>
        <Typo variant="h2" color={COLORS.Text500}>
          {title}
        </Typo>
        <QuestionIcon desc={desc} />
      </TitleWrapper>
      <Slider>
        <CardsWrapper>
          {cards?.map((card, i) => (
            <CardWrapper key={i}>
              <SecondaryCard
                title={card[`general_issue_name_${language}`]}
                contents={card[`general_issue_desc_${language}`]}
                btnLable={t('More')}
                onClick={() => handleClick(i)}
              />
            </CardWrapper>
          ))}
        </CardsWrapper>
      </Slider>
      <EsgTopicPopup
        title={cards[index][`general_issue_name_${language}`]}
        category={category}
        desc={cards[index][`general_issue_desc_${language}`]}
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
      />
    </Container>
  );
};

export default EsgCards;
