import water from 'assets/water.svg';
import dataSecurity from 'assets/dataSecurity.svg';
import ghgEmissions from 'assets/ghgEmissions.svg';
import airQuality from 'assets/airQuality.svg';
import energy from 'assets/energy.svg';
import waste from 'assets/waste.svg';
import eco from 'assets/eco.svg';
import humanRight from 'assets/humanRight.svg';
import customerPrivacy from 'assets/customerPrivacy.svg';
import access from 'assets/access.svg';
import productQuality from 'assets/productQuality.svg';
import welfare from 'assets/welfare.svg';
import sellingPractice from 'assets/sellingPractice.svg';
import labor from 'assets/labor.svg';
import employeeSafety from 'assets/employeeSafety.svg';
import engagement from 'assets/employeeEngagement.svg';
import resilience from 'assets/resilience.svg';
import supplyChain from 'assets/supplyChain.svg';
import efficiency from 'assets/efficiency.svg';
import climateChange from 'assets/climateChange.svg';
import productDesign from 'assets/productDesign.svg';
import competitve from 'assets/competitive.svg';
import criticalIncident from 'assets/criticalIncident.svg';
import ethics from 'assets/ethics.svg';
import legal from 'assets/legal.svg';
import riskManagement from 'assets/riskManagement.svg';

export const SASB_TAG_ID_ICON_MAP = Object.freeze({
  ESG_E110: {
    name_en: 'GHG Emissions',
    name_ko: '온실가스 배출',
    iconUri: ghgEmissions,
  },
  ESG_E120: {
    name_en: 'Air Quality',
    name_ko: '대기 질',
    iconUri: airQuality,
  },
  ESG_E130: {
    name_en: 'Energy Management',
    name_ko: '에너지 관리',
    iconUri: energy,
  },
  ESG_E140: {
    name_en: 'Water & Wastewater Management',
    name_ko: '물 및 폐수 관리',
    iconUri: water,
  },
  ESG_E150: {
    name_en: 'Waste & Hazardous Materials Management',
    name_ko: '폐기물 및 유해 물질 관리',
    iconUri: waste,
  },
  ESG_E160: {
    name_en: 'Ecological Impacts',
    name_ko: '생물다양성 영향',
    iconUri: eco,
  },
  ESG_S210: {
    name_en: 'Human Rights & Community Relations',
    name_ko: '인권 및 지역사회 관계',
    iconUri: humanRight,
  },
  ESG_S220: {
    name_en: 'Customer Privacy',
    name_ko: '고객 개인 정보',
    iconUri: customerPrivacy,
  },
  ESG_S230: {
    name_en: 'Data Security',
    name_ko: '데이터 보안',
    iconUri: dataSecurity,
  },
  ESG_S240: {
    name_en: 'Access & Affordability',
    name_ko: '접근성 및 적정가격',
    iconUri: access,
  },
  ESG_S250: {
    name_en: 'Product Quality & Safety',
    name_ko: '제품 품질 및 안전성',
    iconUri: productQuality,
  },
  ESG_S260: {
    name_en: 'Customer Welfare',
    name_ko: '고객 복지',
    iconUri: welfare,
  },
  ESG_S270: {
    name_en: 'Selling Practices & Product Labeling',
    name_ko: '판매 관행 및 제품 라벨링',
    iconUri: sellingPractice,
  },
  ESG_S310: {
    name_en: 'Labor Practices',
    name_ko: '노동 관행',
    iconUri: labor,
  },
  ESG_S320: {
    name_en: 'Employee Health & Safety',
    name_ko: '직원 건강 및 안전',
    iconUri: employeeSafety,
  },
  ESG_S330: {
    name_en: 'Employee Engagement, Diversity & Inclusion',
    name_ko: '직원 참여, 다양성 및 포용성',
    iconUri: engagement,
  },
  ESG_S410: {
    name_en: 'Product Design & Lifecycle Management',
    name_ko: '제품 설계 및 수명주기 관리',
    iconUri: productDesign,
  },
  ESG_S420: {
    name_en: 'Business Model Resilience',
    name_ko: '비즈니스 모델 탄력성',
    iconUri: resilience,
  },
  ESG_S430: {
    name_en: 'Supply Chain Management',
    name_ko: '공급망 관리',
    iconUri: supplyChain,
  },
  ESG_S440: {
    name_en: 'Materials Sourcing & Efficiency',
    name_ko: '자재 조달 및 효율성',
    iconUri: efficiency,
  },
  ESG_S450: {
    name_en: 'Physical Impacts of Climate Change',
    name_ko: '기후 변화의 물리적 영향',
    iconUri: climateChange,
  },
  ESG_G510: {
    name_en: 'Business Ethics',
    name_ko: '비즈니스 윤리',
    iconUri: ethics,
  },
  ESG_G520: {
    name_en: 'Competitive Behavior',
    name_ko: '경쟁적 행위',
    iconUri: competitve,
  },
  ESG_G530: {
    name_en: 'Management of the Legal & Regulatory Environment',
    name_ko: '법률 및 규제 환경 관리',
    iconUri: legal,
  },
  ESG_G540: {
    name_en: 'Critical Incident Risk Management',
    name_ko: '중대 사고 위험 관리',
    iconUri: criticalIncident,
  },
  ESG_G550: {
    name_en: 'Systemic Risk Management',
    name_ko: '체계적 위험 관리',
    iconUri: riskManagement,
  },
});
