import styled from 'styled-components';

export const LanguageContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const LanguageButtons = styled.div`
  display: flex;
  margin-left: 120px;
  gap: 12px;
`;
