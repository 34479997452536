import { COLORS } from 'styles/colorTheme';
import {
  Display1,
  Display2,
  Display3,
  Head1,
  Head2,
  Head3,
  Body1,
  Body2,
  Body3,
  Button1,
  Button2,
  Caption1,
  Caption2,
  Overline1,
  Overline2,
} from './TypoStyles';

const detectColorParameter = (color) => {
  const keys = Object.keys(COLORS);
  const hit = keys.includes(color);
  if (hit) color = COLORS[color];
  return color;
};

const Typo = ({ variant = 'b2', className, children, color, sx }) => {
  color = detectColorParameter(color);

  if (variant === 'd1')
    return (
      <Display1 className={className} color={color} style={{ ...sx }}>
        {children}
      </Display1>
    );
  if (variant === 'd2')
    return (
      <Display2 className={className} color={color} style={{ ...sx }}>
        {children}
      </Display2>
    );
  if (variant === 'd3')
    return (
      <Display3 className={className} color={color} style={{ ...sx }}>
        {children}
      </Display3>
    );
  if (variant === 'h1')
    return (
      <Head1 className={className} color={color} style={{ ...sx }}>
        {children}
      </Head1>
    );
  if (variant === 'h2')
    return (
      <Head2 className={className} color={color} style={{ ...sx }}>
        {children}
      </Head2>
    );
  if (variant === 'h3')
    return (
      <Head3 className={className} color={color} style={{ ...sx }}>
        {children}
      </Head3>
    );
  if (variant === 'b1')
    return (
      <Body1 className={className} color={color} style={{ ...sx }}>
        {children}
      </Body1>
    );
  if (variant === 'b2')
    return (
      <Body2 className={className} color={color} style={{ ...sx }}>
        {children}
      </Body2>
    );
  if (variant === 'b3')
    return (
      <Body3 className={className} color={color} style={{ ...sx }}>
        {children}
      </Body3>
    );
  if (variant === 'caption1')
    return (
      <Caption1 className={className} color={color} style={{ ...sx }}>
        {children}
      </Caption1>
    );
  if (variant === 'caption2')
    return (
      <Caption2 className={className} color={color} style={{ ...sx }}>
        {children}
      </Caption2>
    );
  if (variant === 'btn1')
    return (
      <Button1 className={className} color={color} style={{ ...sx }}>
        {children}
      </Button1>
    );
  if (variant === 'btn2')
    return (
      <Button2 className={className} color={color} style={{ ...sx }}>
        {children}
      </Button2>
    );
  if (variant === 'overline1')
    return (
      <Overline1 className={className} color={color} style={{ ...sx }}>
        {children}
      </Overline1>
    );
  if (variant === 'overline2')
    return (
      <Overline2 className={className} color={color} style={{ ...sx }}>
        {children}
      </Overline2>
    );
};

export default Typo;
