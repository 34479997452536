import styled from 'styled-components';

export const Container = styled.div`
  display: inline-flex;
  padding: 2px;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

export const CheckBoxWrapper = styled.div`
  margin-top: 4px;
  flex-shrink: 0;
`;

export const Wrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
