import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Chip from 'ui/chips/chip/Chip';
import ComponentPreloader from 'ui/preloaders/componentPreloader/ComponentPreloader';
import ESGdimension from 'data/esgDimensionmap.json';
import Nodata from 'ui/errorSigns/noData/Nodata';
import NewsCardSmall from 'ui/card/newsCards/image/newsCardSmall/NewsCardSmall';
import PrimaryMenu from 'ui/menus/primaryMenu/PrimaryMenu';
import Pagination from 'ui/pagination/Pagination';
import { Container, MenuWrapper, ChipWrapper, CardWrapper, NewsWrapper } from './EsgNewsStyles';

const EsgNews = ({
  dimensions,
  esgNewsMenu,
  articles,
  totalN,
  page,
  handlePage,
  handleMenu,
  isLoading,
  sx,
}) => {
  const language = useSelector((state) => state.user.language);
  const [t] = useTranslation('noData');
  return (
    <Container style={{ ...sx }}>
      <MenuWrapper>
        <PrimaryMenu
          menuList={dimensions}
          mode="fill"
          activeIndex={esgNewsMenu.selectedDimension}
          onClick={(index) => handleMenu(index, 0)}
        />
        <ChipWrapper>
          {ESGdimension[esgNewsMenu.selectedDimension].GIC.map((gic, i) => (
            <Chip
              key={i}
              selected={i === esgNewsMenu.selectedGic}
              onClick={() => handleMenu(esgNewsMenu.selectedDimension, i)}
            >
              {gic[`GIC_Name_${language}`]}
            </Chip>
          ))}
        </ChipWrapper>
      </MenuWrapper>

      {!isLoading ? (
        <>
          <NewsWrapper>
            {articles.map((article, i) => (
              <CardWrapper key={i}>
                <NewsCardSmall article={article} />
              </CardWrapper>
            ))}
          </NewsWrapper>
          {articles.length === 0 ? <Nodata desc={t('No ESG news.')} /> : null}
        </>
      ) : (
        <ComponentPreloader width="1206px" height="300px" />
      )}

      {!isLoading && (
        <Pagination
          totalPage={Math.ceil(totalN / 20)}
          currentPage={page}
          step={5}
          onClick={handlePage}
        />
      )}
    </Container>
  );
};

export default EsgNews;
