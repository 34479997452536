import { getMainNews } from 'api/news';
import { useEffect, useState } from 'react';

const useMainNews = () => {
  useEffect(() => {
    getData();
  }, []);

  const [mainNews, setMainNews] = useState([]);
  const [esgTopicNews, setEsgTopicNews] = useState([]);

  const getData = async () => {
    const res = await getMainNews();
    if (!res) {
      setMainNews([]);
      setEsgTopicNews([]);
    } else {
      const mainNews = res.data.main_news;
      const esgTopicNews = res.data.top_esg_news;
      setMainNews(mainNews);
      setEsgTopicNews(esgTopicNews);
    }
  };

  return [mainNews, esgTopicNews];
};

export default useMainNews;
