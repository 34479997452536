import { media } from 'media';
import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div`
  width: 100%;
`;

export const Subheader = styled.div`
  background-color: ${COLORS.Background50};
  height: 72px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
`;

export const SubheaderWrapper = styled.div`
  width: 1204px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.mobile`
    padding: 0 16px;
  `}
`;

export const Wrapper = styled.div`
  margin: auto;
  width: 1204px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;

  ${media.mobile`
    width: 100%;
    gap: 6px;
  `}
`;

export const SubContentsWarpper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  ${media.mobile`
    margin-top: 12px;
    display: block;
  `}
`;

export const MenuWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
