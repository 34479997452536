import { useState } from 'react';
import { Stack, Button } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ReplayIcon from '@mui/icons-material/Replay';
import Drawer from '@mui/material/Drawer';
import { useApply } from 'components/analyticsPage/tabPanels/components/drawers/indexDrawer/hooks/useApply';
import { useResetIndexQuery } from 'components/analyticsPage/tabPanels/components/drawers/indexDrawer/hooks/useResetIndexQuery';
import IndexTab from 'components/analyticsPage/tabPanels/components/drawers/indexDrawer/components/IndexTab';
import { useSelector, useDispatch } from 'react-redux';
import { getAnalyticsTabList } from 'api/analytics';
import { saveAnalyticsTabList } from 'services/analytics/Action';
import { useTranslation } from 'react-i18next';
import * as amplitude from '@amplitude/analytics-browser';

const IndexDrawer = ({ open, data, action, closeDrawer }) => {
  const [createAnalysis, updateAnalytics] = useApply();
  const [reset] = useResetIndexQuery();
  const [tab] = useState(0);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const applySettings = async () => {
    if (action === 'create') {
      amplitude.track('analytics_analysis_create_complete', { category: 'index' });
      await createAnalysis();
    }
    if (action === 'update') {
      amplitude.track('analytics_analysis_update', { category: 'index' });
      await updateAnalytics();
    }
    const fetchTabList = async () => {
      const results = await getAnalyticsTabList(user.userId);
      dispatch(saveAnalyticsTabList(results.data));
    };
    fetchTabList();
    reset();
    closeDrawer();
    window.location.reload();
  };

  const [t] = useTranslation('analytics');
  const [commonT] = useTranslation('common');

  return (
    <Drawer anchor="right" open={open} onClose={closeDrawer}>
      <Stack>
        <Tabs
          value={tab}
          sx={{ position: 'sticky', backgroundColor: '#fff', zIndex: '100', top: '0px' }}
        >
          <Tab label={t('Create charts')} />
        </Tabs>
        <Stack
          minHeight="88vh"
          maxWidth="70vw"
          p="30px 20px 0 20px"
          spacing={3}
          justifyContent="space-between"
        >
          <Stack spacing={3}>
            <Stack>
              <IndexTab data={data} open={tab === 0} />
            </Stack>
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={2}
            sx={{
              position: 'sticky',
              backgroundColor: '#fff',
              zIndex: '1',
              bottom: '0px',
              padding: '15px 0px',
            }}
          >
            <Button startIcon={<ReplayIcon />} onClick={reset}>
              {commonT('Reset')}
            </Button>
            <Button
              variant="contained"
              onClick={applySettings}
              disabled={
                data.tabName.length === 0 ||
                data.companyId === null ||
                (data.index.multiIndex.dataType === 1 &&
                  data.index.multiIndex.individualScore.esgCode === '') ||
                (data.index.singleIndex.compositeScore.materiality === '' &&
                  data.index.singleIndex.individualScore.esgCode === '')
              }
            >
              {commonT('Apply')}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Drawer>
  );
};

export default IndexDrawer;
