import Typo from 'ui/typo/Typo';
import {
  Container,
  ScoreWrapper,
  Icon,
  DescWrapper,
  TitleWrapper,
  Score,
} from './ScoreCardStackedStyles';
import QuestionIcon from 'ui/icon/questionIcon/QuestionIcon';
import { COLORS } from 'styles/colorTheme';

const ScoreCardStacked = ({ title, score = 0, maxScore = 100, desc, explanation, sx }) => {
  let color;
  let nofStacks = 0;
  if (desc === '매우 높음' || desc === 'Extremely High') {
    color = COLORS.Red200;
    nofStacks = 5;
  } else if (desc === '높음' || desc === 'High') {
    color = COLORS.Red200;
    nofStacks = 4;
  } else if (desc === '보통' || desc === 'Moderate') {
    color = COLORS.Secondary500;
    nofStacks = 3;
  } else if (desc === '낮음' || desc === 'Low') {
    color = COLORS.Blue200;
    nofStacks = 2;
  } else {
    color = COLORS.Blue200;
    nofStacks = 1;
  }
  // const nofStacks = Math.ceil(score / 10);
  const stackArr = new Array(5).fill(null);

  return (
    <Container style={{ ...sx }}>
      <TitleWrapper>
        <Typo variant="h3" color={COLORS.Text400}>
          {title}
        </Typo>
        {explanation ? <QuestionIcon desc={explanation} /> : null}
      </TitleWrapper>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="33"
        height="32"
        viewBox="0 0 33 32"
        fill="none"
      >
        <g>
          {stackArr.map((stack, i) => (
            <rect
              key={i}
              x="0.199951"
              y={28 - 7 * i}
              width="32"
              height="4"
              rx="2"
              fill={i < nofStacks ? color : COLORS.Background100}
              fillOpacity="0.8"
            />
          ))}
        </g>
      </svg>

      <ScoreWrapper>
        <Score>
          <Typo variant="h1" color={COLORS.Text400}>
            {score ? (
              <>
                {score}
                <Typo variant="caption2" color={COLORS.Text100}>
                  /{maxScore}
                </Typo>
              </>
            ) : (
              <> </>
            )}
          </Typo>
        </Score>
      </ScoreWrapper>

      <DescWrapper>
        <Typo variant="overline1" color={COLORS.Text400}>
          {desc ? <>{desc}</> : <></>}
        </Typo>
      </DescWrapper>
    </Container>
  );
};

export default ScoreCardStacked;
