import { saveDataQuery } from 'services/analytics/Action';
import { useDispatch, useSelector } from 'react-redux';

export const useTimeSeriesQuery = () => {

  const dispatch = useDispatch();
  const dataQuery = useSelector((state) => state.analytics.dataQuery);

  const setQuery = (obj) => {
    dispatch(
      saveDataQuery({
        ...dataQuery,
        esgData: {
          ...dataQuery.esgData,
          timeSeries: {
            ...dataQuery.esgData.timeSeries,
            ...obj,
          },
        }
      })
    )
  }

  return [setQuery];
};