import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Divider } from '@mui/material';
import queryString from 'query-string';

import * as authAPI from 'api/auth';
import { addFollowingList } from 'services/mylist/Actions';
import Button from 'ui/buttons/button/Button';
import { COLORS } from 'styles/colorTheme';
import Checkbox from 'ui/checkbox/defaultCheckbox/CheckBox';
import { saveUserId, saveInfo } from 'services/user/Actions';
import { Container, SignupBtnContainer, SignupBtn, RememberMeContainer } from './Signin.styles';
import FindPassword from 'components/authenticationPage/findPassword';
import PreLoader from 'ui/preloaders/screenPreloader/ScreenPreloader';
import { getUserData } from 'api/user';
import { getFollowList } from 'api/followings';
import Typo from 'ui/typo/Typo';
import Input from 'ui/inputs/Input';
import Reverify from '../reVerify/Reverify';

const DemoLogin = () => {
  const [t] = useTranslation('signIn');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState(false);
  const [openResetPwd, setOpenResetPwd] = useState(false);
  const [activatePreloader, setActivatePreloader] = useState(false);
  const [openReverify, setOpenReverify] = useState(false);
  const navigate = useNavigate();
  const handleEnter = (e) => {
    if (e.keyCode === 13) {
      handleLogin();
    }
  };
  const redirectionUrl = queryString.parse(window.location.search);

  useEffect(() => {
    const email = JSON.parse(localStorage.getItem('Email'));
    const isChecked = JSON.parse(localStorage.getItem('Checkbox'));

    if (email) setEmail(email);
    if (isChecked) setIsChecked(isChecked);
  }, []);

  const dispatch = useDispatch();

  const handleLogin = async () => {
    if (isChecked) {
      localStorage.setItem('Email', JSON.stringify(email));
      localStorage.setItem('Checkbox', JSON.stringify(isChecked));
    } else {
      localStorage.removeItem('Email');
      localStorage.setItem('Checkbox', JSON.stringify(isChecked));
    }

    setActivatePreloader(true);

    const result = await authAPI.login({
      email,
      password,
      expire_seconds: 41472000,
    });

    if (result && result.status === 200) {
      const data = result.data;
      localStorage.setItem('access_token', data.access_token);

      const userInfoRes = await getUserData(data.user_id);
      if (!userInfoRes) return;
      if (data.has_temp_password) {
        dispatch(saveUserId(data.user_id));
        dispatch(saveInfo(userInfoRes.data));
        if (redirectionUrl.redirection) {
          setTimeout(() => {
            window.location.href = redirectionUrl.redirection;
          }, 200);
        } else {
          setTimeout(() => {
            window.location.href = '/';
          }, 200);
        }
      } else {
        dispatch(saveUserId(data.user_id));
        dispatch(saveInfo(userInfoRes.data));
        if (redirectionUrl.redirection) {
          setTimeout(() => {
            window.location.href = redirectionUrl.redirection + `${data.user_id}` + `/${data.data}`;
          }, 200);
        } else {
          setTimeout(() => {
            window.location.href = '/';
          }, 200);
        }
      }

      const followList = await getFollowList();
      dispatch(addFollowingList(followList.data));
    } else if (result && result.status === 403) {
    } else {
      setError(true);
    }
    setActivatePreloader(false);
  };

  return (
    <>
      <PreLoader activate={activatePreloader} />

      <Container>
        <Input
          placeHolder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ height: '60px' }}
        />
        <Input
          placeHolder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={(e) => handleEnter(e)}
          type="password"
          helperText={error && t('Incorrect Email/Password')}
          sx={{ height: '60px' }}
        />

        <RememberMeContainer onClick={(e) => setIsChecked(!isChecked)}>
          <Checkbox isChecked={isChecked} sx={{ margin: '12px' }} />
          <Typo variant="b2" color={COLORS.Text400} className="regular">
            {t('Remember me')}
          </Typo>
        </RememberMeContainer>
        <Button onClick={() => handleLogin()} onKeyDown={(e) => handleEnter(e)} sx={{ height: 50 }}>
          <Typo variant="btn1" className="regular">
            {t('Login')}
          </Typo>
        </Button>
        <Button
          onClick={() => setOpenResetPwd(true)}
          sx={{ height: 50, backgroundColor: COLORS.Background100_70 }}
        >
          <Typo variant="btn2" color={COLORS.Text400} className="regular">
            {t('Find my password')}
          </Typo>
        </Button>
        <Divider />
        <SignupBtnContainer>
          <Typo variant="b2" color={COLORS.Text400} className="regular">
            {t("Don't have an account?")}
          </Typo>
          <SignupBtn onClick={() => navigate('/signup')}>
            <Typo variant="b2" color={COLORS.Primary500} className="regular">
              {t('Sign Up')}
            </Typo>
          </SignupBtn>
        </SignupBtnContainer>
        <SignupBtnContainer>
          <SignupBtn onClick={() => setOpenReverify(true)}>
            <Typo variant="b2" color={COLORS.Text400}>
              {t('Resend Email Verification')}
            </Typo>
          </SignupBtn>
        </SignupBtnContainer>
      </Container>

      <FindPassword open={openResetPwd} handleClose={() => setOpenResetPwd(false)} />
      <Reverify open={openReverify} handleClose={() => setOpenReverify(false)} />
    </>
  );
};

export default DemoLogin;
