import styled from "styled-components";

export const Container = styled.button`
  border: none;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
  background: none;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  padding: 2px;
`