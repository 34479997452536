import _ from 'lodash';
import { excelDownload } from 'utils/common';

export const useDownload = (column, rows, name) => {
  const _rows = _.cloneDeep(rows);
  _rows.unshift(column);

  const download = () => excelDownload(_rows, name);

  return [download];
};
