import { media } from 'media';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const ChildrenWrapper = styled.div``;

export const LeftBtn = styled.div`
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  position: absolute;
  left: ${(props) => '-' + props.width / 2 + 'px'};
  top: ${(props) => props.bodyHeight / 2 - props.width / 2 + 'px'};

  ${media.mobile`
    display: none;
  `}
`;

export const RightBtn = styled.div`
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  position: absolute;
  right: ${(props) => '-' + props.width / 2 + 'px'};
  top: ${(props) => props.bodyHeight / 2 - props.width / 2 + 'px'};

  ${media.mobile`
    display: none;
  `}
`;
