import { useTabs } from 'components/analyticsPage/tabs/components/tabItems/hooks/useTabs';
import { useSelector } from 'react-redux';

export const useCreateTab = () => {
  const [selectTab] = useTabs();
  const { tabList } = useSelector((state) => state.analytics);

  const createTab = () => {
    selectTab(tabList.length);
  };

  return [createTab];
};
