import { useEffect, useState } from 'react';
import { getEsgSummaryChart } from 'api/companyDetail';

const useSummarychart = (id, summaryChartMenu, summaryChartTime) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({
    summaryChartData_raw: {},
    summaryChartData_dimension: {},
    summaryChartData_materialCodes: {},
    summaryChartData_materialData: {},
  });

  useEffect(() => {
    getData();
  }, [id, summaryChartMenu, summaryChartTime]);

  const getData = async () => {
    setIsLoading(true);
    const res = await getEsgSummaryChart(id, summaryChartMenu, summaryChartTime);
    if (!res) return;

    if (summaryChartMenu === 'nfr_raw_pulse') setData({ ...data, summaryChartData_raw: res.data });
    if (summaryChartMenu === 'nfr_score_by_dimension')
      setData({ ...data, summaryChartData_dimension: res.data });
    if (summaryChartMenu === 'risk_score_by_material_codes')
      setData({ ...data, summaryChartData_materialCodes: res.data });
    if (summaryChartMenu === 'risk_score_by_material_data_codes')
      setData({ ...data, summaryChartData_materialData: res.data });
    setIsLoading(false);
  };

  return [data, isLoading];
};

export default useSummarychart;
