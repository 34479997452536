import { Stack, Button } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ReplayIcon from '@mui/icons-material/Replay';
import Drawer from '@mui/material/Drawer';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as amplitude from '@amplitude/analytics-browser';

import { getAnalyticsTabList } from 'api/analytics';
import { saveAnalyticsTabList } from 'services/analytics/Action';
import ESGdataTab from 'components/analyticsPage/tabPanels/components/drawers/dataDrawer/components/esgDataTab/ESGdataTab';
import StockdataTab from 'components/analyticsPage/tabPanels/components/drawers/dataDrawer/components/stockDataTab/StockdataTab.js';
import { useResetDataQuery } from 'components/analyticsPage/tabPanels/components/drawers/dataDrawer/hooks/useResetDataQuery';
import { useApply } from 'components/analyticsPage/tabPanels/components/drawers/dataDrawer/hooks/useApply';
import { useDataQuery } from 'components/analyticsPage/tabPanels/components/drawers/dataDrawer/hooks/useDataQuery';

const DataDrawer = ({ open, data, action, closeDrawer }) => {
  const [t] = useTranslation('analytics');
  const [commonT] = useTranslation('common');

  const [createAnalysis, updateAnalytics] = useApply();
  const [reset] = useResetDataQuery();
  const tab = useSelector((state) => state.analytics.dataQuery.target);
  const [query] = useDataQuery();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const applySettings = async () => {
    if (action === 'create') {
      amplitude.track('analytics_analysis_create_complete', { category: 'data' });
      await createAnalysis();
    }
    if (action === 'update') {
      amplitude.track('analytics_analysis_update', { category: 'data' });
      await updateAnalytics();
    }
    const fetchTabList = async () => {
      const results = await getAnalyticsTabList(user.userId);
      dispatch(saveAnalyticsTabList(results.data));
    };
    await fetchTabList();
    reset();
    closeDrawer();
  };

  const handleTabChange = (idx) => {
    if (idx === 0) {
      query({ target: 'dailyData' });
    }
    if (idx === 1) {
      query({ target: 'stockData' });
    }
  };

  return (
    <Drawer anchor="right" open={open} onClose={closeDrawer}>
      <Stack>
        <Tabs
          value={tab === 'dailyData' || tab === 'timeSeries' ? 0 : 1}
          sx={{ position: 'sticky', backgroundColor: '#fff', zIndex: '100', top: '0px' }}
        >
          <Tab label={t('ESG data')} onClick={() => handleTabChange(0)} />
          <Tab label={t('Stock price data')} onClick={() => handleTabChange(1)} />
        </Tabs>
        <Stack
          minHeight="88vh"
          maxWidth="70vw"
          p="30px 20px 0 20px"
          spacing={3}
          justifyContent="space-between"
        >
          <Stack>
            <ESGdataTab data={data} open={tab === 'dailyData' || tab === 'timeSeries'} />
            <StockdataTab data={data} open={tab === 'stockData'} />
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={2}
            sx={{
              position: 'sticky',
              backgroundColor: '#fff',
              zIndex: '1',
              bottom: '0px',
              padding: '15px 0px',
            }}
          >
            <Button startIcon={<ReplayIcon />} onClick={reset}>
              {commonT('Reset')}
            </Button>
            <Button
              variant="contained"
              onClick={applySettings}
              disabled={
                data?.tabName?.length === 0 ||
                data?.companyId === null ||
                (data?.esgData?.dataType === 1 &&
                  data?.esgData?.individualScore?.esgCode?.length === 0) ||
                data?.esgData?.to < data?.esgData?.from
              }
            >
              {commonT('Apply')}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Drawer>
  );
};

export default DataDrawer;
