import { Stack, Typography, TextField, Checkbox } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useSelector } from 'react-redux';
import { useDailyDataQuery } from 'components/analyticsPage/tabPanels/components/drawers/dataDrawer/components/esgDataTab/components/dailyData/hooks/useDailyDataQuery';
import Filters from 'components/analyticsPage/tabPanels/components/drawers/components/Filters';
import { useTranslation } from 'react-i18next';

const CrossSectional = ({ open }) => {
  const query = useSelector((state) => state.analytics.dataQuery.esgData.dailyData);
  const [setQuery] = useDailyDataQuery();
  const [t] = useTranslation('analytics');

  return (
    <Stack spacing={3} sx={{ display: open }}>
      <Stack width="100%" spacing={1}>
        <Typography sx={{ fontWeight: '500' }}>{t('Time window')}</Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            displayStaticWrapperAs="desktop"
            value={query.from}
            onChange={(e) => setQuery({ from: e })}
            disableFuture={true}
            renderInput={(params) => <TextField size="small" {...params} />}
          />
        </LocalizationProvider>
      </Stack>
      <Stack spacing={1}>
        <Typography sx={{ fontWeight: '500' }}>{t('Index')}</Typography>
        <Stack>
          <div>
            <Checkbox checked disabled />
            ESG Category
          </div>
          <Filters filters={query.filters} setQuery={setQuery} originalFilter={query.filters} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CrossSectional;
