import { Container, TitleWrapper, ChartWrapper, Title } from './EsgControversy.styles';
import { COLORS } from 'styles/colorTheme';
import Typo from 'ui/typo/Typo';
import { useTranslation } from 'react-i18next';
import ControversyBarChart from 'ui/charts/horizontalBarChart/ControversyBarChart';

const EsgControversy = ({ data, isLoading, sx }) => {
  const [t] = useTranslation('dashboard');

  return (
    <Container style={{ ...sx }}>
      <TitleWrapper>
        <Title>
          <Typo variant="h3" color={COLORS.Text500}>
            {t('ESG Controversies')}
          </Typo>
          <Typo variant="b2" color={COLORS.Text200} sx={{ marginTop: '8px' }}>
            {t('ESG risk topics and their proportions for the companies on my watchlist.')}
          </Typo>
        </Title>
      </TitleWrapper>
      <ChartWrapper>
        <ControversyBarChart data={data} isLoading={isLoading} />
      </ChartWrapper>
    </Container>
  );
};

export default EsgControversy;
