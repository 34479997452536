import { media } from 'media';
import styled from 'styled-components';

export const CT = styled.div`
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(66, 74, 87, 0.25);

  display: ${(props) => (props.isOpen ? 'inline-block' : 'none')};
  width: ${(props) => (props.width ? props.width : '100%')};
  position: absolute;
  z-index: 999;

  ${media.mobile`
    margin: 0 12px;
    min-width: 300px;
    width: 100%;
  `}
`;
