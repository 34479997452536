import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div``;

export const Wrapper = styled.div`
  border: ${(props) =>
    props.isFocused ? `1px solid ${COLORS.Primary500}` : '1px solid rgba(194, 200, 208, 1)'};
  border-radius: 4px;

  display: flex;
`;

export const Input = styled.input`
  border-radius: 4px;
  width: 100%;
  padding: 4px 12px;
  font-size: 16px;
  height: 36px;
  box-sizing: border-box;

  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  font-family: 'Pretendard';

  &::placeholder {
    color: ${COLORS.Text200};
  }
`;

export const RightComponentWrappser = styled.div`
  margin: auto 16px auto auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HelperTextContainer = styled.div`
  margin-left: 6px;
  margin-top: 2px;
`;
