import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Conatiner = styled.div`
  display: flex;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  padding: 12px 16px;
  flex-direction: column;
  justify-content: space-between;

  border-radius: 4px;
  border: 1px solid ${COLORS.Background200};
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(240, 243, 250, 0.25);

  cursor: pointer;

  :hover {
    background: ${COLORS.Background50};
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  height: 100%;
  word-break: break-all;

  overflow: hidden;
`;

export const TagWrapper = styled.div``;

export const Title = styled.div``;

export const ScoreWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;
