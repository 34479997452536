import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0px 16px 24px;
  gap: 24px;
  justify-content: center;
  align-items: center;
  height: calc(100% - 32px);

  border-radius: 4px;
  border: 1px solid ${COLORS.Background200};
  background: #fff;
`;

export const TitleWrapper = styled.div`
  width: 100%;
`;

export const ChartWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
`;
