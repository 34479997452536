import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Benchmark from './components/benchmark/Benchmark';
import EsgRiskSignal from './components/esgRiskSignal/EsgRiskSignal';
import FollowingListModal from 'ui/modals/followingListModal/FollowingListModal';
import Graph from './components/graph/Graph';
import NoFollowings from './components/NoFollowings';
import News from './components/news/News';
import SmallMenu from 'ui/menus/secondary/small/SmallMenu';
import SelectModalButton from 'ui/buttons/selectModalButton/SelectModalButton';
import Treemap from './components/treemap/Treemap';
import Typo from 'ui/typo/Typo';
import useFollowings from './hooks/useFollowings';
import useEsgRisk from './hooks/useEsgRisk';
import Virals from './components/virals/Virals';
import {
  Container,
  Wrapper,
  SubContentsWarpper,
  SubheaderWrapper,
  MenuWrapper,
  Subheader,
} from './DashboardStyles';
import ModalContainer from 'ui/modals/modalContainer/ModalContainer';
import { getFollowList, unFollow } from 'api/followings';
import { useDispatch, useSelector } from 'react-redux';
import { addFollowingList } from 'services/mylist/Actions';
import * as amplitude from '@amplitude/analytics-browser';

const Dashboard = () => {
  const [t] = useTranslation('dashboard');

  const dispatch = useDispatch();

  const [tapIdx, setTapIdx] = useState(0);
  const [followingList, followingList_risk, corpcodes] = useFollowings();
  const [esgRiskSignalData, isRiskSignalLoading] = useEsgRisk(corpcodes);
  const userId = useSelector((state) => state.user.userId);

  const [modal, setModal] = useState(false);

  const handleClick = () => setModal(!modal);
  const handleApply = async (setList) => {
    const corpCodes = followingList
      .filter((company) => {
        if (!setList.has(company)) return true;
      })
      .map((el) => el.corp_code);
    if (corpCodes.length > 0) {
      await unFollow(corpCodes.join(','));
      const newFollowing = await getFollowList(userId);
      dispatch(addFollowingList(newFollowing.data));
    }
  };
  const handleTapChange = (i) => {
    setTapIdx(i);
    if (i === 0) amplitude.track('dashboard_graph_view');
    if (i === 0) amplitude.track('dashboard_treemap_view');
    if (i === 0) amplitude.track('dashboard_benchmark_view');
    if (i === 0) amplitude.track('dashboard_news_view');
  };

  return (
    <>
      <Container>
        {followingList.length > 0 && (
          <Subheader>
            <SubheaderWrapper>
              <Typo variant="h3">
                {t('My Watchlist')} ({followingList.length})
              </Typo>
              <SelectModalButton title={t('My Watchlist')} onClick={handleClick} />
            </SubheaderWrapper>
          </Subheader>
        )}
        <Wrapper>
          {followingList.length > 0 ? (
            <>
              <EsgRiskSignal data={esgRiskSignalData} isLoading={isRiskSignalLoading} />

              <Virals corpcodes={corpcodes} />

              <SubContentsWarpper>
                <MenuWrapper>
                  <SmallMenu
                    menuList={[t('Graph'), t('Treemap'), t('Benchmark'), t('News')]}
                    activeIndex={tapIdx}
                    onClick={(i) => handleTapChange(i)}
                  />
                </MenuWrapper>
                {tapIdx === 0 ? <Graph followingList={followingList_risk} /> : null}
                {tapIdx === 1 ? <Treemap corpcodes={corpcodes} /> : null}
                {tapIdx === 2 ? <Benchmark followingList={followingList_risk} /> : null}
                {tapIdx === 3 ? <News corpcodes={corpcodes} /> : null}
              </SubContentsWarpper>
            </>
          ) : (
            <NoFollowings />
          )}
        </Wrapper>
      </Container>

      <ModalContainer visible={modal} onClose={() => setModal(false)}>
        <FollowingListModal
          folloiwngList={followingList}
          onClose={() => setModal(false)}
          onApply={handleApply}
        />
      </ModalContainer>
    </>
  );
};

export default Dashboard;
