import Typo from 'ui/typo/Typo';
import { Container, TextWrapper } from './ChipStyles';
import { COLORS } from 'styles/colorTheme';

const Chip = ({
  color = COLORS.Background100,
  maxWidth,
  py = '12px',
  px = '12px',
  selected = false,
  children,
  onClick,
  sx = {},
}) => {
  return (
    <Container
      color={color}
      selected={selected}
      py={py}
      px={px}
      style={{ ...sx }}
      onClick={onClick}
    >
      <TextWrapper maxWidth={maxWidth}>
        <Typo variant="btn2" color={COLORS.Text400}>
          {children}
        </Typo>
      </TextWrapper>
    </Container>
  );
};

export default Chip;
