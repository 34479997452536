import Typo from 'ui/typo/Typo';
import { Container, TitleWrapper, BtnWrapper, DescriptionWrapper } from './Title.styles';
import { COLORS } from 'styles/colorTheme';

const Title = ({ title, desc, more, onClick, sx }) => {
  const handleClick = () => {
    onClick();
  };

  return (
    <Container style={{ ...sx }}>
      <TitleWrapper>
        <Typo variant="h1" color={COLORS.Text500}>
          {title}
        </Typo>
        <BtnWrapper onClick={handleClick}>
          <Typo variant="h3" color={COLORS.Primary500}>
            {more}
          </Typo>
        </BtnWrapper>
      </TitleWrapper>

      {typeof desc === 'string' && (
        <DescriptionWrapper>
          <Typo
            variant="b2"
            color={COLORS.Text200}
            className={'regular'}
            sx={{ lineHeight: '20px' }}
          >
            {desc}
          </Typo>
        </DescriptionWrapper>
      )}

      {Array.isArray(desc) && (
        <DescriptionWrapper>
          {desc.map((el, i) => (
            <div key={i}>
              <Typo
                variant="b2"
                color={COLORS.Text200}
                className={'regular'}
                sx={{ lineHeight: '20px' }}
              >
                {el}
              </Typo>
            </div>
          ))}
        </DescriptionWrapper>
      )}
    </Container>
  );
};

export default Title;
