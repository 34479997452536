import { Container, LabelWrapper } from './LegendRadioItemStyles';
import RadioButton from 'ui/buttons/radioButton/RadioButton';

const LegendRadioItem = ({ children, onClick, name, isSelected = false, sx }) => {
  return (
    <Container style={{ ...sx }} onClick={onClick}>
      <LabelWrapper>{children}</LabelWrapper>
      <RadioButton isChecked={isSelected} name={name} onChange={() => {}} />
    </Container>
  );
};

export default LegendRadioItem;
