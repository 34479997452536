import styled from 'styled-components';

export const Container = styled.div``;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BtnWrapper = styled.div`
  cursor: pointer;
`;

export const DescriptionWrapper = styled.div`
  margin-top: 10px;
`;
