import { ViralWrapper, DimensionWrapper, TopicWrapper } from './ViralsStyles';
import EsgRiskExposure from './components/esgRiskExposure/EsgRiskExposure';
import EsgControversy from './components/esgControversy/EsgControversy';
import useEsgViral from 'components/dashboardPage/hooks/useEsgViral';

const Virals = ({ corpcodes }) => {
  const [esgDemensionViralData, esgTopicViralData, isLoading] = useEsgViral(corpcodes);

  return (
    <ViralWrapper>
      <DimensionWrapper>
        <EsgRiskExposure data={esgDemensionViralData} isLoading={isLoading} />
      </DimensionWrapper>
      <TopicWrapper>
        <EsgControversy data={esgTopicViralData} isLoading={isLoading} />
      </TopicWrapper>
    </ViralWrapper>
  );
};

export default Virals;
