import { Conatiner, TagWrapper, TitleWrapper, ScoreWrapper, Title } from './ThirdCardStyles';
import UpDownChip from 'ui/caption/score3/Score3';
import Typo from 'ui/typo/Typo';
import { COLORS } from 'styles/colorTheme';
import Tag from 'ui/tag/Tag';

const ThirdCard = ({
  width = '208px',
  height = '160px',
  companyId,
  companyName,
  change,
  score,
  onClick,
  sx,
}) => {
  return (
    <Conatiner width={width} height={height} onClick={onClick} style={{ ...sx }}>
      <TitleWrapper>
        <TagWrapper>
          <Tag>{companyId}</Tag>
        </TagWrapper>
        <Title>
          <Typo variant="h3" color={COLORS.Text500}>
            {companyName}
          </Typo>
        </Title>
      </TitleWrapper>

      <ScoreWrapper>
        <Typo variant="caption1" color={COLORS.Text500}>
          {score.toFixed(2)}
        </Typo>
        <UpDownChip change={change} height="24px" />
      </ScoreWrapper>
    </Conatiner>
  );
};

export default ThirdCard;
