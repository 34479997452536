import { Component } from 'react';
import Button from 'ui/buttons/button/Button';
import Typo from 'ui/typo/Typo';
import styled from 'styled-components';

class Privacy extends Component {
  render() {
    return (
      <div style={{ maxWidth: '1440px', margin: 'auto', paddingBottom: '24px' }}>
        <Button variant="underline" onClick={(e) => (window.location.href = '/terms')}>
          View Terms of Use
        </Button>

        <Container style={{ textAlign: 'center' }}>
          <Wrapper>
            <Typo variant="d1">개인정보 처리방침</Typo>
          </Wrapper>
        </Container>

        <Container>
          <Content>
            <Typo>
              (주)페어랩스('www.fairlabs.io'이하 회사)는 개인정보보호법에 따라 이용자의 개인정보
              보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록
              다음과 같은 처리방침을 두고 있습니다. 회사는 개인정보처리방침을 개정하는 경우 웹사이트
              공지사항(또는 개별공지)을 통하여 알려드립니다.
            </Typo>
          </Content>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h1">1. 개인정보의 처리 목적</Typo>
          </Wrapper>
          <Content>
            <Typo>회사는 개인정보를 다음의 목적을 위해 처리합니다.</Typo>
          </Content>
          <Content>
            <Typo>
              처리한 개인정보는 다음의 목적이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는
              사전동의를 구할 예정입니다.
            </Typo>
          </Content>
          <Wrapper>
            <Content>
              <Typo>① 홈페이지 회원가입 및 관리</Typo>
            </Content>
            <Content>
              <Typo>
                회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리,
                제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 각종 고지·통지,
                고충처리, 분쟁 조정을 위한 기록 보존 등을 목적으로 개인정보를 처리합니다.
              </Typo>
            </Content>
            <Content>
              <Typo>② 민원사무 처리</Typo>
            </Content>
            <Content>
              <Typo>
                민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 등을
                목적으로 개인정보를 처리합니다.
              </Typo>
            </Content>
            <Content>
              <Typo>③ 재화 또는 서비스 제공</Typo>
            </Content>
            <Content>
              <Typo>
                서비스 제공, 청구서 발송, 콘텐츠 제공, 맞춤 서비스 제공, 본인인증, 연령인증,
                요금결제·정산, 채권추심 등을 목적으로 개인정보를 처리합니다.
              </Typo>
            </Content>
            <Content>
              <Typo>④ 마케팅 및 광고에의 활용</Typo>
            </Content>
            <Content>
              <Typo>
                신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회
                제공 , 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를
                처리합니다.
              </Typo>
            </Content>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h1">2. 개인정보 파일 현황</Typo>
          </Wrapper>
          <Wrapper>
            <Content>
              <Typo>
                ① 개인정보 파일명 : 개인정보 처리방침 • 개인정보 항목 : 이메일, 휴대전화번호,
                자택주소, 자택전화번호, 비밀번호, 로그인ID, 생년월일, 이름, 회사전화번호, 직책,
                부서, 회사명, 신용카드정보, 은행계좌정보, 결제기록
              </Typo>
            </Content>
            <Wrapper>
              <Content>
                <Typo>• 수집방법 : 홈페이지, 서면양식, 전화/팩스, 자동수집장치</Typo>
              </Content>
              <Content>
                <Typo>• 보유근거 : 서비스 이용의 혼선 방지, 부정이용 방지</Typo>
              </Content>
              <Content>
                <Typo>• 보유기간 :1년</Typo>
              </Content>
              <Content>
                <Typo>
                  • 관련법령 : 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년, 소비자의 불만 또는
                  분쟁처리에 관한 기록 : 3년, 대금결제 및 재화 등의 공급에 관한 기록 : 5년, 계약
                  또는 청약철회 등에 관한 기록 : 5년, 표시/광고에 관한 기록 : 6개월
                </Typo>
              </Content>
            </Wrapper>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h1">3. 개인정보의 처리 및 보유 기간</Typo>
          </Wrapper>
          <Wrapper>
            <Content>
              <Typo>
                ① 회사는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집시에
                동의 받은 개인정보 보유,이용기간 내에서 개인정보를 처리,보유합니다.
              </Typo>
            </Content>
            <Content>
              <Typo>② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.</Typo>
            </Content>
            <Wrapper>
              <Content>
                <Typo>1) 재화 또는 서비스 제공</Typo>
              </Content>
              <Wrapper>
                <Content>
                  <Typo>
                    • 재화 또는 서비스 제공와 관련한 개인정보는 수집.이용에 관한 동의일로부터
                    회원탈퇴시 또는 동의철회시까지 이용목적을 위하여 보유.이용됩니다. 또한, 1년 이상
                    장기 미 이용자는 운영 DB와는 별도로 분리된 DB에 별도 보관, 관리됩니다.
                  </Typo>
                </Content>
                <Content>
                  <Typo>• 보유근거 : 서비스 이용의 혼선 방지, 부정이용 방지</Typo>
                </Content>
                <Content>
                  <Typo>• 관련법령 :</Typo>
                </Content>
                <Wrapper>
                  <Content>
                    <Typo>1) 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년</Typo>
                  </Content>
                  <Content>
                    <Typo>2) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년</Typo>
                  </Content>
                  <Content>
                    <Typo>3) 대금결제 및 재화 등의 공급에 관한 기록 : 5년</Typo>
                  </Content>
                  <Content>
                    <Typo>4) 계약 또는 청약철회 등에 관한 기록 : 5년</Typo>
                  </Content>
                  <Content>
                    <Typo>5) 표시/광고에 관한 기록 : 6개월</Typo>
                  </Content>
                </Wrapper>
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h1">4. 개인정보처리 위탁</Typo>
          </Wrapper>
          <Content>
            <Typo>
              회사는 최적의 서비스 제공을 위하여, 최소한의 제한된 범위에서 특정 개인정보를 외부
              전문업체에 위탁하여 운영하고 있습니다. 이 경우 개인정보가 안전하게 보관 및 관리될 수
              있도록 필요한 사항을 규정하고 관리와 감독을 실시하고 있습니다.
            </Typo>
          </Content>
          <table style={{ width: '50%', margin: '24px' }}>
            <thead style={{ backgroundColor: '#eeeeee' }}>
              <tr>
                <Th>
                  <Typo>수탁자</Typo>
                </Th>
                <Th>
                  <Typo>위탁업무</Typo>
                </Th>
                <Th>
                  <Typo>개인정보의 보유 및 이용기간</Typo>
                </Th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <Td>
                  <Typo>(주)채널코퍼레이션</Typo>
                </Td>
                <Td>
                  <Typo>고객상담</Typo>
                </Td>
                <Td>
                  <Typo>회원 탈퇴 시 또는 위탁 계약 종료시</Typo>
                </Td>
              </tr>
              <tr>
                <Td>
                  <Typo>AWS</Typo>
                </Td>
                <Td>
                  <Typo>회원정보, 결제 정보 등 보관</Typo>
                </Td>
                <Td>
                  <Typo>회원 탈퇴 시 또는 위탁 계약 종료시</Typo>
                </Td>
              </tr>
              <tr>
                <Td>
                  <Typo>스티비 주식회사</Typo>
                </Td>
                <Td>
                  <Typo>회원 탈퇴 시 또는 위탁 계약 종료시</Typo>
                </Td>
                <Td>
                  <Typo>회원 탈퇴 시 또는 위탁 계약 종료시</Typo>
                </Td>
              </tr>
            </tbody>
          </table>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h1">
              5. 정보주체와 법정대리인의 권리·의무 및 그 행사방법 이용자는 개인정보주체로서 다음과
              같은 권리를 행사할 수 있습니다.
            </Typo>
          </Wrapper>
          <Wrapper>
            <Content>
              <Typo>
                ① 정보주체는 회사에 대해 언제든지 개인정보 열람,정정,삭제,처리정지 요구 등의 권리를
                행사할 수 있습니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ② 제1항에 따른 권리 행사는회사에 대해 개인정보 보호법 시행령 제41조제1항에 따라
                서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체 없이
                조치하겠습니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을
                통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른
                위임장을 제출하여야 합니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ④ 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제5항, 제37조 제2항에
                의하여 정보주체의 권리가 제한 될 수 있습니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어
                있는 경우에는 그 삭제를 요구할 수 없습니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ⑥ 회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람
                등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
              </Typo>
            </Content>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h1">6. 처리하는 개인정보의 항목 작성</Typo>
          </Wrapper>
          <Wrapper>
            <Content>
              <Typo>① 회사는 다음의 개인정보 항목을 처리하고 있습니다.</Typo>
            </Content>
            <Wrapper>
              <Content>
                <Typo>{`1) 재화 또는 서비스 제공`}</Typo>
              </Content>
              <Wrapper>
                <Content>
                  <Typo>• 필수항목 :</Typo>
                </Content>
                <Wrapper>
                  <Content>
                    <Typo>- 개인회원: 아이디, 성명, 연락처(유선/휴대폰), 이메일</Typo>
                  </Content>
                  <Content>
                    <Typo>
                      - 법인회원: 아이디, 회사명, 회사주소, 계약담당자 , 사용자, 세금계산서 담당자의
                      성명/부서/직위/전화번호(유선/휴대폰) /이메일
                    </Typo>
                  </Content>
                </Wrapper>
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h1">7. 개인정보의 파기</Typo>
          </Wrapper>
          <Content>
            <Typo>
              회사는 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당 개인정보를
              파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.
            </Typo>
          </Content>
          <Wrapper>
            <Wrapper>
              <Content>
                <Typo>① 파기절차</Typo>
              </Content>
              <Content>
                <Typo>
                  이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류)
                  내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때,
                  DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지
                  않습니다.
                </Typo>
              </Content>
            </Wrapper>
            <Wrapper>
              <Content>
                <Typo>② 파기기한</Typo>
              </Content>
              <Content>
                <Typo>
                  이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터
                  5일 이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그
                  개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는
                  날로부터 5일 이내에 그 개인정보를 파기합니다.
                </Typo>
              </Content>
            </Wrapper>
            <Wrapper>
              <Content>
                <Typo>③ 파기방법</Typo>
              </Content>
              <Content>
                <Typo>
                  전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.
                </Typo>
              </Content>
              <Content>
                <Typo>종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다</Typo>
              </Content>
            </Wrapper>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h1">8. 개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항</Typo>
          </Wrapper>
          <Wrapper>
            <Content>
              <Typo>
                ① 회사는 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는
                ‘쿠키(cookie)’를 사용합니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게
                보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.
              </Typo>
            </Content>
            <Wrapper>
              <Content>
                <Typo>
                  {`1) 쿠키의 사용 목적 : 회원 또는 이용고객의 접속 빈도나 방문 시간 등을 분석,
                  이용자의 취향과 관심분야 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수
                  파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스를 제공하기 위하여 사용됩니다.`}
                </Typo>
              </Content>
              <Content>
                <Typo>
                  {`2) 쿠키의 설치·운영 및 거부 : 웹브라우저 상단의 도구인터넷 옵션 > 개인정보 메뉴의
                  옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다. 단, 쿠키 저장을 거부할 경우
                  맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.`}
                </Typo>
              </Content>
            </Wrapper>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h1">9. 개인정보 보호책임자 작성</Typo>
          </Wrapper>
          <Wrapper>
            <Content>
              <Typo>
                ① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한
                정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를
                지정하고 있습니다.
              </Typo>
            </Content>
            <Wrapper>
              <Content>
                <Typo>▶ 개인정보관리책임자</Typo>
              </Content>
              <Wrapper>
                <Content>
                  <Typo>• 성 명 : 손종진</Typo>
                </Content>
                <Content>
                  <Typo>• 연락처 : team@fairlabs.io, (FAX 0504-133-5119)</Typo>
                </Content>
              </Wrapper>
            </Wrapper>
            <Content>
              <Typo>
                ② 정보주체께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호
                관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로
                문의하실 수 있습니다. 회사는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴
                것입니다.
              </Typo>
            </Content>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h1">10. 개인정보 처리방침 변경</Typo>
          </Wrapper>
          <Wrapper>
            <Content>
              <Typo>
                ① 이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가,
                삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여
                알려드립니다.
              </Typo>
            </Content>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h1">11. 개인정보의 안전성 확보 조치</Typo>
          </Wrapper>
          <Content>
            <Typo>
              회사는 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및
              물리적 조치를 하고 있습니다.
            </Typo>
          </Content>
          <Wrapper>
            <Content>
              <Typo>① 정기적인 자체 감사 실시</Typo>
            </Content>
            <Content>
              <Typo>
                개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고
                있습니다.
              </Typo>
            </Content>
          </Wrapper>
          <Wrapper>
            <Content>
              <Typo>② 개인정보 취급 직원의 최소화 및 교육</Typo>
            </Content>
            <Content>
              <Typo>
                개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를
                관리하는 대책을 시행하고 있습니다.
              </Typo>
            </Content>
          </Wrapper>
          <Wrapper>
            <Content>
              <Typo>③ 내부관리계획의 수립 및 시행</Typo>
            </Content>
            <Content>
              <Typo>
                개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.
              </Typo>
            </Content>
          </Wrapper>
          <Wrapper>
            <Content>
              <Typo>④ 해킹 등에 대비한 기술적 대책</Typo>
            </Content>
            <Content>
              <Typo>
                회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여
                보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에
                시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.
              </Typo>
            </Content>
          </Wrapper>
          <Wrapper>
            <Content>
              <Typo>⑤ 개인정보의 암호화</Typo>
            </Content>
            <Content>
              <Typo>
                이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수
                있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는
                등의 별도 보안기능을 사용하고 있습니다.
              </Typo>
            </Content>
          </Wrapper>
          <Wrapper>
            <Content>
              <Typo>⑥ 접속기록의 보관 및 위변조 방지</Typo>
            </Content>
            <Content>
              <Typo>
                개인정보처리시스템에 접속한 기록을 최소 1년 이상 보관, 관리하고 있으며, 접속 기록이
                위변조 및 도난, 분실되지 않도록 보안기능 사용하고 있습니다.
              </Typo>
            </Content>
          </Wrapper>
          <Wrapper>
            <Content>
              <Typo>⑦ 개인정보에 대한 접근 제한</Typo>
            </Content>
            <Content>
              <Typo>
                개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여
                개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을
                이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
              </Typo>
            </Content>
          </Wrapper>
          <Wrapper>
            <Content>
              <Typo>⑧ 문서보안을 위한 잠금장치 사용</Typo>
            </Content>
            <Content>
              <Typo>
                개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고
                있습니다.
              </Typo>
            </Content>
          </Wrapper>
          <Wrapper>
            <Content>
              <Typo>⑨ 비인가자에 대한 출입 통제</Typo>
            </Content>
            <Content>
              <Typo>
                개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를
                수립, 운영하고 있습니다.
              </Typo>
            </Content>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h1">12. 정보주체의 권익침해에 대한 구제방법</Typo>
          </Wrapper>
          <Content>
            <Typo>
              아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제
              결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.
            </Typo>
          </Content>
          <Wrapper>
            <Content>
              <Typo>• 개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이 118)</Typo>
            </Content>
            <Content>
              <Typo>• 개인정보분쟁조정위원회 (www.kopico.go.kr / 국번없이 1833-6972)</Typo>
            </Content>
            <Content>
              <Typo>• 대검찰청 사이버수사과 (www.spo.go.kr / 국번없이 1301)</Typo>
            </Content>
            <Content>
              <Typo>• 경찰청 사이버수사국 (ecrm.police.go.kr / 국번없이 182)</Typo>
            </Content>
          </Wrapper>
          <Content>
            <Typo>시행일자 : 2024년 2월 5일</Typo>
          </Content>
        </Container>
      </div>
    );
  }
}

export default Privacy;

const Container = styled.div`
  margin-bottom: 24px;
`;

const Wrapper = styled.div`
  margin: 12px 6px;
`;

const Content = styled.div`
  margin: 3px 6px;
`;

const Td = styled.td`
  padding: 8px;
  border: solid 1px #eeeeee;
`;

const Th = styled.th`
  padding: 8px;
`;
