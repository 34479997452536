import { media } from 'media';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
`;

export const HeaderWrapper = styled.div`
  padding-left: 88px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 24px;

  position: sticky;
  top: 0px;
  background-color: #fff;
  z-index: 99;

  ${media.mobile`
    padding: 4px;
  `}
`;

export const BodyWrapper = styled.div`
  margin-left: 88px;
  padding-top: 16px;
  padding-bottom: 36px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
`;

export const SubheaderWrapper = styled.div`
  position: relative;
`;

export const Main = styled.div`
  width: 100%;
`;
