import { media } from 'media';
import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

export const ItemWrapper = styled.div`
  border-bottom: 1px solid ${COLORS.Background50};
  :last-child {
    border-bottom: 0px;
  }
`;

export const CompanyList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;

  ${media.mobile`
    display: block;
    
  `}
`;

export const ChipListWrapper = styled.div`
  display: flex;
  padding: 0px 10px 0px 8px;
  justify-content: space-between;
  align-items: center;

  ${media.mobile`
    display: block;
  `}
`;
