import { Typography, Card } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';

const cardStyle = {
  width: '333px',
  background: '#FAFAFA',
  boxShadow: '0px 20px 30px rgba(0, 0, 0, 0.1)',
  borderRadius: '10px',
  p: '30px',
  m: '10px',
  cursor: 'pointer',
  transition: '0.2s ease-in-out',
  ':hover': {
    backgroundColor: '#e3f2fd',
    transform: 'scale(1.05)',
  },
  '@media(max-width: 768px)': {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const AnalyticsCard = ({ img, title, description, openAnalyticsDrawer }) => {
  return (
    <Card sx={cardStyle} onClick={openAnalyticsDrawer}>
      <CardMedia component="img" image={img} sx={{ width: '140px', p: '10px 0' }} />
      <CardContent sx={{ width: '300px', '@media(max-width: 768px)': { width: '100%' } }}>
        <Typography gutterBottom variant="h5" component="div" fontWeight="500" fontSize="24px">
          {title}
        </Typography>
        <Typography
          variant="body2"
          fontWeight="400"
          fontSize="16px"
          color="#737373"
          sx={{ overflowWrap: 'break-word' }}
        >
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default AnalyticsCard;
