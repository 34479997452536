import { Component } from 'react';
import './Wrapper.css';

class Wrapper extends Component {
  render() {
    const { className, children } = this.props;
    return <div className={' ' + className}>{children}</div>;
  }
}

export default Wrapper;
