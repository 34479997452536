import { COLORS } from 'styles/colorTheme';

const Dot = ({ isSelected = false }) => {
  return (
    <div style={{ width: '14px', display: 'flex', justifyContent: 'center' }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="10"
        viewBox="0 0 11 10"
        fill="none"
      >
        <path
          d="M8.6066 8.53553C10.5592 6.58291 10.5592 3.41709 8.6066 1.46447C6.65398 -0.488155 3.48816 -0.488155 1.53553 1.46447C-0.417088 3.41709 -0.417087 6.58291 1.53553 8.53553C3.48816 10.4882 6.65398 10.4882 8.6066 8.53553Z"
          fill={isSelected ? COLORS.Primary500 : 'none'}
        />
      </svg>
    </div>
  );
};

export default Dot;
