import { Container, CompanyListWrapper } from './TopicListStyles';
import CompanyList from './companyList/CompanyList';
import EsgTopicTitle from '../../listItem/EsgTopicTitle/EsgTopicTitle';

const TopicList = ({ topic, rank, sx }) => {
  return (
    <Container style={{ ...sx }}>
      <EsgTopicTitle topic={topic} rank={rank} />

      <CompanyListWrapper>
        <CompanyList data={topic.company_list} />
      </CompanyListWrapper>
    </Container>
  );
};

export default TopicList;
