import Typo from 'ui/typo/Typo';
import {
  Container,
  Wrapper,
  NameWrapper,
  DataWrapper,
  NameContainer,
  Data,
  Img,
  TextWrapper,
} from './ScoreStyles';
import Plus from 'ui/icon/plus/Plus';
import Score2 from 'ui/caption/score2/Score2';
import pulseImg from 'assets/pulse.svg';
import vectorImg from 'assets/vector.svg';
import exposureImg from 'assets/exposure.svg';
import { COLORS } from 'styles/colorTheme';

const Score = ({
  type,
  companyName,
  score,
  scoreChange,
  onClick,
  onAdd,
  onCancel,
  isSelected,
  sx,
}) => {
  return (
    <Container style={sx}>
      <Wrapper onClick={onClick}>
        <NameContainer>
          <NameWrapper>
            <Typo variant="b1" color={COLORS.Text400}>
              {companyName}
            </Typo>
          </NameWrapper>
        </NameContainer>

        <DataWrapper>
          <Data>
            {type === 'pulse' ? <Img src={pulseImg} alt="pulse" /> : null}
            {type === 'exposure' ? <Img src={exposureImg} alt="exposure" /> : null}
            {type === 'vector' ? <Img src={vectorImg} alt="vector" /> : null}
            <TextWrapper>
              <Typo variant="caption1" color={COLORS.Text300}>
                {score}
                {type === 'exposure' ? '%' : ''}
              </Typo>
            </TextWrapper>
          </Data>
          <Score2 change={scoreChange} type={type} />
          <Plus onClick={isSelected ? onCancel : onAdd} isSelected={isSelected} />
        </DataWrapper>
      </Wrapper>
    </Container>
  );
};

export default Score;
