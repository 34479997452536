import { media } from 'media';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  ${media.mobile`
    gap: 12px;
  `}
`;

export const RangeBarWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  ${media.mobile`
    justify-content: center;
    margin-top: 24px;
  `}
`;
