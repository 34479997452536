import { media } from 'media';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px 0px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 1206px;
  width: 100%;
`;

export const TapWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;
