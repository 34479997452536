import useEsgTree from 'components/dashboardPage/hooks/useEsgTree';
import EsgTreemap from './components/esgTreemap/EsgTreemap';
import { TreemapWrapper, PreloaderWrapper } from './TreemapStyles';
import Preloaer from 'ui/preloaders/componentPreloader/ComponentPreloader';

const Treemap = ({ corpcodes }) => {
  const [esgTreemapData] = useEsgTree(corpcodes);

  return (
    <>
      {esgTreemapData ? (
        <TreemapWrapper>
          <EsgTreemap data={esgTreemapData} isLoading={false} />
        </TreemapWrapper>
      ) : (
        <PreloaderWrapper>
          <Preloaer sx={{ height: '465px' }} />
        </PreloaderWrapper>
      )}
    </>
  );
};

export default Treemap;
