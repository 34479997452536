import React, { forwardRef } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import {
  BottomLine,
  Container,
  ContentsContainer,
  Circle,
  TopLine,
  LineContainer,
} from './EventDetails.styles';
import EventCard from '../eventCard/EventCard';
import { SASB_TAG_ID_ICON_MAP } from 'data/sasbTagIconMap';
import Nodata from 'ui/errorSigns/noData/Nodata';
import { useTranslation } from 'react-i18next';

const EventDetails = forwardRef(({ events = [], selectedTags }, ref) => {
  const language = useSelector((state) => state.user.language);
  const [t] = useTranslation('noData');

  if (events.length === 0) return <Nodata desc={t('No ESG data.')} sx={{ height: '200px' }} />;

  return (
    <div ref={ref}>
      {events.map((event, i) => {
        if (selectedTags.has(event.sasb_tag_id)) {
          return (
            <Container key={i} id={event.x}>
              <LineContainer>
                <TopLine />
                <Circle />
                <BottomLine />
              </LineContainer>

              <ContentsContainer>
                <EventCard
                  title={event.title_}
                  desc={event[`gpt_summary_${language}`]}
                  esg={event[`sasb_tag_label_${language}`]}
                  financialImapct={event[`financial_impact_${language}`]}
                  press={event.press}
                  icon={SASB_TAG_ID_ICON_MAP[event.sasb_tag_id].iconUri}
                  date={moment(event.x).format('YYYY.MM.DD')}
                  link={event.original_link}
                />
              </ContentsContainer>
            </Container>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
});

export default EventDetails;
