import styled from "styled-components";

export const Btn = styled.button`
  //reset button style
  border: none;
  cursor: pointer;
  text-align: center;
  //

  padding-top: ${props => props.py};
  padding-bottom: ${props => props.py};
  padding-left: ${props => props.px};
  padding-right: ${props => props.px};
  border-radius: ${props => props.radius};
  display: inline-block;
  width: 'fit-content';
  background: ${props => props.color};
  color: ${props => props.fontColor};
`

export const TextWrapper = styled.div`
  max-width: ${props => props.maxWidth ? props.maxWidth : 'none'};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; 
`