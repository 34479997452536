import {
  SAVE_USER_ID,
  SAVE_INFO,
  SAVE_EXPIRE,
  UPDATE_INFO,
  SAVE_EAMIL,
  CHANGE_LANGUAGE,
} from './ActionTypes';
import i18n from 'i18n';

const initialState = {
  userId: 0,
  token: null,
  info: {},
  expireAt: '',
  email: '',
  language: i18n.language
    ? i18n.language.slice(0, 2) === 'kr'
      ? 'ko'
      : 'en'
    : navigator.language.slice(0, 2) === 'ko'
    ? 'ko'
    : 'en',
};

export default function user(state = initialState, action = {}) {
  switch (action.type) {
    case SAVE_USER_ID:
      return {
        ...state,
        userId: action.payload,
      };
    case SAVE_INFO:
      return {
        ...state,
        info: action.payload,
      };
    case SAVE_EXPIRE:
      return {
        ...state,
        expireAt: action.payload,
      };
    case UPDATE_INFO:
      return {
        ...state,
        info: action.payload,
      };
    case SAVE_EAMIL:
      return {
        ...state,
        email: action.payload,
      };
    case CHANGE_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    default:
      return state;
  }
}
