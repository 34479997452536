import { Container } from './DashboardNewsStyles';
import Preloaer from 'ui/preloaders/componentPreloader/ComponentPreloader';
import NewsCardSmall from 'ui/card/newsCards/image/newsCardSmall/NewsCardSmall';
import Nodata from 'ui/errorSigns/noData/Nodata';
import { useTranslation } from 'react-i18next';

const DashboardNews = ({ data, isLoading, sx }) => {
  const articles = data?.data;
  const [t] = useTranslation('noData');

  return (
    <Container style={{ ...sx }}>
      {isLoading ? (
        <Preloaer width="1200px" height="375px" />
      ) : (
        <>
          {articles ? (
            <>
              {articles?.map((article, i) => (
                <NewsCardSmall key={i} article={article} />
              ))}
            </>
          ) : (
            <Nodata desc={t('No ESG news.')} sx={{ width: '1204px' }} />
          )}
        </>
      )}
    </Container>
  );
};

export default DashboardNews;
