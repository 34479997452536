import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div`
  display: flex;
  padding: 4px 0px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

export const ScoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
`;

export const Icon = styled.img`
  width: 32px;
  height: 32px;
`;

export const DescWrapper = styled.div`
  padding: 4px 8px;
  color: ${COLORS.Primary500};
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;
