import styled from "styled-components";

export const Container = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #DAE2EC;
  box-shadow: 0px 4px 4px 0px rgba(218, 226, 236, 0.50);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: box-shadow 0.5s ease;

  :hover{
    box-shadow: 0px 4px 4px 0px #C5CFDD;
  }
`

export const Img = styled.img`
  transform: ${props => props.isRotate ? 'rotate(180deg)': 'none'}
`