import { RankWrapper, TopicWrapper, TopicContainer } from './EsgTopicTitleStyles';
import Typo from 'ui/typo/Typo';
import { COLORS } from 'styles/colorTheme';
import { useSelector } from 'react-redux';

const EsgTopicTitle = ({ topic, rank, sx }) => {
  const language = useSelector((state) => state.user.language);

  return (
    <TopicContainer style={sx}>
      <RankWrapper>
        <Typo variant="d2" className="medium" color={COLORS.Primary500}>
          {rank}
        </Typo>
      </RankWrapper>
      <TopicWrapper>
        <Typo variant="h3">{topic[`topic_name_${language}`]}</Typo>
      </TopicWrapper>
    </TopicContainer>
  );
};

export default EsgTopicTitle;
