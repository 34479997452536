import { media } from 'media';
import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div`
  display: flex;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;
  background: #fff;
  cursor: pointer;
  background-color: ${(props) => (props.isActive ? COLORS.Primary500 : '')};
  border-radius: 4px;

  :hover {
    background-color: ${COLORS.Background50};
    color: ${(props) => props.color};
  }

  ${media.mobile`
    /* flex-shrink: 0; */
  `}
`;
