import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div`
  border-radius: 4px;
  background: ${(props) => (props.selected ? '#fff' : '')};
  border: ${(props) =>
    props.selected ? `1px solid ${COLORS.Primary500}` : `1px solid ${COLORS.Background300}`};
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  & .path {
    stroke: ${(props) => (props.selected ? COLORS.Primary500 : '#515562')};
  }

  :hover {
    background: ${COLORS.Background100_70};
    border: 1px solid ${COLORS.Background500};
    & .path {
      stroke: #515562;
    }
  }
`;

export const TextWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
