export default function catmullRom2bezier(data, minValue, xStep, yStep, height) {
  
  const result = [];

  for (let i = 0; i < data.length - 1; i++) {
    const p = [];

    p.push({
      x: Math.max(i - 1, 0) * xStep,
      y: height - (data[Math.max(i - 1, 0)] - minValue) * yStep
    })
    p.push({
      x: i * xStep,
      y: height - (data[i] - minValue) * yStep
    })
    p.push({
      x: (i + 1) * xStep,
      y: height - (data[i + 1] - minValue) * yStep
    })
    p.push({
      x: (Math.min(i + 2, data.length - 1)) * xStep,
      y: height - (data[Math.min(i + 2, data.length - 1)] - minValue) * yStep
    })

    const bp = [];

    bp.push({
        x: ((-p[0].x + 6 * p[1].x + p[2].x) / 6),
        y: ((-p[0].y + 6 * p[1].y + p[2].y) / 6)
    });
    bp.push({
        x: ((p[1].x + 6 * p[2].x - p[3].x) / 6),
        y: ((p[1].y + 6 * p[2].y - p[3].y) / 6)
    });
    bp.push({
        x: p[2].x,
        y: p[2].y
    });
    result.push(bp);
  }

  return result;
}