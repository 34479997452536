import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';
import { media } from 'media';

export const Container = styled.div`
  z-index: 999;
  min-width: 88px;
  position: relative;
  ${media.laptop`
    display: none;
  `}
`;

export const Wrapper = styled.div`
  position: sticky;
  top: 0;
`;

export const MenuContainer = styled.div`
  background-color: white;
  border-right: 1px solid ${COLORS.Background100};
  box-shadow: 4px 0px 12px 0px rgba(218, 226, 236, 0.5);
  transition: all 0.3s;
  position: absolute;

  max-width: 88px;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
`;

export const LogoWrapper = styled.div`
  margin-top: 30px;
  padding-left: 24px;
  padding-right: 24px;
  box-sizing: border-box;
`;

export const MenuWrapper = styled.div`
  width: 100%;
  margin-top: 32px;
  padding: 16px 16px 16px 16px;
  transform: translateX(-15px);
`;
