import React from 'react';
import { Divider } from '@mui/material';

import Typo from 'ui/typo/Typo';
import { Section } from './SettingsSection.styles';
import { COLORS } from 'styles/colorTheme';

function SettingsSection({ title, children }) {
  return (
    <Section>
      <Typo variant="h2" color={COLORS.Text500}>
        {title}
      </Typo>
      <Divider />
      {children}
    </Section>
  );
}

export default SettingsSection;
