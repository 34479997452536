import {
  CHANGE_KEYWORD,
  SAVE_COMPANY_LIST_INFO,
  CHANGE_FILTERS,
  RESET_FILTERS,
  SAVE_FILTERS,
} from './ActionTypes';

const initialState = {
  keyword: '',
  checkedFilters: {
    'size_list': [],
    'social_list': [],
    'environment_list': [],
    'governance_list': [],
    'tech_list': [],
    'industry_class_list': []
  },
  filters: [
    [],
    [],
    [],
    [],
    [],
    []
  ]
};

export default function search(state = initialState, action = {}) {
  switch (action.type) {
    case CHANGE_KEYWORD:
      return {
        ...state,
        keyword: action.payload,
      };
    case SAVE_COMPANY_LIST_INFO:
      return {
        ...state,
        companyList: action.payload.data,
      };
    case CHANGE_FILTERS:
      return {
        ...state,
        checkedFilters: action.payload,
      };
    case SAVE_FILTERS:
      return {
        ...state,
        filters: action.payload,
      }
    case RESET_FILTERS:
      return {
        ...state, 
        keyword: initialState.keyword,
        checkedFilters: initialState.checkedFilters,
      }
    default:
      return state;
  }
}
