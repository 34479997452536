import React, { useState } from 'react';

import {
  Input as In,
  HelperTextContainer,
  Container,
  Wrapper,
  RightComponentWrappser,
} from './Input.styles';
import Typo from 'ui/typo/Typo';
import { COLORS } from 'styles/colorTheme';

function Input({
  value,
  name,
  onChange,
  onKeyDown,
  placeHolder,
  helperText,
  readOnly = false,
  type = 'text',
  rightComponent,
  sx,
}) {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Container>
      <Wrapper isFocused={isFocused}>
        <In
          value={value}
          name={name}
          onChange={onChange}
          onKeyDown={onKeyDown}
          placeholder={placeHolder}
          type={type}
          style={sx}
          disabled={readOnly}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
        {rightComponent && <RightComponentWrappser>{rightComponent}</RightComponentWrappser>}
      </Wrapper>
      {helperText && (
        <HelperTextContainer>
          <Typo variant="b2" color={COLORS.Red200}>
            {helperText}
          </Typo>
        </HelperTextContainer>
      )}
    </Container>
  );
}

export default Input;
