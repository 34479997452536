import { changeLanguage } from 'services/user/Actions';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18n';

const useLanguage = () => {
  const language = useSelector((state) => state.user.language);
  const dispatch = useDispatch();

  const setLanguage = (changeTo) => {
    if (changeTo === 'kr') {
      i18n.changeLanguage('kr');
      dispatch(changeLanguage('ko'));
    } else {
      i18n.changeLanguage('en');
      dispatch(changeLanguage('en'));
    }
  };

  return { language, setLanguage };
};

export default useLanguage;
