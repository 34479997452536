import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 4px;
`;

export const Bar = styled.div`
  width: 40px;
  height: 8px;
  background-color: ${(props) => props.color};
`;

export const BarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
`;
