import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div`
  display: flex;
  padding: 12px 4px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${COLORS.Background100_70};
`;

export const Label = styled.div`
  line-height: 100%;
`;

export const Value = styled.div`
  line-height: 100%;
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: ${(props) => (props.hasLink ? 'pointer' : 'unset')};
`;
