import { saveFeedbak } from 'api/news';

const useFeedback = (user, article, cb) => {
  const up = () => {
    saveFeedbak(user.id, user.email, JSON.stringify(article), 'positive');
    if (cb) cb();
  };

  const down = () => {
    saveFeedbak(user.id, user.email, JSON.stringify(article), 'negative');
    if (cb) cb();
  };

  return [up, down];
};

export default useFeedback;
