import TransitionButton from 'ui/buttons/transition/TransitionButton';
import { Container, ChildrenWrapper, LeftBtn, RightBtn } from './SliderStyles';
import { useRef, useEffect, useState } from 'react';
import { gsap } from 'gsap';

const Slider = ({ children, sx }) => {
  const ref = useRef();
  const btnRef = useRef();
  const [childOffset, setChildOffset] = useState({
    width: undefined,
    height: undefined,
  });
  const [btnChildOffset, setBtnChildOffset] = useState({
    width: undefined,
    height: undefined,
  });
  const [cardWidth, setCardWidth] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollWidth, setScrollWidth] = useState({
    fullWidth: 0,
    barWidth: 0,
  });

  const moveRight = () => {
    const stepStrength = Math.floor(childOffset.width / cardWidth) * cardWidth;
    const overFlowChildren = ref.current.children[0];
    gsap.to(overFlowChildren, {
      scrollLeft: overFlowChildren.scrollLeft + stepStrength,
      ease: 'power4',
    });
  };

  const moveLeft = () => {
    const stepStrength = Math.floor(childOffset.width / cardWidth) * cardWidth;
    const overFlowChildren = ref.current.children[0];
    gsap.to(overFlowChildren, {
      scrollLeft: overFlowChildren.scrollLeft - stepStrength,
      ease: 'power4',
    });
  };

  useEffect(() => {
    if (!ref) return;
    const childWidth = ref.current.offsetWidth;
    const childHeight = ref.current.offsetHeight;
    const btnChildWidth = btnRef.current.offsetWidth;
    const elList = ref.current.children[0].children;
    const overFlowChildren = ref.current.children[0];

    const gap = ref.current.children[0].style.gap.slice(0, -2);
    let fullWidth = 0;
    for (let i = 0; i < elList.length; i++) {
      fullWidth += elList[i].offsetWidth;
      if (gap) fullWidth += Number(gap);
    }

    function throttle(func, delay) {
      let timeoutId;
      return function () {
        const context = this;
        const args = arguments;
        if (!timeoutId) {
          timeoutId = setTimeout(function () {
            func.apply(context, args);
            timeoutId = null;
          }, delay);
        }
      };
    }

    setScrollWidth({
      fullWidth: overFlowChildren.scrollWidth,
      barWidth: overFlowChildren.clientWidth,
    });

    function handleScroll() {
      const overFlowChildren = ref.current.children[0];
      setScrollPosition(overFlowChildren.scrollLeft);
    }

    const throttledScroll = throttle(handleScroll, 500);
    if (ref.current) {
      ref.current.children[0].addEventListener('scroll', throttledScroll);
    }

    setCardWidth(fullWidth / elList.length);
    setChildOffset({
      width: childWidth,
      height: childHeight,
    });
    setBtnChildOffset({
      width: btnChildWidth,
      height: btnChildWidth,
    });

    return () => {
      if (ref.current) {
        ref.current.children[0].removeEventListener('scroll', throttledScroll);
      }
    };
  }, [children]);

  return (
    <Container style={sx}>
      <LeftBtn
        visible={scrollPosition > 0}
        ref={btnRef}
        width={btnChildOffset.width}
        bodyHeight={childOffset.height}
        onClick={moveLeft}
      >
        <TransitionButton direction="left" />
      </LeftBtn>
      <RightBtn
        visible={scrollPosition < scrollWidth.fullWidth - scrollWidth.barWidth}
        width={btnChildOffset.width}
        bodyHeight={childOffset.height}
        onClick={moveRight}
      >
        <TransitionButton />
      </RightBtn>

      <ChildrenWrapper ref={ref}>{children}</ChildrenWrapper>
    </Container>
  );
};

export default Slider;
