import CompanyDetail from 'components/companyDetailPage/CompanyDetail';
import useAmplitude from 'hooks/useAmplitude';
import { useParams } from 'react-router-dom';

export const CompanyDetails = () => {
  const { id } = useParams();
  useAmplitude('companydetail', { company_code: id });
  return <CompanyDetail />;
};

export default CompanyDetails;
