import { media } from 'media';
import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.mobile`
    justify-content: start;
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid ${COLORS.Background100};

  ${media.mobile`
    justify-content: start;
    margin: 12px;
    flex-shrink: 0;
  `}
`;
