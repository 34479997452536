import { useParams } from 'react-router-dom';
import Preloader from 'ui/preloaders/componentPreloader/ComponentPreloader';

import { ComponentWrapper } from './Timelins.styles';
import EventDetails from './components/eventDetails/EventDetails';
import TimelineChart from 'ui/charts/timelineChart/TimelineChart';
import TimelineFilter from './components/timelineFilter/TimelineFilter';
import Top from 'ui/transition/top/Top';
import useNfrData from './hooks/useNfrData';
import useEvents from './hooks/useEvents';
import useTags from './hooks/useTags';
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import ThirdMenu from 'ui/menus/thirdMenu/ThirdMenu';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import { ChartContainer, ChartWrapper } from '../summary/Summart.styles';

gsap.registerPlugin(ScrollToPlugin);

export default function Timeline() {
  const { id } = useParams();
  const [t] = useTranslation('chart');

  const [filterIdx, setFilterIdx] = useState(3);
  const filters = ['1m', '3m', '6m', '1y'];
  const handleFilter = (i) => {
    setFilterIdx(i);
    amplitude.track('companydetail_timeline_mainchart_view', { filter: filters[i] });
  };

  const [nfr, isNfrLoading] = useNfrData(id, filters[filterIdx]);
  const [events, isEventLoading] = useEvents(id, filters[filterIdx]);
  const [tags, selectedTags, flagGroups, selectTag, eventMap] = useTags(events);

  const handleClick = (tag) => selectTag(tag);

  const handleFlagClick = (point) => {
    const element = document.getElementById(point.x);
    const offsetheight = element.offsetHeight;
    const offsetTop = element.offsetTop;
    const scroll = offsetTop - offsetheight / 2;
    gsap.to(window, {
      scrollTo: { y: scroll, autoKill: false },
      duration: 1,
      ease: 'power2.inOut',
    });
    amplitude.track('companydetail_timeline_flag_click');
  };

  return (
    <>
      <div>
        {!isNfrLoading && !isEventLoading ? (
          <>
            <div style={{ width: 'fit-content' }}>
              <ThirdMenu
                menuList={[t('1m'), t('3m'), t('6m'), t('1y')]}
                activeIndex={filterIdx}
                onClick={handleFilter}
              />
            </div>
            <ChartContainer>
              <ChartWrapper>
                <TimelineChart
                  data={{ nfrData: nfr, flagGroups: flagGroups }}
                  selectedTags={selectedTags}
                  onClick={handleFlagClick}
                />
              </ChartWrapper>
            </ChartContainer>
          </>
        ) : (
          <Preloader width="1204px" height="400px" />
        )}
        <ComponentWrapper>
          <TimelineFilter
            totalTags={eventMap}
            selectedTags={[...selectedTags]}
            onClick={handleClick}
          />
        </ComponentWrapper>
        <ComponentWrapper>
          <EventDetails events={events} selectedTags={selectedTags} />
        </ComponentWrapper>
      </div>
      <Top />
    </>
  );
}
