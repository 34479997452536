import { getESGRiskRankByIndustry } from 'api/home';
import { useState, useEffect } from 'react';
import industryGicmap from 'data/industryGicmap.json';

const useRiskRank = () => {
  const [riskRankData, setRiskRankData] = useState([]);
  const [industryIndex, setIndustryIndex] = useState(0);
  const [gicIndex, setGicIndex] = useState('esg');
  const [isLoading, setIsLoading] = useState(false);

  const getData = async (industryIndex, gicIndex) => {
    setIsLoading(true);
    const industryInfo = industryGicmap[industryIndex];
    const gicInfo = industryInfo.GIC;
    const gicCode = gicIndex === 'esg' ? 'esg' : gicInfo[gicIndex].GICCode;
    const riskRank = await getESGRiskRankByIndustry(industryInfo.industryCode, gicCode);
    if (riskRank && riskRank.status === 200) setRiskRankData(riskRank.data);
    setIsLoading(false);
  };

  useEffect(() => {
    getData(industryIndex, gicIndex);
  }, [gicIndex, industryIndex]);

  return [riskRankData, industryIndex, gicIndex, setIndustryIndex, setGicIndex, isLoading];
};

export default useRiskRank;
