import { Component } from 'react';
import { Box, Stack } from '@mui/material';
import MainCard from 'ui/card/mainCard';
import FindCompanySearch from './findCompanySearch/FindCompanySearch';

class FindCompanyBox extends Component {
  handleClickAway = (e) => {
    if (e.target.tagName === 'INPUT') return;
    this.props.handleClose();
  };

  render() {
    const { data, keyword, sx, amount, handleClick } = this.props;

    return (
      <MainCard border={false} elevation={16} content={false} boxShadow>
        <Box sx={sx}>
          {keyword && (
            <Stack spacing={1.5} p={1}>
              <FindCompanySearch data={data} amount={amount} handleClick={handleClick} />
            </Stack>
          )}
        </Box>
      </MainCard>
    );
  }
}

export default FindCompanyBox;
