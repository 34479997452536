import { combineReducers } from 'redux';
import user from 'services/user/Reducers';
import page from 'services/page/Reducers';
import search from 'services/search/Reducers';
import company from 'services/company/Reducers';
import mylist from 'services/mylist/Reducers';
import analytics from 'services/analytics/Reducers';

export default combineReducers({
  user,
  page,
  search,
  company,
  mylist,
  analytics,
});
