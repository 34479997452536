import { Container, Img } from './TransitionButtonStyles';
import right from 'assets/right_black.svg';

const TransitionButton = ({ direction = 'right', variant = 'big', onClick, sx }) => {
  let width, height;

  if (variant === 'small') {
    width = '32px';
    height = '32px';
  } else {
    width = '48px';
    height = '48px';
  }

  return (
    <Container onClick={onClick} width={width} height={height} style={sx}>
      <Img
        src={right}
        alt="arrow"
        isRotate={direction === 'left'}
        width={variant === 'small' ? '16px' : '24px'}
      />
    </Container>
  );
};

export default TransitionButton;
