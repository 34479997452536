import { Component } from 'react';
import { Card, CardHeader, Typography, CardContent, Divider } from '@mui/material';

class MainCard extends Component {
  render() {
    const headerSX = {
      '& .MuiCardHeader-action': { mr: 0 }
    };
  
    return (
      <Card
        ref={this.props.ref}
        sx={{
            borderRadius:'12px',
            border: this.props.border ? '1px solid' : 'none',
            borderColor: 'primary',
            ...this.props.sx
        }}
      >
        {/* card header and action */}
        {!this.props.darkTitle && this.props.title && <CardHeader sx={headerSX} title={this.props.title} action={this.props.secondary} />}
        {this.props.darkTitle && this.props.title && (
          <CardHeader sx={headerSX} title={<Typography variant="h3">{this.props.title}</Typography>} action={this.props.secondary} />
        )}

        {/* content & header divider */}
        {this.props.title && <Divider />}

        {/* card content */}
        {this.props.content && (
          <CardContent sx={this.props.contentSX} className={this.props.contentClass}>
            {this.props.children}
          </CardContent>
        )}
        {!this.props.content && this.props.children}
      </Card>
    )
  }
}

export default MainCard;