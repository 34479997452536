import Typo from 'ui/typo/Typo';
import { Container, BarContainer, Bar } from './ViralBarStyles';
import { COLORS } from 'styles/colorTheme';

const ViralBar = ({ color = '#fff', score = 0, topic, sx }) => {
  let score_ = Math.max(score, 2);
  if (score_ > 100) score_ = 100;
  if (score_ < 0) score_ = 0;

  return (
    <Container style={{ ...sx }}>
      <BarContainer>
        <Bar color={color} score={score_} />
      </BarContainer>
      <Typo variant="overline1" color={COLORS.Text300}>
        {topic}
      </Typo>
    </Container>
  );
};

export default ViralBar;
