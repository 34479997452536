import { media } from 'media';
import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div`
  display: flex;
  flex-shrink: 0;
  min-height: 44px;

  padding: 0px 20px;
  margin: ${(props) => props.margin};
  justify-content: center;
  align-items: center;
  background: #fff;
  cursor: pointer;
  border-bottom: ${(props) =>
    props.isActive ? `2px solid ${COLORS.Primary500}` : `2px solid #FFF`};

  ${media.mobile`
    justify-content: start;
    padding: 0px 12px;
    margin: 0 12px;
  `}
`;

export const Wrapper = styled.div`
  max-width: ${(props) => props.maxWidth};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
