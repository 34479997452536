import { Stack, Typography } from '@mui/material';
import Typo from 'ui/typo/Typo';
import ModalComponent from 'ui/modals/defaultModal';
import { useTabs } from 'components/analyticsPage/tabs/components/tabItems/hooks/useTabs';
import { useSelector } from 'react-redux';

const RemoveModal = ({ modalState, handleModal }) => {
  const [, removeTab] = useTabs();
  const { tabList } = useSelector((state) => state.analytics);

  return (
    <>
      {modalState.open ? (
        <ModalComponent
          open={modalState.open}
          onClose={() => handleModal()}
          onSubmit={() => removeTab(modalState.idx)}
        >
          <Stack>
            <Typo variant="b1">
              Are you sure you want to delete "{tabList[modalState.idx]?.tab_name}"?
            </Typo>
          </Stack>
        </ModalComponent>
      ) : null}
    </>
  );
};

export default RemoveModal;
