import { Container, Icon, Popper } from './QuestionIconStyles';
import question from 'assets/qustion.svg';
import { useRef, useEffect, useState } from 'react';
import { COLORS } from 'styles/colorTheme';
import Typo from 'ui/typo/Typo';

const QuestionIcon = ({ dp = 'right', desc, width = '580px', sx }) => {
  const [isHovering, setIsHovering] = useState(false);
  const ref = useRef();

  const mouseover = () => setIsHovering(true);
  const mouseleave = () => setIsHovering(false);

  useEffect(() => {
    const instance = ref.current;

    if (instance) {
      instance.addEventListener('mouseover', mouseover);
      instance.addEventListener('mouseleave', mouseleave);
    }

    return () => {
      if (instance) {
        instance.removeEventListener('mouseover', mouseover);
        instance.removeEventListener('mouseleave', mouseleave);
      }
    };
  }, []);

  return (
    <Container style={{ ...sx }}>
      <Icon src={question} alt="question" ref={ref} />
      <Popper width={width} isHovering={isHovering} dp={dp}>
        <Typo variant="b2" color={COLORS.Text300}>
          {desc}
        </Typo>
      </Popper>
    </Container>
  );
};

export default QuestionIcon;
