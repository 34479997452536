import { createRoot } from 'react-dom/client';
import Root from './Root';
import 'react-app-polyfill/ie9';
import smoothscroll from 'smoothscroll-polyfill';

import './styles/reset.css';
import './styles/common.css';

import * as serviceWorker from './serviceWorker';

import './i18n';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Root />)

smoothscroll.polyfill();
serviceWorker.unregister();