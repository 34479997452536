import { saveDataQuery } from 'services/analytics/Action';
import { useDispatch, useSelector } from 'react-redux';

export const useStockdataQuery = () => {

  const dispatch = useDispatch();
  const dataQuery = useSelector((state) => state.analytics.dataQuery);

  const setQuery = (obj) => {
    dispatch(
      saveDataQuery({
        ...dataQuery,
        stockData: {
          ...dataQuery.stockData,
          ...obj,
        }
      })
    )
  }

  return [setQuery];
};