import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 4px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

export const BarContainer = styled.div`
  height: 100px;
  display: flex;
  align-items: end;
`

export const Bar = styled.div`
  background-color: ${props => props.color};
  border-radius: 20px 20px 0px 0px;
  width: 16px;
  height: ${props => (props.score/100 * 100)+'px'};
`