import { Stack, Button } from '@mui/material';
import DrawerInput from 'components/analyticsPage/input/DrawerInput';
import MultiIndex from 'components/analyticsPage/tabPanels/components/drawers/indexDrawer/components/components/multiIndex/MultiIndex';
import SingleIndex from 'components/analyticsPage/tabPanels/components/drawers/indexDrawer/components/components/singleIndex/SingleIndex';
import { useIndexQuery } from 'components/analyticsPage/tabPanels/components/drawers/indexDrawer/hooks/useIndexQuery';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const IndexTab = ({ data, open }) => {
  const [setQuery] = useIndexQuery();
  const tab = useSelector((state) => state.analytics.indexQuery.target);
  const handleTabChange = (idx) => {
    if (idx === 0) {
      setQuery({ target: 'multiIndex' });
    }
    if (idx === 1) {
      setQuery({ target: 'singleIndex' });
    }
  };
  const [t] = useTranslation('analytics');

  return (
    <Stack spacing={3} sx={{ display: open ? '' : 'none' }}>
      <DrawerInput data={data} setQuery={setQuery} />
      <Stack direction="row">
        <Button
          disableRipple
          disableElevation
          disableFocusRipple
          variant={tab === 'multiIndex' ? 'contained' : 'outlined'}
          onClick={() => handleTabChange(0)}
          sx={{
            textTransform: 'none',
            borderRadius: '12px 0 0 12px',
            boxShadow: 'unset',
            borderRight: 'none',
            width: '240px',
            ':hover': {
              borderRight: 'none',
            },
          }}
        >
          {t('Multi-index')}
        </Button>
        <Button
          disableRipple
          disableElevation
          disableFocusRipple
          variant={tab === 'singleIndex' ? 'contained' : 'outlined'}
          onClick={() => handleTabChange(1)}
          sx={{
            textTransform: 'none',
            borderRadius: '0 12px 12px 0',
            boxShadow: 'unset',
            borderLeft: 'none',
            width: '240px',
            ':hover': {
              borderLeft: 'none',
            },
          }}
        >
          {t('Single-index')}
        </Button>
      </Stack>
      <MultiIndex open={tab === 'multiIndex' ? '' : 'none'} />
      <SingleIndex open={tab === 'singleIndex' ? '' : 'none'} />
    </Stack>
  );
};

export default IndexTab;
