import _ from 'lodash';

export const useFilters = () => {

  const getFilters = (filters, target) => {
    
    const arr = _.cloneDeep(filters);
    
    const recursive = (arr, target) => {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].category === target.category) {
          arr[i].check = !target.check;
          if (arr[i].subCategory) {
            arr[i].subCategory = syncCategoryToSubCategory(arr[i].subCategory, !target.check);
          }
          return arr;
        }
        if (arr[i].subCategory) {
          arr[i].subCategory = recursive(arr[i].subCategory, target);
          return arr;
        }
      }
    }
  
    const syncCategoryToSubCategory = (arr, value) => {
      for (let i = 0; i < arr.length; i ++) {
        arr[i].check = value;
        if (arr[i].subCategory) {
          arr[i].subCategory = syncCategoryToSubCategory(arr[i].subCategory, value);
        }
      }
      return arr;
    }

    return recursive(arr, target);
  }

  return [getFilters];
} 