import { media } from 'media';
import styled from 'styled-components';

export const Container = styled.section`
  width: 1204px;

  ${media.mobile`
    width: 100%;
    margin: 12px;
  `}
`;

export const TitleWrapper = styled.div`
  padding: 0 0 24px 0;
`;

export const NewPasswordContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 6px;
`;

export const SectionContainer = styled.div`
  max-width: 580px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
