import { useDispatch, useSelector } from 'react-redux';
import { deleteAnalytics } from 'api/analytics';
import { saveSelectedTab } from 'services/analytics/Action';
import * as amplitude from '@amplitude/analytics-browser';

export const useTabs = () => {
  const dispatch = useDispatch();
  const analytics = useSelector((state) => state.analytics);
  const user = useSelector((state) => state.user);

  const selectTab = (value) => {
    dispatch(saveSelectedTab(value));
  };
  const removeTab = async (idx) => {
    const obj = {
      tabId: analytics.tabList[idx].id,
      userId: user.userId,
    };
    amplitude.track('analytics_analysis_delete', {
      category: analytics?.tabList[idx]?.tab_type === 'index-analytics' ? 'index' : 'data',
    });
    await deleteAnalytics(obj);
    selectTab(0);
    window.location.href = '/analytics';
  };

  return [selectTab, removeTab];
};
