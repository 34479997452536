import Typo from 'ui/typo/Typo';
import { Container, Wrapper } from './BreadCrumbStyles';
import right from 'assets/right_small.svg';
import { COLORS } from 'styles/colorTheme';

const BreadCrumb = ({ list = [], sx }) => {
  return (
    <Container style={{ ...sx }}>
      {list.map((el, i) => (
        <Wrapper key={i}>
          <Typo variant="overline2" color={COLORS.Text300}>
            {el}
          </Typo>
          {i < list.length - 1 ? <img src={right} alt="right" /> : null}
        </Wrapper>
      ))}
    </Container>
  );
};

export default BreadCrumb;
