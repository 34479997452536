import { media } from 'media';
import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div`
  border-radius: 4px;
  box-shadow: -4px 0px 12px 0px ${COLORS.Background800};
  position: relative;
  background-color: #fff;
  min-width: 800px;
  max-width: 1200px;

  ${media.mobile`
    min-width: 100%;
    max-width: 100%;
    width: 90vw;
  `}
`;

export const Header = styled.div`
  padding: 20px 20px 20px 32px;
  border-radius: 4px 4px 0px 0px;
  background-color: ${COLORS.Background800};
  min-height: 30px;
  display: flex;
  align-items: center;
`;

export const Cancel = styled.span`
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
`;

export const Footer = styled.div`
  padding: 20px 20px 20px 40px;
  border-radius: 0px 0px 4px 4px;
  background-color: ${COLORS.Background800};
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Body = styled.div`
  padding: 40px 32px;
  max-height: 50vh;
  overflow-y: auto;
`;
