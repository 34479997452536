import { media } from 'media';
import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div`
  padding: 12px 0;
  max-width: 770px;
  ${media.mobile`
    margin: 12px 0px;
    padding: 20px;
  `}
`;

export const Title = styled.div``;

export const Body = styled.div`
  margin-bottom: 15px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;
