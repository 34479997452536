import { Container, LogoWrapper, MenuWrapper, Wrapper, MenuContainer } from './LeftPannelStyles';
import Logo from './components/logo/Logo';
import Menu from './components/menu/Menu';
import { useEffect, useRef } from 'react';

export const LeftPannel = ({ address, onClick }) => {
  const ref = useRef();
  const mouseover = () => (ref.current.style.maxWidth = '300px');
  const mouseleave = () => (ref.current.style.maxWidth = '88px');
  const click = () => {
    ref.current.removeEventListener('mouseover', mouseover);
    ref.current.style.maxWidth = '88px';
    setTimeout(() => {
      ref.current.addEventListener('mouseover', mouseover);
    }, 1000);
  };

  useEffect(() => {
    const instance = ref.current;

    if (instance) {
      instance.addEventListener('mouseover', mouseover);
      instance.addEventListener('mouseleave', mouseleave);
      instance.addEventListener('click', click);
    }

    return () => {
      if (instance) {
        instance.removeEventListener('mouseover', mouseover);
        instance.removeEventListener('mouseleave', mouseleave);
        instance.removeEventListener('click', click);
      }
    };
  }, []);

  return (
    <Container>
      <Wrapper>
        <MenuContainer ref={ref}>
          <LogoWrapper>
            <Logo onClick={() => onClick('/')} />
          </LogoWrapper>
          <MenuWrapper>
            <Menu address={address} onClick={onClick} />
          </MenuWrapper>
        </MenuContainer>
      </Wrapper>
    </Container>
  );
};

export default LeftPannel;
