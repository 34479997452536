import Typo from 'ui/typo/Typo';
import { Container, Wrapper } from './TagStyles';
import { COLORS } from 'styles/colorTheme';

const Tag = ({ children, variant, width, uppercase = true, sx }) => {
  let background = 'rgba(25, 118, 210, 0.01)';
  let borderColor = 'rgba(25, 118, 210, 0.50)';
  let color = 'rgba(25, 118, 210, 1)';

  if (variant === 'topic') {
    background = 'rgba(25, 118, 210, 0.01)';
    borderColor = 'rgba(25, 118, 210, 0.50)';
    color = 'rgba(25, 118, 210, 1)';
  }

  if (variant === 'company') {
    background = '#fff';
    borderColor = COLORS.Text100;
    color = COLORS.Text300;
  }

  return (
    <Container
      uppercase={uppercase}
      background={background}
      borderColor={borderColor}
      width={width}
      style={{ ...sx }}
    >
      <Wrapper>
        <Typo variant="caption1" color={color}>
          {children}
        </Typo>
      </Wrapper>
    </Container>
  );
};

export default Tag;
