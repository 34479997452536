import Typo from 'ui/typo/Typo';
import { Container, TextWrapper, ScoreWrapper, Wrapper, Img } from './CompanyItemStyles';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import exposureImg from 'assets/exposure.svg';
import { COLORS } from 'styles/colorTheme';

const CompanyItem = ({ data }) => {
  const data_ = Number(data.ratio.toFixed(2));
  const navigate = useNavigate();
  const language = useSelector((state) => state.user.language);

  return (
    <Container>
      <Wrapper onClick={() => navigate(`/company/${data.corp_code}`)}>
        <TextWrapper>
          <Typo variant="b1">{data[`comp_name_${language}`]}</Typo>
        </TextWrapper>

        <ScoreWrapper>
          <Img src={exposureImg} alt="exposure" />
          <Typo variant="caption1" color={COLORS.Text300} sx={{ marginLeft: '4px' }}>
            {data_} %
          </Typo>
        </ScoreWrapper>
      </Wrapper>
    </Container>
  );
};

export default CompanyItem;
