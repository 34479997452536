import { media } from 'media';
import styled from 'styled-components';

export const IndustryWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 40px;

  ${media.mobile`
    display: block;
  `}
`;
