import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

export const ChipListWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 8px;
`;

export const ChipImgWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 3px;
`;

export const ContentsWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const MenuWrapper = styled.div`
  max-width: 560px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ListWrapper = styled.div`
  max-width: 560px;
  width: 100%;
`;

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
`;

export const IndustryRankWrapper = styled.div`
  width: 100%;
`;
