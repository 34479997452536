import { media } from 'media';
import styled from 'styled-components';

export const Container = styled.div`
  height: 420px;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(248, 249, 252, 1);

  ${media.mobile`
    margin: 0px 12px;
  `}
`;
