import React, { useRef } from 'react';
import { ModalContainer as Container, Modal } from './ModalContainer.styles';
const ModalContainer = ({ visible, onClose, children }) => {
  const ref = useRef();
  const handleAwayclick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) onClose();
  };

  return (
    <Container visible={visible} onClick={handleAwayclick}>
      <Modal>
        <div ref={ref}>{visible ? children : null}</div>
      </Modal>
    </Container>
  );
};

export default ModalContainer;
