import HeatmapChart from 'ui/charts/heatmapChart/HeatmapChart';
import { Container, TitleWrapper, Title, ChartWrapper } from './EsgRiskSignal.styles';
import RangeBar from 'ui/bars/rangeBar/RangeBar';
import Typo from 'ui/typo/Typo';
import { COLORS, REDCOLORSET } from 'styles/colorTheme';
import { useTranslation } from 'react-i18next';

const EsgRiskSignal = ({ data, isLoading, sx }) => {
  const [t] = useTranslation('dashboard');

  return (
    <Container style={{ ...sx }}>
      <TitleWrapper>
        <Title>
          <Typo variant="h2" color={COLORS.Text500}>
            {t('ESG Risk Signal')}
          </Typo>
          <Typo variant="b2" color={COLORS.Text200} sx={{ marginTop: '8px' }}>
            {t('Distribution of ESG risks for the companies on my watchlist at a glance.')}
          </Typo>
        </Title>
        <RangeBar
          ranges={[
            { value: 80, color: REDCOLORSET[4] },
            { value: 60, color: REDCOLORSET[3] },
            { value: 40, color: REDCOLORSET[2] },
            { value: 20, color: REDCOLORSET[1] },
            { value: 0, color: REDCOLORSET[0] },
          ]}
        />
      </TitleWrapper>
      <ChartWrapper>
        <HeatmapChart data={data} isLoading={isLoading} />
      </ChartWrapper>
    </Container>
  );
};

export default EsgRiskSignal;
