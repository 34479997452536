import { Container } from './ComponentPreloader.styles';
import Typo from 'ui/typo/Typo';
import { COLORS } from 'styles/colorTheme';
import CircularProgress from '@mui/material/CircularProgress';

const ComponentPreloader = ({
  width = '300px',
  height = '150px',
  minWidth,
  maxWidth,
  minHeight,
  maxHeight,
  sx,
}) => {
  return (
    <Container
      width={width}
      height={height}
      minWidth={minWidth}
      maxWidth={maxWidth}
      minHeight={minHeight}
      maxHeight={maxHeight}
      style={sx}
    >
      <CircularProgress size={48} />
      <Typo color={COLORS.Text200} sx={{ marginTop: '16px' }}>
        Loading...
      </Typo>
    </Container>
  );
};

export default ComponentPreloader;
