import { CHANGE_MENU_EXTENSION } from './ActionTypes';

const initialState = {
  menuExtension: false,
};

export default function page(state = initialState, action = {}) {
  switch (action.type) {
    case CHANGE_MENU_EXTENSION:
      return {
        ...state,
        menuExtension: action.payload,
      }
    default:
      return state;
  }
}
