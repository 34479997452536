import { media } from 'media';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 40px;
  flex-direction: column;
`;

export const TopicNewsWrapper = styled.div`
  padding: 0 16px 16px 16px;
`;

export const EsgTopicNewsWrapper = styled.div`
  padding: 16px;
`;

export const FilterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SmallCardWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 24px;
  justify-content: space-between;

  ${media.mobile`
    display: block;
  `}
`;
