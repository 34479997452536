import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getMultiIndexAnalytics, getSingleIndexAnalytics } from 'api/analytics';
import { multiIndexAnalyticsMap, singleIndexAnalyticsMap } from 'data/filtersmap';
import { useIndexQuery } from 'components/analyticsPage/tabPanels/components/drawers/indexDrawer/hooks/useIndexQuery';
import _ from 'lodash';

export const useIndexAnalysis = () => {
  const analytics = useSelector((state) => state.analytics);
  const data = analytics.tabList[analytics.selectedTab];

  const [setQuery] = useIndexQuery();
  const [series, setSeries] = useState();
  const [column, setColumn] = useState([]);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isNoResponse, setIsNoResponse] = useState(false);

  useEffect(() => {
    getData();
  }, [analytics.tabList]);

  const getData = async () => {
    let result;
    setLoading(true);
    setColumn([]);
    setRows([]);
    try {
      await (async () => {
        if (data.tab_type === 'index-analytics') result = await getMultiIndexData(data);
        if (data.tab_type === 'index-analytics-multi') result = await getSingleIndexData(data);
        const { series, column, rows, isNoResponse } = result;
        setSeries(series);
        setColumn(column);
        setRows(rows);
        setIsNoResponse(isNoResponse);
      })();
      await (async () => {
        const query = await convertIndexUritoQueryObj(data, analytics.indexQuery);
        setQuery(query);
      })();
    } catch (e) {
      setIsNoResponse(true);
    }
    setLoading(false);
  };

  return [column, rows, series, isNoResponse, loading];
};

const getMultiIndexData = async (data) => {
  try {
    let result = await getMultiIndexAnalytics(data);
    const rawData = result.data;
    const max = rawData.date.length;
    const [column, rows] = getIndexRowsandCol(rawData);

    let lineChart = {};
    let series = [];

    for (let item in multiIndexAnalyticsMap) {
      if (rawData[item]) {
        lineChart[multiIndexAnalyticsMap[item]] = [];
        for (let i = 0; i < max; i++) {
          let date = Number(moment(rawData.date[i]).format('x'));
          lineChart[multiIndexAnalyticsMap[item]].push([date, rawData[item][i]]);
        }
      }
    }

    for (let i in lineChart) {
      series.push({
        type: 'spline',
        name: i,
        data: lineChart[i],
      });
    }

    return { series: series, column: column, rows: rows, isNoResponse: false };
  } catch (e) {
    return { series: [], column: [], rows: [], isNoResponse: true };
  }
};

const getSingleIndexData = async (data) => {
  try {
    const result = await getSingleIndexAnalytics(data);
    const rawData = result.data;
    const max = rawData.date.length;
    const [column, rows] = getIndexRowsandCol(rawData);

    let lineChart = {};
    let series = [];

    for (let item in multiIndexAnalyticsMap) {
      if (rawData[item]) {
        lineChart[multiIndexAnalyticsMap[item]] = [];
        for (let i = 0; i < max; i++) {
          let date = Number(moment(rawData.date[i]).format('x'));
          lineChart[multiIndexAnalyticsMap[item]].push([date, rawData[item][i]]);
        }
      }
    }
    for (let i in lineChart) {
      series.push({
        type: 'spline',
        name: i,
        data: lineChart[i],
      });
    }

    return { series: series, column: column, rows: rows, isNoResponse: false };
  } catch (e) {
    return { series: [], column: [], rows: [], isNoResponse: true };
  }
};

const getIndexRowsandCol = (data) => {
  let rows = [];
  let column = [];
  for (let i in data) column.push(i);
  for (let i = 0; i < data.date.length; i++) {
    let row = [];
    for (let j = 0; j < column.length; j++) {
      row.push(data[column[j]][i]);
    }
    rows.push(row);
  }

  return [column, rows];
};

export const convertIndexUritoQueryObj = async (analysisInfo, indexQuery) => {
  const _indexQuery = _.cloneDeep(indexQuery);

  const companyId = analysisInfo.corp_code;
  _indexQuery.companyId = companyId;

  _indexQuery.companyName_ko = analysisInfo.corp_name_ko;
  _indexQuery.companyName_en = analysisInfo.corp_name_en;
  _indexQuery.tabName = analysisInfo.tab_name;
  _indexQuery.prevTabName = analysisInfo.tab_name;

  if (analysisInfo.tab_type === 'index-analytics') _indexQuery.target = 'multiIndex';
  if (analysisInfo.tab_type === 'index-analytics-multi') _indexQuery.target = 'singleIndex';

  let filtersArr = analysisInfo.filters.split(/=|&/);
  if (analysisInfo.tab_type === 'index-analytics') {
    _indexQuery.index.multiIndex.from = moment
      .unix(filtersArr[filtersArr.indexOf('from') + 1])
      .format('YYYY/MM/DD');
    _indexQuery.index.multiIndex.to = moment
      .unix(filtersArr[filtersArr.indexOf('to') + 1])
      .format('YYYY/MM/DD');
  }
  if (analysisInfo.tab_type === 'index-analytics-multi') {
    _indexQuery.index.singleIndex.from = moment
      .unix(filtersArr[filtersArr.indexOf('from') + 1])
      .format('YYYY/MM/DD');
    _indexQuery.index.singleIndex.to = moment
      .unix(filtersArr[filtersArr.indexOf('to') + 1])
      .format('YYYY/MM/DD');
  }

  let filters = filtersArr[filtersArr.indexOf('index') + 1].split(',');
  if (analysisInfo.tab_type === 'index-analytics') {
    _indexQuery.index.multiIndex.filters = [
      { category: 'Pulse', check: filters.indexOf('0') !== -1 ? true : false },
      { category: 'Score', check: filters.indexOf('1') !== -1 ? true : false },
      { category: 'Momentum', check: filters.indexOf('2') !== -1 ? true : false },
      { category: 'ESG Exposure', check: filters.indexOf('3') !== -1 ? true : false },
    ];
  }
  if (analysisInfo.tab_type === 'index-analytics-multi') {
    _indexQuery.index.singleIndex.filters = [
      { category: 'Pulse', check: filters.indexOf('0') !== -1 ? true : false },
      { category: 'Score', check: filters.indexOf('1') !== -1 ? true : false },
      { category: 'Momentum', check: filters.indexOf('2') !== -1 ? true : false },
      { category: 'ESG Exposure', check: filters.indexOf('3') !== -1 ? true : false },
    ];
  }

  if (analysisInfo.tab_type === 'index-analytics')
    _indexQuery.index.multiIndex.dataType = Number(filtersArr[filtersArr.indexOf('data_type') + 1]);
  if (analysisInfo.tab_type === 'index-analytics-multi')
    _indexQuery.index.singleIndex.dataType = filtersArr[filtersArr.indexOf('data_type') + 1];

  if (filtersArr.indexOf('materiality') !== -1) {
    if (analysisInfo.tab_type === 'index-analytics')
      _indexQuery.index.multiIndex.compositeScore.materiality =
        filtersArr[filtersArr.indexOf('materiality') + 1];
    if (analysisInfo.tab_type === 'index-analytics-multi')
      _indexQuery.index.singleIndex.compositeScore.materiality =
        filtersArr[filtersArr.indexOf('materiality') + 1];
  }

  if (filtersArr.indexOf('code') !== -1) {
    if (analysisInfo.tab_type === 'index-analytics')
      _indexQuery.index.multiIndex.individualScore.esgCode =
        filtersArr[filtersArr.indexOf('code') + 1];
    if (analysisInfo.tab_type === 'index-analytics-multi')
      _indexQuery.index.singleIndex.individualScore.esgCode =
        filtersArr[filtersArr.indexOf('code') + 1];
  }

  return _indexQuery;
};
