import styled from 'styled-components';

export const ModalContainer = styled.div`
  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: fixed;
  background-color: rgba(0, 0, 0, 0.13);
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 9999;
`;

export const Modal = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
