import { useState } from 'react';
import { Typography, Stack, Button, Chip, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TuneIcon from '@mui/icons-material/Tune';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DenseTable from 'components/analyticsPage/denseTable';
import LoadingCheck from 'components/analyticsPage/loadingCheck/LoadingCheck';
import DataDrawer from 'components/analyticsPage/tabPanels/components/drawers/dataDrawer';
import { useDownload } from 'components/analyticsPage/tabPanels/hooks/useDownload';
import { useDataAnalysis } from 'components/analyticsPage/tabPanels/components/dataAnalysis/hooks/useDataAnalysis';
import { useSelector } from 'react-redux';
import { useResetDataDrawer } from 'components/analyticsPage/tabPanels/components/dataAnalysis/hooks/useResetDrawer';
import ModalComponent from 'ui/modals/defaultModal/Modal';
import ComponentPreloader from 'ui/preloaders/componentPreloader/ComponentPreloader';
import { getUserData } from 'api/user';

export const DataAnalysis = () => {
  const [column, rows, isNoResponse, isLoading] = useDataAnalysis();
  const [openDataDrawer, setOpenDataDrawer] = useState(false);
  const dataQuery = useSelector((state) => state.analytics.dataQuery);
  const [download] = useDownload(column, rows, dataQuery.companyName_en);
  useResetDataDrawer([openDataDrawer]);
  const user = useSelector((state) => state.user.info);

  const [openModal, setOpenModal] = useState(false);
  const [iframeLoading, setIframeLoading] = useState(true);

  const handleDownloadClick = async () => {
    try {
      const userCheck = await getUserData(user.id);
      if (userCheck?.data?.user_type === 'pro') {
        download();
      } else {
        setOpenModal(true);
      }
    } catch (e) {
      window.alert('Failed download.');
    }
  };

  const iframLoaded = () => {
    setIframeLoading(false);
  };

  const handleModalClose = () => {
    setIframeLoading(true);
    setOpenModal(false);
  };

  return (
    <>
      <Stack spacing={2}>
        <Stack direction="row" alignItems="center">
          <Typography variant="h5">Data</Typography>
          <Tooltip title="Data analytics provide source data so that users can directly analyze corporate financial and non-financial data in the form of cross-sections and time series after sophisticated normalization and standardization.">
            <InfoOutlinedIcon fontSize="10px" sx={{ cursor: 'pointer', ml: '5px' }} />
          </Tooltip>
        </Stack>
        <Stack justifyContent="space-between" direction="row" alignItems="center">
          <Stack direction="row" alignItems="center" spacing={2} height="100%">
            <Button
              variant="contained"
              startIcon={<TuneIcon />}
              sx={{ height: '100%' }}
              onClick={() => setOpenDataDrawer(true)}
            >
              Settings
            </Button>
          </Stack>
          <Button
            variant="outlined"
            startIcon={<FileDownloadIcon />}
            sx={{ height: '100%', '@media(max-width: 768px)': { display: 'none' } }}
            onClick={handleDownloadClick}
          >
            Export
          </Button>
        </Stack>
        <LoadingCheck isLoading={isLoading} isNoResponse={isNoResponse}>
          <DenseTable columns={column} rows={rows} />
        </LoadingCheck>
        <DataDrawer
          open={openDataDrawer}
          data={dataQuery}
          action="update"
          closeDrawer={() => setOpenDataDrawer(false)}
        />
      </Stack>
      <ModalComponent open={openModal} onClose={handleModalClose} width="fit-content">
        <div style={{ position: 'relative' }}>
          {iframeLoading && (
            <div style={{ position: 'absolute', left: '50%', top: '50%', translate: '-50% -50%' }}>
              <ComponentPreloader />
            </div>
          )}
          <div style={{ height: '30px' }} />
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSepD-PA2voKvTytaM1cfrsCjVVNCB6aXq7md1Pp_E0usIEx8A/viewform?embedded=true"
            width={640}
            height={window.innerHeight * 0.7}
            onLoad={iframLoaded}
          />
        </div>
      </ModalComponent>
    </>
  );
};

export default DataAnalysis;
