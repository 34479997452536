import { getESGTopictTrend } from 'api/home';
import { useState, useEffect } from 'react';

const useEsgTopicTrend = () => {
  const [esgTopicTrendData, setEsgTopicTrendData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    const esgTopic = await getESGTopictTrend();
    if (esgTopic) setEsgTopicTrendData(esgTopic.data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return [esgTopicTrendData, isLoading];
};

export default useEsgTopicTrend;
