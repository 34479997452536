import React from 'react';

import { Container, Title } from './EventCard.styles';
import { COLORS } from 'styles/colorTheme';
import Typo from 'ui/typo/Typo';
import { useTranslation } from 'react-i18next';

export default function EventCard({ title, date, desc, esg, financialImapct, press, icon, link }) {
  const handleClick = () => window.open(link, '_blank');
  const [t] = useTranslation('timeline');

  return (
    <Container>
      <Title onClick={handleClick}>
        <Typo variant="b2" color={COLORS.Text500} className={'medium'} sx={{ cursor: 'pointer' }}>
          {'"'}
          {title}
          {'"'}
        </Typo>
      </Title>
      <div style={{ display: 'flex', alignItems: 'center', gap: 3, padding: '4px 0px' }}>
        <img src={icon} width={16} height={16} />
        <div>
          <Typo variant="b3" color={COLORS.Text400}>
            {esg}
          </Typo>
        </div>
        <div>
          <Typo variant="b3" color={COLORS.Text400}>
            •
          </Typo>
        </div>
        <div>
          <Typo variant="b3" color={COLORS.Text400}>
            {date}
          </Typo>
        </div>
      </div>
    </Container>
  );
}
