import { Container, CardWrapper, CardColumn } from './TopNewsStyles';
import NewsCardMain from 'ui/card/newsCards/main/NewsCardMain';

const TopNews = ({ articles = [], sx }) => {
  return (
    <Container style={{ ...sx }}>
      <CardColumn>
        {articles.slice(0, 5).map((article, i) => (
          <CardWrapper key={i}>
            <NewsCardMain key={i} article={article} number={i + 1} />
          </CardWrapper>
        ))}
      </CardColumn>
      <CardColumn>
        {articles.slice(5, 10).map((article, i) => (
          <CardWrapper key={i}>
            <NewsCardMain key={i + 5} article={article} number={i + 1 + 5} />
          </CardWrapper>
        ))}
      </CardColumn>
    </Container>
  );
};

export default TopNews;
