import axiosInstance from './apiInterceptor';

export const getEsgTopicNews = async (page = 1, per_page = 20, tax_code = 'ESG-E110') => {
  return axiosInstance({
    method: 'GET',
    url: '/news/esg-topic',
    params: { page: page, per_page: per_page, tax_code: tax_code },
  });
};

export const getIndustryNews = async (page = 1, per_page = 20, industry_code = 'CG-AA') => {
  return axiosInstance({
    method: 'GET',
    url: '/news/industry',
    params: { page: page, per_page: per_page, industry_code: industry_code },
  });
};

export const getMainNews = async () => {
  return axiosInstance({
    method: 'GET',
    url: '/news/main',
  });
};

export const saveFeedbak = (userId, email, article, feedback) => {
  return axiosInstance({
    method: 'POST',
    url: '/news/create-news-feedback',
    data: {
      user_id: userId,
      email: email,
      news_info: article,
      feedback: feedback,
    },
  });
};
