import { media } from 'media';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  column-gap: 60px;
  row-gap: 60px;

  ${media.mobile`
    display: block;
  `}
`;

export const NewsCardBundleWrapper = styled.div`
  width: fit-content;

  ${media.mobile`
    width: 100%;
  `}
`;
