import UpDownChip from 'ui/caption/score3/Score3';
import { Container, Bar, TitleWrapper, ScoreWrapper, ButtonWrapper } from './ListItemStyles';
import CancelButton from 'ui/icon/cancel/Cancel';
import Typo from 'ui/typo/Typo';
import { COLORS } from 'styles/colorTheme';
import LightLineChart from 'ui/charts/lightLineChart/LightLineChart';

const ListItem = ({
  tagColor,
  title,
  score,
  lightLineChartData = [],
  scoreChange,
  onClick,
  handleCancel,
  displayCancel = true,
  isSelected = false,
  navigator,
  sx,
}) => {
  const onClickCancel = (event) => {
    handleCancel();
    event.stopPropagation();
  };

  const handleNavigator = () => {
    if (navigator) navigator();
  };

  return (
    <Container style={{ ...sx }} isSelected={isSelected} onClick={onClick}>
      <TitleWrapper navigator={!!navigator} onClick={handleNavigator}>
        {tagColor ? <Bar tagColor={tagColor} /> : null}
        <Typo variant="h3" color={COLORS.Text500}>
          {title}
        </Typo>
      </TitleWrapper>
      <ScoreWrapper displayCancel={displayCancel}>
        {lightLineChartData.length > 0 ? (
          <LightLineChart
            fillColor={false}
            color={
              scoreChange === 0 ? COLORS.Text400 : scoreChange > 0 ? COLORS.Red300 : COLORS.Blue300
            }
            data={lightLineChartData}
          />
        ) : null}
        <Typo variant="caption1" color={COLORS.Text500} sx={{ width: '37px', textAlign: 'end' }}>
          {score?.toFixed(2)}
        </Typo>
        <UpDownChip change={scoreChange} sx={{ width: '58px' }} />
      </ScoreWrapper>
      <ButtonWrapper isVisible={displayCancel}>
        <CancelButton onClick={onClickCancel} />
      </ButtonWrapper>
    </Container>
  );
};

export default ListItem;
