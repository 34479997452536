import { useEffect } from 'react';
import TermsComponent from 'components/privacyTerms/TermsComponent';

export const Terms = () => {
  useEffect(() => {
    window.document.title = 'Fairlabs 이용약관';
  });

  return (
    <>
      <header>
        <div className="header">
          <div className="header-wrapper">
            <span className="pointer">
              <img
                width="120px"
                src="/images/header/logo.svg"
                alt=""
                onClick={(e) => (window.location.href = '/')}
              />
            </span>
          </div>
        </div>
      </header>
      <TermsComponent />
    </>
  );
};

export default Terms;
