import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div`
  display: flex;
  width: 340px;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid ${COLORS.Background200};
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(240, 243, 250, 0.25);

  height: 100%;
  box-sizing: border-box;
`;

export const Title = styled.div`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Contents = styled.div`
  width: 100%;
  line-height: 1.2;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const More = styled.div`
  color: ${COLORS.Primary500};
  cursor: pointer;
  :hover {
    text-decoration-line: underline;
  }
`;
