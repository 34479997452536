import { Stack, Typography, Radio, Select, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { esgCodes } from 'data/esgCode';
import Filters from 'components/analyticsPage/tabPanels/components/drawers/components/Filters';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useMultiIndexQuery } from 'components/analyticsPage/tabPanels/components/drawers/indexDrawer/components/components/multiIndex/hooks/useMultiIndexQuery';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const MultiIndex = ({ open }) => {
  const query = useSelector((state) => state.analytics.indexQuery.index.multiIndex);
  const [setQuery] = useMultiIndexQuery();
  const [t] = useTranslation('analytics');

  return (
    <Stack spacing={3} sx={{ display: open }}>
      <Stack spacing={2}>
        <Stack width="100%" spacing={1}>
          <Typography sx={{ fontWeight: '500' }}>{t('Time window')}</Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="From"
              displayStaticWrapperAs="desktop"
              value={query.from}
              onChange={(e) => setQuery({ from: e })}
              disableFuture={true}
              renderInput={(params) => <TextField size="small" {...params} />}
            />
            <DatePicker
              label="To"
              displayStaticWrapperAs="desktop"
              value={query.to}
              onChange={(e) => setQuery({ to: e })}
              minDate={query.from}
              disableFuture={true}
              renderInput={(params) => <TextField size="small" {...params} />}
            />
          </LocalizationProvider>
        </Stack>
        <Stack spacing={1}>
          <Typography sx={{ fontWeight: '500' }}>{t('Data type')}</Typography>
          <FormControl sx={{ ml: 1.5 }}>
            <RadioGroup
              value={query.dataType}
              onChange={(e) => setQuery({ dataType: Number(e.target.value) })}
            >
              <FormControlLabel value={0} control={<Radio />} label={t('Materiality')} />
              <FormControl size="small" disabled={query.dataType !== 0} sx={{ width: '100%' }}>
                <InputLabel>Select a Materiality</InputLabel>
                <Select
                  label="Select a ESG Category"
                  value={query.compositeScore.materiality}
                  onChange={(e) => setQuery({ compositeScore: { materiality: e.target.value } })}
                >
                  <MenuItem value={0}>Data-driven Materiality</MenuItem>
                  <MenuItem value={1}>SASB Materiality</MenuItem>
                </Select>
              </FormControl>
              <FormControlLabel value={1} control={<Radio />} label={t('ESG Category')} />
              <FormControl size="small" disabled={query.dataType !== 1} sx={{ width: '100%' }}>
                <InputLabel>Select a ESG Category</InputLabel>
                <Select
                  label="Select a ESG Category"
                  value={query.individualScore.esgCode}
                  MenuProps={{ sx: { height: '350px' } }}
                  onChange={(e) => setQuery({ individualScore: { esgCode: e.target.value } })}
                >
                  {esgCodes.map((el, i) => (
                    <MenuItem value={el.general_issue_code} key={i}>
                      {el.general_issue_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </RadioGroup>
          </FormControl>
        </Stack>
      </Stack>
      <Stack>
        <Typography sx={{ fontWeight: '500' }}>{t('Index')}</Typography>
        <Filters filters={query.filters} setQuery={setQuery} originalFilter={query.filters} />
      </Stack>
    </Stack>
  );
};

export default MultiIndex;
