import axiosInstance from './apiInterceptor';

export const getCompanyHeatmapData = async (codes = []) => {
  const corp_codes = codes.join(',');
  return axiosInstance({
    method: 'GET',
    url: `/dashboard/heatmap-chart`,
    params: { corp_codes: corp_codes },
  });
};

export const getCompanyEsgViralData = async (codes = []) => {
  const corp_codes = codes.join(',');
  return axiosInstance({
    method: 'GET',
    url: `/dashboard/viral-chart`,
    params: { corp_codes: corp_codes },
  });
};

export const getCompanyTreemapData = async (codes = []) => {
  const corp_codes = codes.join(',');
  return axiosInstance({
    method: 'GET',
    url: `/dashboard/treemap-chart`,
    params: { corp_codes: corp_codes },
  });
};

export const getDashboardNews = async (codes = [], page = 1, per_page = 20) => {
  const corp_codes = codes.join(',');
  return axiosInstance({
    method: 'GET',
    url: `/news/following-companies`,
    params: { page: page, per_page: per_page, corp_codes: corp_codes },
  });
};
