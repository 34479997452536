import styled from 'styled-components';

export const Display1 = styled.span`
  font-size: 32px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  color: ${(props) => props.color};
  font-variant-numeric: tabular-nums;
`;

export const Display2 = styled.span`
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  color: ${(props) => props.color};
  font-variant-numeric: tabular-nums;
`;

export const Display3 = styled.span`
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  color: ${(props) => props.color};
  font-variant-numeric: tabular-nums;
`;

export const Head1 = styled.span`
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  color: ${(props) => props.color};
  font-variant-numeric: tabular-nums;

  &.semibold {
    font-weight: 600;
  }
`;

export const Head2 = styled.span`
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  font-variant-numeric: tabular-nums;

  color: ${(props) => props.color};

  &.regular {
    font-weight: 400;
  }

  &.medium {
    font-weight: 500;
  }
`;

export const Head3 = styled.span`
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: ${(props) => props.color};
  font-variant-numeric: tabular-nums;

  &.regular {
    font-weight: 400;
  }

  &.medium {
    font-weight: 500;
  }

  &.underline {
    text-decoration: underline;
  }
`;

export const Body1 = styled.span`
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: ${(props) => props.color};
  font-variant-numeric: tabular-nums;

  &.regular {
    font-weight: 400;
  }

  &.medium {
    font-weight: 500;
  }
`;

export const Body2 = styled.span`
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: ${(props) => props.color};
  font-variant-numeric: tabular-nums;

  &.regular {
    font-weight: 400;
  }

  &.medium {
    font-weight: 500;
  }
`;

export const Body3 = styled.span`
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: ${(props) => props.color};
  font-variant-numeric: tabular-nums;

  &.regular {
    font-weight: 400;
  }

  &.medium {
    font-weight: 500;
  }
`;

export const Button1 = styled.span`
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: ${(props) => props.color};
  font-variant-numeric: tabular-nums;

  &.underline {
    text-decoration: underline;
  }
`;

export const Button2 = styled.span`
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: ${(props) => props.color};
  font-variant-numeric: tabular-nums;

  &.regular {
    font-weight: 400;
  }

  &.medium {
    font-weight: 500;
  }
`;

export const Caption1 = styled.span`
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;

  font-variant-numeric: tabular-nums;

  color: ${(props) => props.color};
`;

export const Caption2 = styled.span`
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: ${(props) => props.color};
  font-variant-numeric: tabular-nums;
`;

export const Overline1 = styled.span`
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: ${(props) => props.color};
  font-variant-numeric: tabular-nums;
`;

export const Overline2 = styled.span`
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: ${(props) => props.color};
  font-variant-numeric: tabular-nums;
`;
