import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

export const Arrow = styled.img`
  transform: ${(props) => (props.direction === 'left' ? 'rotate(180deg)' : '')};
  cursor: pointer;
`;

export const ArrowWrapper = styled.div`
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BtnWrapper = styled.div`
  display: inline-block;
  width: fit-content;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  color: ${(props) => (props.isActive ? COLORS.Primary500 : '')};

  :hover {
    background-color: ${COLORS.Background50};
  }
`;
