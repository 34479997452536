import { useEffect } from 'react';
import { convertDataUritoQueryObj } from 'components/analyticsPage/tabPanels/components/dataAnalysis/hooks/useDataAnalysis';
import { useDataQuery } from 'components/analyticsPage/tabPanels/components/drawers/dataDrawer/hooks/useDataQuery';
import { useSelector } from 'react-redux';

export const useResetDataDrawer = (trigger) => {
  const analytics = useSelector((state) => state.analytics);
  const data = analytics.tabList[analytics.selectedTab];
  const [setQuery] = useDataQuery();

  useEffect(() => {
    const getQuery = async () => {
      const query = await convertDataUritoQueryObj(data, analytics.dataQuery);
      setQuery(query);
    };
    getQuery();
  }, [...trigger]);

  return;
};
