import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 4px;
  border-bottom: 1px solid ${COLORS.Background100};
  cursor: pointer;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 4px 4px 4px 8px;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;

  :hover {
    background-color: ${COLORS.Background50};
  }
`;

export const NameWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 20px;
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: hidden;
`;

export const DataWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Data = styled.div`
  padding: 4px;
  display: inline-flex;
  align-items: center;
  gap: 4px;
`;

export const Img = styled.img``;

export const TextWrapper = styled.div`
  min-width: 38px;
  display: flex;
  justify-content: end;
`;
