import { Typography, Stack } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const NoResults = () => {
  return (
    <Stack direction="row" justifyContent="center" alignItems="center">
      <SearchIcon fontSize="large" sx={{ color: '#0074BD', mr: 1 }} />
      <Stack>
        <Typography fontWeight="500">No results found</Typography>
        <Typography color="#969696">Try another search</Typography>
      </Stack>
    </Stack>
  )
};

export default NoResults;