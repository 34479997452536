import Typo from 'ui/typo/Typo';
import { Container, ScoreWrapper, Icon, DescWrapper, TitleWrapper } from './IndexCardStyles';
import QuestionIcon from 'ui/icon/questionIcon/QuestionIcon';
import { COLORS } from 'styles/colorTheme';

const IndexCard = ({ title, icon, score, desc, explanation, sx }) => {
  return (
    <Container style={{ ...sx }}>
      <TitleWrapper>
        <Typo variant="h3">{title}</Typo>
        {explanation ? <QuestionIcon desc={explanation} /> : null}
      </TitleWrapper>
      <ScoreWrapper>
        {icon ? <Icon src={icon} alt="icon" /> : null}
        {score ? <>{score}</> : <></>}
      </ScoreWrapper>
      <DescWrapper>
        <Typo variant="overline1" color={COLORS.Text400}>
          {desc ? <>{desc}</> : <></>}
        </Typo>
      </DescWrapper>
    </Container>
  );
};

export default IndexCard;
