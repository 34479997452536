import Typo from 'ui/typo/Typo';
import { Container, TitleWrapper, DescriptionWrapper } from './CompanySummaryStyles';
import { COLORS } from 'styles/colorTheme';
import DescriptionItem from 'ui/listItem/descriptionItem/DescriptionItem';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const CompanySummary = ({ data, sx }) => {
  const {
    ISIN,
    corp_reg_number,
    biz_reg_number,
    founded_date,
    public_date,
    ceo_ko,
    ceo_en,
    address,
    web_url,
    ir_url,
    tel,
    fax,
    material_topic_count,
    description_ko,
    description_link_ko,
    description_en,
    description_link_en,
    employee_count,
    applicant_number,
    twitter,
    facebook,
    linkedin,
  } = data;

  const [t] = useTranslation('companyDetails');
  const language = useSelector((state) => state.user.language);

  return (
    <Container style={{ ...sx }}>
      <TitleWrapper>
        <Typo variant="h2" color={COLORS.Text500}>
          {t('Company Information')}
        </Typo>
      </TitleWrapper>

      <DescriptionWrapper>
        <DescriptionItem label={t('CEO')} value={data[`ceo_${language}`] || data[`ceo_ko`]} />
        <DescriptionItem label={t('Biz reg number')} value={biz_reg_number} />
        <DescriptionItem label={t('Homepage')} value={web_url} link={`http://${web_url}`} />
        <DescriptionItem label={t('Founded date')} value={founded_date} />
        <DescriptionItem label={t('Corp reg number')} value={corp_reg_number} />
        {/* <DescriptionItem label={t('Linkedin')} value={linkedin} link={linkedin} /> */}
        <DescriptionItem label={t('Address')} value={address} />
        <DescriptionItem label={t('Tel')} value={tel} />
      </DescriptionWrapper>
    </Container>
  );
};

export default CompanySummary;
