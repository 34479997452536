import { Container, NewsWrapper } from './CompanyDetailNewsStyles';
import Typo from 'ui/typo/Typo';
import { COLORS } from 'styles/colorTheme';
import { useTranslation } from 'react-i18next';
import Nodata from 'ui/errorSigns/noData/Nodata';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getCompanyDetailNews } from 'api/companyDetail';
import Preloader from 'ui/preloaders/componentPreloader/ComponentPreloader';
import NewsCardSmall from 'ui/card/newsCards/image/newsCardSmall/NewsCardSmall';

const CompanyDetailNews = ({ sx }) => {
  const [t] = useTranslation('news');
  const [dT] = useTranslation('noData');
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [newsData, setNewsData] = useState({
    data: [],
    count: 0,
  });

  const getNewsData = async () => {
    setIsLoading(true);
    const res = await getCompanyDetailNews(id);
    if (res?.data) {
      setNewsData(res.data);
    } else {
      setNewsData({
        data: [],
        count: 0,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (id) {
      getNewsData();
    }
  }, [id]);

  return (
    <Container style={{ ...sx }}>
      <Typo variant="h2" color={COLORS.Text500}>
        {t('Main News')}
      </Typo>
      {isLoading ? (
        <Preloader width="1172px" height="300px" />
      ) : (
        <>
          {newsData.data.length > 0 ? (
            <NewsWrapper>
              {newsData.data.map((article, i) => (
                <NewsCardSmall key={i} article={article} />
              ))}
            </NewsWrapper>
          ) : (
            <Nodata desc={dT('No ESG news.')} />
          )}
        </>
      )}
    </Container>
  );
};

export default CompanyDetailNews;
