import styled from 'styled-components';

export const Container = styled.div`
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  padding: 4px;
  gap: 2px;
  width: fit-content;
  min-width: 51px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextContainer = styled.div`
  box-sizing: content-box;
  display: flex;
  align-items: center;
`;

export const Img = styled.img`
  width: 12px;
  height: 12px;
`;
