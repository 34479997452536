import { useEffect, useState } from 'react';
import { getEsgBenchmarkChart } from 'api/companyDetail';
import moment from 'moment';
import { useSelector } from 'react-redux';

const useBenchmark = (followingList, benchmarkDataMenu, benchmarkDataTime, colors) => {
  const language = useSelector((state) => state.user.language);

  const [isLoading, setIsLoading] = useState();
  const [data, setData] = useState({
    benchmarkData_raw: [],
    benchmarkData_dimension: [],
    benchmarkData_materialCodes: [],
    benchmarkData_materialData: [],
  });

  useEffect(() => {
    getData();
  }, [benchmarkDataMenu, benchmarkDataTime]);

  const getData = async () => {
    setIsLoading(true);

    const dataArr = [];

    for (let i = 0; i < followingList.length; i++) {
      const company = followingList[i];

      const res = await getEsgBenchmarkChart(
        company.corp_code,
        benchmarkDataMenu,
        benchmarkDataTime,
      );
      if (!res) continue;

      let arr = [];
      let legend = [];

      if (benchmarkDataMenu === 'nfr_raw_pulse') arr = res.data;

      if (benchmarkDataMenu === 'nfr_score_by_dimension') {
        for (let i = 0; i < res.data.length; i++) {
          const element = res.data[i];
          arr.push(element.scores);
          legend.push(element[`dimension_name_${language}`]);
        }
      }

      if (
        benchmarkDataMenu === 'risk_score_by_material_codes' ||
        benchmarkDataMenu === 'risk_score_by_material_data_codes'
      ) {
        for (let i = 0; i < res.data.length; i++) {
          const element = res.data[i];
          arr.push(element.scores);
          legend.push(element[`general_issue_names_${language}`]);
        }
      }

      const obj = {
        name: company[`comp_name_${language}`],
        color: colors[i],
        data: arr,
        legend: legend,
      };
      dataArr.push(obj);
    }
    if (benchmarkDataMenu === 'nfr_raw_pulse') setData({ ...data, benchmarkData_raw: dataArr });
    if (benchmarkDataMenu === 'nfr_score_by_dimension')
      setData({ ...data, benchmarkData_dimension: dataArr });
    if (benchmarkDataMenu === 'risk_score_by_material_codes')
      setData({ ...data, benchmarkData_materialCodes: dataArr });
    if (benchmarkDataMenu === 'risk_score_by_material_data_codes')
      setData({ ...data, benchmarkData_materialData: dataArr });
  };

  return [data, isLoading, setIsLoading];
};

export default useBenchmark;
