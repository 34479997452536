import TreemapChart from 'ui/charts/treemapChart/TreemapChart';
import RangeBar from 'ui/bars/rangeBar/RangeBar';
import { Container, RangeBarWrapper } from './EsgTreemapStyles';
import Preloaer from 'ui/preloaders/componentPreloader/ComponentPreloader';

const EsgTreemap = ({ data, isLoading, sx }) => {
  return (
    <Container style={{ ...sx }}>
      <RangeBarWrapper>
        <RangeBar
          ranges={[
            { value: '+0.3', color: 'rgba(222, 111, 98, 1)' },
            { value: '+0.1', color: 'rgba(228, 144, 134, 1)' },
            { value: '+0.03', color: 'rgba(253, 227, 225, 1)' },
            { value: '0', color: 'rgba(241, 243, 246, 1)' },
            { value: '-0.03', color: 'rgba(205, 222, 249, 1)' },
            { value: '-0.1', color: 'rgba(133, 173, 233, 1)' },
            { value: '-0.3', color: 'rgba(67, 129, 222, 1)' },
          ]}
        />
      </RangeBarWrapper>
      {isLoading ? (
        <Preloaer width="1200px" height="375px" />
      ) : (
        <TreemapChart data={data} isLoading={isLoading} />
      )}
    </Container>
  );
};

export default EsgTreemap;
