import { Container } from "./CardStyles"

const Card = ({ children, sx }) => {
  return (
    <Container
      style={{
        ...sx
      }}
    >
      {children}
    </Container>
  )
}

export default Card