import { IndustryWrapper } from './IndustryModalStyles';
import industrySectormap from 'data/industrySectormap.json';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Industry from 'ui/list/industry/Industry';
import PrimaryModalFrame from '../primaryModalFrame/PrimaryModalFrame';

const IndustryModal = ({ current, apply, close, sx }) => {
  const { t: newsT } = useTranslation('news');

  const [_index, set_index] = useState(current);
  const handleClose = () => {
    set_index(current);
    close();
  };
  const handleApply = () => {
    apply(_index);
    close();
  };

  const language = useSelector((state) => state.user.language);
  let index = 0;
  return (
    <PrimaryModalFrame
      header={newsT('Select Industry')}
      onApply={handleApply}
      onClose={handleClose}
      sx={sx}
    >
      <IndustryWrapper>
        {industrySectormap.map((sector, i) => {
          const length = sector.industry.length;
          const cpIdx = index;
          index += length;
          return (
            <Industry
              key={index}
              idx={cpIdx}
              title={sector[`sectorName_${language}`]}
              industryList={sector.industry}
              selectedIndex={_index}
              onClick={(i) => set_index(i)}
            />
          );
        })}
      </IndustryWrapper>
    </PrimaryModalFrame>
  );
};

export default IndustryModal;
