import { Component } from 'react';
import { Typography, Stack, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

class LoadingData extends Component {
  render() {
    const { isLoading } = this.props;
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '600px' }}
      >
        {isLoading && (
          <Stack direction="row" alignItems="center">
            <CircularProgress size={60} />
            <Stack direction="column" sx={{ pl: 4 }}>
              <Typography sx={{fontSize: '15px', fontWeight: '700', color: '#333333'}}>DATA LOADING</Typography>
              <Typography sx={{fontSize: '15px', fontWeight: '400', color: '#747474'}}>Data aggregation in progress.</Typography>
            </Stack>
          </Stack>
        )}
      </Box>
    );
  }
}

export default LoadingData;
