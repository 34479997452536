import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SigninBtnContainer = styled.div`
  font-size: 14px;
  display: flex;
  justify-content: space-around;
`;

export const SigninBtn = styled.span`
  margin-left: 5px;
  cursor: pointer;
`;

export const ValidityContainer = styled.div`
  border: 1px solid rgba(194, 200, 208, 1);
  border-radius: 3px;
  padding: 12px 12px 6px 16px;
`;

export const List = styled.div`
  margin: 4px 0px;
`;

export const CheckBoxWrappwer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 6px;
`;

export const Eye = styled.img`
  padding: 10px 0px 10px 8px;
  cursor: pointer;
`;
