import { media } from 'media';
import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div`
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  gap: 24px;

  border-radius: 4px;
  border: 1px solid ${COLORS.Background200};
  background: #fff;

  max-width: 1204px;

  ${media.mobile`
    margin: 0px 6px;
    displat: block;
  `}
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.mobile`
    flex-direction: column;
    gap: 12px;
  `}
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ChartWrapper = styled.div`
  ${media.mobile`
    width: 100%;
    overflow: auto;
  `}
`;
