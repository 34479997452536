import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  getBasicDataAnalytics,
  getAdvancedDataAnalytics,
  getStockDataAnalytics,
} from 'api/analytics';
import moment from 'moment';
import _ from 'lodash';
import {
  basicDataAnalyticMap,
  advancedDataAnalyticsMap,
  stockDataAnalyticsMap,
} from 'data/filtersmap';
import { useDataQuery } from 'components/analyticsPage/tabPanels/components/drawers/dataDrawer/hooks/useDataQuery';

export const useDataAnalysis = () => {
  const [setQuery] = useDataQuery();
  const [column, setColumn] = useState([]);
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isNoResponse, setIsNoResponse] = useState(false);
  const analytics = useSelector((state) => state.analytics);
  const data = analytics.tabList[analytics.selectedTab];

  useEffect(() => {
    getData(data);
    const updateQuery = async () => {
      const query = await convertDataUritoQueryObj(data, analytics.dataQuery);
      setQuery(query);
    };
    updateQuery();
  }, [analytics.tabList]);

  const fetchBasicDataAnayltics = async (data) => {
    const response = await getBasicDataAnalytics(data);
    const dataLength = response.data[Object.keys(response.data)[0]].length;
    let colArr = [];
    let rowArr = Array(dataLength)
      .fill()
      .map(() => Array().fill());

    for (let item in basicDataAnalyticMap) {
      if (response.data[item]) {
        response.data[item].forEach((el, i) => {
          if (item === 'sasb' || item === 'data') {
            let element = el ? 'Yes' : 'No';
            rowArr[i].push(element);
          } else if (
            item === 'pulse' ||
            item === 'score' ||
            item === 'momentum' ||
            item === 'neg_exposure' ||
            item === 'score_change' ||
            item === 'score_change_ratio'
          ) {
            let element = el === null ? 'N/A' : el.toFixed(2);
            rowArr[i].push(element);
          } else {
            let element = el === null ? 'N/A' : el;
            rowArr[i].push(element);
          }
        });
        colArr.push(basicDataAnalyticMap[item]);
      }
    }

    return { colArr, rowArr };
  };

  const fetchAdvancedAnalytics = async (data) => {
    const response = await getAdvancedDataAnalytics(data);
    const dataLength = response.data[Object.keys(response.data)[0]].length;
    let colArr = [];
    let rowArr = Array(dataLength)
      .fill()
      .map(() => Array().fill());

    for (let item in advancedDataAnalyticsMap) {
      if (response.data[item]) {
        response.data[item].forEach((el, i) => {
          if (item === 'sasb' || item === 'data') {
            let element = el ? 'Yes' : 'No';
            rowArr[i].push(element);
          } else if (item === 'date') {
            let element = el === null ? 'N/A' : moment(el).format('YYYY-MM-DD');
            rowArr[i].push(element);
          } else if (
            item === 'pulse' ||
            item === 'score' ||
            item === 'momentum' ||
            item === 'neg_exposure' ||
            item === 'score_change' ||
            item === 'score_change_ratio'
          ) {
            let element = el === null ? 'N/A' : el.toFixed(2);
            rowArr[i].push(element);
          } else {
            let element = el === null ? 'N/A' : el;
            rowArr[i].push(element);
          }
        });
        colArr.push(advancedDataAnalyticsMap[item]);
      }
    }

    return { colArr, rowArr };
  };

  const fetchStockAnalytics = async (data) => {
    const response = await getStockDataAnalytics(data);
    const dataLength = response.data[Object.keys(response.data)[0]].length;
    let colArr = [];
    let rowArr = Array(dataLength)
      .fill()
      .map(() => Array().fill());

    for (let item in stockDataAnalyticsMap) {
      if (response.data[item]) {
        response.data[item].forEach((el, i) => {
          if (item === 'date') {
            rowArr[i].push(moment(el).format('YYYY-MM-DD'));
          } else {
            let element = el === null ? 'N/A' : el;
            rowArr[i].push(element);
          }
        });
        colArr.push(stockDataAnalyticsMap[item]);
      }
    }

    return { colArr, rowArr };
  };

  const getData = async (data) => {
    let result;
    setIsLoading(true);
    setColumn([]);
    setRows([]);
    try {
      if (data.tab_type === 'data-analytics') {
        result = await fetchBasicDataAnayltics(data);
      } else if (data.tab_type === 'data-analytics-timeseries') {
        result = await fetchAdvancedAnalytics(data);
      } else if (data.tab_type === 'data-analytics-stock') {
        result = await fetchStockAnalytics(data);
      }
      setColumn(result.colArr);
      setRows(result.rowArr);
    } catch (e) {
      setIsNoResponse(true);
      console.error(e);
    }
    setIsLoading(false);
  };

  return [column, rows, isNoResponse, isLoading];
};

export const convertDataUritoQueryObj = async (analysisInfo, dataQuery) => {
  const _dataQuery = _.cloneDeep(dataQuery);
  //Set Drawer Tab
  if (
    analysisInfo.tab_type === 'data-analytics' ||
    analysisInfo.tab_type === 'data-analytics-timeseries'
  )
    _dataQuery.drawerTab = 0;
  if (analysisInfo.tab_type === 'data-analytics-stock') _dataQuery.drawerTab = 1;
  //Set Name and Company Id
  const companyId = analysisInfo.corp_code;
  _dataQuery.companyId = companyId;
  _dataQuery.companyName_ko = analysisInfo.corp_name_ko;
  _dataQuery.companyName_en = analysisInfo.corp_name_en;
  _dataQuery.tabName = analysisInfo.tab_name;
  _dataQuery.prevTabName = analysisInfo.tab_name;

  //Set From To
  let filtersArr = analysisInfo.filters.split(/=|&/);
  if (analysisInfo.tab_type === 'data-analytics') {
    _dataQuery.esgData.dailyData.from = moment
      .unix(filtersArr[filtersArr.indexOf('date') + 1])
      .format('YYYY/MM/DD');
    _dataQuery.esgData.dailyData.to = moment
      .unix(filtersArr[filtersArr.indexOf('date') + 1])
      .format('YYYY/MM/DD');
  }
  if (analysisInfo.tab_type === 'data-analytics-timeseries') {
    _dataQuery.esgData.timeSeries.from = moment
      .unix(filtersArr[filtersArr.indexOf('from') + 1])
      .format('YYYY/MM/DD');
    _dataQuery.esgData.timeSeries.to = moment
      .unix(filtersArr[filtersArr.indexOf('to') + 1])
      .format('YYYY/MM/DD');
  }
  if (analysisInfo.tab_type === 'data-analytics-stock') {
    _dataQuery.stockData.from = moment
      .unix(filtersArr[filtersArr.indexOf('from') + 1])
      .format('YYYY/MM/DD');
    _dataQuery.stockData.to = moment
      .unix(filtersArr[filtersArr.indexOf('to') + 1])
      .format('YYYY/MM/DD');
  }

  //Set Filters
  if (analysisInfo.tab_type === 'data-analytics') {
    let materialityArr = filtersArr[filtersArr.indexOf('materiality') + 1].split(',');
    let sectorArr = filtersArr[filtersArr.indexOf('sector') + 1].split(',');
    let industryArr = filtersArr[filtersArr.indexOf('industry') + 1].split(',');
    let esgArr = filtersArr[filtersArr.indexOf('esg') + 1].split(',');

    _dataQuery.esgData.dailyData.filters = [
      {
        category: 'Data-driven Materiality',
        check: materialityArr.indexOf('0') !== -1 ? true : false,
      },
      { category: 'SASB Materiality', check: materialityArr.indexOf('1') !== -1 ? true : false },
      {
        category: 'Last Updated Date',
        check: filtersArr[filtersArr.indexOf('last_update') + 1] === '1' ? true : false,
      },
      {
        category: 'ESG Data',
        check: false,
        subCategory: [
          { category: 'Sector Rank', check: sectorArr.indexOf('0') !== -1 ? true : false },
          { category: 'Sector Percentile', check: sectorArr.indexOf('1') !== -1 ? true : false },
          { category: 'Industry Rank', check: industryArr.indexOf('0') !== -1 ? true : false },
          {
            category: 'Industry Percentile',
            check: industryArr.indexOf('1') !== -1 ? true : false,
          },
          { category: 'ESG Pulse', check: esgArr.indexOf('0') !== -1 ? true : false },
          { category: 'ESG Score', check: esgArr.indexOf('1') !== -1 ? true : false },
          { category: 'ESG Momentum', check: esgArr.indexOf('2') !== -1 ? true : false },
          { category: 'ESG Exposure', check: esgArr.indexOf('3') !== -1 ? true : false },
          { category: 'ESG Score Change', check: esgArr.indexOf('4') !== -1 ? true : false },
          { category: 'ESG Score Change Ratio', check: esgArr.indexOf('5') !== -1 ? true : false },
        ],
      },
    ];
    if (filtersArr.indexOf('score') !== -1)
      _dataQuery.dataType = Number(filtersArr[filtersArr.indexOf('score') + 1]);
    if (filtersArr.indexOf('score_materiality') !== -1) {
      _dataQuery.esgData.dailyData.compositeScore.materiality =
        filtersArr[filtersArr.indexOf('score_materiality') + 1];
      _dataQuery.esgData.dailyData.individualScore.esgCode =
        filtersArr[filtersArr.indexOf('code') + 1];
    }
    _dataQuery.target = 'dailyData';
  }

  if (analysisInfo.tab_type === 'data-analytics-timeseries') {
    let materialityArr = filtersArr[filtersArr.indexOf('materiality') + 1].split(',');
    let sectorArr = filtersArr[filtersArr.indexOf('sector') + 1].split(',');
    let industryArr = filtersArr[filtersArr.indexOf('industry') + 1].split(',');
    let esgArr = filtersArr[filtersArr.indexOf('esg') + 1].split(',');

    _dataQuery.esgData.timeSeries.filters = [
      {
        category: 'Data-driven Materiality',
        check: materialityArr.indexOf('0') !== -1 ? true : false,
      },
      { category: 'SASB Materiality', check: materialityArr.indexOf('1') !== -1 ? true : false },
      {
        category: 'Last Updated Date',
        check: filtersArr[filtersArr.indexOf('last_update') + 1] === '1' ? true : false,
      },
      {
        category: 'ESG Data',
        check: false,
        subCategory: [
          { category: 'Sector Rank', check: sectorArr.indexOf('0') !== -1 ? true : false },
          { category: 'Sector Percentile', check: sectorArr.indexOf('1') !== -1 ? true : false },
          { category: 'Industry Rank', check: industryArr.indexOf('0') !== -1 ? true : false },
          {
            category: 'Industry Percentile',
            check: industryArr.indexOf('1') !== -1 ? true : false,
          },
          { category: 'ESG Pulse', check: esgArr.indexOf('0') !== -1 ? true : false },
          { category: 'ESG Score', check: esgArr.indexOf('1') !== -1 ? true : false },
          { category: 'ESG Momentum', check: esgArr.indexOf('2') !== -1 ? true : false },
          { category: 'ESG Exposure', check: esgArr.indexOf('3') !== -1 ? true : false },
          { category: 'ESG Score Change', check: esgArr.indexOf('4') !== -1 ? true : false },
          { category: 'ESG Score Change Ratio', check: esgArr.indexOf('5') !== -1 ? true : false },
        ],
      },
    ];
    if (filtersArr.indexOf('data_type') !== -1)
      _dataQuery.esgData.timeSeries.dataType = Number(
        filtersArr[filtersArr.indexOf('data_type') + 1],
      );
    if (filtersArr.indexOf('score_materiality') !== -1) {
      _dataQuery.esgData.timeSeries.compositeScore.materiality =
        filtersArr[filtersArr.indexOf('score_materiality') + 1];
      _dataQuery.esgData.timeSeries.individualScore.esgCode =
        filtersArr[filtersArr.indexOf('code') + 1];
    }
    _dataQuery.target = 'timeSeries';
  }

  if (analysisInfo.tab_type === 'data-analytics-stock') {
    let stockArr = filtersArr[filtersArr.indexOf('stock') + 1].split(',');

    _dataQuery.stockData.filters = [
      {
        category: 'Stock Market Data',
        check: false,
        subCategory: [
          { category: 'Price', check: stockArr.indexOf('0') !== -1 ? true : false },
          { category: 'Change', check: stockArr.indexOf('1') !== -1 ? true : false },
          { category: '% Change', check: stockArr.indexOf('2') !== -1 ? true : false },
          { category: 'Day Range', check: stockArr.indexOf('3') !== -1 ? true : false },
          { category: 'Market Cap', check: stockArr.indexOf('4') !== -1 ? true : false },
          { category: 'Avg. Volume', check: stockArr.indexOf('5') !== -1 ? true : false },
          { category: '1 Month Performance', check: stockArr.indexOf('6') !== -1 ? true : false },
          { category: '3 Month Performance', check: stockArr.indexOf('7') !== -1 ? true : false },
          { category: '6 Month Performance', check: stockArr.indexOf('8') !== -1 ? true : false },
          { category: '1 Year Performance', check: stockArr.indexOf('9') !== -1 ? true : false },
          { category: 'YTD Performance', check: stockArr.indexOf('10') !== -1 ? true : false },
        ],
      },
    ];
    _dataQuery.target = 'stockData';
  }

  return _dataQuery;
};
