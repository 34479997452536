import { useSelector } from 'react-redux';
import { useSingleIndexQuery } from 'components/analyticsPage/tabPanels/components/drawers/indexDrawer/components/components/singleIndex/hooks/useSingleIndexQuery';

export const useIndividualScore = () => {
  const query = useSelector((state) => state.analytics.indexQuery.index.singleIndex);
  const [setQuery] = useSingleIndexQuery();

  let individualScore = [];
  if (query.individualScore.esgCode.length > 0) {
    individualScore = query.individualScore.esgCode.split(',').map(Number);
  }

  const setIndividualScore = (event) => {
    let str = event.target.value.join();
    if (event.target.value.length === 0) str = '';

    let typeStr = '1';
    if (query.compositeScore.materiality !== '') typeStr = '0,1';

    setQuery({
      individualScore: {
        esgCode: str,
      },
      dataType: typeStr,
    });
  };

  return [individualScore, setIndividualScore];
};
