import styled from "styled-components";

export const Container = styled.button`
  cursor: pointer;
  border: none;
  background-color: unset;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    background-color: #C5CFDD;
  }
`

export const Icon = styled.img``