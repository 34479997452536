import { Container } from './SmallMenu.styles';
import Small from 'ui/taps/secondary/small/Small';

const SmallMenu = ({ menuList = [], activeIndex, onClick }) => {
  return (
    <Container>
      {menuList.map((menu, i) => (
        <Small isActive={activeIndex === i} onClick={() => onClick(i)} key={i}>
          {menu}
        </Small>
      ))}
    </Container>
  );
};

export default SmallMenu;
