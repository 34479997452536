import { useEffect, useState } from 'react';
import { getESGRiskScore } from 'api/company';

const useNfrData = (id, filter) => {
  const [nfr, setNfr] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id) getData();
  }, [filter]);

  const getData = async () => {
    setIsLoading(true);
    const nfr = await getESGRiskScore(id, filter);
    setNfr(nfr?.data);
    setIsLoading(false);
  };

  return [nfr, isLoading];
};

export default useNfrData;
