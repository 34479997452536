import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const Menu = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  gap: 4px;
  cursor: pointer;
`;

export const IconWrapper = styled.div``;

export const ModalWrapper = styled.div`
  position: absolute;
  top: 56px;
  right: 0px;
`;
