import styled from 'styled-components';
import { media } from 'media';

export const ChartContainer = styled.div`
  ${media.mobile`
    overflow: auto;
  `}
`;

export const ChartWrapper = styled.div`
  ${media.mobile`
    min-width: 900px;
  `}
`;
