import axiosInstance from './apiInterceptor';

export const getFollowList = async () => {
  return axiosInstance({
    method: 'GET',
    url: `/user/following-list
`,
  });
};

export const getFollowListWithRisk = async (corpCodes) => {
  return axiosInstance({
    method: 'GET',
    url: '/dashboard/following-companies-esg-risk',
    params: {
      corp_codes: corpCodes,
    },
  });
};

export const followCompany = async (corpCode) => {
  return axiosInstance({
    method: 'POST',
    url: `/user/follow`,
    data: {
      corp_code: corpCode,
    },
  });
};

export const unFollow = async (corpCodes) => {
  return axiosInstance({
    method: 'DELETE',
    url: 'user/unfollow',
    params: {
      corp_codes: corpCodes,
    },
  });
};
