import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  padding: 4px;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`

export const Dot = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 8px;
  background: ${props => props.color};
`

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`