import axiosInstance from 'api/apiInterceptor';
import axios from 'axios';
// user data
export const getUserData = async (userId) => {
  // return axiosInstance.get(`/user/${userId}`);
  return await axios({
    method: 'GET',
    url: `${process.env.REACT_APP_API}/user/${userId}`,
  });
};

// user expiration-day
export const getUserExpiration = () => {
  return axiosInstance.get(`/user/expiration-date`);
};

// user refresh
export const makeUserRefresh = async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_API}/user/refresh-token`, {
      withCredentials: true,
    });
    return res;
  } catch (e) {
    return null;
  }
};

// user data
export const updateUserData = (userId, user) => {
  return axiosInstance.patch(`/user/${userId}/update`, user);
};

// delete account
export const deleteAccount = (userId, params) => {
  const body = {
    options: params.options,
    details: params.details,
  };
  return axiosInstance({
    method: 'delete',
    url: `/user/${userId}/delete`,
    data: body,
  });
};

// data validation check
export const getLastValidDate = async () => {
  const options = {
    method: 'GET',
    url: `/general/latest-valid-date`,
  };

  return await axiosInstance(options);
};

export const changePassword = async (password, newPassword, newPasswordConfirm) => {
  const options = {
    method: 'POST',
    url: `/user/change-password`,
    data: {
      password: password,
      new_password: newPassword,
      new_password_confirm: newPasswordConfirm,
    },
  };

  return await axiosInstance(options);
};
