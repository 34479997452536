import ThirdTap from 'ui/taps/thirdTap/ThirdTap';
import { Container, Wrapper } from './ThirdMenuStyles';

const ThirdMenu = ({ menuList = [], activeIndex = 0, onClick, sx }) => {
  const handleClick = (index) => {
    onClick(index);
  };

  return (
    <Container style={{ ...sx }}>
      <Wrapper>
        {menuList.map((menu, i) => (
          <ThirdTap isActive={i === activeIndex} key={i} onClick={() => handleClick(i)}>
            {menu}
          </ThirdTap>
        ))}
      </Wrapper>
    </Container>
  );
};

export default ThirdMenu;
