import styled from 'styled-components';

export const Container = styled.div`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  padding: 16px;
  flex-direction: column;
  gap: 16px;
`;
export const TitleWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const CardsWrapper = styled.div`
  display: flex;
  gap: 16px;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const CardWrapper = styled.div`
  width: fit-content;
`;
