import { CheckBoxContainer, CheckBox, CheckBox_unfilled, HiddenCheckBox } from './CheckBoxStyles';
import Check from 'assets/check.svg';
import Check_green from 'assets/check_green.svg';
import Check_gray from 'assets/check_gray.svg';

const Checkbox = ({ isChecked, isDisabled, fill = true, onClick, sx }) => {
  const handleChange = (e) => {
    if (isDisabled) return;
    onClick(e);
  };

  return (
    <CheckBoxContainer style={{ ...sx }}>
      {/* <HiddenCheckBox 
        type='checkbox' 
        checked={isChecked} 
        onChange={handleChange}
      /> */}
      {fill ? (
        <CheckBox
          checked={isChecked}
          disabled={isDisabled}
          icon={isDisabled ? Check_gray : Check}
          onClick={handleChange}
        />
      ) : (
        <CheckBox_unfilled
          checked={isChecked}
          disabled={isDisabled}
          icon={isDisabled ? Check_gray : Check_green}
        />
      )}
    </CheckBoxContainer>
  );
};

export default Checkbox;
