import MainCard from 'ui/card/mainCard';
import Button from 'ui/buttons/button/Button';
import {
  MainCardStyles,
  ButtonContainer,
  ContentsWrapper,
  CloseButtonWrapper,
} from './Modal.styles';
import cancel from 'assets/cancel.svg';
import { useTranslation } from 'react-i18next';
import ModalContainer from '../modalContainer/ModalContainer';

const ModalComponent = ({
  open,
  onClose,
  children,
  onSubmit,
  submitTitle = 'Submit',
  width = 400,
}) => {
  const [t] = useTranslation('etc');
  return (
    <ModalContainer visible={open} onClose={onClose}>
      <MainCard
        sx={{
          ...MainCardStyles,
          width: width,
          maxHeight: '80%',
        }}
      >
        <ContentsWrapper>{children}</ContentsWrapper>

        <ButtonContainer>
          {onSubmit && (
            <Button
              variant="underline"
              onClick={onSubmit}
              sx={{ marginLeft: '12px', padding: '12px' }}
            >
              {t(submitTitle)}
            </Button>
          )}
        </ButtonContainer>

        <CloseButtonWrapper onClick={onClose}>
          <img src={cancel} width={40} />
        </CloseButtonWrapper>
      </MainCard>
    </ModalContainer>
  );
};

export default ModalComponent;
