import BenchmarkLineChart from './benchmarkLineChart/BenchmarkLineChart';
import { useState } from 'react';
import useBenchmark from 'components/dashboardPage/hooks/useBenchmark';
import { BENCHMARKCOLORS, GRAPH2 } from 'styles/colorTheme';
import ListItem from 'ui/listItem/default/ListItem';
import { useSelector } from 'react-redux';
import * as amplitude from '@amplitude/analytics-browser';

const Benchmark = ({ followingList }) => {
  const colors = [...BENCHMARKCOLORS, ...GRAPH2];
  const [benchmarkDataMenu, setbenchmarkDataMenu] = useState('nfr_raw_pulse');
  const [benchmarkDataTime, setbenchmarkDataTime] = useState('1y');
  const [benchmarkData, isBenchmarkLoading, setIsLoading] = useBenchmark(
    followingList,
    benchmarkDataMenu,
    benchmarkDataTime,
    colors,
  );
  const language = useSelector((state) => state.user.language);

  const handleTimeWindow = (i) => {
    setbenchmarkDataTime(i);
    let value;
    if (benchmarkDataMenu === 'nfr_raw_pulse') value = 'ESG risk';
    if (benchmarkDataMenu === 'nfr_score_by_dimension') value = 'ESG group risk';
    if (benchmarkDataMenu === 'risk_score_by_material_codes') value = 'SASB ESG factor';
    if (benchmarkDataMenu === 'risk_score_by_material_data_codes') value = 'Data-Driven ESG factor';
    amplitude.track('dashboard_benchmark_view', { category: value, filter: i });
  };

  const handleMenu = (i) => {
    setbenchmarkDataMenu(i);
    let value;
    if (i === 'nfr_raw_pulse') value = 'ESG risk';
    if (i === 'nfr_score_by_dimension') value = 'ESG group risk';
    if (i === 'risk_score_by_material_codes') value = 'SASB ESG factor';
    if (i === 'risk_score_by_material_data_codes') value = 'Data-Driven ESG factor';
    amplitude.track('dashboard_benchmark_view', { category: value, filter: benchmarkDataTime });
  };

  return (
    <>
      <BenchmarkLineChart
        riskData={benchmarkData.benchmarkData_raw}
        groupRiskData={benchmarkData.benchmarkData_dimension}
        esgFactorData={benchmarkData.benchmarkData_materialCodes}
        dataDrivenData={benchmarkData.benchmarkData_materialData}
        timeWindow={benchmarkDataTime}
        esgMenu={benchmarkDataMenu}
        setTimeWindow={(i) => handleTimeWindow(i)}
        setEsgMenu={(i) => handleMenu(i)}
        isLoading={isBenchmarkLoading}
        setIsLoading={(bool) => setIsLoading(bool)}
      />
      <div>
        {followingList.map((company, i) => (
          <ListItem
            key={i}
            tagColor={colors[i]}
            title={company[`comp_name_${language}`]}
            // score={}
            // scoreChange={}
            // onClick={() => setSummaryChartCompanyIdx(i)}
            // handleCancel={() => cancelFollowing(company.corp_code)}
            displayCancel={false}
          />
        ))}
      </div>
    </>
  );
};

export default Benchmark;
