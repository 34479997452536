import { Container } from './AddButtonStyles';
import plus from 'assets/plus_green.svg';
import check from 'assets/check_green.svg';

const AddButton = ({ isAdded = false, children, onClick, sx }) => {
  return (
    <Container isAdded={isAdded} style={{ ...sx }} onClick={onClick}>
      {isAdded ? <img src={check} alt="plus" /> : <img src={plus} alt="plus" />}
      {children}
    </Container>
  );
};

export default AddButton;
