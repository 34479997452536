import { media } from 'media';
import styled from 'styled-components';

export const Container = styled.div`
  width: 1204px;
  display: flex;
  flex-direction: column;
  gap: 60px;

  ${media.mobile`
    width: 100%;
    gap: 24px;
  `}
`;
