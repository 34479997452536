import { media } from 'media';
import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div`
  display: flex;
  width: 358px;
  padding: 8px 0px;
  justify-content: space-between;
  align-items: center;
  color: ${COLORS.Text400};

  :hover {
    color: ${COLORS.Primary500};
    & .path {
      stroke: ${COLORS.Primary500};
    }
  }

  ${media.mobile`
    width: 100%;
  `}
`;

export const Title = styled.div`
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Icon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
