import { makeUserRefresh } from 'api/user';
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    config.headers.Authorization = `Token ${localStorage.getItem('access_token')}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    //forbidden
    if (error.response.status === 401) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('state');
      window.location.href = '/signin/forbidden';
    }

    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;

      const refreshToken = await makeUserRefresh();
      if (refreshToken) {
        localStorage.setItem('access_token', refreshToken.data.access_token);
        originalRequest.headers.Authorization = `Token ${refreshToken.data.access_token}`;
        return axios(originalRequest);
      } else {
        console.error('Refresh token request failed');
        localStorage.removeItem('access_token');
        localStorage.removeItem('state');
        window.location.href = '/signin';
      }
    }
    return undefined;
  },
);

export default axiosInstance;
