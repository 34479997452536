import SearchBar from 'ui/searchBar/primarySearchBar/SearchBar';
import {
  Container,
  SearchBarWrapper,
  HeaderMenuWrapper,
  MobileContainer,
  InputWrapper,
  InputContainer,
  Input,
  HambergerBtn,
} from './HeaderStyles';
import HeaderMenu from './headerMenu/HeaderMenu';
import { useState, useCallback } from 'react';
import { getHotSearch } from 'api/search';
import { Drawer, Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as amplitude from '@amplitude/analytics-browser';
import search from 'assets/search.svg';
import hamberger from 'assets/hamberger.svg';
import Menu from '../leftPannel/components/menu/Menu';
import MobileMenu from '../leftPannel/components/menu/MobileMenu';

const Header = () => {
  const [t] = useTranslation('mainframe');

  const [inputText, setInputText] = useState('');
  const [candidateList, setCandidateList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [alarm, setAlarm] = useState(false);

  const handleChange = async (event) => {
    const query = event.target.value;
    setInputText(query);
    if (query.length === 0) {
      clearStates();
      return;
    }
    setCandidateCompanies(query);
  };

  const debounce = (callback, delay) => {
    let timerId = null;
    return (...args) => {
      if (timerId) clearTimeout(timerId);
      timerId = setTimeout(() => {
        callback(...args);
      }, delay);
    };
  };

  const setCandidateCompanies = useCallback(
    debounce((query) => getCandidateCompanies(query), 300),
    [],
  );

  const getCandidateCompanies = async (q) => {
    const res = await getHotSearch(q);
    if (!isOpen) setIsOpen(true);
    setCandidateList(res.data);
  };

  const clearStates = () => {
    setInputText('');
    setCandidateList([]);
    setIsOpen(false);
  };

  const handleNavigate = (id) => {
    window.location.href = `/company/${id}`;
    clearStates();
  };

  const handleEnter = async (event) => {
    const inputValue = event.target.value;

    if (!inputValue || inputValue.length === 0) return;

    const res = await getHotSearch(inputValue);

    if (res.data && res.data.length > 0) {
      amplitude.track('search_complete', { company_code: res.data[0].corp_code });
      window.location.href = `/company/${res.data[0].corp_code}`;
    } else {
      setAlarm(true);
    }
    clearStates();
  };

  const onKeyDown = (e) => {
    if (e.code === 'Enter') {
      return handleEnter(e);
    }

    if (e.code === 'Escape') {
      e.target.value = '';
      return onChange(e);
    }
  };

  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <>
      {/* Desktop */}
      <Container>
        <SearchBarWrapper>
          <SearchBar
            value={inputText}
            onChange={handleChange}
            onEnter={handleEnter}
            handleNavigate={handleNavigate}
            searchList={candidateList}
          />
        </SearchBarWrapper>
        <HeaderMenuWrapper>
          <HeaderMenu />
          <HambergerBtn>
            <img
              src={hamberger}
              alt="hamberger"
              width={30}
              onClick={() => setOpenDrawer(!openDrawer)}
            />
          </HambergerBtn>
        </HeaderMenuWrapper>
      </Container>

      {/* Mobile */}
      <MobileContainer>
        <InputContainer>
          <InputWrapper className="input-wrapper">
            <img src={search} width={24} />
            <Input value={inputText} onChange={handleChange} onKeyDown={onKeyDown} />
          </InputWrapper>
        </InputContainer>
        <img
          src={hamberger}
          alt="hamberger"
          width={30}
          onClick={() => setOpenDrawer(!openDrawer)}
        />
      </MobileContainer>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={1000}
        open={alarm}
        onClose={() => setAlarm(false)}
        message={t('Could not find the company')}
      />
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)} anchor="right">
        <MobileMenu />
      </Drawer>
    </>
  );
};

export default Header;
