import { Stack, Typography, Radio, Select, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { esgCodes } from 'data/esgCode';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSingleIndexQuery } from 'components/analyticsPage/tabPanels/components/drawers/indexDrawer/components/components/singleIndex/hooks/useSingleIndexQuery';
import { useSelector } from 'react-redux';
import { useIndex } from 'components/analyticsPage/tabPanels/components/drawers/indexDrawer/components/components/singleIndex/hooks/useIndex';
import { useCompositeScore } from 'components/analyticsPage/tabPanels/components/drawers/indexDrawer/components/components/singleIndex/hooks/useCompositeScore';
import { useIndividualScore } from 'components/analyticsPage/tabPanels/components/drawers/indexDrawer/components/components/singleIndex/hooks/useIndividualScore';
import { useTranslation } from 'react-i18next';

const SingleIndex = ({ open }) => {
  const query = useSelector((state) => state.analytics.indexQuery.index.singleIndex);
  const [setQuery] = useSingleIndexQuery();
  const [getIndex, setIndex] = useIndex();
  const [compositeScore, setCompositeScore] = useCompositeScore();
  const [individualScore, setIndividualScore] = useIndividualScore();
  const [t] = useTranslation('analytics');

  return (
    <Stack spacing={3} sx={{ display: open }}>
      <Stack spacing={2}>
        <Stack width="100%" spacing={1}>
          <Typography sx={{ fontWeight: '500' }}>{t('Time window')}</Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="From"
              displayStaticWrapperAs="desktop"
              value={query.from}
              onChange={(e) => setQuery({ from: e })}
              disableFuture={true}
              renderInput={(params) => <TextField size="small" {...params} />}
            />
            <DatePicker
              label="To"
              displayStaticWrapperAs="desktop"
              value={query.to}
              onChange={(e) => setQuery({ to: e })}
              minDate={query.from}
              disableFuture={true}
              renderInput={(params) => <TextField size="small" {...params} />}
            />
          </LocalizationProvider>
        </Stack>
        <Stack spacing={2}>
          <Typography sx={{ fontWeight: '500' }}>{t('Data type')}</Typography>
          <Stack spacing={2}>
            <Typography>{t('Materiality')}</Typography>
            <FormControl size="small" sx={{ width: '100%', ml: 1.5 }}>
              <InputLabel>Select a Materiality</InputLabel>
              <Select
                label="Select a ESG Category"
                value={compositeScore}
                onChange={(e) => setCompositeScore(e)}
                multiple
              >
                <MenuItem value={0}>Data-driven Materiality</MenuItem>
                <MenuItem value={1}>SASB Materiality</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Stack spacing={2} sx={{ width: '100%', maxWidth: '480px' }}>
            <Typography>{t('ESG Category')}</Typography>
            <FormControl size="small" sx={{ width: '100%', ml: 1.5 }}>
              <InputLabel>Select a ESG Category</InputLabel>
              <Select
                label="Select a ESG Category"
                value={individualScore}
                MenuProps={{ sx: { height: '300px' } }}
                onChange={(e) => setIndividualScore(e)}
                multiple
              >
                {esgCodes.map((el, i) => (
                  <MenuItem value={Number(`${el.general_issue_code}`)} key={i}>
                    {el.general_issue_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
      </Stack>
      <Stack>
        <Typography sx={{ fontWeight: '500' }}>{t('Index')}</Typography>
        <FormControl>
          <RadioGroup value={getIndex(query.filters)} onChange={(e) => setIndex(e)}>
            <FormControlLabel value={0} control={<Radio />} label="Pulse" />
            <FormControlLabel value={1} control={<Radio />} label="Score" />
            <FormControlLabel value={2} control={<Radio />} label="Momentum" />
            <FormControlLabel value={3} control={<Radio />} label="ESG Exposure" />
          </RadioGroup>
        </FormControl>
      </Stack>
    </Stack>
  );
};

export default SingleIndex;
