import CardTitle from 'ui/title/Title';
import { Container, Wrapper, NewsCardBundleWrapper } from './EsgTopicNewsStyles';
import { useSelector } from 'react-redux';
import VerticalNewsFrame from 'ui/card/newsCards/verticalFrame/VerticalNewsFrame';
import { useTranslation } from 'react-i18next';
import Nodata from 'ui/errorSigns/noData/Nodata';

const EsgTopicNews = ({ topics = [], navigateToESG, sx }) => {
  const language = useSelector((state) => state.user.language);
  const [t] = useTranslation('news');
  const [dT] = useTranslation('noData');

  return (
    <Container style={{ ...sx }}>
      <CardTitle title={t('ESG Topics')} more={t('More')} onClick={navigateToESG} />
      {topics.length > 0 ? (
        <Wrapper>
          {topics.map((topic, i) => (
            <NewsCardBundleWrapper key={i}>
              <VerticalNewsFrame
                title={topic[`general_issue_name_${language}`]}
                articles={topic.articles}
                onClick={navigateToESG}
              />
            </NewsCardBundleWrapper>
          ))}
        </Wrapper>
      ) : (
        <div>
          <Nodata desc={dT('No ESG news.')} sx={{ width: '100%' }} />
        </div>
      )}
    </Container>
  );
};

export default EsgTopicNews;
