import { media } from 'media';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;

  ${media.mobile`
    gap: 0px;
  `}
`;

export const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 560px;

  ${media.mobile`
    width: 100%;
    display: block;
  `}
`;

export const CardWrapper = styled.div``;
