import { useEffect, useState } from 'react';
import { getCompanyESGEvents } from 'api/company';

const useEvents = (id, filter) => {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getData();
  }, [filter]);

  const getData = async () => {
    setIsLoading(true);
    const esgEvents = await getCompanyESGEvents(id, filter);
    if (esgEvents) {
      const sortedEvents = sortEventsbyTimeOrder(esgEvents?.data);
      setEvents(sortedEvents);
    } else {
      setEvents([]);
    }
    setIsLoading(false);
  };

  const sortEventsbyTimeOrder = (events) => events?.sort((a, b) => b.x - a.x);

  return [events, isLoading];
};

export default useEvents;
