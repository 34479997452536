import * as amplitude from '@amplitude/analytics-browser';
import { useEffect } from 'react';

const useAmplitude = (page, properties) => {
  useEffect(() => {
    amplitude.track(`${page}_enter`, properties);
  }, []);
};

export default useAmplitude;
