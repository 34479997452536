import { Btn, TextWrapper } from './ButtonStyles';
import { COLORS } from 'styles/colorTheme';

const Button = ({
  color = COLORS.Primary500,
  className,
  children,
  disabled,
  fontColor = '#ffffff',
  variant = 'Primary',
  maxWidth,
  onKeyDown,
  onClick,
  py = '12px',
  px = '12px',
  radius = '4px',
  type,
  sx = {},
}) => {
  if (variant === 'underline') {
    color = '#ffffff';
    fontColor = COLORS.Primary500;
    py = '4px';
    px = '8px';
  }
  return (
    <Btn
      color={color}
      className={className}
      fontColor={fontColor}
      py={py}
      px={px}
      radius={radius}
      style={{ ...sx }}
      onClick={onClick}
      onKeyDown={onKeyDown}
      disabled={disabled}
      type={type}
    >
      <TextWrapper maxWidth={maxWidth}>{children}</TextWrapper>
    </Btn>
  );
};

export default Button;
