import { useState } from 'react';

export const useRemoveModal = () => {
  const [modalState, setModalState] = useState({
    open: false,
    idx: null,
  })

  const handleModal = (idx = null) => {
    setModalState({
      open: !modalState.open,
      idx: idx,
    })
  }

  return [modalState, handleModal];
}