import React from 'react';

import Typo from 'ui/typo/Typo';
import Input from 'ui/inputs/Input';
import { Container, InputContainer, BtnWrapper, ErrorMessage } from './SettingsItem.styles';
import Button from 'ui/buttons/button/Button';
import { COLORS } from 'styles/colorTheme';

function SettingsItem({ errors, title, touched, onChange, onClick, name, value, ...otherProps }) {
  return (
    <Container>
      <div>
        <Typo variant="b1" color={COLORS.Text400}>
          {title}
        </Typo>
      </div>
      <InputContainer>
        <Input onChange={onChange} name={name} value={value} {...otherProps} />
        {errors && touched && (
          <ErrorMessage>
            <Typo variant="caption2">{errors}</Typo>
          </ErrorMessage>
        )}
        {onClick && (
          <BtnWrapper>
            <Button variant="underline" type="submit" onClick={onClick}>
              <Typo variant="btn1">Save</Typo>
            </Button>
          </BtnWrapper>
        )}
      </InputContainer>
    </Container>
  );
}

export default SettingsItem;
