import { COLORS } from 'styles/colorTheme';
import catmullRom2bezier from 'utils/catmullRom2bezier';
import { useState, useMemo, memo } from 'react';

const LightLineChart = ({
  data = [],
  width = 77,
  height = 21,
  lineWidth = 1,
  padding = 5,
  curve = true,
  fillColor = true,
  color = COLORS.Red300,
}) => {
  const [pathData, setPathData] = useState('');
  const [colorPathData, setColorPathData] = useState('');

  useMemo(() => {
    if (data.length === 0) return;
    const minValue = Math.min(...data) - lineWidth - padding;
    const maxValue = Math.max(...data) + lineWidth + padding;
    const xStep = width / (data.length - 1);
    const yStep = height / (maxValue - minValue);
    if (curve) {
      const points = catmullRom2bezier(data, minValue, xStep, yStep, height);

      function makePath(points) {
        var result = 'M' + points[0][0].x + ',' + points[0][0].y + ' ';
        for (var i = 0; i < points.length; i++) {
          result +=
            'C' +
            points[i][0].x +
            ',' +
            points[i][0].y +
            ' ' +
            points[i][1].x +
            ',' +
            points[i][1].y +
            ' ' +
            points[i][2].x +
            ',' +
            points[i][2].y +
            ' ';
        }
        return result;
      }

      function makeColorPath(points) {
        var result = 'M' + points[0][0].x + ',' + points[0][0].y + ' ';
        for (var i = 0; i < points.length; i++) {
          result +=
            'C' +
            points[i][0].x +
            ',' +
            points[i][0].y +
            ' ' +
            points[i][1].x +
            ',' +
            points[i][1].y +
            ' ' +
            points[i][2].x +
            ',' +
            points[i][2].y +
            ' ';
        }
        result += 'L' + points[points.length - 1][2].x + ',' + height + ' ';
        result += 'L' + 0 + ',' + height + 'z';
        return result;
      }

      setPathData(makePath(points));
      setColorPathData(makeColorPath(points));
    } else {
      const points = data.map((value, index) => ({
        x: index * xStep,
        y: height - (value - minValue) * yStep,
      }));
      const pathData_ = `M${points.map((p) => `${p.x},${p.y}`).join(' L')}`;
      setPathData(pathData_);
      const colorPathData_ =
        `M${points.map((p) => `${p.x},${p.y}`).join(' L')}` +
        'L' +
        points[points.length - 1].x +
        ',' +
        height +
        ' ' +
        'L' +
        0 +
        ',' +
        height +
        'z';
      setColorPathData(colorPathData_);
    }
  }, [data, width, height, lineWidth, padding, curve]);

  return (
    <svg width={width} height={height} id="chart">
      <defs>
        <linearGradient id="gradient" gradientTransform="rotate(90)">
          <stop offset="5%" stopColor={color} />
          <stop offset="55%" stopColor="white" />
        </linearGradient>
      </defs>
      {fillColor ? (
        <path
          d={colorPathData}
          stroke="none"
          strokeLinejoin="round"
          strokeWidth={lineWidth}
          fill="url('#gradient')"
        />
      ) : null}
      <path
        id="line"
        d={pathData}
        stroke={color}
        strokeLinejoin="round"
        strokeWidth={lineWidth}
        fill="none"
      />
    </svg>
  );
};

export default memo(LightLineChart);
