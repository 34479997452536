import moment from 'moment';
import axiosInstance from 'api/apiInterceptor';
import axios from 'axios';

export const login = async (auth) => {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_API}/user/login`,
      {
        ...auth,
      },
      {
        withCredentials: true,
      },
    );
    return result;
  } catch (e) {
    if (e.response.status === 403) {
      window.alert('Please verify your email account.');
      return { status: 403 };
    }
    console.log('err ', e);
  }
};

export const signup = async (data) => {
  try {
    const result = await axios.post(`${process.env.REACT_APP_API}/user/create`, {
      ...data,
      expire_at: moment(new Date(Date.parse(new Date()) + 30 * 1000 * 60 * 60 * 24)).format(
        'YYYY-MM-DDTHH:mm:ss',
      ),
    });

    return result;
  } catch (e) {
    console.log('err ', e);
  }
};

export const checkEmail = async (email) => {
  return await axios.get(
    `${process.env.REACT_APP_API}/user/check-email-unique?email=${encodeURI(email)}`,
  );
};

export const resendVerifyEmail = async (email) => {
  return await axios.post(`${process.env.REACT_APP_API}/user/resend-email-verification`, {
    email,
  });
};

export const emailVerify = async (email, code) => {
  try {
    const result = await axios.post(`${process.env.REACT_APP_API}/user/activate?email=${email}`, {
      code,
    });

    return result;
  } catch (e) {
    console.log('err ', e);
  }
};

export const resetPassword = async (email) => {
  try {
    const result = await axios.post(`${process.env.REACT_APP_API}/user/reset-password`, {
      email,
    });

    return result;
  } catch (e) {
    console.log('err ', e.response.data.error);
  }
};

export const changePassword = async (password) => {
  try {
    const result = await axiosInstance.post(`/user/change-password/`, { ...password });
    return result;
  } catch (e) {
    console.log('err ', e.response.data.error);
  }
};
