export const Colors = [
  '#115f9a',
  '#22a7f0',
  '#48b5c4',
  '#76c68f',
  '#a6d75b',
  '#d0ee11',
  '#48b5c4',
  '#218c07',
  '#F5F0BB',
  '#F0EBE3',
  '#E4DCCF',
  '#7D9D9C',
  '#576F72',
  '#C2DED1',
  '#ECE5C7',
  '#CDC2AE',
  '#354259',
  
  '#FAD9A1',
  '#F37878',
  '#6E85B7',
  '#B2C8DF',
  '#F8F9D7',
  '#C4D7E0',
  '#F5F0BB',
  '#C4DFAA',
  '#90C8AC',
  '#73A9AD',
  '#F0EBE3',
  '#E4DCCF',
  '#7D9D9C',
  '#576F72',
  '#F2D7D9',
  '#D3CEDF',
  '#9CB4CC',
  '#748DA6',
];
