import Signin from 'components/authenticationPage/authentication/Signin';
import logo from 'assets/logo.svg';
import { useTranslation } from 'react-i18next';
import Typo from 'ui/typo/Typo';
import { COLORS } from 'styles/colorTheme';
import languageImg from 'assets/language.svg';
import useLanguage from 'hooks/useLanguage';
import useAmplitude from 'hooks/useAmplitude';

const SigninForm = () => {
  const [t] = useTranslation('signIn');
  const { language, setLanguage } = useLanguage();

  const handleClick = () => setLanguage(language === 'ko' ? 'en' : 'kr');
  useAmplitude('signin');

  return (
    <div style={{ height: '90vh' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          padding: '30px 0',
        }}
      >
        <img
          style={{
            position: 'relative',
            left: '50%',
            transform: 'translateX(-50%)',
            cursor: 'pointer',
          }}
          src={logo}
          alt="logo"
          width="35px"
          onClick={() => (window.location = 'https://fairlabs.io')}
        />
        <div
          style={{
            position: 'relative',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginRight: '10%',
            gap: '8px',
            cursor: 'pointer',
          }}
          onClick={handleClick}
        >
          <img src={languageImg} alt="language" />
          <Typo variant="btn2" className="regular">
            {language === 'ko' ? '한국어' : 'English'}
          </Typo>
        </div>
      </div>
      <div
        style={{
          height: '100%',
          display: 'flex',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '48px',
            maxWidth: '450px',
            width: '100%',
            padding: '50px 0',
            margin: 'auto',
          }}
        >
          <Typo variant="d1" color={COLORS.Text500}>
            {t('Welcome')}
          </Typo>
          <div
            style={{
              width: '70%',
              fontSize: '14px',
              fontWeight: '400',
            }}
          >
            <Form />
          </div>
        </div>
      </div>
    </div>
  );
};

const Form = (props) => (
  <div>
    <Signin />
  </div>
);

export default SigninForm;
