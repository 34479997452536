import PrimaryTap from 'ui/taps/primary/regular/PrimaryTap';
import { Container } from './PrimaryMenuStyles';
import { forwardRef } from 'react';

const PrimaryMenu = forwardRef(
  ({ menuList = [], activeIndex = 0, margin, onClick, mode = 'slider', itemMaxWidth, sx }, ref) => {
    const handleClick = (index) => onClick(index);

    return (
      <Container mode={mode} style={{ ...sx }} ref={ref}>
        {menuList.map((menu, i) => (
          <PrimaryTap
            isActive={i === activeIndex}
            maxWidth={itemMaxWidth}
            key={i}
            margin={margin}
            onClick={() => handleClick(i)}
          >
            {menu}
          </PrimaryTap>
        ))}
      </Container>
    );
  },
);

export default PrimaryMenu;
