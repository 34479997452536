import _ from 'lodash';
import axiosInstance from 'api/apiInterceptor';

export const getCompanyList = async (keyword, filters) => {
  try {
    let params = {};
    if (filters || !_.isEmpty(filters)) {
      Object.keys(filters).map((key) => {
        if (filters[key].length > 0) {
          params[key] = filters[key] + '';
        }
      });
    }

    if (keyword && keyword.trim()) {
      params['keyword'] = keyword;
    }

    const config =
      !keyword && !filters && _.isEmpty(filters)
        ? {
            headers: {
              Authorization: `Token ${localStorage.getItem('access_token')}`,
            },
          }
        : {
            headers: {
              Authorization: `Token ${localStorage.getItem('access_token')}`,
            },
            params,
          };

    return await axiosInstance.get(`/company`, config);
  } catch (e) {}
};

export const getCompanySizeFilter = async () => {
  return await axiosInstance.get(`/filters/size`);
};

export const getCompanyIndustryClassFilter = async () => {
  return await axiosInstance.get(`/filters/industry-class`);
};

export const getCompanyEnvironmentFilter = async () => {
  return await axiosInstance.get(`/filters/environment`);
};

export const getCompanySocialFilter = async () => {
  return await axiosInstance.get(`/filters/social`);
};

export const getCompanyGoveranceFilter = async () => {
  return await axiosInstance.get(`/filters/governance`);
};

export const getCompanyTechnologyFilter = async () => {
  return await axiosInstance.get(`/filters/tech`);
};

export const getHotSearch = async (keyword) => {
  const params = {
    keyword: keyword,
  };
  return await axiosInstance.get(`/general/hot-search`, {
    params,
  });
};

export const getSearchHistory = async (limit = 10) => {
  const params = {
    limit: limit,
  };
  return await axiosInstance.get(`/user/recently-viewed`, {
    params,
  });
};
