import { Modal, Fade, Box, Stack } from '@mui/material';
import MainCard from 'ui/card/mainCard';
import CloseIcon from '@mui/icons-material/Close';

const AnalyticsModal = ({ open, handleClose, children }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    maxHeight: '80vh',
    bgcolor: 'background.paper',
    overflowY: 'auto',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '@media(max-width: 768px)': { width: '80%' },
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box>
          <MainCard sx={style}>
            <Stack direction="row" justifyContent="flex-end">
              <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
            </Stack>
            <div>{children}</div>
          </MainCard>
        </Box>
      </Fade>
    </Modal>
  );
};

export default AnalyticsModal;
