import { media } from 'media';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 280px;
  flex-direction: column;
  gap: 20px;

  ${media.mobile`
    margin-bottom: 24px;
  `}
`;

export const TitleWrapper = styled.div`
  padding: 0px 6px;

  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${media.mobile`
    gap: 1px;
  `}
`;
