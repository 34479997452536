import {
  SAVE_ANALYTICS_TAB_LIST,
  SAVE_SELECTED_ANALYTICS_TAB,
  SAVE_DATA_QUERY,
  SAVE_INDEX_QUERY,
  SVAE_EVENT_QUERY,
  RESET_DATA_QUERY,
  RESET_INDEX_QUERY,
  RESET_EVENT_QUERY,
  RESET_DATA_QUERY_FILTERS,
  RESET_INDEX_QUERY_FILTERS,
  RESET_EVENT_QUERY_FILTERS,
  SAVE_INPUT_INFO,
} from './ActionTypes';
import moment from 'moment';
import {
  esgDataColumns,
  multiIndexColumns,
  singleIndexColumns,
  stockDataColums,
} from 'data/columns';

const dailyData = {
  from: moment().subtract(1, 'days').format(),
  to: moment().subtract(1, 'days').format(),
  filters: esgDataColumns,
  compositeScore: {
    materiality: 0,
  },
  individualScore: {
    esgCode: '',
  },
};

const timeSeries = {
  from: moment().subtract(1, 'years').format(),
  to: moment().subtract(1, 'days').format(),
  filters: esgDataColumns,
  dataType: 0,
  compositeScore: {
    materiality: 0,
  },
  individualScore: {
    esgCode: '',
  },
};

const dataQueryInit = {
  tabName: '',
  prevTabName: '',
  companyId: null,
  companyName_ko: null,
  companyName_en: null,
  target: 'dailyData',
  esgData: {
    dailyData: dailyData,
    timeSeries: timeSeries,
  },
  stockData: {
    from: moment().subtract(1, 'years').format(),
    to: moment().subtract(1, 'days').format(),
    filters: stockDataColums,
  },
};

const multiIndex = {
  from: moment().subtract(1, 'years').format(),
  to: moment().subtract(1, 'days').format(),
  filters: multiIndexColumns,
  dataType: 0,
  compositeScore: {
    materiality: 0,
  },
  individualScore: {
    esgCode: '',
  },
};

const singleIndex = {
  from: moment().subtract(1, 'years').format(),
  to: moment().subtract(1, 'days').format(),
  filters: singleIndexColumns,
  dataType: '0',
  compositeScore: {
    materiality: '0',
  },
  individualScore: {
    esgCode: '',
  },
};

const indexQueryInit = {
  tabName: '',
  prevTabName: '',
  companyId: null,
  companyName_ko: null,
  companyName_en: null,
  target: 'multiIndex',
  index: {
    multiIndex: multiIndex,
    singleIndex: singleIndex,
  },
};

const eventQueryInit = {};

const initialState = {
  tabList: [],
  selectedTab: 0,
  dataQuery: dataQueryInit,
  indexQuery: indexQueryInit,
  eventQuery: eventQueryInit,
};

export const analytics = (state = initialState, action = {}) => {
  switch (action.type) {
    case SAVE_ANALYTICS_TAB_LIST:
      return {
        ...state,
        tabList: action.payload,
      };
    case SAVE_SELECTED_ANALYTICS_TAB:
      return {
        ...state,
        selectedTab: action.payload,
      };
    case SAVE_DATA_QUERY:
      return {
        ...state,
        dataQuery: action.payload,
      };
    case SAVE_INDEX_QUERY:
      return {
        ...state,
        indexQuery: action.payload,
      };
    case SVAE_EVENT_QUERY:
      return {
        ...state,
        eventQuery: action.payload,
      };
    case RESET_DATA_QUERY:
      return {
        ...state,
        dataQuery: initialState.dataQuery,
      };
    case RESET_DATA_QUERY_FILTERS:
      return {
        ...state,
        dataQuery: {
          ...state.dataQuery,
          prevTabName: initialState.dataQuery.prevTabName,
          target: initialState.dataQuery.target,
          esgData: initialState.dataQuery.esgData,
          stockData: initialState.dataQuery.stockData,
        },
      };
    case RESET_INDEX_QUERY:
      return {
        ...state,
        indexQuery: initialState.indexQuery,
      };
    case RESET_INDEX_QUERY_FILTERS:
      return {
        ...state,
        indexQuery: {
          ...state.indexQuery,
          prevTabName: initialState.indexQuery.prevTabName,
          target: initialState.indexQuery.target,
          index: initialState.indexQuery.index,
        },
      };
    case RESET_EVENT_QUERY:
      return {
        ...state,
        eventQuery: initialState.eventQuery,
      };
    case RESET_EVENT_QUERY_FILTERS:
      return {
        ...state,
        eventQuery: {
          ...initialState.eventQuery,
        },
      };
    case SAVE_INPUT_INFO:
      return {
        ...state,
        dataQuery: {
          ...initialState.dataQuery,
          ...action.payload,
        },
        indexQuery: {
          ...initialState.indexQuery,
          ...action.payload,
        },
        eventQuery: {
          ...initialState.eventQuery,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default analytics;
