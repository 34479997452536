import { useState } from 'react';
import AnalyticsModal from 'components/analyticsPage/analyticsModal';
import { Stack } from '@mui/material';
import icon02 from 'assets/icon_02.svg';
import icon03 from 'assets/icon_03.svg';
import icon04 from 'assets/icon_04.svg';
import Step from 'components/analyticsPage/tabPanels/components/initiateCTA/components/tutorialModal/components/Step';

const TutorialModal = ({ open, closeModal }) => {
  const [tutorialStep, setTutorialStep] = useState(0);
  const totalSteps = 3;

  const next = () => {
    if (tutorialStep === totalSteps) return;
    setTutorialStep(tutorialStep + 1);
  };

  const prev = () => {
    if (tutorialStep === 0) return;
    setTutorialStep(tutorialStep - 1);
  };

  return (
    <AnalyticsModal open={open} handleClose={closeModal}>
      <Stack spacing={2} sx={{ mb: 2, display: tutorialStep === 0 ? '' : 'none' }}>
        <Step
          tutorialStep={tutorialStep}
          totalSteps={totalSteps}
          prev={prev}
          next={next}
          handleClose={closeModal}
          setTutorialStep={setTutorialStep}
          title="Welcome to ESG Analytics"
          subTitle="A new experience designed to help you build customizable ESG analytics"
          img={icon02}
        />
      </Stack>
      <Stack spacing={2} sx={{ mb: 2, display: tutorialStep === 1 ? '' : 'none' }}>
        <Step
          tutorialStep={tutorialStep}
          totalSteps={totalSteps}
          prev={prev}
          next={next}
          handleClose={closeModal}
          setTutorialStep={setTutorialStep}
          title="Find the insights by using “advanced search” page"
          subTitle="You can find a specific ESG category and its time-series data"
          img={icon04}
        />
      </Stack>
      <Stack spacing={2} sx={{ mb: 2, display: tutorialStep === 2 ? '' : 'none' }}>
        <Step
          tutorialStep={tutorialStep}
          totalSteps={totalSteps}
          prev={prev}
          next={next}
          handleClose={closeModal}
          setTutorialStep={setTutorialStep}
          title="Compare ESG categories with data on the “Basic Data” page"
          subTitle="You can access to material ESG categories by using the “Basic Data” page"
          img={icon03}
        />
      </Stack>
    </AnalyticsModal>
  );
};

export default TutorialModal;
