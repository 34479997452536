import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div`
  display: inline-flex;
  padding: 12px;
  align-items: center;
  gap: 4px;

  border-radius: 30px;
  border: 1px solid ${COLORS.Background200};
  background: #fff;
  cursor: pointer;

  :hover {
    background: ${COLORS.Background50};
  }
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
`;
