import {
  SAVE_USER_ID,
  SAVE_INFO,
  SAVE_EXPIRE,
  UPDATE_INFO,
  SAVE_EAMIL,
  CHANGE_LANGUAGE,
} from './ActionTypes';

export const saveUserId = (userId) => ({
  type: SAVE_USER_ID,
  payload: userId,
});

export const saveInfo = (user) => ({
  type: SAVE_INFO,
  payload: user,
});

export const saveExpire = (date) => ({
  type: SAVE_EXPIRE,
  payload: date,
});

export const updateInfo = (user) => ({
  type: UPDATE_INFO,
  payload: user,
});

export const saveEmail = (email) => ({
  type: SAVE_EAMIL,
  payload: email,
});

export const changeLanguage = (lng) => ({
  type: CHANGE_LANGUAGE,
  payload: lng,
});
