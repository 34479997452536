import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const TopicContainer = styled.div`
  padding: 4px 7px;
  display: flex;
  align-items: center;
  position: relative;
  color: ${COLORS.Text500};

  :hover {
    color: ${COLORS.Primary500};
  }
`;

export const RankWrapper = styled.div`
  margin-right: 12px;
`;

export const TopicWrapper = styled.div`
  width: 80%;

  display: 'block';
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
