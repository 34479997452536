import { 
  SAVE_ANALYTICS_TAB_LIST,
  SAVE_SELECTED_ANALYTICS_TAB,
  SAVE_DATA_QUERY,
  SAVE_INDEX_QUERY,
  SVAE_EVENT_QUERY,
  RESET_DATA_QUERY,
  RESET_INDEX_QUERY,
  RESET_EVENT_QUERY,
  RESET_DATA_QUERY_FILTERS,
  SAVE_INPUT_INFO,
} from "./ActionTypes";

export const saveAnalyticsTabList = (arr) => ({
  type: SAVE_ANALYTICS_TAB_LIST,
  payload: arr,
});

export const saveSelectedTab = (int) => ({
  type: SAVE_SELECTED_ANALYTICS_TAB,
  payload: int,
})

export const saveDataQuery = (obj) => ({
  type: SAVE_DATA_QUERY,
  payload: obj
})

export const saveIndexQuery = (obj) => ({
  type: SAVE_INDEX_QUERY,
  payload: obj
})

export const saveEventQuery = (obj) => ({
  type: SVAE_EVENT_QUERY,
  payload: obj
})

export const resetDataQuery = () => ({
  type: RESET_DATA_QUERY,
})

export const resetDataQueryFilters = () => ({
  type: RESET_DATA_QUERY_FILTERS,
})

export const resetIndexQuery = () => ({
  type: RESET_INDEX_QUERY,
})

export const resetIndexQueryFilters = () => ({
  type: RESET_DATA_QUERY_FILTERS,
})

export const resetEventQuery = () => ({
  type: RESET_EVENT_QUERY,
})

export const resetEventQueryFilters = () => ({
  type: RESET_DATA_QUERY_FILTERS,
})

export const saveInputInfo = (obj) => ({
  type: SAVE_INPUT_INFO,
  payload: obj
})