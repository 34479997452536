import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import SecondaryChip from 'ui/chips/secondaryChip/SecondaryChip';
import { SASB_TAG_ID_ICON_MAP } from 'data/sasbTagIconMap';
import Typo from 'ui/typo/Typo';
import {
  Container,
  Wrapper,
  CategoryContainer,
  CategoryWrapper,
  ChipContainer,
} from './TimelineFilter.styles';

export default function TimelineFilter({ totalTags = {}, selectedTags = [], onClick }) {
  const [t] = useTranslation('companyDetails');
  const language = useSelector((state) => state.user.language);

  if (!totalTags) return;

  const sum = Object.keys(totalTags).reduce((prev, curr) => {
    return prev + totalTags[curr].length;
  }, 0);

  return (
    <Container>
      <Wrapper>
        <CategoryContainer>
          <CategoryWrapper>
            <Typo variant="h2">
              {t('Events')} ({sum})
            </Typo>
          </CategoryWrapper>
        </CategoryContainer>
        <ChipContainer>
          {Object.keys(totalTags).map((key, i) => (
            <SecondaryChip
              icon={SASB_TAG_ID_ICON_MAP[totalTags[key][0].sasb_tag_id].iconUri}
              selected={selectedTags.includes(totalTags[key][0].sasb_tag_id)}
              key={i}
              onClick={() => onClick(totalTags[key][0].sasb_tag_id)}
              fontSx={{ fontSize: '14px' }}
            >
              {SASB_TAG_ID_ICON_MAP[totalTags[key][0].sasb_tag_id][`name_${language}`]} (
              {totalTags[key].length})
            </SecondaryChip>
          ))}
        </ChipContainer>
      </Wrapper>
    </Container>
  );
}
