import TabPanel from 'ui/tabPanel/TabPanel';
import InitiateCTA from 'components/analyticsPage/tabPanels/components/initiateCTA';
import DataAnalysis from 'components/analyticsPage/tabPanels/components/dataAnalysis';
import IndexAnalysis from 'components/analyticsPage/tabPanels/components/indexAnalysis';
import { useSelector } from 'react-redux';

export const TabPanels = () => {
  const analytics = useSelector((state) => state.analytics);

  return (
    <>
      {analytics.tabList.map((el, i) => (
        <TabPanel value={analytics.selectedTab} index={i} key={`tabPanel_${i}`}>
          {el.tab_type === 'data-analytics' ||
          el.tab_type === 'data-analytics-timeseries' ||
          el.tab_type === 'data-analytics-stock' ? (
            <DataAnalysis />
          ) : null}
          {el.tab_type === 'index-analytics' || el.tab_type === 'index-analytics-multi' ? (
            <IndexAnalysis />
          ) : null}
        </TabPanel>
      ))}
      <TabPanel value={analytics.selectedTab} index={analytics.tabList.length}>
        <InitiateCTA />
      </TabPanel>
    </>
  );
};

export default TabPanels;
