import { media } from 'media';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 40px;
  flex-direction: column;
`;

export const MenuWrapper = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
`;

export const ChipWrapper = styled.div`
  display: flex;
  gap: 16px;
  row-gap: 4px;
  flex-wrap: wrap;
`;

export const NewsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 576px);
  justify-content: space-between;
  row-gap: 24px;

  ${media.mobile`
    display: block;
  `}
`;

export const CardWrapper = styled.div``;
