import { Component } from 'react';
import Button from 'ui/buttons/button/Button';
import Typo from 'ui/typo/Typo';
import styled from 'styled-components';

class Terms extends Component {
  componentDidMount() {
    document.getElementsByTagName('html')[0].classList.add('static');
  }

  componentWillUnmount() {
    document.getElementsByTagName('html')[0].classList.remove('static');
  }

  render() {
    return (
      <div style={{ maxWidth: '1440px', margin: 'auto' }}>
        <Button variant="underline" onClick={(e) => (window.location.href = '/privacy')}>
          View Privacy
        </Button>

        <Container style={{ textAlign: 'center' }}>
          <Wrapper>
            <Typo variant="d1">서비스 이용약관</Typo>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h1">제 1 장 총 칙</Typo>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h2">제 1 조 【 목 적 】</Typo>
          </Wrapper>
          <Wrapper>
            <Typo>
              본 약관은 주식회사 페어랩스(이하 "회사")가 운영하는 홈페이지(www.fairlabs.io)를 통해
              제공하는 서비스 (이하 "서비스"라 합니다)를 회사의 회원이 이용함에 있어서 회사와 회원간
              권리·의무에 관한 사항, 서비스 이용조건과 절차 및 기타 필요한 사항을 규정함을 목적으로
              합니다.
            </Typo>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h2">제 2 조 【 용어의 정의 】</Typo>
          </Wrapper>
          <Wrapper>
            <Typo>본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</Typo>
            <Content>
              <Typo>① 서비스: 회사가 제공하는 인터넷상의 모든 서비스를 말합니다.</Typo>
            </Content>
            <Content>
              <Typo>
                ② 이용자: 회사가 운영하는 서비스에 접속하여 회사가 제공하는 서비스를 받는 회원 및
                비회원을 말합니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ③ 회 원: 회사와 서비스 이용계약을 체결하고 이용자 아이디(ID)를 신청하여 회사로부터
                이용 승낙을 받은 개인 또는 법인으로서 본 약관에 따라 회사가 제공하는 서비스를
                계속적으로 이용할 수 있는 개인(이하 “개인회원”)또는 법인(이하 “법인회원”)을
                말합니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ④ 아이디: 회원의 식별과 회원의 서비스이용을 위하여 회원이 선정하고 회사가 승인하여
                회사에 등록된 문자나 숫자 혹은 그 조합을 말합니다 (이하 "ID").{' '}
              </Typo>
            </Content>
            <Content>
              <Typo>
                ⑤ 비밀번호: 회원이 부여받은 ID와 일치된 회원임을 확인하고, 회원의 권익 및 비밀보호를
                위하여 회원 스스로가 선정하여 회사에 등록한 문자와 숫자의 조합을 말합니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ⑥ 운영자: 서비스의 전반적인 관리와 원활한 운영을 위하여 회사에서 선정한 자를
                말합니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ⑦ 이용요금: 회원이 회사의 유료서비스 이용을 위해 납입하여야 할 요금(정보이용료)을
                말합니다.
              </Typo>
            </Content>
            <Content>
              <Typo>⑧ 해지: 회사 또는 회원이 서비스 이용계약을 종료시키는 것을 말합니다.</Typo>
            </Content>
            <Content>
              <Typo>
                ⑨ 이 약관에서 사용하는 용어의 정의는 전항에서 정한 것을 제외하고는 거래관행 및
                관계법령에 따릅니다.
              </Typo>
            </Content>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h2">제 3 조 【 약관의 효력 및 개정 】</Typo>
          </Wrapper>
          <Wrapper>
            <Content>
              <Typo>
                ① 본 약관은, 회사가 본 약관의 내용과 상호, 영업소 소재지, 사업자등록번호,
                연락처(전화, 팩스, e-mail주소 등) 등을 이용자가 알 수 있도록 회사의 서비스 화면을
                통하여 이를 게시하거나 기타의 방법으로 회원에게 통지함으로써 그 효력이 발생됩니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ② 회사는 영업상 중요한 사유가 발생될 경우와 기타 회사가 필요하다고 인정하는 경우에
                약관의 규제 등에 관한 법률, 전자거래기본법, 전자서명법, 정보통신망 이용 촉진 등에
                관한 법률, 방문판매 등에 관한 법률, 소비자보호법 등 관련법령을 위배하지 않는
                범위에서 본 약관을 개정할 수 있습니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ③ 회사가 본 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께
                그 적용일자 7일 이전부터 적용일자 전일까지 제1항과 같은 방법으로 공지 또는
                통지합니다. 이 때, 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그
                이전에 이미 체결된 계약에 대해서는 개정전의 약관조항이 그대로 적용됩니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ④ 회원은 개정된 약관에 동의하지 않을 경우 회원 탈퇴를 요청할 수 있으며, 약관 변경의
                효력발생 이후 계속적인 서비스이용은 회원이 약관변경에 동의한 것으로 간주됩니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ⑤ 본 약관에서 정하지 아니한 사항 및 본 약관의 해석에 관하여는 관계법령, 및 상관례,
                공정거래위원회 고시 전자상거래 등에서의 소비자 보호지침에 따릅니다.
              </Typo>
            </Content>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h1">제 2 장 서비스 이용계약</Typo>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h2">제 4 조 【 서비스 이용신청 】</Typo>
          </Wrapper>
          <Wrapper>
            <Content>
              <Typo>
                ① 서비스 이용을 신청하는 자는 회사가 요청하는 소정의 회원가입신청 양식이 요구하는
                사항을 기록하여 신청하여야 합니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ② 회사는 이용고객(또는 회원)이 되고자 하는 자가 관련 법령에서 규정하는 미성년자일
                경우 서비스 이용 신청에 대한 승낙을 보류할 수 있습니다. 회사는 위 승낙을 위하여
                필요한 경우 이용고객(또는 회원)이 되고자 하는 미성년자의 법정대리인의 동의를 요청할
                수 있습니다. 이를 어길 시 발생하는 결과에 대해 회사는 책임을 지지 않습니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ③ 온라인 가입신청 양식에 기재되는 모든 이용자 정보는 실제 데이터인 것으로
                간주됩니다. 만일, 실명이나 실제 정보를 입력하지 않은 경우 이용자는 법적인 보호를
                받을 수 없으며, 서비스 사용에 제한을 받을 수 있습니다.
              </Typo>
            </Content>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h2">제 5 조 【 서비스 이용계약의 성립 】</Typo>
          </Wrapper>
          <Wrapper>
            <Content>
              <Typo>
                ① 서비스 이용계약은 이용자가 본 약관에 대하여 동의하고, 회사에게 한 이용신청에
                대하여 회사가 이를 승낙함으로써 성립하며, 이 때 비로소 이용자는 회원으로서 자격을
                갖게 됩니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ② 이용자가 회원가입을 신청할 때 본 약관을 읽고 서비스상에서 "가입" 버튼을 클릭하면
                본 약관에 동의하는 것으로 간주됩니다.
              </Typo>
            </Content>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h2">제 6 조 【 이용신청에 대한 승낙 거절 등 】</Typo>
          </Wrapper>
          <Wrapper>
            <Content>
              <Typo>
                ① 회사는 다음 각 호에 해당하는 신청에 대하여는 승낙을 거절하거나 그 사유가 해소될
                때까지 승낙을 유보할 수 있습니다.
              </Typo>
            </Content>
            <Wrapper>
              <Content>
                <Typo>1) 타인 명의로 신청한 경우</Typo>
              </Content>
              <Content>
                <Typo>2) 주민등록상 본인 실명으로 신청하지 않은 경우</Typo>
              </Content>
              <Content>
                <Typo>
                  3) 신청 시 필요사항을 허위로 기재하여 신청한 경우나 또는 오기가 있거나 허위서류를
                  첨부한 경우
                </Typo>
              </Content>
              <Content>
                <Typo>
                  4) 정보를 악용하거나 사회의 안녕과 질서 혹은 미풍양속을 저해할 목적으로 신청한
                  경우
                </Typo>
              </Content>
              <Content>
                <Typo>
                  5) 신용정보의 이용과 보호에 관한 법률에 의하여 신용불량자로 등록되어 있는 경우
                </Typo>
              </Content>
              <Content>
                <Typo>6) 회사의 서비스설비 용량에 여유가 없는 경우</Typo>
              </Content>
              <Content>
                <Typo>7) 회사가 서비스를 제공하기에는 기술적으로 문제가 있다고 판단되는 경우</Typo>
              </Content>
              <Content>
                <Typo>
                  8) 이용자가 유료서비스를 이용할 경우에 회사에 납부하여야 할 요금을 납부하지 않은
                  경우
                </Typo>
              </Content>
              <Content>
                <Typo>9) 이용자의 귀책사유로 이용승낙이 곤란한 경우</Typo>
              </Content>
              <Content>
                <Typo>10) 기타 회사가 정한 이용신청조건에 미비된 경우</Typo>
              </Content>
            </Wrapper>
          </Wrapper>
          <Content>
            <Typo>
              ② 회원은 다음 각호에 해당하는 ID를 사용할 수 없으며, 이러한 ID가 등록 신청된 경우에
              회사는 동 ID의 등록을 반려하거나 취소할 수 있고, 회원의 ID 변경 시까지 서비스이용을
              제한할 수 있습니다.
            </Typo>
          </Content>
          <Wrapper>
            <Content>
              <Typo>
                1) 회원ID가 회원의 전화번호, 주민등록번호 등으로 등록되어 있어서 회원의 사생활을
                침해할 우려가 있는 경우
              </Typo>
            </Content>
            <Content>
              <Typo>2) 타인에게 혐오감을 주거나 미풍양속에 어긋나는 경우</Typo>
            </Content>
            <Content>
              <Typo>3) 기타 회사 소정의 합리적인 사유가 있는 경우</Typo>
            </Content>
          </Wrapper>
          <Content>
            <Typo>
              ③ 회사는 제1항 및 제2항의 규정에 의하여 승낙이 거절되거나 제한 또는 유보되는 경우,
              이용을 신청한자에게 이를 즉시 알려야 한다. 다만, 이용을 신청한 자의 고의 또는 과실
              등으로 통지할 수 없는 경우에는 그러하지 아니합니다.
            </Typo>
          </Content>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h1">제 3 장 서비스 이용</Typo>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h2">제 7 조 【 서비스 이용 범위 】</Typo>
          </Wrapper>
          <Content>
            <Typo>
              회원은 회원가입할 때 발급된 ID로, 회사가 정한 가격정책에 따른 서비스를 이용할 수
              있습니다. 단, 회사가 자료의 저작권을 가진 컨텐츠 제공자와 특정 회원들에게만 서비스할
              것을 조건으로 계약한 일부 자료의 경우 다른 회원들은 열람하지 못할 수도 있습니다. 또한
              회사는 저작권 보호를 위해 보고서 조회 및 다운로드 횟수를 제한할 수 있습니다.
            </Typo>
          </Content>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h2">제 8 조 【 정보의 제공 등 】</Typo>
          </Wrapper>
          <Content>
            <Typo>
              회사는 회원이 서비스를 이용할 때 필요하다고 인정되는 다양한 정보의 추가 또는
              변경내용을 공지사항이나 e-mail 등의 방법으로 회원에게 제공할 수 있습니다.
            </Typo>
          </Content>
          <Wrapper>
            <Content>
              <Typo>
                ① 회사는 본 약관에 별도 규정이 없는 한 이용자가 등록한 전화, 이메일, 문자메시지 등
                기타 통신수단을 이용하여 이용자에게 통지할 수 있습니다. 이용자가 이메일주소,
                휴대전화번호 정보를 허위로 제출 또는 등록하거나, 변경된 정보를 수정 또는 회사에
                고지하지 않은 경우 회사는 이용자가 사전에 제출 또는 등록한 정보로 발송한 때에
                이용자에게 도달한 것으로 합니다.{' '}
              </Typo>
            </Content>
            <Content>
              <Typo>
                ② 회사는 이용자 전체에 대한 통지의 경우 7일 이상 회사의 홈페이지 또는 공지사항
                게시판에 게시함으로써 제1항의 통지에 갈음할 수 있습니다.
              </Typo>
            </Content>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h2">제 9 조 【 회원의 게시물 】</Typo>
          </Wrapper>
          <Content>
            <Typo>
              회사는 회원이 게재하거나 등록하는 서비스 내의 자료(또는 내용물)이 다음 각호의 1에
              해당한다고 판단되는 경우에 사전통지 없이 삭제할 수 있습니다.
            </Typo>
          </Content>
          <Wrapper>
            <Content>
              <Typo>
                ① 다른 회원 또는 제3자를 비방하거나 중상모략으로 명예를 손상시키는 내용인 경우
              </Typo>
            </Content>
            <Content>
              <Typo>② 공공질서 및 미풍양속에 위반되는 내용인 경우</Typo>
            </Content>
            <Content>
              <Typo>③ 범죄적 행위에 결부된다고 인정되는 내용일 경우</Typo>
            </Content>
            <Content>
              <Typo>④ 회사의 저작권, 제3자의 저작권, 기타 권리를 침해하는 내용인 경우</Typo>
            </Content>
            <Content>
              <Typo>⑤ 회사에서 규정한 게시기간을 초과한 경우</Typo>
            </Content>
            <Content>
              <Typo>⑥ 회원이 서비스 게시판에 음란물을 게재하거나 음란사이트를 링크하는 경우</Typo>
            </Content>
            <Content>
              <Typo>⑦ 기타 관계법령에 위반된다고 판단되는 경우</Typo>
            </Content>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h2">제 1 0 조 【 게시물의 저작권 】</Typo>
          </Wrapper>
          <Wrapper>
            <Content>
              <Typo>
                ① 회사가 작성한 저작물에 대한 저작권 및 기타 지적재산권은 제공사 및 회사에
                귀속합니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ② 회사가 운영하는 커뮤니티 등에 게재된 자료에 대한 권리와 책임은 게시자에게
                있습니다. 회사는, 게시자의 동의가 없는 한, 이를 영리적인 목적으로 사용할 수
                없습니다. 단, 비영리적인 경우에는 그러하지 아니합니다. 회사는 서비스 내에 회원의
                게시물을 게재할 권한이 있습니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ③ 회원은 서비스를 이용하여 얻은 정보를 가공·판매하는 행위 등 서비스 내에 게재된
                자료를 상업적으로 사용할 수 없습니다.
              </Typo>
            </Content>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h2">제 1 1 조 【 광고게재 및 광고주와의 거래 】</Typo>
          </Wrapper>
          <Wrapper>
            <Content>
              <Typo>
                ① 회사는 서비스의 운용과 관련하여 서비스 화면, e-mail 등에 광고를 게재할 수
                있습니다. 회원은 서비스 이용 시 노출되는 광고게재에 대해 동의하는 것으로 간주됩니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ② 본 서비스상에 게재된 광고내용이나 본 서비스를 통한 광고주의 판촉활동에 대하여
                회원은 본인의 책임과 판단으로 참여하거나 교신 또는 거래하여야 하며, 그 결과로서
                발생하는 모든 손실 또는 손해에 대해 회사는 책임을 지지 않는 것을 원칙으로 합니다.
              </Typo>
            </Content>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h2">제 1 2 조 【 서비스 이용시간 】</Typo>
          </Wrapper>
          <Wrapper>
            <Content>
              <Typo>
                ① 서비스의 이용은, 회사의 업무상 또는 기술상에 특별한 지장이 없는 한, 연중무휴 1일
                24시간 가능함을 원칙으로 합니다. 다만, 정기점검 등의 필요로 회사가 정한 날이나
                시간은 제외됩니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ② 회사는 서비스를 일정범위로 분할하여 각 범위별로 이용가능 시간을 별도로 정할 수
                있습니다. 이 경우, 회사는 제3조 제1항의 방법으로 사전에 공지합니다.
              </Typo>
            </Content>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h2">제 1 3 조 【 서비스 이용의 한계와 책임 】</Typo>
          </Wrapper>
          <Content>
            <Typo>
              회원은, 회사가 서면으로 허용한 경우를 제외하고는, 서비스를 이용하여 상품을 판매하는
              영업활동을 할 수 없습니다. 특히, 회원은 해킹·돈벌이 광고·음란 사이트 등을 통한
              상업행위, 상용S/W 불법배포 등을 할 수 없습니다. 이를 위반하여 발생된 영업활동의 결과
              및 손실, 관계기관에 의한 구속 등 법적 조치 등에 관해서는 회사가 책임을 지지 않습니다.
            </Typo>
          </Content>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h2">제 1 4 조 【 서비스 제공의 변경 및 제한 등 】</Typo>
          </Wrapper>
          <Wrapper>
            <Content>
              <Typo>
                ① 회사는 다음 각호에 해당하는 경우 서비스의 전부 또는 일부를 제한하거나 중지할 수
                있습니다.
              </Typo>
            </Content>
            <Wrapper>
              <Content>
                <Typo>1) 서비스용 설비의 보수 등 공사로 인한 부득이한 경우</Typo>
              </Content>
              <Content>
                <Typo>
                  2) 전기통신사업법에 규정된 기간통신사업자가 전기통신서비스를 중지했을 경우
                </Typo>
              </Content>
              <Content>
                <Typo>
                  3) 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인
                  서비스 이용에 지장이 있는 경우
                </Typo>
              </Content>
              <Content>
                <Typo>4) 기타 불가항력적 사유가 있는 경우</Typo>
              </Content>
            </Wrapper>
            <Content>
              <Typo>
                ② 회사는 상당한 이유가 있는 경우에 회사의 정책상, 운영상, 기술상의 필요에 따라
                제공하고 있는 서비스의 전부 또는 일부를 변경할 수 있으며, 이에 대하여 관련법에
                특별한 규정이 없는 한 이용자에게 별도의 보상을 하지 않습니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ③ 회사가 서비스의 이용을 변경 또는 제한하거나 중지한 때에는 그 사유 및 제한기간 등을
                제3조 제1항의 방법으로 지체 없이 회원에게 알리도록 합니다.
              </Typo>
            </Content>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h1">제 4 장 유료서비스 이용</Typo>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h2">제 1 5 조 【 유료서비스 이용계약의 성립 】</Typo>
          </Wrapper>
          <Wrapper>
            <Content>
              <Typo>
                ① 유료서비스 이용계약은 회사가 운영하는 서비스상에서 본 약관에 동의 후
                유료회원신청을 하고 회사가 이를 승낙하거나 또는 회사가 제시하는 회원가입신청서를
                작성 · 제출하고 회사가 이를 승낙함으로써 성립합니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ② 유료서비스 이용계약은 계약기간을 원칙적으로 계약일로부터 1년간으로 하며, 계약기간
                종료일로부터 1개월 이전까지 회원과 회사가 상대방에게 별도의 계약 해지의사를 표시하지
                않으면 자동적으로 1년씩 연장되는 것으로 합니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ③ 이용요금 납입방법을 회사명의의 입금계좌로 현금입금으로 선택하신 회원의 경우
                이용요금 납입일의 익일부터 서비스를 이용하실 수 있습니다. 재가입하시는 회원의 경우
                회사에 미납금이 있을 때에는 이를 완납하셔야 서비스를 이용하실 수 있습니다.
              </Typo>
            </Content>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h2">제 1 6 조 【 이용요금 및 결제방식 】</Typo>
          </Wrapper>
          <Wrapper>
            <Content>
              <Typo>
                ① 서비스 이용요금 및 결제방식은 회사의 가격정책에 따라 서비스상에서 공지 또는
                회원가입신청서에서 정한 바에 따릅니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ② 회사는 정하는 바에 따라 이용요금을 감면 또는 할인할 수 있습니다. 이 때, 감면 또는
                할인의 구체적인 대상 및 적용기준은 회사가 정하는 바에 따릅니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ③ 이용요금의 결제방식은 회사명의의 입금계좌로 현금입금, 회원명의의 카드결제 두 가지
                방법 중 회원이 원하는 방법을 선택할 수 있습니다. 또한, 납입방법은 매월 이용요금을
                납입하는 월납의 방법과 계약 시 1년 이용요금을 한번에 납입하는 연납의 방법 중 회원이
                원하는 방법을 선택할 수 있습니다. 연납하시는 회원의 경우 회사가 정하는 바의 일정률의
                이용요금 할인혜택이 주어집니다. 또한 모든 유료서비스는 선납을 원칙으로 합니다.
              </Typo>
            </Content>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h2">제 1 7 조 【 이용요금의 청구와 납입 】</Typo>
          </Wrapper>
          <Wrapper>
            <Content>
              <Typo>
                ① 회사는, 별도로 정하는 경우를 제외하고는, 이용자의 회원ID를 기준으로 이용요금을
                청구합니다. 따라서, 회사가 별도로 동의하지 않는 한, 이용요금 납입자는 이용자를
                원칙으로 합니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ② 이용자는 회사와 계약된 신용카드사를 이용하여 별도의 매출전표에 서명하지 않고
                이용요금을 납입할 수 있습니다. 단, 카드분실 등에 따른 불이익에 대해서는 이용자의
                책임으로 합니다. 이용자는 신용카드의 유효기간 만료 · 분실 · 재발행 등으로 인해
                카드번호가 바뀔 경우에 반드시 회사에 통보하여야 하며, 이 경우에는 변경된 카드에
                요금이 부과됩니다. 한편, 이용자의 요청에 따라 회사가 승낙한 경우에는 다른 방법에
                의해서도 이용요금 납부가 가능합니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ③ 이용자가 이용요금을 회사가 지정한 기일까지 납입하지 아니한 때에는 이용요금의
                100분의 2에 해당하는 가산금을 부과합니다. 단, 회사는 이용자의 책임이 없는 사유로
                납입청구서가 요금납입자나 그 가족 또는 대리인에게 배달되지 아니하였을 경우에는
                가산금을 부과하지 않습니다.
              </Typo>
            </Content>

            <Content>
              <Typo>
                ④ 회사는 이용자가 본 약관에서 정한 사항을 위반하여 불법으로 이용요금 등을 면탈한
                경우에는 회사는 그 사유를 명시하여 관련 법정이자율에 의한 지연손해금을 일할계산하여
                적용한 금액을 청구합니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ⑤ 회사가 정한 기간 동안 이용자가 이용요금을 체납할 경우, 회사는 신용정보사업자 또는
                신용정보집중 기관에 이용자의 개인신상정보 등을 제공할 수 있습니다. 이용자 본인의
                신고누락 등으로 인하여 발생하는 불이익에 대하여 회사는 책임지지 않습니다.
              </Typo>
            </Content>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h2">제 1 8 조 【 결제방식 / 납입방법 등의 변경 】</Typo>
          </Wrapper>
          <Content>
            <Typo>
              계약기간 중 결제방식 혹은 납입방법을 변경한 경우 변경된 방법에 의한 이용요금의 청구는
              변경일의 익월에 대한 이용요금 청구시점부터 적용됩니다.
            </Typo>
          </Content>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h2">제 1 9 조 【 이용요금의 연체 】</Typo>
          </Wrapper>
          <Content>
            <Typo>
              납입기일을 5일이상 경과하여 이용요금을 연체한 경우 회사는 회원에 대해 계약유지 여부를
              유선 혹은 e-mail로 물을 수 있으며 유지여부가 확인되지 않으면 회사는 해당회원 ID에 대해
              서비스를 임의로 중지할 수 있습니다. 이 경우에도 회사가 제공하는 무료서비스는 이용할 수
              있습니다. 중지된 서비스를 다시 이용하기 위해 연체된 이용요금을 납부하고 이 사실을
              회사에 유선 혹은 E-mail로 통보하면 회사는 입금여부를 확인하는 즉시 서비스를 재개하며
              이 경우 서비스 재개일을 재가입일로 상정하고, 이용요금을 계산하게 됩니다.
            </Typo>
          </Content>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h2">제 2 0 조 【 이용요금에 대한 이의신청 】</Typo>
          </Wrapper>
          <Wrapper>
            <Content>
              <Typo>
                ① 회원은 회사가 청구한 서비스 이용요금에 대해 이의가 있을 경우 유선 혹은 E-mail로
                이의를 신청할 수 있고, 회사는 이에 대해 조사한 후 당해 회원에게 즉시 통보해야
                합니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ② 회사는 회원이 과다 납입한 이용료에 대해서는 회원의 요구에 따라 환불하거나, 익월
                청구요금에서 상계할 수 있고, 회사가 과소 청구한 금액에 대해서는 회원이 이를
                납입하더라도 회원에 대해 불이익이 되는 처분을 할 수 없습니다.
              </Typo>
            </Content>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h2">제 2 1 조 【 청약의 철회 】</Typo>
          </Wrapper>
          <Content>
            <Typo>
              청약의 철회는 계약 성립 이후 7일 이내에 사용 내역이 없는 경우에 한해 가능하며 회원은
              유선연락 및 email 등 서면으로 신청할 수 있습니다. 사용 내역은 데이터 조회, 다운로드
              등으로 기록됩니다. 계약 기간 중 중도해지 및 환불은 본 이용약관 제27조를 따릅니다.
            </Typo>
          </Content>
          <Wrapper>
            <Content>
              <Typo>① 청약의 철회가 성립할 경우 전액환불 합니다.</Typo>
            </Content>
            <Content>
              <Typo>
                ② 회원은 정당한 사유없이 결제 후 청약 철회를 반복적으로 요청할 수 없으며, 회사는
                회원이 청약 철회 정책을 악용하여 서비스를 이용하거나 업무방해 행위를 하는 경우
                서비스 이용과 결제를 거절할 수 있습니다.
              </Typo>
            </Content>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h1">제 5 장 계약당사자의 의무</Typo>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h2">제 2 2 조 【 회사의 의무 】</Typo>
          </Wrapper>
          <Wrapper>
            <Content>
              <Typo>
                ① 회사는 특별한 사정이 없는 한, 회원이 서비스 이용을 신청한 날부터 서비스를 이용할
                수 있도록 합니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ② 회사는 본 약관에서 정한 바에 따라 계속적이고 안정적인 서비스 제공을 위하여
                지속적으로 노력하며, 설비에 장애가 생기거나 멸실된 때에는 지체 없이 이를 수리
                복구하여야 합니다. 다만, 천재지변, 비상사태 또는 이에 준하는 부득이한 경우에는 그
                서비스를 일시 중단하거나 중지할 수 있습니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ③ 회사는 회원으로부터 소정의 절차에 의해 제기되는 의견이나 불만이 정당하다고 인정할
                경우에는 우선적으로 적절한 절차를 거친 후 처리하여야 합니다. 다만, 신속한 처리가
                곤란한 경우에는 회원에게 그 사유와 처리 일정을 E-mail 또는 전화 등으로 통보하여야
                합니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ④ 회사는 회원의 프라이버시 보호와 관련하여 본 약관 및 서비스 내에서 제시된 내용을
                준수합니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ⑤ 회사는 이용계약의 체결, 계약사항의 변경 및 해지 등 회원과의 계약관련 절차 및 내용
                등에 있어 회원에게 최대한의 편의를 제공하도록 노력합니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ⑥ 회사는 소비자보호단체의 소비자보호 업무 추진에 필요한 자료 등의 제공 요구에 적극
                협력합니다.
              </Typo>
            </Content>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h2">제 2 3 조 【 개인정보 보호정책 】</Typo>
          </Wrapper>
          <Wrapper>
            <Content>
              <Typo>
                ① 회사는 개인정보보호법 및 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관계
                법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호
                및 사용에 대해서는 관련법령 및 회사의 개인정보처리방침이 적용됩니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ② 회사는 회원의 개인정보 수집 시 서비스 제공을 위하여 필요한 범위에서 최소한의
                개인정보를 수집합니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ③ 회사는 회원의 개인정보를 수집·이용하는 때에는 당해 회원에게 그 목적을 고지하고
                동의를 받습니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ④ 회사는 수집된 개인정보를 목적 외의 용도로 이용할 수 없으며, 새로운 이용목적이
                발생하는 경우 또는 제3자에게 이를 제공하는 경우에는 이용·제공 단계에서 당해 회원에게
                그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는
                예외로 합니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ⑤ 회사가 제2항과 제3항에 의해 회원의 동의를 받아야 하는 경우에는 개인정보관리
                책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적,
                제3자에 대한 정보제공 관련사항(제공받은자, 제공목적 및 제공할 정보의 내용) 등
                정보통신망 이용촉진 및 정보보호 등에 관한 법률 제22조 제1항이 규정한 사항을 미리
                명시하거나 고지해야 하며 회원은 언제든지 이 동의를 철회할 수 있습니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ⑥ 회원은 언제든지 회사가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을
                요구할 수 있으며 회사는 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 회원이
                오류의 정정을 요구한 경우에는 회사는 그 오류를 정정할 때까지 당해 개인정보를
                이용하지 않습니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ⑦ 회사는 개인정보 보호를 위하여 회원의 개인정보를 취급하는 자를 최소한으로
                제한하여야 하며 신용카드, 은행계좌 등을 포함한 회원의 개인정보의 분실, 도난, 유출,
                동의 없는 제3자 제공, 변조 등으로 인한 회원의 손해에 대하여 모든 책임을 집니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ⑧ 회사 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은
                목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ⑨ 회사는 개인정보의 수집·이용·제공에 관한 동의 란을 미리 선택한 것으로 설정해두지
                않습니다. 또한 개인정보의 수집·이용·제공에 관한 회원의 동의거절시 제한되는 서비스를
                구체적으로 명시하고, 필수수집항목이 아닌 개인정보의 수집·이용·제공에 관한 회원의
                동의 거절을 이유로 서비스 제공을 제한하거나 거절하지 않습니다.
              </Typo>
            </Content>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h2">제 2 4 조 【 회원의 의무 】</Typo>
          </Wrapper>
          <Wrapper>
            <Content>
              <Typo>
                ① 회원은 본 약관에서 규정하는 사항과 서비스 이용안내 또는 주의사항 등 회사가 적법한
                절차와 내용으로 공지 혹은 통지하는 사항을 준수하여야 하며, 기타 회사의 업무에
                방해되는 행위를 하여서는 안됩니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ② 회원은 자신에게 부여된 ID와 비밀번호의 관리 소홀과 부정 사용이 발생하지 않도록
                유의하여야 하며, 회사의 고의 · 과실이 없는 한, 회원의 ID와 비밀번호에 관한 모든
                관리책임은 회원에게 있습니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ③ 회원 자신의 ID나 비밀번호가 부정하게 사용되었다는 사실을 발견한 경우에는 즉시
                회사에 신고하여야 하며, 회사의 고의·과실이 없는 한, 신고를 하지 않아 발생하는 모든
                결과에 대한 책임은 회원에게 있습니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ④ 회원은 회사의 사전승낙 없이는 서비스를 이용하여 영업활동을 할 수 없으며, 그
                영업활동의 결과와 회원이 약관을 위반한 영업활동을 하여 발생한 결과에 대하여 회사는
                책임을 지지 않습니다. 회원의 이와 같은 영업활동으로 회사가 손해를 입은 경우, 회원은
                회사에 대하여 손해배상책임을 부담합니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ⑤ 회원은, 회사의 명시적인 동의가 없는 한, 서비스의 이용권한 기타 이용계약상 지위를
                타인에게 양도·증여·담보제공할 수 없습니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ⑥ 회원은 ‘www.fairlabs.io’ 유료서비스를 통해 제공받은 정보를 저작권자의 허락 없이
                저작권법상 복제ㆍ전송ㆍ배포ㆍ 발행하는 등의 행위를 하여서는 안됩니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ⑦ 회원이 서비스 이용과 관련하여 다음 각호의 1에 해당되는 행위를 한 경우, 회사는
                회원의 서비스 이용계약을 해지, 서비스이용을 제한, 게시물 등을 삭제할 수 있습니다.
              </Typo>
            </Content>
            <Wrapper>
              <Content>
                <Typo>
                  1) 회원의 ID와 비밀번호, 다른 회원의 ID와 비밀번호, 주민등록번호 등을 도용하는
                  행위
                </Typo>
              </Content>
              <Content>
                <Typo>2) 서비스 이용 신청 또는 서비스 내용 변경 시 허위내용을 등록하는 행위</Typo>
              </Content>
              <Content>
                <Typo>
                  3) 회사 또는 이용자가 게시한 정보 및 콘텐츠를 무단으로 수집, 변경하거나 제3자에게
                  제공하는 행위
                </Typo>
              </Content>
              <Content>
                <Typo>
                  4) 타인의 특허, 상표, 영업비밀, 저작권 기타 지적재산권을 침해하는 내용을
                  게재하거나 e-mail 기타의 방법으로 타인에게 유포하는 행위
                </Typo>
              </Content>
              <Content>
                <Typo>
                  5) 공공질서 및 미풍양속에 위반되는 저속·음란한 내용의 정보·문장·도형 등을
                  전송·게시·e-mail 기타의 방법으로 타인에게 유포하는 행위
                </Typo>
              </Content>
              <Content>
                <Typo>
                  6) 모욕적이거나 위협적이어서 타인의 프라이버시를 침해할 수 있는 내용, 타인의
                  명예를 훼손하는 내용을 전송·게시·e-mail 기타의 방법으로 타인에게 유포하는 행위
                </Typo>
              </Content>
              <Content>
                <Typo>7) 범죄와 결부된다고 객관적으로 판단되는 행위</Typo>
              </Content>
              <Content>
                <Typo>
                  8) 회사의 승인을 받지 않고 다른 이용자의 개인정보를 수집 또는 저장하는 행위
                </Typo>
              </Content>
              <Content>
                <Typo>
                  9) 회사의 동의 없이 데이터 등을 추출하기 위하여 로봇(봇), 크롤러, 스파이더,
                  스크레이퍼, 매크로 프로그램 또는 기타 자동화 수단이나 수동 프로세스를 이용하여
                  회사의 웹 ∙ 앱 ∙ 서버에 접근하거나 회사 서비스에 포함된 콘텐츠 및 정보를 모니터링,
                  복제, 수집, 확인, 정리, 사용하는 등의 행위
                </Typo>
              </Content>
              <Content>
                <Typo>
                  10) 회사의 사이트나 데이터베이스가 보관된 서버에의 접근을 제한하기 위한 보호조치를
                  우회하거나 침해하는 행위
                </Typo>
              </Content>
              <Content>
                <Typo>
                  11) 비인가된 회사 사이트 및 서비스 변경, 해킹, 변경, 훼손, 디스어셈블링,
                  디컴파일링 또는 리버스 엔지니어링, 소스 코드 유출 및 변경하는 행위
                </Typo>
              </Content>
              <Content>
                <Typo>
                  12) 회사의 서비스 제공 목적 외의 용도로 서비스를 이용하거나 제 3자에게 임의로
                  서비스를 임대하는 행
                </Typo>
              </Content>
              <Content>
                <Typo>13) 기타 불법적이거나 부당한 행위</Typo>
              </Content>
            </Wrapper>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h1">제 6 장 계약사항의 변경</Typo>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h2">제 2 5 조 【 계약 사항의 변경 】</Typo>
          </Wrapper>
          <Wrapper>
            <Content>
              <Typo>
                ① 회원은 개인정보관리를 통해 언제든지 개인정보를 열람하고 수정할 수 있습니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ② 회원은 서비스이용 신청 시 또는 유료서비스 이용계약 체결 시에 기재한 사항이
                변경되었을 경우에는 이를 온라인으로 수정하거나 또는 유선으로 수정요청을 해야 하며,
                회원정보의 미변경으로 인하여 발생되는 문제의 책임은 회원에게 있습니다. 특히, 다음에
                해당하는 사항의 변경이 있을 경우에는 이를 지체 없이 회사에 고지하거나 온라인을
                통하여 변경한 후 정확한 기재여부를 확인하여야 합니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ☞ 주소, 전화번호, 이용요금 납입방법, 신용카드의 변경사항, 사업자등록번호 (법인의
                경우)
              </Typo>
            </Content>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h2">제 2 6 조 【 회원의 지위 승계 및 양도 제한 】</Typo>
          </Wrapper>
          <Wrapper>
            <Content>
              <Typo>
                ① 회원은 회사가 서면으로 동의한 경우에만 제3자에게 회원의 지위를 승계시키거나
                회원으로서의 권리의무를 양도할 수 있습니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ② 제1항의 규정에 의하여 회원의 지위를 승계하거나 회원으로서의 권리의무를 양도받은
                자는 다음 각호의 1에 해당하는 사항을 회사에 지체 없이 통보하여야 합니다.
              </Typo>
            </Content>
            <Wrapper>
              <Content>
                <Typo>1) 회원 변경사항</Typo>
              </Content>
              <Content>
                <Typo>2) 요금고지 주소가 변경되는 경우에는 그 변경되는 요금고지 주소</Typo>
              </Content>
              <Content>
                <Typo>3) 요금납입자가 변경되는 경우에는 그 변경되는 요금납입자</Typo>
              </Content>
            </Wrapper>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h1">제 7 장 계약의 해지</Typo>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h2">제 2 7 조 【 해지 및 환불 】</Typo>
          </Wrapper>
          <Wrapper>
            <Content>
              <Typo>
                ① 회원이 이용계약을 해지하고자 하는 때에는 본인이 직접 온라인, 유선, 이메일 등
                서명으로 회사에 신청하여야 합니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ② 회사는 제1항의 규정에 따른 해지신청이 접수된 경우 해지의 효력이 발생하는 날에
                서비스의 이용을 제한합니다. 다만 회원의 해지 희망일이 해지 신청일과 다른 경우 해지
                희망일부터 서비스 이용을 제한합니다.
              </Typo>
            </Content>
            <Content>
              <Typo>③ 월간(1~11개월) 이용권: 청약의 철회만 가능합니다.</Typo>
            </Content>
            <Content>
              <Typo>
                ④ 연간(12개월) 이용권: 계약의 안정성을 위해 해지 1개월 이전에 서면으로 통보해야 하며
                환불 금액은 월할 계산하여 이용요금을 산출하고 환불할 금액이 있는 경우 회원명의의
                통장입금 혹은 카드 부분취소 등의 방법으로 환불합니다. 사용기간(월)은 해지신청일이
                해당하는 경과월에 1개월을 더하여 계산합니다. (예를 들어, 서비스 사용일수가 1개월을
                초과하여 추가 10일을 사용했다면 경과월은 2개월 입니다.) 또한 중도 해지 및 환불은
                결제일 포함하여 85일 이내에 신청 시에만 가능합니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ⑤ 회사는 회원이 다음 각 호의 1에 해당하여 이용계약을 해지하고자 할 경우에는 해지조치
                7일전까지 그 뜻을 회원에게 통지하여 의견을 진술할 기회를 주어야 합니다.
              </Typo>
            </Content>
            <Wrapper>
              <Content>
                <Typo>
                  1) 회원이 이용제한 규정을 위반하거나 그 이용제한 기간 내에 제한사유를 해소하지
                  않는 경우
                </Typo>
              </Content>
              <Content>
                <Typo>2) 방송통신심의위원회가 이용해지를 요구한 경우</Typo>
              </Content>
              <Content>
                <Typo>3) 회원이 정당한 사유 없이 의견진술에 응하지 아니한 경우</Typo>
              </Content>
              <Content>
                <Typo>
                  4) 타인 명의로 신청을 하였거나 신청서 내용의 허위 기재 또는 허위 서류를 첨부하여
                  이용계약을 체결한 경우
                </Typo>
              </Content>
            </Wrapper>
            <Content>
              <Typo>
                ⑥ 회사는 제3항의 규정에 의하여 해지된 회원에 대하여는 별도로 정한 기간 동안 가입을
                제한할 수 있습니다.
              </Typo>
            </Content>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h1">제 8 장 손해배상</Typo>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h2">제 2 8 조 【 손해배상의 범위 및 청구 】</Typo>
          </Wrapper>
          <Wrapper>
            <Content>
              <Typo>
                ① 회사는 천재지변 등 불가항력적이거나 회사의 귀책사유 없이 발생한 회원의 손해에
                대해서는 손해배상책임을 부담하지 않습니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ② 손해배상의 청구는 회사에 청구사유, 청구금액 및 산출근거를 기재하여 서면으로 하여야
                합니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ③ 회원이 본 약관의 규정을 위반함으로 인하여 회사에 손해가 발생하게 되는 경우, 이
                약관을 위반한 회원은 회사에 발생하는 모든 손해를 배상하여야 합니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ④ 회원이 서비스를 이용하는 과정에서 행한 불법 행위나 이 약관 위반행위로 인하여
                회사가 당해 회원 이외의 제3자로부터 손해배상 청구 또는 소송을 비롯한 각종 이의제기를
                받는 경우 당해 회원은 자신의 책임과 비용으로 회사를 면책 시켜야 하며, 회사가
                면책되지 못한 경우 당해 회원은 그로 인하여 회사에 발생한 모든 손해를 배상하여야
                합니다.
              </Typo>
            </Content>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h2">제 2 9 조 【 면책조항 】</Typo>
          </Wrapper>
          <Wrapper>
            <Content>
              <Typo>
                ① 회원은 회사가 제공하는 서비스에 부정확한 정보나 지연, 오류가 있을 수 있음을
                인정하며, 아울러 이러한 정보의 부정확이나 지연, 오류로 야기될 수 있는 회원의
                직ㆍ간접적 손해에 대하여 회사 또는 컨텐츠 제공자에게 어떠한 책임이나 의무를 부과시킬
                수 없습니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ② 회사는 회원이 회사의 서비스 제공으로부터 기대되는 이익을 얻지 못하였거나 서비스
                자료에 대한 취사선택 또는 이용으로 발생하는 손해 등에 대해서는 회사에 명백한
                귀책사유가 없는 한 책임을 지지 않습니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ③ 회사는 회원의 귀책사유로 인하여 발생한 서비스 이용의 장애에 대해서는 책임을 지지
                않습니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ④ 회사는 회원이 게시 또는 전송한 자료의 내용에 대해서는 책임을 지지 않습니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ⑤ 회사는 회원 상호간 및 회원과 제3자간에 서비스를 매개로 발생한 분쟁에 대해 개입할
                의무가 없으며, 이로 인한 손해를 배상할 책임이 없습니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ⑥ 회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 발생한 손해에
                대한 책임이 면제됩니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ⑦ 회원은 서비스상의 데이터 및 분석자료가 오로지 투자를 위한 정보제공을 목적으로 하는
                것이라는 점 및 따라서 투자의 책임은 전적으로 정보를 이용하는 회원에게 있다는 점을
                확인합니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ⑧ 회사는 회원이 서비스상 게재한 각종 정보, 자료, 사실의 신뢰도, 정확성 등 내용에
                대하여 책임을 지지 않습니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ⑨ 회사는 회사의 사이트에 연결되거나 제휴한 업체(타 온라인사이트)에 포함되어 있는
                내용의 유효성, 적합성, 법적 합리성, 저작권 준수 여부 등에 책임을 지지 않으며, 이로
                인한 어떠한 손해에 대하여도 책임을 지지 않습니다.
              </Typo>
            </Content>
          </Wrapper>
        </Container>

        <Container>
          <Wrapper>
            <Typo variant="h2">제 3 0 조 【 분쟁의 해결 】</Typo>
          </Wrapper>
          <Wrapper>
            <Content>
              <Typo>
                ① 회사와 회원은 서비스와 관련하여 분쟁이 발생한 경우에 이를 원만하게 해결하기 위하여
                필요한 모든 노력을 하여야 합니다.
              </Typo>
            </Content>
            <Content>
              <Typo>
                ② 제1항의 규정에도 불구하고, 동 분쟁으로 인하여 소송이 제기될 경우 동 소송은
                민사소송법상의 법원을 관할법원으로 하는 소에 의해 해결합니다.
              </Typo>
            </Content>
          </Wrapper>
        </Container>
        <Container>
          <Wrapper>
            <Typo variant="h2">부칙</Typo>
          </Wrapper>
          <Wrapper>
            <Content>
              <Typo>1. (시행일) 본 약관은 2024년 2월 5일부터 시행됩니다.</Typo>
            </Content>
            <Content>
              <Typo>
                2. 개정된 약관의 적용일자 이전 이용자 또는 회원은 개정된 이용약관의 적용을 받습니다.
              </Typo>
            </Content>
          </Wrapper>
        </Container>
      </div>
    );
  }
}

export default Terms;

const Container = styled.div`
  margin-bottom: 24px;
`;

const Wrapper = styled.div`
  margin: 12px 6px;
`;

const Content = styled.div`
  margin: 3px 6px;
`;
