import { useTranslation } from 'react-i18next';

import Tag from 'ui/tag/Tag';
import {
  Container,
  UserWrapper,
  Item,
  Itme_2,
  ItemImg,
  Name,
  NameWrapper,
  ItemWrapper,
  ButtonWrapper,
} from './ProfileModalStyles';
import Typo from 'ui/typo/Typo';
import { COLORS } from 'styles/colorTheme';
import acc from 'assets/account.svg';
import dark from 'assets/darkmode.svg';
import lang from 'assets/lang.svg';
import payment from 'assets/payment.svg';
import signout from 'assets/signout.svg';
import gear from 'assets/gear.svg';
import Button from 'ui/buttons/button/Button';
import { useNavigate } from 'react-router-dom';
import useLanguage from 'hooks/useLanguage';

export const ProfileModal = ({ name, plan, email, isVisible = false, close, sx }) => {
  const [t] = useTranslation('mainframe');
  const navigate = useNavigate();

  const { language, setLanguage } = useLanguage();

  const logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('state');
    window.location.href = '/signin';
  };

  const ko_props = {
    color: language === 'ko' ? '#fff' : '#000',
    background: language === 'ko' ? COLORS.Primary500 : COLORS.Background100,
  };

  const en_props = {
    color: language === 'en' ? '#fff' : '#000',
    background: language === 'en' ? COLORS.Primary500 : COLORS.Background100,
  };

  return (
    <Container visible={isVisible} style={{ ...sx }}>
      <UserWrapper>
        <NameWrapper>
          <Name>
            <Typo variant="h1" color={COLORS.Text400} className={'semibold'}>
              {name}
            </Typo>
          </Name>
          <Tag uppercase={false}>{plan}</Tag>
        </NameWrapper>
        <Typo variant="overline1" color={COLORS.Text300}>
          {email}
        </Typo>
      </UserWrapper>

      <Item
        onClick={() => {
          navigate('/settings');
          close();
        }}
      >
        <ItemImg src={gear} />
        <Typo variant="b2" color={COLORS.Text400}>
          {t('Account Settings')}
        </Typo>
      </Item>
      {/* <Item>
        <ItemImg src={payment} />
        <Typo>{PROFILEMODAL.ITEM2.KR}</Typo>
      </Item>
      <Item>
        <ItemImg src={dark} />
        <Typo>{PROFILEMODAL.ITEM4.KR}</Typo>
      </Item> */}
      <Itme_2>
        <ItemImg src={lang} />
        <ItemWrapper>
          <Typo variant="b2" color={COLORS.Text400}>
            {t('Language')}
          </Typo>
          <ButtonWrapper>
            <Button
              py="4px"
              px="8px"
              radius="1px"
              sx={{ ...ko_props }}
              onClick={() => setLanguage('kr')}
            >
              <Typo variant="b2">한국어</Typo>
            </Button>
            <Button
              py="4px"
              px="8px"
              radius="1px"
              sx={{ ...en_props }}
              onClick={() => setLanguage('en')}
            >
              <Typo variant="b2">English</Typo>
            </Button>
          </ButtonWrapper>
        </ItemWrapper>
      </Itme_2>
      <Item
        onClick={logout}
        style={{
          borderTop: `1px solid ${COLORS.Background300}`,
          borderRadius: '0 0 12px 12px',
        }}
      >
        <ItemImg src={signout} />
        <Typo variant="b2" color={COLORS.Text400}>
          {t('Signout')}
        </Typo>
      </Item>
    </Container>
  );
};

export default ProfileModal;
