import { Container, Icon } from './CancelStyles';
import cancel from 'assets/cancel.svg';

const Cancel = ({ onClick, sx }) => {
  return (
    <Container style={{ ...sx }} onClick={onClick}>
      <Icon src={cancel} alt="cancel" />
    </Container>
  );
};

export default Cancel;
