import { Container } from './MenuStyles';
import MenuItem from './components/MenuItem';
import { useTranslation } from 'react-i18next';

const Menu = ({ address, onClick }) => {
  const [t] = useTranslation('mainframe');

  return (
    <Container>
      <MenuItem
        type="dashboard"
        title={t('Dashboard')}
        isSelected={address === '/dashboard'}
        onClick={() => onClick('dashboard')}
      />
      <MenuItem
        type="home"
        title={t('Home')}
        isSelected={address === '/'}
        onClick={() => onClick('/')}
      />
      <MenuItem
        type="analysis"
        title={t('Analytics')}
        isSelected={address === '/analytics'}
        onClick={() => onClick('analytics')}
      />
      <MenuItem
        type="news"
        title={t('News')}
        isSelected={address === '/news'}
        onClick={() => onClick('news')}
      />
      <MenuItem
        type="settings"
        title={t('Account Settings')}
        isSelected={address === '/settings'}
        onClick={() => onClick('settings')}
      />
    </Container>
  );
};

export default Menu;
