import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div``;

export const Wrapper = styled.div`
  margin-bottom: 16px;
`;

export const CategoryContainer = styled.div`
  margin-bottom: 16px;
`;

export const CategoryWrapper = styled.span`
  /* cursor: pointer;
  color: ${COLORS.Primary500}; */
`;

export const ChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;
