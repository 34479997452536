import { Container, IconWrapper, TypoWrapper } from './MenuItemStyles';
import Typo from 'ui/typo/Typo';
import Dot from './dot/Dot';
import { useEffect, useRef, useState } from 'react';
import { COLORS } from 'styles/colorTheme';
import AnalysisIcon from './icons/AnalysisIcon';
import HomeIcon from './icons/HomeIcon';
import DashboardIcon from './icons/DashboardIcon';
import NewsIcon from './icons/NewsIcon';
import SettingsIcon from './icons/SettingsIcon';

const MenuItem = ({ title, type, onClick, isSelected }) => {
  const refEl = useRef();
  const [mouseState, setMouseState] = useState('mouseup');
  const [textColor, setTextColor] = useState(COLORS.Text300);

  useEffect(() => {
    const instance = refEl.current;

    instance.addEventListener('mousedown', (event) => {
      instance.style.backgroundColor = COLORS.Text400;
      setTextColor('#fff');
      setMouseState('mousedown');
    });

    instance.addEventListener('mouseup', (event) => {
      instance.style.backgroundColor = '';
      setTextColor(COLORS.Text300);
      setMouseState('mouseup');
    });

    instance.addEventListener('mouseleave', (event) => {
      instance.style.backgroundColor = '';
      setTextColor(COLORS.Text300);
      setMouseState('mouseup');
    });

    return () => {
      instance.removeEventListener('mousedown', (event) => {
        instance.style.backgroundColor = COLORS.Text400;
        setTextColor('#fff');
        setMouseState('mousedown');
      });
      instance.removeEventListener('mouseup', (event) => {
        instance.style.backgroundColor = '';
        setTextColor(COLORS.Text300);
        setMouseState('mouseup');
      });
      instance.removeEventListener('mouseleave', (event) => {
        instance.style.backgroundColor = '';
        setTextColor(COLORS.Text300);
        setMouseState('mouseup');
      });
    };
  }, []);

  return (
    <Container ref={refEl} onClick={onClick}>
      <IconWrapper>
        <Dot isSelected={isSelected} />
        {type === 'dashboard' ? <DashboardIcon state={mouseState} isSelected={isSelected} /> : null}
        {type === 'home' ? <HomeIcon state={mouseState} isSelected={isSelected} /> : null}
        {type === 'analysis' ? <AnalysisIcon state={mouseState} isSelected={isSelected} /> : null}
        {type === 'news' ? <NewsIcon state={mouseState} isSelected={isSelected} /> : null}
        {type === 'settings' ? <SettingsIcon state={mouseState} isSelected={isSelected} /> : null}
      </IconWrapper>
      <TypoWrapper>
        <Typo variant="h3" color={textColor} className="medium">
          {title}
        </Typo>
      </TypoWrapper>
    </Container>
  );
};

export default MenuItem;
