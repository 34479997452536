import { media } from 'media';
import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  row-gap: 40px;
  column-gap: 20px;
  padding: 16px;

  ${media.mobile`
    grid-template-columns: 1fr 1fr;
  `}
`;
