import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div`
  display: inline-flex;
  height: 42px;
  padding: 12px 16px 12px 12px;
  align-items: center;
  gap: 2px;
  box-sizing: border-box;
  cursor: pointer;

  border-radius: 30px;
  border: 1px solid ${COLORS.Background200};
  background: #fff;

  :hover {
    border: 1px solid #fff;
    box-shadow: 0px 0px 10px 1px rgba(66, 74, 87, 0.25);
  }
`;
