import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div`
  display: inline-flex;
  padding: 8px 12px;
  align-items: center;
  gap: 4px;
  font-size: 14px;

  height: 30px;

  border-radius: 30px;
  border: ${(props) =>
    props.isAdded ? `1px solid ${COLORS.Primary500}` : `1px solid ${COLORS.Background200}`};
  background: ${(props) => (props.isAdded ? `` : `#fff`)};
  cursor: pointer;

  :hover {
    background: ${(props) => (props.isAdded ? `` : `${COLORS.Background50}`)};
  }
`;
