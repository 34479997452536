import { useState } from 'react';
import { Stack, Button } from '@mui/material';
import DailyData from 'components/analyticsPage/tabPanels/components/drawers/dataDrawer/components/esgDataTab/components/dailyData/DailyData';
import TimeSeries from 'components/analyticsPage/tabPanels/components/drawers/dataDrawer/components/esgDataTab/components/timeSeries/TimeSeries';
import DrawerInput from 'components/analyticsPage/input/DrawerInput';
import { useDataQuery } from 'components/analyticsPage/tabPanels/components/drawers/dataDrawer/hooks/useDataQuery';
import { useSelector } from 'react-redux';
import { COLORS } from 'styles/colorTheme';
import { useTranslation } from 'react-i18next';

const ESGdataTab = ({ data, open }) => {
  const [t] = useTranslation('analytics');

  const [setQuery] = useDataQuery();
  const tab = useSelector((state) => state.analytics.dataQuery.target);
  const handleTabChange = (idx) => {
    if (idx === 0) {
      setQuery({ target: 'dailyData' });
    }
    if (idx === 1) {
      setQuery({ target: 'timeSeries' });
    }
  };

  return (
    <Stack spacing={3} sx={{ display: open ? '' : 'none' }}>
      <DrawerInput data={data} setQuery={setQuery} />
      <Stack direction="row">
        <Button
          disableRipple
          disableElevation
          disableFocusRipple
          variant={tab === 'dailyData' ? 'contained' : 'outlined'}
          onClick={() => handleTabChange(0)}
          sx={{
            textTransform: 'none',
            borderRadius: '12px 0 0 12px',
            boxShadow: 'unset',
            borderRight: 'none',
            width: '240px',
            ':hover': {
              borderRight: 'none',
            },
            '&.MuiButton-outlined': {
              color: COLORS.Primary500,
              border: `1px solid ${COLORS.Primary500}`,
            },
            '&.MuiButton-contained': {
              backgroundColor: COLORS.Primary500,
            },
          }}
        >
          {t('Daily data')}
        </Button>
        <Button
          disableRipple
          disableElevation
          disableFocusRipple
          variant={tab === 'timeSeries' ? 'contained' : 'outlined'}
          onClick={() => handleTabChange(1)}
          sx={{
            textTransform: 'none',
            borderRadius: '0 12px 12px 0',
            boxShadow: 'unset',
            borderLeft: 'none',
            width: '240px',
            ':hover': {
              borderLeft: 'none',
            },
            '&.MuiButton-outlined': {
              color: COLORS.Primary500,
              border: `1px solid ${COLORS.Primary500}`,
            },
            '&.MuiButton-contained': {
              backgroundColor: COLORS.Primary500,
            },
          }}
        >
          {t('Time series data')}
        </Button>
      </Stack>
      <DailyData open={tab === 'dailyData' ? '' : 'none'} />
      <TimeSeries open={tab === 'timeSeries' ? '' : 'none'} />
    </Stack>
  );
};

export default ESGdataTab;
