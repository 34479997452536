import { Container } from './ESGTopicTrendListsStyles';
import TopicList from '../../../../../ui/list/topicList/TopicList';

const ESGTopicTrendCompanyLists = ({ trends = [] }) => {
  return (
    <Container>
      {trends.map((trend, index) => (
        <TopicList topic={trend} rank={index + 1} key={index} />
      ))}
    </Container>
  );
};

export default ESGTopicTrendCompanyLists;
