import moment from 'moment/moment';
import axiosInstance from 'api/apiInterceptor';

export const getCompanyImg = async (company) => {
  return await axiosInstance.get(`/company/${company}/img`);
};

export const confirmCompanyImg = async (img_link) => {
  return await axiosInstance.get(`${img_link}`);
};

export const getCompanySummary = async (company) => {
  return await axiosInstance.get(`/company/${company}/summary/about`);
};

export const getCompanyTechnology = async (company) => {
  return await axiosInstance.get(`/company/${company}/summary/technology`);
};

export const getCompanyESG = async (company) => {
  return await axiosInstance.get(`/company/${company}/summary/esg`);
};

export const getCompanyESGI = async (company) => {
  return await axiosInstance.get(`/company/${company}/summary/esgi`);
};

export const getCompanyESGSummary = async (company, filters) => {
  let params;

  if (filters.period === 'yearly') {
    params = {
      start_year: filters.startDate.year(),
      end_year: filters.endDate.year(),
      period: filters.period,
    };
  } else if (filters.period === 'quarterly') {
    params = {
      start_year: filters.startDate.year(),
      end_year: filters.endDate.year(),
      period: filters.period,
      start_quarter: filters.startDate.quarter(),
      end_quarter: filters.endDate.quarter(),
    };
  } else if (filters.period === 'monthly') {
    params = {
      start_year: filters.startDate.year(),
      end_year: filters.endDate.year(),
      period: filters.period,
      start_month: filters.startDate.month(),
      end_month: filters.endDate.month(),
    };
  }

  if (!!filters.sentiments) {
    params['sentiments'] = filters.sentiments;
  }

  if (!!filters.transform) {
    params['transform'] = filters.transform;
  }

  return await axiosInstance.get(`/company/${company}/summary/esg_summary`, { params });
};

export const getCompanyPatentLatentValueEstimation = async (company, filters) => {
  // start_year : 쿼리 시작 년도
  // end_year : 쿼리 종료 년도
  // period: 쿼리 기간 타입
  // start_month : period == monthly일때만 사용. 시작 월
  // end_month : period ==month일때만 사용. 종료 월
  // start_quarter : period == quarterly 일때만 사용. 시작 분기
  // end_quarter: period == quarterly 일때만 사용. 종료 분기
  // value : enum("scope", "impact", "market", "trend", "green")
  // transform: enum("log", "change_ratio")
  let params;
  if (filters.period === 'yearly') {
    params = {
      period: filters.period,
      start_year: filters.startDate.year(),
      end_year: filters.endDate.year(),
    };
  } else if (filters.period === 'quarterly') {
    params = {
      period: filters.period,
      start_year: filters.startDate.year(),
      end_year: filters.endDate.year(),
      start_quarter: filters.startDate.quarter(),
      end_quarter: filters.endDate.quarter(),
    };
  } else if (filters.period === 'monthly') {
    params = {
      period: filters.period,
      start_year: filters.startDate.year(),
      end_year: filters.endDate.year(),
      start_month: filters.startDate.month(),
      end_month: filters.endDate.month(),
    };
  }

  if (!!filters.value) {
    params['value'] = filters.value;
  }

  if (!!filters.transform) {
    params['transform'] = filters.transform;
  }

  return await axiosInstance.get(`/company/${company}/summary/patent-latent-value-estimation`, {
    params,
  });
};

export const getCompanyProfileDetail = async (company) => {
  return await axiosInstance.get(`/company/${company}/profile`);
};

export const getCompanyProfileShareholderExectuvies = async (company) => {
  return await axiosInstance.get(`/company/${company}/profile/shareholder/exectuvies`);
};

export const getCompanyProfileShareholderEmployees = async (company) => {
  return await axiosInstance.get(`/company/${company}/profile/shareholder/employees`);
};

export const getCompanyProfileShareholderMajor = async (company) => {
  return await axiosInstance.get(`/company/${company}/profile/shareholder/major`);
};

export const getCompanyFinancialsFinancialData = async (company) => {
  return await axiosInstance.get(`/company/${company}/financials/financial-data`);
};

export const getCompanyFinancialsStockMarketData = async (company) => {
  return await axiosInstance.get(`/company/${company}/financial/stock-market-data`);
};

export const getCompanyFinancialsStockPrice = async (company, filters) => {
  let params = {};

  params['from'] = moment(filters.startDate).format('X');
  params['to'] = moment(filters.endDate).format('X');

  if (!!filters.additional_data) {
    params['additional_data'] = filters.additional_data;
  }

  params['adjust'] = filters.adjust;

  return await axiosInstance.get(`/company/${company}/financial/stock-price`, { params });
};

export const getCompanyFinancialsFinancialStatistics = async (company) => {
  return await axiosInstance.get(
    `/company/${company}/financials/financial-statistics?from=2020-03-03&to=2020-05-05`,
  );
};

export const getCompanyHighlights = async (company) => {
  return await axiosInstance.get(`/company/${company}/technology/highlights`);
};

export const getCompanyTechnologicalLens = async (company, filters) => {
  let params = {};

  params['from'] = filters.startDate;
  params['to'] = filters.endDate;
  params['application'] = filters.application;
  params['publication'] = filters.publication;
  params['family'] = filters.family;

  return await axiosInstance.get(`/company/${company}/technology/technological-lens`, { params });
};

export const getGreenTechnologyPortfolio = async (company, filters) => {
  let params = {};

  params['from'] = filters.startDate;
  params['to'] = filters.endDate;

  return await axiosInstance.get(`/company/${company}/technology/green_technology_portfolio`, {
    params,
  });
};

export const getTechnologyPortfoliosTreeMap = async (company, filters) => {
  let params = {};

  params['from'] = moment(filters.startDate).format('X');
  params['to'] = moment(filters.endDate).format('X');
  params['limit'] = filters.limit;

  return await axiosInstance.get(`/company/${company}/technology/technology_portfolios_tree_map`, {
    params,
  });
};

export const getTechnologyPath = async (company, filters) => {
  let params = {};

  params['from'] = filters.startDate;
  params['to'] = filters.endDate;
  params['pacet'] = filters.pacet;

  return await axiosInstance.get(`/company/${company}/technology/technology-path`, {
    params,
  });
};

export const getGreenTechnologyTargetMarketForPatent = async (company) => {
  return await axiosInstance.get(`/company/${company}/technology/target-market-for-patent`);
};

export const getGreenTechnologyPortfolioChangeByYear = async (company, filters) => {
  let params = {};

  params['start_year'] = filters.start_year;
  params['end_year'] = filters.end_year;

  return await axiosInstance.get(
    `/company/${company}/technology/technology-portfolio-change-by-year`,
    { params },
  );
};

export const getTechnologyMostRecentPatents = async (company) => {
  return await axiosInstance.get(`/company/${company}/technology/technology-most-recent-patents`);
};

export const getESGHighlights = async (company) => {
  return await axiosInstance.get(`/company/${company}/esg/highlights`);
};

export const getESGDetail = async (company) => {
  return await axiosInstance.get(`/company/${company}/esg/esg`);
};

export const getESGSummary = async (company) => {
  return await axiosInstance.get(`/company/${company}/esg/diff`);
};

export const getESGLensEnvironmental = async (company, filters) => {
  let params = {};

  params['from'] = filters.startDate;
  params['to'] = filters.endDate;

  return await axiosInstance.get(`/company/${company}/esg/esg-lens/environmental`, {
    params,
  });
};

export const getESGLensSocial = async (company, filters) => {
  let params = {};

  params['from'] = filters.startDate;
  params['to'] = filters.endDate;

  return await axiosInstance.get(`/company/${company}/esg/esg-lens/social`, { params });
};

export const getESGLensGovernance = async (company, filters) => {
  let params = {};

  params['from'] = filters.startDate;
  params['to'] = filters.endDate;

  return await axiosInstance.get(`/company/${company}/esg/esg-lens/governance`, {
    params,
  });
};

export const getESGNewsArticles = async (company, filters) => {
  let params = {};

  params['from'] = filters.startDate;
  params['to'] = filters.endDate;
  params['category'] = filters.category;

  return await axiosInstance.get(`/company/${company}/esg/news-articles`, { params });
};

export const getESGScore = async (company, filters) => {
  let params = {};

  params['date'] = filters.endDate;

  return await axiosInstance.get(`/company/${company}/esg/esg-score?date=${filters.endDate}`, {
    params,
  });
};

export const getNewsSasb = async (company, filters) => {
  const params = {
    from: filters.startDate,
    to: filters.endDate,
    category: filters.category,
    limit: filters.limit,
  };

  return await axiosInstance.get(`/company/${company}/news/sasb-tags`, {
    params,
  });
};

export const getNewsInfo = async (company, filters) => {
  const params = {
    limit: filters.limit,
    page: filters.page,
    per_page: 10,
  };

  return await axiosInstance.get(`/company/${company}/news/info-es`, {
    params,
  });
};

export const getDisclosureTopics = async (company) => {
  return axiosInstance.get(`/company/${company}/industry/disclosure_topics`);
};

export const getGeneralIssues = async (company) => {
  return axiosInstance.get(`/company/${company}/industry/general_issues`);
};

export const getESGExposure = async (company, limit) => {
  const params = {
    limit: limit,
  };

  return axiosInstance.get(`/company/${company}/esg/exposure`, {
    params,
  });
};

export const getCompanyESGRisk = async (company, filters) => {
  const params = {
    from: filters.startDate,
    to: filters.endDate,
  };

  return await axiosInstance.get(`/company/${company}/esg/risk`, {
    params,
  });
};

export const getIndustrySimilarity = async (company, limit) => {
  const params = {
    limit: limit,
  };

  return await axiosInstance.get(`/company/${company}/industry/similarity`, {
    params,
  });
};

export const getESGSimilarity = async (company, limit) => {
  const params = {
    limit: limit,
  };

  return await axiosInstance.get(`/company/${company}/esg/similarity`, {
    params,
  });
};

export const getESGTrends = async (code, limit) => {
  const params = {
    code: code,
    limit: limit,
  };

  return await axiosInstance.get(`/company/esg/trends`, {
    params,
  });
};

export const getESGMomentum = async (company) => {
  return await axiosInstance.get(`/company/${company}/esg/momentum`);
};

export const getESGExposureRatio = async (company) => {
  return await axiosInstance.get(`/company/${company}/esg/exposure-ratio`);
};

export const getCompanyMateriality = async (filters) => {
  return await axiosInstance.get(`/company/${filters.corp_code}/dashboard/materiality`, {
    params: {
      from: filters.from,
      to: filters.to,
      materiality: filters.materiality,
    },
  });
};

export const getESGRiskScore = async (companyId, filter) => {
  return axiosInstance({
    method: 'GET',
    url: `/company/${companyId}/esg-risk-chart`,
    params: { time_range: filter },
  });
};

export const getCompanyESGEvents = async (companyId, filter) => {
  return axiosInstance({
    method: 'GET',
    url: `/company/${companyId}/esg-events`,
    params: { time_range: filter },
  });
};
