import { useSelector } from 'react-redux';
import moment from 'moment';
import { createAnalytics, updateAnalytics } from 'api/analytics';
import * as amplitude from '@amplitude/analytics-browser';

export const useApply = () => {
  const { dataQuery, tabList, selectedTab } = useSelector((state) => state.analytics);
  const user = useSelector((state) => state.user);

  let tabFilters;
  if (dataQuery.target === 'dailyData')
    tabFilters = generateDailyDataFilters(dataQuery.esgData.dailyData);
  if (dataQuery.target === 'timeSeries')
    tabFilters = generateTimeSeriesFilters(dataQuery.esgData.timeSeries);
  if (dataQuery.target === 'stockData') tabFilters = generateStockDataFilters(dataQuery.stockData);

  let tabType;
  if (dataQuery.target === 'dailyData') tabType = 'data-analytics';
  if (dataQuery.target === 'timeSeries') tabType = 'data-analytics-timeseries';
  if (dataQuery.target === 'stockData') tabType = 'data-analytics-stock';

  const filters = {
    tabName: dataQuery.tabName === dataQuery.prevTabName ? undefined : dataQuery.tabName,
    userId: user.userId,
    corpCode: dataQuery.companyId,
    tabType: tabType,
    filters: tabFilters,
    prevName: dataQuery.prevTabName,
  };

  const createAnalysis = async () => {
    try {
      await createAnalytics(filters);
      amplitude.track('Analytics event', {
        description: 'Create analytics',
        data: filters,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const updateAnalysis = async () => {
    try {
      await updateAnalytics({ ...filters, tabId: tabList[selectedTab].id });
      amplitude.track('Analytics event', {
        description: 'Update analytics',
        data: filters,
      });
    } catch (e) {
      console.error(e);
    }
  };

  return [createAnalysis, updateAnalysis];
};

const generateDailyDataFilters = (query) => {
  let filters;
  let industryArr = [];
  query.filters[3].subCategory[2].check && industryArr.push(0);
  query.filters[3].subCategory[3].check && industryArr.push(1);
  const industry = industryArr.join();

  let materialityArr = [];
  query.filters[0].check && materialityArr.push(0);
  query.filters[1].check && materialityArr.push(1);
  const materiality = materialityArr.join();

  let sectorArr = [];
  query.filters[3].subCategory[0].check && sectorArr.push(0);
  query.filters[3].subCategory[1].check && sectorArr.push(1);
  const sector = sectorArr.join();

  let esgArr = [];
  query.filters[3].subCategory[4].check && esgArr.push(0);
  query.filters[3].subCategory[5].check && esgArr.push(1);
  query.filters[3].subCategory[6].check && esgArr.push(2);
  query.filters[3].subCategory[7].check && esgArr.push(3);
  query.filters[3].subCategory[8].check && esgArr.push(4);
  query.filters[3].subCategory[9].check && esgArr.push(5);
  const esg = esgArr.join();

  const lastUpdate = `${query.filters[2].check ? 1 : 0}`;

  filters = `date=${Math.floor(
    moment(query.from).format('x') / 1000,
  )}&industry=${industry}&materiality=${materiality}&esg=${esg}&last_update=${lastUpdate}&sector=${sector}`;

  return filters;
};

const generateTimeSeriesFilters = (query) => {
  let filters;
  let industryArr = [];
  query.filters[3].subCategory[2].check && industryArr.push(0);
  query.filters[3].subCategory[3].check && industryArr.push(1);
  const industry = industryArr.join();

  let materialityArr = [];
  query.filters[0].check && materialityArr.push(0);
  query.filters[1].check && materialityArr.push(1);
  const materiality = materialityArr.join();

  let sectorArr = [];
  query.filters[3].subCategory[0].check && sectorArr.push(0);
  query.filters[3].subCategory[1].check && sectorArr.push(1);
  const sector = sectorArr.join();

  let esgArr = [];
  query.filters[3].subCategory[4].check && esgArr.push(0);
  query.filters[3].subCategory[5].check && esgArr.push(1);
  query.filters[3].subCategory[6].check && esgArr.push(2);
  query.filters[3].subCategory[7].check && esgArr.push(3);
  query.filters[3].subCategory[8].check && esgArr.push(4);
  query.filters[3].subCategory[9].check && esgArr.push(5);
  const esg = esgArr.join();

  const lastUpdate = `${query.filters[2].check ? 1 : 0}`;

  filters = `from=${Math.floor(moment(query.from).format('x') / 1000)}&to=${Math.floor(
    moment(query.to).format('x') / 1000,
  )}&esg=${esg}&last_update=${lastUpdate}&materiality=${materiality}&data_type=${
    query.dataType
  }&code=${query.individualScore.esgCode}&industry=${industry}&sector=${sector}&score_materiality=${
    query.compositeScore.materiality
  }`;

  return filters;
};

const generateStockDataFilters = (query) => {
  let filters;
  let stockArr = [];
  query.filters[0].subCategory.forEach((el, i) => {
    if (el.check === true) stockArr.push(i);
  });
  const stock = stockArr.join();
  filters = `from=${Math.floor(moment(query.from).format('x') / 1000)}&to=${Math.floor(
    moment(query.to).format('x') / 1000,
  )}&stock=${stock}
  `;
  return filters;
};
