import Chip from 'ui/chips/chip/Chip';
import { ChipListWrapper } from '../ESGIndustryRiskStyles';
import { useSelector } from 'react-redux';

const IndustryList = ({ list = [], category, handler }) => {
  const language = useSelector((state) => state.user.language);

  return (
    <ChipListWrapper>
      {list.map((industry, index) => {
        if (index > 8) return;
        return (
          <Chip
            selected={category.industryIndex === index}
            onClick={() => handler.industryIndexHandler(index)}
            key={index}
          >
            {`${industry[`industryName_${language}`]}(${industry.company_count})`}
          </Chip>
        );
      })}
    </ChipListWrapper>
  );
};

export default IndustryList;
