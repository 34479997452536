import { COLORS } from 'styles/colorTheme';
import { Container, TextWrapper } from './IndustryMenuItemStyles';
import Typo from 'ui/typo/Typo';

const IndustryMenuItem = ({ selected, text, onClick, sx }) => {
  return (
    <Container style={{ ...sx }} onClick={onClick} selected={selected}>
      <TextWrapper>
        <Typo variant="b1" color={COLORS.Text400}>
          {text}
        </Typo>
      </TextWrapper>
      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none">
        <path
          className="path"
          d="M1 1L6.46967 6.46967C6.76256 6.76256 6.76256 7.23744 6.46967 7.53033L0.999999 13"
          stroke="#515562"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </Container>
  );
};

export default IndustryMenuItem;
