import { media } from 'media';
import styled from 'styled-components';

export const ViralWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;

  ${media.mobile`
    display: block;
    margin: 0px 6px;
    box-sizing: border-box;
  `}
`;

export const DimensionWrapper = styled.div`
  width: 100%;

  ${media.mobile`
    margin-bottom: 6px;
  `}
`;

export const TopicWrapper = styled.div`
  width: 100%;
`;
