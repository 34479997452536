import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import { Colors } from 'data/colors';
import { useState, useEffect } from 'react';

export const IndexAnalysis = ({ data }) => {
  let isBarChart = false;
  for (let i in data) {
    if (data[i].type === 'column') {
      isBarChart = true;
      break;
    }
  }

  const initialOptions = {
    colors: Colors,
    chart: {
      zoomType: 'x',
      height: '60%',
      lineWidth: 2,
      resetZoomButton: {
        theme: {
          fill: '#f1f3f4',
          stroke: 'none',
          r: 12,
          states: {
            hover: {
              fill: '#e4f2fd',
              style: {
                color: 'white',
              },
            },
          },
        },
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    xAxis: [
      {
        type: 'datetime',
        gridLineWidth: 0,
        crosshair: {
          width: 2,
          dashStyle: 'shortdot',
        },
        tickInterval: 24 * 3600 * 1000 * 12,
      },
    ],
    yAxis: [
      {
        title: {
          text: '',
          information: 'line',
        },
        alignTicks: false,
        tickInterval: 20,
        height: isBarChart ? '70%' : '100%',
      },
      {
        title: {
          text: '',
          information: 'stacked',
        },
        gridLineWidth: 0,
        stackLabels: {
          enabled: false,
        },
        visible: false,
        height: isBarChart ? '35%' : '100%',
        top: '35%',
      },
      {
        title: {
          text: '',
          information: 'ratio',
        },
        gridLineWidth: 0,
        top: '77%',
        height: '22%',
        visible: false,
      },
    ],
    legend: {
      align: 'center',
      verticalAlign: 'bottom',
      backgroundColor: '#ffffff',
    },
    tooltip: {
      formatter: function () {
        let content = '';
        if (this.points[0].series.yAxis.userOptions.title.information === 'line') {
          this.points.forEach((point) => {
            content += `<br><span style="color:${point.series.color}">●  </span><div>${
              point.series.name
            }: ${point.y.toFixed(3)}</div>`;
          });
          return `<div>
              <div style="font-weight: bold">
                ${moment(this.points[0].x).format('YYYY-MM-DD')}
              </div>
              <br>
              ${content}
            </div>`;
        } else {
          let stacked = [];
          this.points.map((point) => {
            if (point.series.yAxis.userOptions.title.information === 'ratio') return;
            stacked.push(point);
          });
          stacked.sort((a, b) => b.y - a.y);
          stacked.forEach((point, i) => {
            if (i > 5) return;
            content += `<br><span style="color:${point.series.color}">●   </span><div>${
              point.series.name
            }: ${point.y.toFixed(3)} (${point.percentage.toFixed(2)}%)</div>`;
          });
          return `<div>
              <div style="font-weight: bold">
                ${moment(this.points[0].x).format('YYYY-MM-DD')}
              </div><br><br>
              <div style="font-weight: bold">Top 5 list</div>
              <br>
              ${content}
            </div>`;
        }
      },
      shared: true,
      outside: true,
    },
    plotOptions: {
      column: {
        showInLegend: false,
      },
      spline: {
        yAxis: 0,
        marker: false,
        zIndex: 1,
      },
    },
    exporting: {
      enabled: false,
    },
  };

  const [options, setOptions] = useState(initialOptions);

  useEffect(() => {
    const options_ = _.cloneDeep(initialOptions);
    options_.series = data;
    setOptions(options_);
  }, [data]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      containerProps={{ style: { maxWidth: '100%', minWidth: '800px' } }}
    />
  );
};

export default IndexAnalysis;
