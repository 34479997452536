import styled from "styled-components";

export const Container = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  border-radius: 30px;
  background: ${ props => props.background };
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TextContainer = styled.div`
  box-sizing: content-box;
  display: flex;
  align-items: center;
`

export const Img = styled.img`
  width: 12px;
  height: 12px;
`