import styled from 'styled-components';

export const TitleWrapper = styled.div`
  padding: 0 24px 12px 0px;
`;

export const BodyWrapper = styled.div`
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;
