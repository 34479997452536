import { media } from 'media';
import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
  row-gap: 24px;

  ${media.mobile`
    display: block;
    margin-top: 24px;
  `}
`;
