import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import moment from 'moment';
import { GRAPH2, BENCHMARKCOLORS } from 'styles/colorTheme';
import _ from 'lodash';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const EsgGroupRiskChart = ({
  title,
  data = {
    date: [],
  },
  legendInfo = [],
}) => {
  const colors = [...GRAPH2, ...BENCHMARKCOLORS];
  const language = useSelector((state) => state.user.language);

  const initialOptions = {
    chart: {
      type: 'spline',
      zoomType: 'x',
      resetZoomButton: {
        theme: {
          fill: '#f1f3f4',
          stroke: 'none',
          r: 12,
          states: {
            hover: {
              fill: '#e4f2fd',
              style: {
                color: 'white',
              },
            },
          },
        },
      },
    },
    title: {
      text: title,
      align: 'center',
    },
    xAxis: {
      visible: true,
      type: 'datetime',
      lineWidth: 1,
      lineColor: '#C5CFDD',
      tickWidth: 0,
      labels: {
        align: 'center',
        style: {
          fontSize: '12px',
          color: '#515562',
        },
        formatter: function () {
          return moment(this.value).format('YY/MM');
        },
      },
      crosshair: {
        color: '#78899F',
        dashStyle: 'LongDash',
      },
      minPadding: 0,
      maxPadding: 0,
      tickPositioner: function () {
        let positions = [];
        let startDate = this.dataMin;
        let endDate = this.dataMax;

        while (startDate <= endDate) {
          positions.push(moment(startDate).date(15));
          startDate = moment(startDate).add(1, 'months').valueOf();
        }

        return positions;
      },
    },
    yAxis: [],
    legend: { enabled: false },
    tooltip: {
      enabled: true,
      crosshair: true,
      shared: true,
      useHTML: true,
      borderRadius: 4,
      padding: 12,
      shadow: {
        offsetX: 0,
        offsetY: 3,
        opacity: 0.03,
      },
    },
    series: [],
    plotOptions: {
      series: {
        states: {
          hover: {
            halo: null,
          },
        },
      },
      spline: {
        lineWidth: 2.0,
        marker: {
          enabled: false,
          radius: 5,
          symbol: 'circle',
          states: {
            hover: {
              enabled: true,
              lineWidth: 0,
            },
          },
        },
        animation: false,
      },
    },
    credits: {
      enabled: false,
    },
  };

  const [options, setOptions] = useState(initialOptions);

  const generateChart = () => {
    let series = [];
    let yAxis = [];
    let yAxisindex = 0;

    for (let i = 0; i < data.length; i++) {
      const element = data[i];

      let obj = {
        name: element[`dimension_name_${language}`],
        visible: legendInfo[yAxisindex]?.isVisible,
        data: element.scores,
        tooltip: {
          xDateFormat: '%Y.%m.%d',
          headerFormat: `{point.key}`,
          pointFormatter: function () {
            return `
              <div style="margin-top: 10px;display:flex;align-items: center;gap: 4px;">
                <div style="width: 8px;height: 8px;background-color: ${
                  colors[i]
                };border-radius: 50%;"></div>
                <div>${
                  element[`dimension_name_${language}`]
                } <span style="margin-left: 8px;">${this.y.toFixed(2)}</span></div>
              </div>
            `;
          },
        },
        color: colors[i],
      };

      series.push(obj);
      yAxis.push({
        title: '',
        labels: {
          style: {
            fontSize: '12px',
            color: '#646876',
          },
          formatter: function () {
            return this.value.toFixed(2);
          },
        },
        visible: true,
        opposite: true,
      });
      yAxisindex++;
    }

    const newOptions = _.cloneDeep(initialOptions);

    newOptions.yAxis = yAxis;
    newOptions.series = series;

    return newOptions;
  };

  useEffect(() => {
    setOptions(generateChart());
  }, [data, legendInfo]);

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default EsgGroupRiskChart;
