import { media } from 'media';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  ${media.mobile`
    flex-wrap: wrap;
  `}
`;

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 4px;
`;
