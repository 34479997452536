import Nodata from 'ui/errorSigns/noData/Nodata';
import EsgTopicNews from './components/esgTopicNews/EsgTopicNews';
import TopNews from './components/topNews/TopNews';
import {
  Container,
  TopicNewsWrapper,
  EsgTopicNewsWrapper,
  SmallCardWrapper,
} from './MainNewsStyles';
import NewsCardSmall from 'ui/card/newsCards/image/newsCardSmall/NewsCardSmall';
import { useTranslation } from 'react-i18next';

const MainNews = ({ mainNews, esgTopicNews, navigateToESG }) => {
  const [t] = useTranslation('noData');
  return (
    <Container>
      {mainNews.length > 0 ? (
        <TopicNewsWrapper>
          <TopNews articles={mainNews} />
          <SmallCardWrapper>
            {mainNews.slice(10).map((article, i) => (
              <NewsCardSmall key={i} article={article} />
            ))}
          </SmallCardWrapper>
        </TopicNewsWrapper>
      ) : (
        <Nodata desc={t('No ESG news.')} />
      )}
      <EsgTopicNewsWrapper>
        <EsgTopicNews topics={esgTopicNews} navigateToESG={navigateToESG} />
      </EsgTopicNewsWrapper>
    </Container>
  );
};

export default MainNews;
