import LoadingData from 'ui/preloaders/loadingData/LoadingData';
import UnavailableData from 'ui/errorSigns/unavailableData/UnavailableData';

export const LoadingCheck = ({ children, isLoading, isNoResponse }) => {
  return (
    <>
      {isLoading && !isNoResponse && <LoadingData isLoading={isLoading} />}
      {isNoResponse && <UnavailableData noData={isNoResponse} />}
      {!isLoading && !isNoResponse && children}
    </>
  );
};

export default LoadingCheck;
