import { Component } from 'react';
import { Typography, Stack, Box } from '@mui/material';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

class UnavailableData extends Component {
  render() {
    const { noData } = this.props;
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '600px' }}
      >
        {noData && (
          <Stack direction="row" alignItems="center">
            <WarningRoundedIcon sx={{ color: '#FF3A67', fontSize: 70 }}/>
            <Stack direction="column" sx={{ pl: 3 }} spacing={1}>
              <Typography sx={{ fontSize: '15px', fontWeight: '700', color: '#333333' }}>
                DATA UNAVAILABLE
              </Typography>
              <Typography sx={{ fontSize: '15px', fontWeight: '400', color: '#747474', width: '550px' }}>
                This could be an server connection issue or insufficient data for generating report
                in the given time range. Please check back later.
              </Typography>
            </Stack>
          </Stack>
        )}
      </Box>
    );
  }
}

export default UnavailableData;
