import { saveIndexQuery } from 'services/analytics/Action';
import { useDispatch, useSelector } from 'react-redux';

export const useIndexQuery = () => {

  const dispatch = useDispatch();
  const indexQuery = useSelector((state) => state.analytics.indexQuery);

  const setQuery = (obj) => {
    dispatch(
      saveIndexQuery({
        ...indexQuery,
        ...obj,
      })
    )
  }

  return [setQuery];
};