import styled from 'styled-components';
import { media } from 'media';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${media.mobile`
    margin-bottom: 12px;
  `}
`;

export const CompanyListWrapper = styled.div``;
