import {Stack, Typography, Button} from '@mui/material';

const dotStyle ={
  height: '8px',
  width: '8px',
  backgroundColor: '#bbb',
  borderRadius: '50%',
  display: 'inline-block',
  cursor: 'pointer',
};

const Step = ({
    tutorialStep, 
    totalSteps, 
    setTutorialStep, 
    prev, 
    next,
    title,
    subTitle,
    img,
    handleClose,
  }) => {

  return (
    <>
      <Typography fontWeight='400' fontSize='36px' color='#007BC7'>
        {title}
      </Typography>
      <Typography fontWeight='400' fontSize='20px' color='#747474'>
        {subTitle}
      </Typography>
      <Stack alignItems='center' spacing={8}>
        <Stack sx={{'@media(max-width: 768px)':{display: 'none'}, py: '20px'}}>
          <img src={img} width='100%' />
        </Stack>
        <Stack direction='row' spacing={2}>
          {new Array(totalSteps).fill('').map((el, i) => <span style={i===tutorialStep ? {...dotStyle, backgroundColor: '#007BC7'}:dotStyle} key={i} onClick={() => setTutorialStep(i)}/>)}
        </Stack>
      </Stack>
      <Stack direction='row' spacing={2} sx={{position: 'fixed', bottom: '20px', right: '20px'}}>
        <Button onClick={prev}>Previous</Button>
        {totalSteps -1 === tutorialStep ? 
          <Button onClick={handleClose} variant='contained'>Done</Button> :
          <Button onClick={next} variant='contained'>Next</Button>
        }
      </Stack>
    </>
  )
};

export default Step;