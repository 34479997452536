import { media } from 'media';
import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;

  ${media.mobile`
    display: none;
  `}
`;

export const MobileContainer = styled.div`
  display: none;
  margin-top: 10px;

  ${media.mobile`
    display: flex;
    gap: 10px;
  `}
`;

export const SearchBarWrapper = styled.div`
  position: absolute;
  left: ${window.innerWidth};
  top: 0px;
  width: 680px;
`;

export const HeaderMenuWrapper = styled.div`
  position: absolute;
  right: 0px;

  display: flex;
  gap: 12px;
`;

export const HambergerBtn = styled.div`
  display: none;

  ${media.laptop`
    display: inline-block;
    margin: auto;
  `}
`;

export const InputContainer = styled.div`
  max-width: ${(props) => props.maxWidth};
  box-sizing: border-box;
  width: 100%;
  min-height: 48px;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 30px;
  border: 1px solid ${COLORS.Primary500};
  box-shadow: 0px 4px 4px 0px rgba(218, 226, 236, 0.5);
  background: #fff;

  transition: box-shadow ease 0.5s, border-radius ease 0.5s;
  z-index: 2;

  :hover {
    box-shadow: 0px 4px 4px 0px #dae2ec;
  }
`;

export const InputWrapper = styled.div`
  padding: 4px 20px;
  height: 40px;
  display: flex;
  gap: 10px;
  align-items: center;

  &.active {
    padding: 5px 21px;
  }
`;

export const Input = styled.input`
  all: unset;
  width: 90%;

  &::placeholder {
    font-size: 16px;
    font-weight: 400;
    color: rgba(140, 144, 159, 1);
  }
`;
