import { useSingleIndexQuery } from 'components/analyticsPage/tabPanels/components/drawers/indexDrawer/components/components/singleIndex/hooks/useSingleIndexQuery';
import { useSelector } from 'react-redux';

export const useCompositeScore = () => {
  const query = useSelector((state) => state.analytics.indexQuery.index.singleIndex);
  const [setQuery] = useSingleIndexQuery();

  let compositeScore = [];
  if (query.compositeScore.materiality.length > 0) {
    compositeScore = query.compositeScore.materiality.split(',').map(Number);
  }

  const setCompositeScore = (event) => {
    let str = event.target.value.join();
    if (event.target.value.length === 0) str = '';

    let typeStr = '0';
    if (query.individualScore.esgCode !== '') typeStr = '0,1';

    setQuery({
      compositeScore: {
        materiality: str,
      },
      dataType: typeStr,
    });
  };

  return [compositeScore, setCompositeScore];
};
