import CardTitle from 'ui/title/Title';
import Card from 'ui/card/defaultCard/Card';
import { CompanyListsWrapper } from './HomeNewsStyles';
import TopNews from 'components/newsPage/components/mainNews/components/topNews/TopNews';
import Preloaer from 'ui/preloaders/componentPreloader/ComponentPreloader';
import { useTranslation } from 'react-i18next';
import Nodata from 'ui/errorSigns/noData/Nodata';
import { useNavigate } from 'react-router-dom';

const HomeNews = ({ data, isLoading, sx }) => {
  const [t] = useTranslation('news');
  const [dT] = useTranslation('noData');
  const navigate = useNavigate();

  return (
    <Card sx={{ ...sx }}>
      <CardTitle
        title={t('Main News')}
        desc={t('Highlighting major ESG news from the last day.')}
        more={t('More News')}
        onClick={() => navigate('/news')}
      />
      {isLoading ? (
        <Preloaer width="1174px" height="710.5px" />
      ) : (
        <>
          {data?.length > 0 ? (
            <CompanyListsWrapper>
              <TopNews articles={data} />
            </CompanyListsWrapper>
          ) : (
            <Nodata desc={dT('No ESG news.')} sx={{ height: '200px' }} />
          )}
        </>
      )}
    </Card>
  );
};

export default HomeNews;
