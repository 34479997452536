export const SAVE_ANALYTICS_TAB_LIST = 'SAVE_ANALYTICS_TAB_LIST';
export const SAVE_SELECTED_ANALYTICS_TAB = 'SAVE_SELECTED_ANALYTICS_TAB';
export const SAVE_DATA_QUERY = 'SAVE_DATA_QUERY';
export const SAVE_INDEX_QUERY = 'SAVE_INDEX_QUERY';
export const SVAE_EVENT_QUERY = 'SAVE_EVENT_QUERY';
export const RESET_DATA_QUERY = 'RESET_DATA_QUERY';
export const RESET_DATA_QUERY_FILTERS = 'RESET_DATA_QUERY_FILTERS';

export const RESET_INDEX_QUERY = 'RESET_INDEX_QUERY';
export const RESET_INDEX_QUERY_FILTERS = 'RESET_INDEX_QUERY_FILTERS';

export const RESET_EVENT_QUERY = 'RESET_EVENT_QUERY';
export const RESET_EVENT_QUERY_FILTERS = 'RESET_EVENT_QUERY_FILTERS';

export const SAVE_INPUT_INFO = 'SAVE_INPUT_INFO';