import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 32px;
  border-radius: 10px;
  cursor: pointer;

  :hover {
    background-color: ${COLORS.Background100_70};
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const TypoWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
