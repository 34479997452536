import { Container } from "./ProfileIconStyles"

const ProfileIcon = ({ onClick }) => {
  return (
    <Container onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
        <path d="M4.09998 21V20C4.09998 16.6863 6.85342 14 10.25 14H14.35C17.7465 14 20.5 16.6863 20.5 20V21" stroke="#333743" strokeWidth="1.5" strokeLinecap="round"/>
        <path d="M12.3 11C10.0356 11 8.19995 9.20914 8.19995 7C8.19995 4.79086 10.0356 3 12.3 3C14.5643 3 16.4 4.79086 16.4 7C16.4 9.20914 14.5643 11 12.3 11Z" stroke="#333743" strokeWidth="1.5" strokeLinecap="round"/>
      </svg>
    </Container>
  )
}

export default ProfileIcon