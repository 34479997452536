export const esgDataColumns = [
  { category: 'Data-driven Materiality', check: true },
  { category: 'SASB Materiality', check: true },
  { category: 'Last Updated Date', check: false },
  {
    category: 'ESG Data',
    check: false,
    subCategory: [
      { category: 'Sector Rank', check: false },
      { category: 'Sector Percentile', check: false },
      { category: 'Industry Rank', check: true },
      { category: 'Industry Percentile', check: true },
      { category: 'ESG Pulse', check: true },
      { category: 'ESG Score', check: true },
      { category: 'ESG Exposure', check: true },
      { category: 'ESG Momentum', check: true },
      { category: 'ESG Score Change', check: false },
      { category: 'ESG Score Change Ratio', check: false },
    ],
  },
];

export const multiIndexColumns = [
  { category: 'Pulse', check: true },
  { category: 'Score', check: true },
  { category: 'Momentum', check: true },
  { category: 'ESG Exposure', check: false },
];

export const singleIndexColumns = [
  { category: 'Pulse', check: true },
  { category: 'Score', check: false },
  { category: 'Momentum', check: false },
  { category: 'ESG Exposure', check: false },
];

export const stockDataColums = [
  {
    category: 'Stock Market Data',
    check: false,
    subCategory: [
      { category: 'Price', check: true },
      { category: 'Change', check: false },
      { category: '% Change', check: true },
      { category: 'Day Range', check: false },
      { category: 'Market Cap', check: false },
      { category: 'Avg. Volume', check: true },
      { category: '1 Month Performance', check: false },
      { category: '3 Month Performance', check: false },
      { category: '6 Month Performance', check: false },
      { category: '1 Year Performance', check: false },
      { category: 'YTD Performance', check: false },
    ],
  },
];
