import CardTitle from 'ui/title/Title';
import Card from 'ui/card/defaultCard/Card';
import { Container, MenuWrapper, ContentsWrapper } from './ESGIndustryRiskStyles';
import MenuItem from 'ui/listItem/industryMenu/IndustryMenuItem';
import industryGicmap from 'data/industryGicmap.json';
import Preloaer from 'ui/preloaders/componentPreloader/ComponentPreloader';
import IndustryList from './components/IndustryList';
import CompanyList from './components/CompanyList';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const ESGIndustryRisk = ({
  data = [],
  category,
  handler,
  addFollowing,
  cancelFollowing,
  followingList,
  isLoading,
  sx,
}) => {
  const [t] = useTranslation('home');
  const language = useSelector((state) => state.user.language);

  return (
    <Card sx={sx}>
      <Container>
        <CardTitle
          title={t('ESG Risk Industry Rank')}
          desc={t(
            'Explore companies by industry-specific ESG risk factors according to the SASB Standards.',
          )}
          // more={t('More Industry')}
        />

        <IndustryList list={industryGicmap} category={category} handler={handler} />

        <ContentsWrapper>
          <MenuWrapper>
            <MenuItem
              selected={category.gicIndex === 'esg'}
              text={t('Company with high ESG risk')}
              onClick={() => handler.gicIndexHandler('esg')}
            />
            {industryGicmap[category.industryIndex]?.GIC?.map((el, i) => (
              <MenuItem
                selected={category.gicIndex === i}
                key={i}
                text={el[`GIC_Name_${language}`]}
                onClick={() => handler.gicIndexHandler(i)}
              />
            ))}
          </MenuWrapper>

          {isLoading ? (
            <Preloaer width="560px" height="640px" />
          ) : (
            <CompanyList
              data={data}
              addFollowing={addFollowing}
              cancelFollowing={cancelFollowing}
              followingList={followingList}
            />
          )}
        </ContentsWrapper>
      </Container>
    </Card>
  );
};

export default ESGIndustryRisk;
