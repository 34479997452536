import { COLORS } from 'styles/colorTheme';
import { Container, TextContainer, Img } from './Score2Styles';
import Typo from 'ui/typo/Typo';
import up from 'assets/redUpNarrowArrow.svg';
import down from 'assets/blueDownNarrowArrow.svg';

const Score2 = ({ change, type, sx }) => {
  if (change === undefined) return;

  const data_ = change;

  let background = COLORS.Background100;
  let color = COLORS.Text400;

  if (data_ > 0) {
    background = COLORS.Red100;
    color = COLORS.Red200;
  }

  if (data_ < 0) {
    background = COLORS.Blue100;
    color = COLORS.Blue200;
  }

  return (
    <Container style={sx}>
      {data_ < 0 ? <Img src={down} alt="down" /> : null}
      {data_ > 0 ? <Img src={up} alt="up" /> : null}
      {data_ === 0 ? <Img alt="zero" /> : null}

      <TextContainer>
        <Typo variant="caption1" color={color}>
          {Math.abs(change).toFixed(2)}
          {type === 'exposure' ? '%p' : ''}
        </Typo>
      </TextContainer>
    </Container>
  );
};

export default Score2;
