import Check from 'assets/check_green.svg';
import Data from 'assets/data.svg';
import Event from 'assets/event.svg';
import TabItem from 'components/analyticsPage/tabs/components/tabItems/components/TabItem';
import { useTabs } from 'components/analyticsPage/tabs/components/tabItems/hooks/useTabs';
import { useSelector } from 'react-redux';
import { COLORS } from 'styles/colorTheme';

const TabItems = ({ handleModal }) => {
  const { tabList } = useSelector((state) => state.analytics);
  const [selectTab] = useTabs();

  const getImageSrc = (type) => {
    if (
      type === 'data-analytics' ||
      type === 'data-analytics-timeseries' ||
      type === 'data-analytics-stock'
    )
      return Data;
    if (type === 'index-analytics' || type === 'index-analytics-multi') return Check;
    if (type === 'event-analyrics') return Event;
  };

  return (
    <>
      {tabList?.map((el, i) => (
        <TabItem
          icon={<img src={getImageSrc(el.tab_type)} />}
          iconPosition="start"
          label={
            <div
              style={{
                color: 'black',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                display: 'inline-block',
                maxWidth: '140px',
              }}
            >
              {el?.tab_name}
            </div>
          }
          key={i}
          idx={i}
          onClick={() => selectTab(i)}
          openModal={() => handleModal(i)}
        />
      ))}
    </>
  );
};

export default TabItems;
