export const esgCodes = 
  [
    {
      "id": 1,
      "general_issue_code": 110,
      "general_issue_name": "GHG Emissions",
      "general_issue_desc": null,
      "tax_code": "ESG_E001"
    },
    {
      "id": 2,
      "general_issue_code": 120,
      "general_issue_name": "Air Quality",
      "general_issue_desc": null,
      "tax_code": "ESG_E002"
    },
    {
      "id": 3,
      "general_issue_code": 130,
      "general_issue_name": "Energy Management",
      "general_issue_desc": null,
      "tax_code": "ESG_E003"
    },
    {
      "id": 4,
      "general_issue_code": 140,
      "general_issue_name": "Water & Wastewater Management",
      "general_issue_desc": null,
      "tax_code": "ESG_E004"
    },
    {
      "id": 5,
      "general_issue_code": 150,
      "general_issue_name": "Waste & Hazardous Materials Management",
      "general_issue_desc": null,
      "tax_code": "ESG_E005"
    },
    {
      "id": 6,
      "general_issue_code": 160,
      "general_issue_name": "Ecological Impacts",
      "general_issue_desc": null,
      "tax_code": "ESG_E006"
    },
    {
      "id": 7,
      "general_issue_code": 210,
      "general_issue_name": "Human Rights & Community Relations",
      "general_issue_desc": null,
      "tax_code": "ESG_S001"
    },
    {
      "id": 8,
      "general_issue_code": 220,
      "general_issue_name": "Customer Privacy",
      "general_issue_desc": null,
      "tax_code": "ESG_S002"
    },
    {
      "id": 9,
      "general_issue_code": 230,
      "general_issue_name": "Data Security",
      "general_issue_desc": null,
      "tax_code": "ESG_S003"
    },
    {
      "id": 10,
      "general_issue_code": 240,
      "general_issue_name": "Access & Affordability",
      "general_issue_desc": null,
      "tax_code": "ESG_S004"
    },
    {
      "id": 11,
      "general_issue_code": 250,
      "general_issue_name": "Product Quality & Safety",
      "general_issue_desc": null,
      "tax_code": "ESG_S005"
    },
    {
      "id": 12,
      "general_issue_code": 260,
      "general_issue_name": "Customer Welfare",
      "general_issue_desc": null,
      "tax_code": "ESG_S006"
    },
    {
      "id": 13,
      "general_issue_code": 270,
      "general_issue_name": "Selling Practices & Product Labeling",
      "general_issue_desc": null,
      "tax_code": "ESG_S007"
    },
    {
      "id": 14,
      "general_issue_code": 310,
      "general_issue_name": "Labor Practices",
      "general_issue_desc": null,
      "tax_code": "ESG_S008"
    },
    {
      "id": 15,
      "general_issue_code": 320,
      "general_issue_name": "Employee Health & Safety",
      "general_issue_desc": null,
      "tax_code": "ESG_S009"
    },
    {
      "id": 16,
      "general_issue_code": 330,
      "general_issue_name": "Employee Engagement, Diversity & Inclusion",
      "general_issue_desc": null,
      "tax_code": "ESG_S010"
    },
    {
      "id": 17,
      "general_issue_code": 410,
      "general_issue_name": "Product Design & Lifecycle Management",
      "general_issue_desc": null,
      "tax_code": "ESG_S013"
    },
    {
      "id": 18,
      "general_issue_code": 420,
      "general_issue_name": "Business Model Resilience",
      "general_issue_desc": null,
      "tax_code": "ESG_G006"
    },
    {
      "id": 19,
      "general_issue_code": 430,
      "general_issue_name": "Supply Chain Management",
      "general_issue_desc": null,
      "tax_code": "ESG_S011"
    },
    {
      "id": 20,
      "general_issue_code": 440,
      "general_issue_name": "Materials Sourcing & Efficiency",
      "general_issue_desc": null,
      "tax_code": "ESG_S012"
    },
    {
      "id": 21,
      "general_issue_code": 450,
      "general_issue_name": "Physical Impacts of Climate Change",
      "general_issue_desc": null,
      "tax_code": "ESG_E007"
    },
    {
      "id": 22,
      "general_issue_code": 510,
      "general_issue_name": "Business Ethics",
      "general_issue_desc": null,
      "tax_code": "ESG_G001"
    },
    {
      "id": 23,
      "general_issue_code": 520,
      "general_issue_name": "Competitive Behavior",
      "general_issue_desc": null,
      "tax_code": "ESG_G002"
    },
    {
      "id": 24,
      "general_issue_code": 530,
      "general_issue_name": "Management of the Legal & Regulatory Environment",
      "general_issue_desc": null,
      "tax_code": "ESG_G003"
    },
    {
      "id": 25,
      "general_issue_code": 540,
      "general_issue_name": "Critical Incident Risk Management",
      "general_issue_desc": null,
      "tax_code": "ESG_G004"
    },
    {
      "id": 26,
      "general_issue_code": 550,
      "general_issue_name": "Systemic Risk Management",
      "general_issue_desc": null,
      "tax_code": "ESG_G005"
    }
  ]