import { getESGMarketTrend } from 'api/home';
import { useState, useEffect } from 'react';
import * as amplitude from '@amplitude/analytics-browser';

const useEsgMarketTrend = () => {
  const [esgMarketData, setEsgMarketData] = useState([]);
  const [marketTrendCategory, setMarketTrendCategory] = useState('neg_exposure_raw');
  const [isLoading, setIsLoading] = useState(false);

  const fetchESGMarketTrendDate = async (marketTrendCategory) => {
    setIsLoading(true);
    const esgMarket = await getESGMarketTrend(marketTrendCategory);
    if (esgMarket.status === 200) setEsgMarketData(esgMarket.data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchESGMarketTrendDate(marketTrendCategory);
  }, [marketTrendCategory]);

  const handleRiskChange = (category) => {
    setMarketTrendCategory(category);
    let value;
    if (category === 'neg_exposure_raw') value = 'exposure';
    if (category === 'nfr_raw_pulse') value = 'pulse';
    if (category === 'nfr_raw_score') value = 'score';

    amplitude.track('home_risk_insights_view', { category: value });
  };

  return [esgMarketData, marketTrendCategory, handleRiskChange, isLoading];
};

export default useEsgMarketTrend;
