import { useState } from 'react';
import { getHotSearch } from 'api/search';

export const useHotSearch = () => {
  const [inputAnchorEl, setInputAnchorEl] = useState(null);
  const [hotsearchCompanyList, setHotsearchCompanyList] = useState([]);

  const initHotsearch = (e) => setInputAnchorEl(e.currentTarget);
  const finHotSerach = () => setInputAnchorEl(null);
  const getCompanyList = async (keyword) => {
    if (keyword.length > 0) {
      try {
        const companies = await getHotSearch(keyword);
        if (companies?.status === 200) {
          setHotsearchCompanyList(companies?.data);
        }
      } catch (e) {
        console.log('Error', e);
      }
    }
  };

  return [
    hotsearchCompanyList, 
    inputAnchorEl, 
    initHotsearch,
    getCompanyList,
    finHotSerach,
  ];
}