import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border-radius: 4px;
  padding: 6px 8px;
  gap: 6px;
`;

export const Svg = styled.svg`
  fill: ${COLORS.Background500};
  cursor: pointer;

  &.thumbsDown {
    transform: scale(1, -1);

    :active {
      fill: ${COLORS.Primary500};
      transform: rotate(10deg) scale(1, -1);
    }
  }

  &.thumbsUp {
    &:active {
      fill: ${COLORS.Primary500};
      transform: rotate(10deg);
    }
  }

  &:hover {
    scale: 1.1;
  }

  transition: all 0.2s;
`;
