import { media } from 'media';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;
`;

export const TitleWrapper = styled.div`
  margin-bottom: 16px;
`;

export const DescriptionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 12px;

  ${media.mobile`
    display: block;
  `}
`;
