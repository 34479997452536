import React from 'react';
import { useTranslation } from 'react-i18next';
import lang from 'assets/lang.svg';

import { LanguageContainer, LanguageButtons } from './LanguageSettings.styles';
import { COLORS } from 'styles/colorTheme';
import Typo from 'ui/typo/Typo';
import Button from 'ui/buttons/button/Button';
import useLanguage from 'hooks/useLanguage';

function LanguageSettings() {
  const [t] = useTranslation('mainframe');
  const { language, setLanguage } = useLanguage();

  const ko_props = {
    color: language === 'ko' ? '#fff' : '#000',
    background: language === 'ko' ? COLORS.Primary500 : COLORS.Background100,
  };

  const en_props = {
    color: language === 'en' ? '#fff' : '#000',
    background: language === 'en' ? COLORS.Primary500 : COLORS.Background100,
  };

  return (
    <LanguageContainer>
      <img src={lang} alt="lang" />
      <Typo>{t('Language')}</Typo>
      <LanguageButtons>
        <Button
          py="4px"
          px="8px"
          radius="1px"
          sx={{ ...ko_props }}
          onClick={() => setLanguage('kr')}
        >
          한국어
        </Button>
        <Button
          py="4px"
          px="8px"
          radius="1px"
          sx={{ ...en_props }}
          onClick={() => setLanguage('en')}
        >
          English
        </Button>
      </LanguageButtons>
    </LanguageContainer>
  );
}

export default LanguageSettings;
