import ListItem from 'ui/listItem/default/ListItem';
import {
  Container,
  PopupWrapper,
  CompanyListWrapper,
  CompanyNameWrapper,
} from './BenchmarkCompanyListStyles';
import SecondarySearchBar from 'ui/searchBar/secondarySearchBar/SecondarySearchBar';
import Popup from 'ui/popup/container/PopupContainer';
import { useEffect, useRef, useState } from 'react';
import Typo from 'ui/typo/Typo';
import { getHotSearch } from 'api/search';
import { getCompanyEsgProfile } from 'api/companyDetail';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { COLORS } from 'styles/colorTheme';
import * as amplitude from '@amplitude/analytics-browser';

const BenchmarkCompanyList = ({ companyList = [], addCompanyList, removeCompanyList, sx }) => {
  const [inputText, setInputText] = useState('');
  const [candidateList, setCandidateList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const language = useSelector((state) => state.user.language);
  const [t] = useTranslation('companyDetails');
  const [commonT] = useTranslation('common');

  const handleChange = async (event) => {
    const query = event.target.value;
    setInputText(query);
    if (query.length === 0) {
      clearStates();
      return;
    }
    getCandidateCompanies(query);
  };

  const getCandidateCompanies = async (q) => {
    const res = await getHotSearch(q);
    if (!isOpen) setIsOpen(true);
    setCandidateList(res.data);
  };

  const clearStates = () => {
    setInputText('');
    setCandidateList([]);
    setIsOpen(false);
  };

  const addCompany = async (company) => {
    const res = await getCompanyEsgProfile(company.corp_code);
    if (res.data) {
      addCompanyList(res.data);
      amplitude.track('companydetail_benchmark_company_add', { category: 'search' });
    }
    clearStates();
  };

  const ref = useRef({ isHovering: false });
  const idxRef = useRef(undefined);
  const containerRef = useRef();
  const inputRef = useRef();

  const onKeyDown = (e) => {
    if (e.code === 'Enter') {
      if (idxRef.current != undefined) {
        addCompany(candidateList[idxRef.current]);
      }
      return setIsOpen(false);
    }

    if (e.code === 'Escape') {
      e.target.value = '';
      return handleChange(e);
    }

    if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (e.nativeEvent.isComposing) return;
      const list = containerRef.current.querySelectorAll('.search-results');
      if (!list || list.length <= 0) return;

      if (idxRef.current === undefined) {
        list[0].classList.add('focus');
        changeInputValue(list[0]);
        idxRef.current = 0;
        return;
      }

      if (idxRef.current >= list.length - 1) {
        list[list.length - 1].classList.remove('focus');
        list[0].classList.add('focus');
        changeInputValue(list[0]);
        idxRef.current = 0;
        return;
      }

      list[idxRef.current].classList.remove('focus');
      list[idxRef.current + 1].classList.add('focus');
      changeInputValue(list[idxRef.current + 1]);
      idxRef.current = idxRef.current + 1;
    }

    if (e.key === 'ArrowUp') {
      e.preventDefault();
      const list = containerRef.current.querySelectorAll('.search-results');
      if (!list || list.length <= 0) return;

      if (idxRef.current === undefined) {
        list[list.length - 1].classList.add('focus');
        changeInputValue(list[list.length - 1]);
        idxRef.current = list.length - 1;
        return;
      }

      if (idxRef.current <= 0) {
        list[0].classList.remove('focus');
        list[list.length - 1].classList.add('focus');
        changeInputValue(list[list.length - 1]);
        idxRef.current = list.length - 1;
        return;
      }

      list[idxRef.current].classList.remove('focus');
      list[idxRef.current - 1].classList.add('focus');
      changeInputValue(list[idxRef.current - 1]);
      idxRef.current = idxRef.current - 1;
    }
  };

  useEffect(() => {
    if (idxRef.current !== undefined) {
      const list = containerRef.current.querySelector('.focus');
      if (!list) return (idxRef.current = undefined);
      list.classList.remove('focus');
    }
    idxRef.current = undefined;
  }, [candidateList]);

  const changeInputValue = (element) => {
    if (!element) return;
    const text = element.querySelector('.company-name').textContent;
    inputRef.current.value = text;
  };

  useEffect(() => {
    const instance = ref.current;

    instance.addEventListener('mouseover', () => {
      instance.isHovering = true;
    });
    instance.addEventListener('mouseleave', () => {
      instance.isHovering = false;
    });

    return () => {
      instance.removeEventListener('mouseover', () => {
        instance.isHovering = true;
      });
      instance.removeEventListener('mouseleave', () => {
        instance.isHovering = false;
      });
    };
  }, []);

  const onFocus = () => {
    if (!containerRef.current) return;
    setIsOpen(true);
    amplitude.track('companydetail_benchmark_search_start');
  };

  const onBlur = (e) => {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      if (idxRef.current != undefined) {
        const text = ref.current.querySelector('.focus .company-name').textContent;
        setInputText(text);
      }
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (!containerRef.current) return;
    const instance = inputRef.current;
    if (isOpen) {
      document.addEventListener('click', onBlur);
      instance.removeEventListener('focus', onFocus);
    } else {
      instance.addEventListener('focus', onFocus);
      document.removeEventListener('click', onBlur);
    }
    return () => {
      document.removeEventListener('click', onBlur);
      instance.removeEventListener('focus', onFocus);
    };
  }, [isOpen]);

  const splitHighlightString = (fullString, highlightString) => {
    if (!fullString) return;

    let highlight = '';
    let leftOver = fullString;
    const _fullString = fullString.toLowerCase();
    const _highlightString = highlightString.toLowerCase();

    for (let i = 0; i < highlightString.length; i++) {
      if (_fullString[i] === _highlightString[i]) {
        highlight += fullString[i];
        leftOver = leftOver.substring(1);
      } else break;
    }

    return (
      <Typo variant="b2">
        <span style={{ color: COLORS.Primary500 }}>{highlight}</span>
        <span>{leftOver}</span>
      </Typo>
    );
  };

  return (
    <Container ref={containerRef} style={{ ...sx }}>
      <SecondarySearchBar
        ref={inputRef}
        value={inputText}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        placeholder={t('Search company to benchmark')}
      />

      <PopupWrapper ref={ref}>
        <Popup isOpen={isOpen} width={'600px'} sx={{ top: '0px' }}>
          {candidateList.slice(0, 10).map((company, i) => (
            <CompanyListWrapper
              className="search-results"
              id={i}
              isLast={candidateList.slice(0, 10).length - 1 === i}
              onClick={() => addCompany(company)}
            >
              {splitHighlightString(company[`stock_code`], inputText)}
              <CompanyNameWrapper>
                <div className="company-name">
                  {splitHighlightString(company[`corp_name_${language}`], inputText)}
                </div>
                <Typo variant="b2">{company[`stock_market_${language}`]}</Typo>
              </CompanyNameWrapper>
            </CompanyListWrapper>
          ))}
          {candidateList.length === 0 ? (
            <div style={{ padding: '12px' }}>
              <Typo variant="b2">{commonT('No results')}</Typo>
            </div>
          ) : (
            ''
          )}
        </Popup>
      </PopupWrapper>

      {companyList.map((company, i) => (
        <ListItem
          key={i}
          tagColor={company.tagColor}
          title={company.title}
          score={company.score}
          scoreChange={company.scoreChange}
          onClick={() => {}}
          navigator={() => (window.location.href = `/company/${company.corpCode}`)}
          handleCancel={() => removeCompanyList(company, i)}
          displayCancel={i === 0 ? false : true}
          sx={i === 0 ? { paddingRight: '80px' } : {}}
        />
      ))}
    </Container>
  );
};

export default BenchmarkCompanyList;
