import { Container, MenuItemContainer } from './MobileMenu.styles';
import { useTranslation } from 'react-i18next';
import AnalysisIcon from './components/icons/AnalysisIcon';
import HomeIcon from './components/icons/HomeIcon';
import DashboardIcon from './components/icons/DashboardIcon';
import NewsIcon from './components/icons/NewsIcon';
import SettingsIcon from './components/icons/SettingsIcon';
import Typo from 'ui/typo/Typo';
import { COLORS } from 'styles/colorTheme';
import { useLocation, useNavigate } from 'react-router-dom';
import { Divider } from '@mui/material';
import signout from 'assets/signout.svg';

const MenuItem = ({ type, title, onClick, isSelected, sx }) => {
  return (
    <MenuItemContainer onClick={onClick} isSelected={isSelected} style={sx}>
      {type === 'dashboard' ? <DashboardIcon /> : null}
      {type === 'home' ? <HomeIcon /> : null}
      {type === 'analysis' ? <AnalysisIcon /> : null}
      {type === 'news' ? <NewsIcon /> : null}
      {type === 'settings' ? <SettingsIcon /> : null}
      {type === 'signOut' ? <img src={signout} /> : null}
      <Typo variant="h3" color={COLORS.Text300} className="medium">
        {title}
      </Typo>
    </MenuItemContainer>
  );
};

const MobileMenu = () => {
  const [t] = useTranslation('mainframe');
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleSignout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('state');
    window.location.href = '/signin';
  };

  return (
    <Container>
      <MenuItem
        type="dashboard"
        title={t('Dashboard')}
        isSelected={pathname === '/dashboard'}
        onClick={() => navigate('/dashboard')}
      />
      <MenuItem
        type="home"
        title={t('Home')}
        isSelected={pathname === '/'}
        onClick={() => navigate('/')}
      />
      <MenuItem
        type="analysis"
        title={t('Analytics')}
        isSelected={pathname === '/analytics'}
        onClick={() => navigate('/analytics')}
      />
      <MenuItem
        type="news"
        title={t('News')}
        isSelected={pathname === '/news'}
        onClick={() => navigate('/news')}
      />
      <MenuItem
        type="settings"
        title={t('Account Settings')}
        isSelected={pathname === '/settings'}
        onClick={() => navigate('/settings')}
      />
      <Divider />
      <MenuItem type="signOut" title={t('Signout')} isSelected={false} onClick={handleSignout} />
    </Container>
  );
};

export default MobileMenu;
