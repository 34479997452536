import { TitleWrapper, BodyWrapper } from './EsgTopicPopupStyles';
import Typo from 'ui/typo/Typo';
import { COLORS } from 'styles/colorTheme';
import ModalComponent from 'ui/modals/defaultModal/Modal';

const EsgTopicPopup = ({ title, desc, isOpen = false, closeModal, category }) => {
  return (
    <ModalComponent
      onClose={closeModal}
      onSubmit={closeModal}
      submitTitle="Okay"
      open={isOpen}
      width={580}
    >
      <TitleWrapper>
        <div>
          <Typo variant="h2" color={COLORS.Text400}>
            {title}
          </Typo>
        </div>
        <div>
          <Typo variant="b3" color={COLORS.Text100}>
            {category === 'sasb' ? 'SASB Material Topic' : 'Emerging Material Topic'}
          </Typo>
        </div>
      </TitleWrapper>

      <BodyWrapper>
        <Typo variant="b2" color={COLORS.Text300} sx={{ lineHeight: '24px' }}>
          {desc}
        </Typo>
      </BodyWrapper>
    </ModalComponent>
  );
};

export default EsgTopicPopup;
