import { COLORS } from 'styles/colorTheme';

const AnalysisIcon = ({ state = 'mouseup', isSelected = false }) => {
  let color = COLORS.Text400;
  if (state === 'mousedown') color = '#fff';

  return (
    <>
      {isSelected ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill={color}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.5 4.5H4.5V16.5H19.5V4.5ZM7.78738 12.6938C7.87848 12.656 7.96127 12.6007 8.031 12.531V12.5295L10.5705 9.99L13.632 12.2865L17.085 7.968C17.1521 7.89184 17.203 7.80287 17.2346 7.70647C17.2663 7.61007 17.2781 7.50825 17.2693 7.40716C17.2604 7.30608 17.2312 7.20783 17.1833 7.11838C17.1354 7.02892 17.0699 6.95012 16.9906 6.88673C16.9114 6.82334 16.8201 6.77669 16.7223 6.7496C16.6245 6.72251 16.5223 6.71555 16.4217 6.72913C16.3212 6.74271 16.2244 6.77655 16.1373 6.82861C16.0502 6.88067 15.9746 6.94987 15.915 7.032L13.3695 10.215L10.4295 8.01L6.969 11.469C6.89927 11.5387 6.84395 11.6215 6.80621 11.7126C6.76848 11.8037 6.74905 11.9014 6.74905 12C6.74905 12.0986 6.76848 12.1963 6.80621 12.2874C6.84395 12.3785 6.89927 12.4613 6.969 12.531C7.03873 12.6007 7.12152 12.656 7.21262 12.6938C7.30373 12.7315 7.40138 12.7509 7.5 12.7509C7.59862 12.7509 7.69627 12.7315 7.78738 12.6938Z"
            fill={color}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.75 18H8.418L5.82 22.5H7.551L10.149 18H13.86L16.458 22.5H18.189L15.591 18H20.25C20.4489 18 20.6397 17.921 20.7803 17.7803C20.921 17.6397 21 17.4489 21 17.25V4.5H22.5C22.6989 4.5 22.8897 4.42098 23.0303 4.28033C23.171 4.13968 23.25 3.94891 23.25 3.75C23.25 3.55109 23.171 3.36032 23.0303 3.21967C22.8897 3.07902 22.6989 3 22.5 3H1.5C1.30109 3 1.11032 3.07902 0.96967 3.21967C0.829018 3.36032 0.75 3.55109 0.75 3.75C0.75 3.94891 0.829018 4.13968 0.96967 4.28033C1.11032 4.42098 1.30109 4.5 1.5 4.5H3V17.25C3 17.4489 3.07902 17.6397 3.21967 17.7803C3.36032 17.921 3.55109 18 3.75 18ZM4.5 4.5H19.5V16.5H4.5V4.5Z"
            fill={color}
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill={color}
        >
          <path
            d="M8.418 18H3.75C3.55109 18 3.36032 17.921 3.21967 17.7803C3.07902 17.6397 3 17.4489 3 17.25V4.5H1.5C1.30109 4.5 1.11032 4.42098 0.96967 4.28033C0.829018 4.13968 0.75 3.94891 0.75 3.75C0.75 3.55109 0.829018 3.36032 0.96967 3.21967C1.11032 3.07902 1.30109 3 1.5 3H22.5C22.6989 3 22.8897 3.07902 23.0303 3.21967C23.171 3.36032 23.25 3.55109 23.25 3.75C23.25 3.94891 23.171 4.13968 23.0303 4.28033C22.8897 4.42098 22.6989 4.5 22.5 4.5H21V17.25C21 17.4489 20.921 17.6397 20.7803 17.7803C20.6397 17.921 20.4489 18 20.25 18H15.591L18.189 22.5H16.458L13.86 18H10.149L7.551 22.5H5.82L8.418 18ZM19.5 4.5H4.5V16.5H19.5V4.5ZM8.031 12.531C7.96127 12.6007 7.87848 12.656 7.78738 12.6938C7.69627 12.7315 7.59862 12.7509 7.5 12.7509C7.40138 12.7509 7.30373 12.7315 7.21262 12.6938C7.12152 12.656 7.03873 12.6007 6.969 12.531C6.89927 12.4613 6.84395 12.3785 6.80621 12.2874C6.76848 12.1963 6.74905 12.0986 6.74905 12C6.74905 11.9014 6.76848 11.8037 6.80621 11.7126C6.84395 11.6215 6.89927 11.5387 6.969 11.469L10.4295 8.01L13.3695 10.215L15.915 7.032C15.9746 6.94987 16.0502 6.88067 16.1373 6.82861C16.2244 6.77655 16.3212 6.74271 16.4217 6.72913C16.5223 6.71555 16.6245 6.72251 16.7223 6.7496C16.8201 6.77669 16.9114 6.82334 16.9906 6.88673C17.0699 6.95012 17.1354 7.02892 17.1833 7.11838C17.2312 7.20783 17.2604 7.30608 17.2693 7.40716C17.2781 7.50825 17.2663 7.61007 17.2346 7.70647C17.203 7.80287 17.1521 7.89184 17.085 7.968L13.632 12.2865L10.5705 9.99L8.031 12.5295V12.531Z"
            fill={color}
          />
        </svg>
      )}
    </>
  );
};

export default AnalysisIcon;
