import Card from 'ui/card/defaultCard/Card';
import ESGRiskInsights from './components/ESGRiskInsights/ESGRiskInsights';

const ESG_Stock_MarketTrend = ({
  data,
  category,
  handler,
  addFollowing,
  cancelFollowing,
  followingList,
  isLoading,
  sx,
}) => {
  return (
    <Card sx={sx}>
      <ESGRiskInsights
        data={data?.esgMarketData}
        handler={handler}
        category={category?.marketTrendCategory}
        addFollowing={addFollowing}
        cancelFollowing={cancelFollowing}
        followingList={followingList}
        isLoading={isLoading?.isLoading_esgMarket}
      />
    </Card>
  );
};

export default ESG_Stock_MarketTrend;
