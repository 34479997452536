import { Container } from './ToolTipStyles';
import Typo from 'ui/typo/Typo';

const ToolTip = ({ label, isOpen = false, anchorEl, sx }) => {
  if (!Boolean(anchorEl)) return;

  const { bottom, hieght, left, right, top, width, x, y } = anchorEl.getBoundingClientRect();

  return (
    <Container isOpen={isOpen} y={y + 30} x={x} style={{ ...sx }}>
      <Typo variant="caption2" color="#fff">
        {label}
      </Typo>
    </Container>
  );
};

export default ToolTip;
