import { media } from 'media';
import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 10% repeat(26, 36px);
  column-gap: 4px;
  row-gap: 4px;
  justify-content: center;
  align-items: center;
  position: relative;

  ${media.mobile`
    justify-content: start;
    grid-template-columns: 30% repeat(26, 36px);
  `}
`;

export const LegendWrapper = styled.div`
  max-width: 36px;
  height: 20px;
  text-align: center;
  overflow: hidden;
  word-break: break-all;
  line-height: 16px;
`;

export const SquareWrapper = styled.div``;

export const CompanyNameWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  cursor: pointer;
`;
