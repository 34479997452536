import { media } from 'media';
import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div`
  display: flex;
  width: 351px;
  padding: 10px;
  border-radius: 5px;
  flex-direction: column;
  gap: 12px;
  position: relative;

  :hover {
    background: ${COLORS.Background50};
    & .feedback {
      visibility: visible;
      opacity: 1;
    }
  }

  ${media.mobile`
    width: 100%;
    box-sizing: border-box;
    gap: 0px;
  `}
`;

export const ContentsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &.medium {
    gap: 8px;
  }

  ${media.mobile`
    overflow: hidden;
  `}
`;

export const Title = styled.div`
  cursor: pointer;
  word-break: break-all;

  :hover {
    text-decoration-line: underline;
  }
`;

export const TagWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const CompanyTagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const TopicTagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const TitleImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-self: stretch;
`;

export const FeedbacWrapper = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
  visibility: hidden;
  opacity: 0;

  transition: all 0.3s;
`;
