import { Stack, Typography, Checkbox } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField } from '@mui/material';
import Filters from 'components/analyticsPage/tabPanels/components/drawers/components/Filters';
import { useDataQuery } from 'components/analyticsPage/tabPanels/components/drawers/dataDrawer/hooks/useDataQuery';
import DrawerInput from 'components/analyticsPage/input/DrawerInput';
import { useStockdataQuery } from 'components/analyticsPage/tabPanels/components/drawers/dataDrawer/components/stockDataTab/hooks/useStockdataQuery';
import { useTranslation } from 'react-i18next';

const StockdataTab = ({ data, open }) => {
  const [setDataQuery] = useDataQuery();
  const [setStockdataQuery] = useStockdataQuery();
  const [t] = useTranslation('analytics');

  return (
    <Stack
      spacing={3}
      sx={{
        display: open ? '' : 'none',
        minWidth: '450px',
        '@media(max-width: 768px)': {
          minWidth: '100%',
        },
      }}
    >
      <DrawerInput data={data} setQuery={setDataQuery} />
      <Stack width="100%" spacing={1}>
        <Typography sx={{ fontWeight: '500' }}>{t('Time window')}</Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="From"
            displayStaticWrapperAs="desktop"
            value={data.stockData.from}
            onChange={(e) => setStockdataQuery({ from: e })}
            disableFuture={true}
            renderInput={(params) => <TextField size="small" {...params} />}
          />
          <DatePicker
            label="To"
            displayStaticWrapperAs="desktop"
            value={data.stockData.to}
            onChange={(e) => setStockdataQuery({ to: e })}
            minDate={data.stockData.from}
            disableFuture={true}
            renderInput={(params) => <TextField size="small" {...params} />}
          />
        </LocalizationProvider>
      </Stack>
      <Stack spacing={1}>
        <Typography sx={{ fontWeight: '500' }}>{t('Index')}</Typography>
        <Stack>
          <div>
            <Checkbox checked disabled />
            Stock Name
          </div>
          <div>
            <Checkbox checked disabled />
            Stock Code
          </div>
          <div>
            <Checkbox checked disabled />
            Date
          </div>
          <Filters
            filters={data.stockData.filters}
            setQuery={setStockdataQuery}
            originalFilter={data.stockData.filters}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default StockdataTab;
