import { useSelector } from 'react-redux';
import moment from 'moment';
import { createAnalytics, updateAnalytics } from 'api/analytics';

export const useApply = () => {
  
  const { indexQuery, tabList, selectedTab } = useSelector((state) => state.analytics);
  const user = useSelector((state) => state.user);

  let tabFilters;
  if (indexQuery.target === 'multiIndex') tabFilters = generateMultiIndexUri(indexQuery.index.multiIndex, indexQuery);
  if (indexQuery.target === 'singleIndex') tabFilters = generateSingleIndexUri(indexQuery.index.singleIndex, indexQuery);

  let tabType;
  if (indexQuery.target === 'multiIndex') tabType = 'index-analytics';
  if (indexQuery.target === 'singleIndex') tabType = 'index-analytics-multi';

  const filters = {
    tabName: indexQuery.tabName === indexQuery.prevTabName ? undefined : indexQuery.tabName,
    userId: user.userId,
    corpCode: indexQuery.companyId,
    tabType: tabType,
    filters: tabFilters,
    prevName: indexQuery.prevTabName,
  }

  const createAnalysis = async () => {
    try {
      await createAnalytics(filters);
    } catch (e) {
      console.error(e)
    }
  }

  const updateAnalysis = async () => {
    try {
      await updateAnalytics({...filters, tabId: tabList[selectedTab].id});
    } catch (e) {
      console.error(e)
    }
  }

  return [createAnalysis, updateAnalysis];
}

const generateMultiIndexUri = (query) => {

  let filters;
  let indexArr = [];
  query.filters[0].check && indexArr.push(0);
  query.filters[1].check && indexArr.push(1);
  query.filters[2].check && indexArr.push(2);
  query.filters[3].check && indexArr.push(3);
  const index = indexArr.join();

  filters = `from=${Math.floor(moment(query.from).format('x') / 1000)
  }&to=${Math.floor(moment(query.to).format('x') / 1000)
  }&index=${index
  }&materiality=${query.compositeScore.materiality
  }&data_type=${query.dataType
  }&code=${query.individualScore.esgCode
  }`

  return filters;
}

const generateSingleIndexUri = (query) => {
  
  let filters;
  let indexArr = [];
  query.filters[0].check && indexArr.push(0);
  query.filters[1].check && indexArr.push(1);
  query.filters[2].check && indexArr.push(2);
  query.filters[3].check && indexArr.push(3);
  const index = indexArr.join();

  filters = `from=${Math.floor(moment(query.from).format('x') / 1000)
  }&to=${Math.floor(moment(query.to).format('x') / 1000)
  }&index=${index
  }&data_type=${query.dataType
  }&materiality=${query.compositeScore.materiality
  }&code=${query.individualScore.esgCode
  }`

  return filters;
}