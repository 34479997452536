import Typo from 'ui/typo/Typo';
import { Container, ViralBarsWrapper, ChartWrapper, MenuWrapper } from './ViralChartStyles';
import ThirdMenu from 'ui/menus/thirdMenu/ThirdMenu';
import { COLORS, GRAPH1 } from 'styles/colorTheme';
import PieChart from 'ui/charts/pieChart/PieChart';
import ViralBar from 'ui/bars/viralBar/ViralBar';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const ViralChart = ({ data, timeWindow, setTimeWindow, sx }) => {
  const [t] = useTranslation('companyDetails');
  const language = useSelector((state) => state.user.language);

  const viralBarData = data[1]?.gics?.ratios;

  let activeIndex = 0;
  if (timeWindow === '2024') activeIndex = 0;
  if (timeWindow === '2023') activeIndex = 1;
  if (timeWindow === '2022') activeIndex = 2;
  if (timeWindow === '2021') activeIndex = 3;
  if (timeWindow === '2020') activeIndex = 4;
  if (timeWindow === '2019') activeIndex = 5;

  const handleTimeWindow = (i) => {
    if (i === 0) setTimeWindow('2024');
    if (i === 1) setTimeWindow('2023');
    if (i === 2) setTimeWindow('2022');
    if (i === 3) setTimeWindow('2021');
    if (i === 4) setTimeWindow('2020');
    if (i === 5) setTimeWindow('2019');
  };

  return (
    <Container style={{ ...sx }}>
      <Typo variant="h2" color={COLORS.Text500}>
        {t('Viral Status')}
      </Typo>
      <MenuWrapper>
        <ThirdMenu
          menuList={[2024, 2023, 2022, 2021, 2020, 2019]}
          activeIndex={activeIndex}
          onClick={handleTimeWindow}
        />
      </MenuWrapper>
      <ChartWrapper>
        <PieChart data={data[0]?.dimensions} />
      </ChartWrapper>
      <ViralBarsWrapper>
        {viralBarData?.slice(0, 6).map((ratio, i) => (
          <ViralBar
            key={i}
            color={GRAPH1[0]}
            score={ratio}
            topic={data[1]?.gics[`general_issue_names_${language}`][i].slice(0, 2)}
          />
        ))}
        {viralBarData?.slice(6, 13).map((ratio, i) => (
          <ViralBar
            key={i}
            color={GRAPH1[1]}
            score={ratio}
            topic={data[1]?.gics[`general_issue_names_${language}`][i + 6].slice(0, 2)}
          />
        ))}
        {viralBarData?.slice(13, 16).map((ratio, i) => (
          <ViralBar
            key={i}
            color={GRAPH1[2]}
            score={ratio}
            topic={data[1]?.gics[`general_issue_names_${language}`][i + 13].slice(0, 2)}
          />
        ))}
        {viralBarData?.slice(16, 21).map((ratio, i) => (
          <ViralBar
            key={i}
            color={GRAPH1[3]}
            score={ratio}
            topic={data[1]?.gics[`general_issue_names_${language}`][i + 16].slice(0, 2)}
          />
        ))}
        {viralBarData?.slice(21).map((ratio, i) => (
          <ViralBar
            key={i}
            color={GRAPH1[4]}
            score={ratio}
            topic={data[1]?.gics[`general_issue_names_${language}`][i + 21].slice(0, 2)}
          />
        ))}
      </ViralBarsWrapper>
    </Container>
  );
};

export default ViralChart;
