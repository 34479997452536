import { Container } from './Small.styles';
import Typo from 'ui/typo/Typo';

const FifthTap = ({ isActive = false, children, sx, onClick }) => {
  return (
    <Container isActive={isActive} style={{ ...sx }} onClick={onClick}>
      <Typo variant="h3">{children}</Typo>
    </Container>
  );
};

export default FifthTap;
