import CompanyLineChart from 'components/companyDetailPage/components/summary/companyLineChart/CompanyLineChart';
import useSummarychart from 'components/dashboardPage/hooks/useSummarychart';
import ListItem from 'ui/listItem/default/ListItem';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as amplitude from '@amplitude/analytics-browser';

const Graph = ({ followingList }) => {
  const navigate = useNavigate();
  const [summaryChartMenu, setSummaryChartMenu] = useState('nfr_raw_pulse');
  const [summaryChartTime, setSummaryChartTime] = useState('1y');
  const [summaryChartCompanyIdx, setSummaryChartCompanyIdx] = useState(0);
  const [summaryChartData, isLoading] = useSummarychart(
    followingList[summaryChartCompanyIdx]?.corp_code,
    summaryChartMenu,
    summaryChartTime,
  );
  const language = useSelector((state) => state.user.language);

  const handleTimeWindow = (i) => {
    setSummaryChartTime(i);
    let value;
    if (summaryChartMenu === 'nfr_raw_pulse') value = 'ESG risk';
    if (summaryChartMenu === 'nfr_score_by_dimension') value = 'ESG group risk';
    if (summaryChartMenu === 'risk_score_by_material_codes') value = 'SASB ESG factor';
    if (summaryChartMenu === 'risk_score_by_material_data_codes') value = 'Data-Driven ESG factor';
    amplitude.track('dashboard_graph_view', { category: value, filter: i });
  };

  const handleMenu = (i) => {
    setSummaryChartMenu(i);
    let value;
    if (i === 'nfr_raw_pulse') value = 'ESG risk';
    if (i === 'nfr_score_by_dimension') value = 'ESG group risk';
    if (i === 'risk_score_by_material_codes') value = 'SASB ESG factor';
    if (i === 'risk_score_by_material_data_codes') value = 'Data-Driven ESG factor';
    amplitude.track('dashboard_graph_view', { category: value, filter: summaryChartTime });
  };

  return (
    <>
      <CompanyLineChart
        riskData={summaryChartData.summaryChartData_raw}
        groupRiskData={summaryChartData.summaryChartData_dimension}
        esgFactorData={summaryChartData.summaryChartData_materialCodes}
        dataDrivenData={summaryChartData.summaryChartData_materialData}
        timeWindow={summaryChartTime}
        esgMenu={summaryChartMenu}
        setTimeWindow={(i) => handleTimeWindow(i)}
        setEsgMenu={(i) => handleMenu(i)}
        isLoading={isLoading}
      />
      <div>
        {followingList.map((company, i) => (
          <ListItem
            key={i}
            tagColor={''}
            title={company[`comp_name_${language}`]}
            score={company.score}
            scoreChange={company.change_ratio}
            lightLineChartData={company.chart_data}
            onClick={() => setSummaryChartCompanyIdx(i)}
            navigator={() => navigate(`/company/${company.corp_code}`)}
            displayCancel={false}
            isSelected={summaryChartCompanyIdx === i}
          />
        ))}
      </div>
    </>
  );
};

export default Graph;
