import React from 'react';
import Button from 'ui/buttons/button/Button';
import cancel from 'assets/whiteCancel.svg';
import Typo from 'ui/typo/Typo';
import { useTranslation } from 'react-i18next';

import { Header, Container, Cancel, Body, Footer } from './PrimaryModalFrame.styles';

export default function PrimaryModalFrame({ children, header, onApply, onClose, sx }) {
  const { t: commonT } = useTranslation('common');

  return (
    <Container style={{ ...sx }}>
      <Cancel>
        <img src={cancel} alt="cancel" onClick={onClose} />
      </Cancel>
      <Header>
        <Typo variant="h2" color="#fff" className="medium">
          {header}
        </Typo>
      </Header>
      <Body>{children}</Body>
      <Footer>
        <Button sx={{ width: '145px' }} onClick={onApply}>
          <Typo variant="btn2" className="medium">
            {commonT('Apply')}
          </Typo>
        </Button>
      </Footer>
    </Container>
  );
}
