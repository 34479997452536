import ProfileIcon from 'ui/icon/profileIcon/ProfileIcon';
import { Container, Menu, IconWrapper, ModalWrapper } from './HeaderMenuStyles';
import Typo from 'ui/typo/Typo';
import Button from 'ui/buttons/button/Button';
import ProfileModal from 'ui/modals/profileModal/ProfileModal';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

const HeaderMenu = () => {
  const [openModal, setOpenModal] = useState(false);
  const user = useSelector((state) => state.user.info);

  const modalRef = useRef();
  const iconRef = useRef();

  const handleClickOutSide = (e) => {
    if (
      modalRef.current &&
      !modalRef.current.contains(e.target) &&
      !iconRef.current.contains(e.target)
    ) {
      setOpenModal(false);
    }
  };

  useEffect(() => {
    if (openModal) {
      document.addEventListener('click', handleClickOutSide);
    } else {
      document.removeEventListener('click', handleClickOutSide);
    }

    return () => {
      document.removeEventListener('click', handleClickOutSide);
    };
  }, [openModal]);

  return (
    <>
      <Container>
        {/* <Menu
          onClick={() => {}}
        >
          <Button>
            <Typo variant="b2" color="#fff">{MAINFRAME.HEADER.GUIDE.KR}</Typo>
          </Button>
        </Menu>
        <Menu
          onClick={() => {}}
        >
          <Typo variant="b2">{MAINFRAME.HEADER.PRICE.KR}</Typo>
        </Menu> */}
        <IconWrapper ref={iconRef}>
          <ProfileIcon onClick={() => setOpenModal((prev) => !prev)} />
        </IconWrapper>
      </Container>

      <ModalWrapper ref={modalRef}>
        <ProfileModal
          name={user.fullname}
          plan={user.user_type}
          email={user.email}
          isVisible={openModal}
          close={() => setOpenModal(false)}
        />
      </ModalWrapper>
    </>
  );
};

export default HeaderMenu;
