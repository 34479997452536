import { Container } from './LegendCheckboxStyles';
import { COLORS } from 'styles/colorTheme';

const LegendCheckbox = ({ onClick, isSelected = false }) => {
  return (
    <Container onClick={onClick}>
      {isSelected ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
        >
          <path
            d="M1 11C1 6.286 1 3.929 2.464 2.464C3.93 1 6.286 1 11 1C15.714 1 18.071 1 19.535 2.464C21 3.93 21 6.286 21 11C21 15.714 21 18.071 19.535 19.535C18.072 21 15.714 21 11 21C6.286 21 3.929 21 2.464 19.535C1 18.072 1 15.714 1 11Z"
            fill={COLORS.Text400}
            stroke={COLORS.Text400}
            strokeWidth="1.5"
          />
          <path
            d="M6 13L7.797 10.844C8.509 9.989 8.865 9.562 9.333 9.562C9.802 9.562 10.158 9.989 10.87 10.844L11.13 11.156C11.842 12.011 12.198 12.438 12.667 12.438C13.135 12.438 13.491 12.011 14.203 11.156L16 9"
            fill="#000000"
          />
          <path
            d="M6 13L7.797 10.844C8.509 9.989 8.865 9.562 9.333 9.562C9.802 9.562 10.158 9.989 10.87 10.844L11.13 11.156C11.842 12.011 12.198 12.438 12.667 12.438C13.135 12.438 13.491 12.011 14.203 11.156L16 9"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
        >
          <path
            d="M1 11C1 6.286 1 3.929 2.464 2.464C3.93 1 6.286 1 11 1C15.714 1 18.071 1 19.535 2.464C21 3.93 21 6.286 21 11C21 15.714 21 18.071 19.535 19.535C18.072 21 15.714 21 11 21C6.286 21 3.929 21 2.464 19.535C1 18.072 1 15.714 1 11Z"
            stroke={COLORS.Text400}
          />
        </svg>
      )}
    </Container>
  );
};

export default LegendCheckbox;
