import BenchmarkLineChart from './benchmarkLineChart/BenchmarkLineChart';
import BenchmarkCompanyList from './benchmarkCompanyList/BenchmarkCompanyList';
import SimilarityCards from './similarityCards/SimilarityCards';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getCompanySimilarities, getEsgBenchmarkChart } from 'api/companyDetail';
import { useParams } from 'react-router-dom';
import { BENCHMARKCOLORS, GRAPH2 } from 'styles/colorTheme';
import { useSelector } from 'react-redux';
import { getCompanyEsgProfile } from 'api/companyDetail';
import * as amplitude from '@amplitude/analytics-browser';

const Benchmark = () => {
  const [t] = useTranslation('companyDetails');
  const language = useSelector((state) => state.user.language);
  const { id } = useParams();
  const colors = [...BENCHMARKCOLORS, ...GRAPH2];
  const [benchmarkData_raw, setBenchmarkData_raw] = useState([]);
  const [benchmarkData_dimension, setBenchmarkData_dimension] = useState([]);
  const [benchmarkData_materialCodes, setBenchmarkData_materialCodes] = useState([]);
  const [benchmarkData_materialData, setBenchmarkData_materialData] = useState([]);
  const [benchmarkDataMenu, setbenchmarkDataMenu] = useState('nfr_raw_pulse');
  const [benchmarkDataTime, setbenchmarkDataTime] = useState('1y');
  const [isLoading, setIsLoading] = useState(true);
  const [companyList, setCompanyList] = useState([]);
  const [similarityData, setSimilarityData] = useState({});

  const addCompanyList = (company) => {
    const isDuplicated = companyList.findIndex((el, i) => el.corpCode === company.corp_code) !== -1;
    if (isDuplicated) return;

    const newCompany = {
      corpCode: company.corp_code,
      title: company[`comp_name_${language}`],
      score: company.esg_risk,
      scoreChange: company.esg_risk_change_ratio,
      tagColor: colors[companyList.length],
    };
    if (companyList.length >= 6) companyList.splice(1, 1);
    setCompanyList([...companyList, newCompany]);
  };

  const removeCompanyList = (company, index) => {
    companyList.splice(index, 1);
    setCompanyList([...companyList]);
    amplitude.track('companydetail_benchmakrt_company_remove');
  };

  const getSimilarityData = async () => {
    const res = await getCompanySimilarities(id);
    if (res && res.data) {
      setSimilarityData(res.data);
    }
  };

  const clearData = () => {
    setSimilarityData({});
  };

  useEffect(() => {
    if (id) {
      getSimilarityData();
    }

    return () => {
      clearData();
    };
  }, [id]);

  useEffect(() => {
    if (id && companyList.length > 0) getBenchmarkData();
  }, [id, benchmarkDataMenu, benchmarkDataTime, companyList]);

  useEffect(() => {
    if (id) {
      initCompanyList();
    }
  }, []);

  const initCompanyList = async () => {
    const res = await getCompanyEsgProfile(id);
    if (res.data) {
      addCompanyList(res.data);
    }
  };

  const getBenchmarkData = async () => {
    setIsLoading(true);

    const dataArr = [];

    for (let i = 0; i < companyList.length; i++) {
      const company = companyList[i];
      let arr = [];
      let legend = [];

      const res = await getEsgBenchmarkChart(
        company.corpCode,
        benchmarkDataMenu,
        benchmarkDataTime,
      );
      if (!res) return;

      if (benchmarkDataMenu === 'nfr_raw_pulse') arr = res.data;

      if (benchmarkDataMenu === 'nfr_score_by_dimension') {
        for (let i = 0; i < res.data.length; i++) {
          const element = res.data[i];
          arr.push(element.scores);
          legend.push(element[`dimension_name_${language}`]);
        }
      }

      if (
        benchmarkDataMenu === 'risk_score_by_material_codes' ||
        benchmarkDataMenu === 'risk_score_by_material_data_codes'
      ) {
        for (let i = 0; i < res.data.length; i++) {
          const element = res.data[i];
          arr.push(element.scores);
          legend.push(element[`general_issue_names_${language}`]);
        }
      }

      const obj = {
        name: company.title,
        color: company.tagColor,
        data: arr,
        legend: legend,
      };
      dataArr.push(obj);
    }

    if (benchmarkDataMenu === 'nfr_raw_pulse') setBenchmarkData_raw(dataArr);
    if (benchmarkDataMenu === 'nfr_score_by_dimension') setBenchmarkData_dimension(dataArr);
    if (benchmarkDataMenu === 'risk_score_by_material_codes')
      setBenchmarkData_materialCodes(dataArr);
    if (benchmarkDataMenu === 'risk_score_by_material_data_codes')
      setBenchmarkData_materialData(dataArr);

    setIsLoading(false);
  };

  const handleTimeWindow = (i) => {
    setbenchmarkDataTime(i);
    let value;
    if (benchmarkDataMenu === 'nfr_raw_pulse') value = 'ESG risk';
    if (benchmarkDataMenu === 'nfr_score_by_dimension') value = 'ESG group risk';
    if (benchmarkDataMenu === 'risk_score_by_material_codes') value = 'SASB ESG factor';
    if (benchmarkDataMenu === 'risk_score_by_material_data_codes') value = 'Data-Driven ESG factor';
    amplitude.track('companydetail_benchmark_mainchart_view', { category: value, filter: i });
  };

  const handleMenu = (i) => {
    setbenchmarkDataMenu(i);
    let value;
    if (i === 'nfr_raw_pulse') value = 'ESG risk';
    if (i === 'nfr_score_by_dimension') value = 'ESG group risk';
    if (i === 'risk_score_by_material_codes') value = 'SASB ESG factor';
    if (i === 'risk_score_by_material_data_codes') value = 'Data-Driven ESG factor';
    amplitude.track('companydetail_benchmark_mainchart_view', {
      category: value,
      filter: benchmarkDataTime,
    });
  };

  const handleESGSimilarityClick = (company) => {
    addCompanyList(company);
    amplitude.track('companydetail_benchmark_company_add', {
      category: 'ESG similarity click',
    });
  };

  const handleIndustrySimliarityClick = (company) => {
    addCompanyList(company);
    amplitude.track('companydetail_benchmark_company_add', {
      category: 'Industry similarity click',
    });
  };

  return (
    <>
      <BenchmarkLineChart
        rawData={benchmarkData_raw}
        groupRiskData={benchmarkData_dimension}
        esgFactorData={benchmarkData_materialCodes}
        dataDrivenData={benchmarkData_materialData}
        timeWindow={benchmarkDataTime}
        esgMenu={benchmarkDataMenu}
        setTimeWindow={(i) => handleTimeWindow(i)}
        setEsgMenu={(i) => handleMenu(i)}
        isLoading={isLoading}
      />
      <BenchmarkCompanyList
        companyList={companyList}
        addCompanyList={addCompanyList}
        removeCompanyList={removeCompanyList}
      />
      <SimilarityCards
        title={t('ESG Similarity')}
        // desc={''}
        cards={similarityData?.esg_similarity_companies}
        addCompanyList={handleESGSimilarityClick}
      />
      <SimilarityCards
        title={t('Industry Similarity')}
        // desc={''}
        cards={similarityData?.industry_similarity_companies}
        addCompanyList={handleIndustrySimliarityClick}
      />
    </>
  );
};

export default Benchmark;
