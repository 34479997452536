import { Container, Arrow, BtnWrapper, ArrowWrapper } from './PaginationStyles';
import Right from 'assets/right.svg';
import { useEffect, useState } from 'react';

const Pagination = ({ totalPage = 12, currentPage = 1, step = 5, onClick, sx }) => {
  const totalBlock = Math.ceil(totalPage / step);
  const [pages, setPages] = useState([]);
  const [block, setBlock] = useState(1);

  useEffect(() => {
    const pages = new Array(totalPage).fill(undefined).map((el, i) => i + 1);
    setPages(pages);
    setBlock(1);
  }, [totalPage]);

  const next = () => setBlock(block + 1);
  const prev = () => setBlock(block - 1);

  return (
    <Container style={{ ...sx }}>
      {totalPage > 0 ? (
        <>
          <ArrowWrapper>
            {block === 1 ? null : <Arrow src={Right} direction="left" alt="left" onClick={prev} />}
          </ArrowWrapper>

          {pages.slice((block - 1) * step, block * step).map((el, i) => (
            <BtnWrapper key={i} isActive={el === currentPage} onClick={() => onClick(el)}>
              {el}
            </BtnWrapper>
          ))}

          <ArrowWrapper>
            {block === totalBlock ? null : <Arrow src={Right} alt="right" onClick={next} />}
          </ArrowWrapper>
        </>
      ) : null}
    </Container>
  );
};

export default Pagination;
