import SecondaryMenu from 'ui/menus/secondary/regular/RegularMenu';
import {
  Container,
  MenuContainer,
  MenuWrapper,
  ThirdMenuWrapper,
  LegendWrapper,
  ItemWrapper,
  SecondaryMenuWrapper,
  ChartContainer,
  ChartWrapper,
} from './CompanyLineChartStyles';
import ThirdMenu from 'ui/menus/thirdMenu/ThirdMenu';
import LegendItem from 'ui/listItem/legendItem/LegendItem';
import { COLORS, GRAPH2, BENCHMARKCOLORS } from 'styles/colorTheme';
import EsgRiskChart from 'ui/charts/esgRiskChart/EsgRiskChart';
import EsgGroupRiskChart from 'ui/charts/esgGroupRiskChart/EsgGroupRiskChart';
import EsgGroupRiskChart_SASB from 'ui/charts/esgGroupRiskChart/EsgGroupRiskChart_SASB';
import { useEffect, useState } from 'react';
import Preloader from 'ui/preloaders/componentPreloader/ComponentPreloader';
import Nodata from 'ui/errorSigns/noData/Nodata';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import BlurScreen from 'ui/blurScreen/BlurScreen';

const CompanyLineChart = ({
  riskData,
  groupRiskData,
  esgFactorData,
  dataDrivenData,
  timeWindow,
  esgMenu,
  setTimeWindow,
  setEsgMenu,
  isLoading,
  isValid = true,
  sx,
}) => {
  const colors = [...GRAPH2, ...BENCHMARKCOLORS];

  const [t] = useTranslation('chart');
  const [dT] = useTranslation('noData');
  const [openInvalid, setOpenInvalid] = useState();

  useEffect(() => {
    if (!isValid) setOpenInvalid(true);
  }, [isValid]);

  const language = useSelector((state) => state.user.language);

  let isPositive = true;
  if (
    riskData?.nfr_raw_data &&
    riskData?.nfr_raw_data[riskData.nfr_raw_data.length - 1][1] -
      riskData?.nfr_raw_data[riskData.nfr_raw_data.length - 2][1] <
      0
  )
    isPositive = false;

  const [esgRiskLegendInfo, setEsgRiskLegendInfo] = useState([
    { label: t('ESG risk'), isVisible: true, color: undefined },
    { label: t('Stock price'), isVisible: true, color: COLORS.Background200 },
  ]);

  const [legendInfo, setLegendInfo] = useState([]);

  useEffect(() => {
    if (esgMenu === 'nfr_raw_pulse') return;

    let arr = [];
    if (esgMenu === 'nfr_score_by_dimension') arr = getLegendArr(groupRiskData);
    if (esgMenu === 'risk_score_by_material_codes') arr = getLegendArr(esgFactorData);
    if (esgMenu === 'risk_score_by_material_data_codes') arr = getLegendArr(dataDrivenData);
    setLegendInfo(arr);
  }, [esgMenu, groupRiskData, esgFactorData, dataDrivenData, language]);

  const handleEsgRiskLegend = (index) => {
    const arr = [...esgRiskLegendInfo];
    arr[index].isVisible = !arr[index].isVisible;
    setEsgRiskLegendInfo(arr);
  };

  const handleLegend = (index) => {
    const arr = [...legendInfo];
    arr[index].isVisible = !arr[index].isVisible;
    setLegendInfo(arr);
  };

  let activeIndex = 0;
  if (timeWindow === '1m') activeIndex = 0;
  if (timeWindow === '3m') activeIndex = 1;
  if (timeWindow === '6m') activeIndex = 2;
  if (timeWindow === '1y') activeIndex = 3;

  let esgMenuIndex = 0;
  if (esgMenu === 'nfr_raw_pulse') esgMenuIndex = 0;
  if (esgMenu === 'nfr_score_by_dimension') esgMenuIndex = 1;
  if (esgMenu === 'risk_score_by_material_codes') esgMenuIndex = 2;
  if (esgMenu === 'risk_score_by_material_data_codes') esgMenuIndex = 3;

  const handleTimeWindow = (i) => {
    if (i === 0) setTimeWindow('1m');
    if (i === 1) setTimeWindow('3m');
    if (i === 2) setTimeWindow('6m');
    if (i === 3) setTimeWindow('1y');
  };

  const handleEsgMenu = (i) => {
    if (i === 0) setEsgMenu('nfr_raw_pulse');
    if (i === 1) setEsgMenu('nfr_score_by_dimension');
    if (i === 2) setEsgMenu('risk_score_by_material_codes');
    if (i === 3) setEsgMenu('risk_score_by_material_data_codes');
  };

  const getLegendArr = (data) => {
    let arr = [];
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      const obj = {
        label: element[`general_issue_names_${language}`] || element[`dimension_name_${language}`],
        isVisible: true,
        color: colors[i] || '#000',
      };
      arr.push(obj);
    }
    return arr;
  };

  return (
    <Container style={{ ...sx }}>
      <MenuContainer>
        <MenuWrapper>
          <SecondaryMenuWrapper>
            <SecondaryMenu
              menuList={[
                t('ESG risk'),
                t('ESG group risk'),
                t('SASB ESG factor'),
                t('Data-driven ESG factor'),
              ]}
              activeIndex={esgMenuIndex}
              onClick={handleEsgMenu}
            />
          </SecondaryMenuWrapper>
          <ThirdMenuWrapper>
            <ThirdMenu
              menuList={[t('1m'), t('3m'), t('6m'), t('1y')]}
              activeIndex={activeIndex}
              onClick={handleTimeWindow}
            />
          </ThirdMenuWrapper>
        </MenuWrapper>
      </MenuContainer>

      {esgMenu === 'nfr_raw_pulse' && !isLoading ? (
        <ChartContainer>
          <ChartWrapper>
            <EsgRiskChart
              isPositive={isPositive}
              nfrData={riskData?.nfr_raw_data}
              stockData={riskData?.stock_data}
              esgRiskLegendInfo={esgRiskLegendInfo}
            />
          </ChartWrapper>
          <BlurScreen
            open={openInvalid}
            title={dT('Not enough data')}
            descriptions={[
              dT('This corresponds to low external exposure to ESG risks.'),
              dT('It generates weak signals with small amounts of data.'),
              dT('To continue viewing, please click the button below.'),
            ]}
            onClick={() => setOpenInvalid(false)}
          />
        </ChartContainer>
      ) : null}

      {esgMenu === 'nfr_score_by_dimension' && !isLoading ? (
        <ChartContainer>
          <ChartWrapper>
            <EsgGroupRiskChart data={groupRiskData} legendInfo={legendInfo} />
          </ChartWrapper>
        </ChartContainer>
      ) : null}

      {esgMenu === 'risk_score_by_material_codes' && !isLoading ? (
        <ChartContainer>
          <ChartWrapper>
            <EsgGroupRiskChart_SASB data={esgFactorData} legendInfo={legendInfo} />
          </ChartWrapper>
        </ChartContainer>
      ) : null}

      {esgMenu === 'risk_score_by_material_data_codes' && !isLoading ? (
        <>
          {Object.keys(dataDrivenData).length > 0 ? (
            <ChartContainer>
              <ChartWrapper>
                <EsgGroupRiskChart_SASB data={dataDrivenData} legendInfo={legendInfo} />
              </ChartWrapper>
            </ChartContainer>
          ) : (
            <Nodata desc={dT('No data.')} />
          )}
        </>
      ) : null}

      <LegendWrapper>
        {esgMenu === 'nfr_raw_pulse' && !isLoading ? (
          <ItemWrapper>
            <LegendItem
              color={isPositive ? COLORS.Red200 : COLORS.Blue200}
              onClick={() => handleEsgRiskLegend(0)}
              isSelected={esgRiskLegendInfo[0].isVisible}
            >
              {t('ESG risk')}
            </LegendItem>
            <LegendItem
              color={esgRiskLegendInfo[1].color}
              onClick={() => handleEsgRiskLegend(1)}
              isSelected={esgRiskLegendInfo[1].isVisible}
            >
              {t('Stock price')}
            </LegendItem>
          </ItemWrapper>
        ) : null}

        {esgMenu !== 'nfr_raw_pulse' && !isLoading ? (
          <ItemWrapper>
            {legendInfo.map((el, i) => (
              <LegendItem
                color={el.color}
                onClick={() => handleLegend(i)}
                isSelected={el.isVisible}
              >
                {el.label}
              </LegendItem>
            ))}
          </ItemWrapper>
        ) : null}
      </LegendWrapper>

      {isLoading ? <Preloader width="1200px" height="432px" /> : null}
    </Container>
  );
};

export default CompanyLineChart;
