import styled from "styled-components"

export const TreemapWrapper = styled.div`
  min-height: 464px;
`

export const PreloaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`