import { useState, useEffect } from 'react';
import CompanyLineChart from './companyLineChart/CompanyLineChart';
import ScoreBoard from './scoreBoard/ScoreBoard';
import CompanySummary from './companySummary/CompanySummary';
import EsgCards from './esgCards/EsgCards';
import ViralChart from './viralChart/ViralChart';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  getCompanyDataValidation,
  getEsgNegativeExposure,
  getEsgSummaryChart,
  getEsgSummaryDetails,
} from 'api/companyDetail';
import * as amplitude from '@amplitude/analytics-browser';
import { ChartContainer, ChartWrapper } from './Summart.styles';

const Summary = () => {
  const [t] = useTranslation('companyDetails');
  const { id } = useParams();

  const [summaryChartData_raw, setSummaryChartData_raw] = useState({});
  const [summaryChartData_dimension, setSummaryChartData_dimension] = useState({});
  const [summaryChartData_materialCodes, setSummaryChartData_materialCodes] = useState({});
  const [summaryChartData_materialData, setSummaryChartData_materialData] = useState({});
  const [summaryChartMenu, setSummaryChartMenu] = useState('nfr_raw_pulse');
  const [summaryChartTime, setSummaryChartTime] = useState('1y');

  const [isLoading, setIsLoading] = useState(true);

  const [scoreBoardData, setScoreBoardData] = useState([]);
  const [summaryDetailsData, setSummaryDetailsData] = useState({});
  const [sasbCards, setSasbCards] = useState([]);
  const [dataDrivenCards, setDataDrivenCards] = useState([]);
  const [viralChartData, setViralChartData] = useState([]);
  const [viralChartTime, setViralChartTime] = useState('2024');
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (id) getViralChartData();
  }, [id, viralChartTime]);

  useEffect(() => {
    if (id) getSummaryChartData();
  }, [id, summaryChartTime, summaryChartMenu]);

  const getViralChartData = async () => {
    const res = await getEsgNegativeExposure(id, viralChartTime);
    if (res.data) {
      setViralChartData(res.data);
    }
  };

  const getSummaryChartData = async () => {
    setIsLoading(true);
    const res = await getEsgSummaryChart(id, summaryChartMenu, summaryChartTime);
    if (!res) return;

    if (summaryChartMenu === 'nfr_raw_pulse') setSummaryChartData_raw(res.data);
    if (summaryChartMenu === 'nfr_score_by_dimension') setSummaryChartData_dimension(res.data);
    if (summaryChartMenu === 'risk_score_by_material_codes')
      setSummaryChartData_materialCodes(res.data);
    if (summaryChartMenu === 'risk_score_by_material_data_codes')
      setSummaryChartData_materialData(res.data);
    setIsLoading(false);
  };

  useEffect(() => {
    if (id) getDetailsData();
  }, [id]);

  const getDetailsData = async () => {
    const res = await getEsgSummaryDetails(id);
    const validityRes = await getCompanyDataValidation(id);

    if (res?.data) {
      const companyDetails = res.data.company_details;
      const scoreBoard = res.data.score_board;
      const sasb = res.data.sasb_general_issues;
      const dataDriven = res.data.data_general_issues;

      setSummaryDetailsData(companyDetails);
      setScoreBoardData(scoreBoard);
      setSasbCards(sasb);
      setDataDrivenCards(dataDriven);
      if (validityRes) setIsValid(validityRes.data?.['is_valid']);
    }
  };

  const handleTimeWindow = (i) => {
    setSummaryChartTime(i);
    let value;
    if (summaryChartMenu === 'nfr_raw_pulse') value = 'ESG risk';
    if (summaryChartMenu === 'nfr_score_by_dimension') value = 'ESG group risk';
    if (summaryChartMenu === 'risk_score_by_material_codes') value = 'SASB ESG factor';
    if (summaryChartMenu === 'risk_score_by_material_data_codes') value = 'Data-Driven ESG factor';
    amplitude.track('companydetail_summary_mainchart_view', { category: value, filter: i });
  };

  const handleMenu = (i) => {
    setSummaryChartMenu(i);
    let value;
    if (i === 'nfr_raw_pulse') value = 'ESG risk';
    if (i === 'nfr_score_by_dimension') value = 'ESG group risk';
    if (i === 'risk_score_by_material_codes') value = 'SASB ESG factor';
    if (i === 'risk_score_by_material_data_codes') value = 'Data-Driven ESG factor';
    amplitude.track('companydetail_summary_mainchart_view', {
      category: value,
      filter: summaryChartTime,
    });
  };

  const handleViralTimeWindow = (i) => {
    setViralChartTime(i);
    amplitude.track('companydetail_summary_viral_status_view', { filter: i });
  };

  return (
    <>
      <CompanyLineChart
        isValid={isValid}
        riskData={summaryChartData_raw}
        groupRiskData={summaryChartData_dimension}
        esgFactorData={summaryChartData_materialCodes}
        dataDrivenData={summaryChartData_materialData}
        timeWindow={summaryChartTime}
        esgMenu={summaryChartMenu}
        setTimeWindow={(i) => handleTimeWindow(i)}
        setEsgMenu={(i) => handleMenu(i)}
        isLoading={isLoading}
      />

      <ScoreBoard data={scoreBoardData} isLoading={isLoading} />
      <CompanySummary data={summaryDetailsData} />
      <EsgCards
        title={t('SASB ESG Topics')}
        cards={sasbCards}
        category={'sasb'}
        desc={t(
          'Material ESG topics mandated for the specific industry in which the company belongs.The IFRS Sustainability Disclosure Standards requires the use of SASB metrics, which outline industry-specific risks and opportunities related to sustainability. The following are key ESG topics that help investors in evaluating a value of a company based on SASB Standards.',
        )}
      />
      <EsgCards
        title={t('Data-driven ESG Topics')}
        cards={dataDrivenCards}
        category={'dataDriven'}
        desc={t(
          'Data-driven, company-specific ESG materiality metrics.Monitor and discover evolving ESG factors using AI to keep you informed. Identify unique sustainability risks and opportunities that are specific to each company, even if they operate within the same industry.',
        )}
      />
      <ChartContainer>
        <ChartWrapper>
          <ViralChart
            data={viralChartData}
            timeWindow={viralChartTime}
            setTimeWindow={(i) => handleViralTimeWindow(i)}
          />
        </ChartWrapper>
      </ChartContainer>
    </>
  );
};

export default Summary;
