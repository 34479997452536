import DashboardNews from './components/dashboardNews/DashboardNews';
import useNews from 'components/dashboardPage/hooks/useNews';

const News = ({ corpcodes }) => {
  const [dashboardNewsData, isNewsLoading] = useNews(corpcodes);

  return <DashboardNews data={dashboardNewsData} isLoading={isNewsLoading} />;
};

export default News;
