import Typo from 'ui/typo/Typo';
import { COLORS } from 'styles/colorTheme';
import { Container } from './NodataStyles';
import caution from 'assets/caution.svg';

export const Nodata = ({ desc, sx }) => {
  return (
    <Container style={{ ...sx }}>
      <img src={caution} alt="caution" />
      <Typo variant="b1" color={COLORS.Text300} sx={{ marginLeft: '8px' }}>
        {desc}
      </Typo>
    </Container>
  );
};

export default Nodata;
