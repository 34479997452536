import { media } from 'media';
import styled from 'styled-components';

export const Container = styled.span`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  position: relative;

  ${media.mobile`
    display: none;
  `}
`;

export const Icon = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

export const Popper = styled.div`
  position: absolute;
  left: ${(props) => (props.dp === 'right' ? '32px' : '0px')};
  top: ${(props) => (props.dp === 'bottom' ? '32px' : '')};
  width: ${(props) => props.width};
  display: ${(props) => (props.isHovering ? 'block' : 'none')};
  border-radius: 12px;
  padding: 24px;
  background-color: #fff;
  word-wrap: break-word;
  z-index: 1;
  box-shadow: 0px 4px 12px rgba(66, 74, 87, 0.25);
`;
