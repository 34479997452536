import Typo from 'ui/typo/Typo';
import { Container, CheckBoxWrapper, Wrapper } from './CheckBoxList.styles';
import Checkbox from 'ui/checkbox/defaultCheckbox/CheckBox';
import { COLORS } from 'styles/colorTheme';

const CheckBoxList = ({ label, isSelected, isDisabled, fill, onClick }) => {
  return (
    <Container>
      <CheckBoxWrapper>
        <Checkbox isChecked={isSelected} isDisabled={isDisabled} fill={fill} onClick={onClick} />
      </CheckBoxWrapper>
      <Wrapper>
        <Typo variant="h3" color={COLORS.Text400}>
          {label}
        </Typo>
      </Wrapper>
    </Container>
  );
};

export default CheckBoxList;
