import styled from 'styled-components';

export const Container = styled.div`
  display: ${(props) => (props.open ? 'block' : 'none')};
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const BlurContainer = styled.div`
  width: 100%;
  height: 100%;
  /* backdrop-filter: blur(4px);
  -webkit-filter: blur(4px); */
  background-color: rgba(245, 245, 245, 0.9);
`;

export const ContentsContainer = styled.div`
  border-radius: 6px;
  padding: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

export const TitleWrapper = styled.div`
  margin-bottom: 12px;
`;

export const DescWrapper = styled.div`
  margin-left: 12px;
`;

export const Li = styled.li`
  margin-bottom: 4px;
`;

export const ButtonWrapper = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 34px;
  margin-top: 8px;
`;
