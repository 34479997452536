import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div`
  margin-bottom: 4px;
`;

export const Wrapper = styled.div`
  padding: 4px 4px 4px 10px;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  cursor: pointer;
  :hover {
    background-color: ${COLORS.Background100_70};
  }
`;

export const TextWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  display: block;
  margin: auto 0;

  max-width: 60%;
`;

export const ScoreWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Img = styled.img``;
