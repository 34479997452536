import {
  Container,
  RadioWrapper,
  HiddenRadio,
  Radio,
  InnerCircle,
} from './RadioButtonStyles'

const RadioButton = ({
  isChecked, 
  isDisabled, 
  name,
  onChange,
}) => {

  const handleChange = (e) => {
    if (isDisabled) return
    onChange(e)
  }

  return(
    <Container>
      <RadioWrapper>
        <HiddenRadio 
          type='radio' 
          name={name}
          checked={isChecked} 
          onChange={handleChange}
        />
        <Radio
          checked={isChecked} 
          disabled={isDisabled}
        >
          <InnerCircle checked={isChecked} disabled={isDisabled}/>
        </Radio>
      </RadioWrapper>
    </Container>
  )
}

export default RadioButton