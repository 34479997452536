import Typo from 'ui/typo/Typo';
import { Container, Label, Value } from './DescriptionItemStyles';
import { COLORS } from 'styles/colorTheme';
import { Skeleton } from '@mui/material';

const DescriptionItem = ({ label, value, link = false, sx }) => {
  return (
    <Container style={{ ...sx }}>
      <Label>
        <Typo variant="b2" color={COLORS.Text400}>
          {label}
        </Typo>
      </Label>
      <Value onClick={link ? () => window.open(link, '_blank') : () => {}} hasLink={link}>
        {value ? (
          <Typo variant="b2" color={link ? COLORS.Blue200 : COLORS.Text300}>
            {value}
          </Typo>
        ) : (
          <Skeleton variant="text" width={240} />
        )}
      </Value>
    </Container>
  );
};

export default DescriptionItem;
