import { media } from 'media';
import styled from 'styled-components';

export const ComponentWrapper = styled.div`
  margin-top: 36px;

  ${media.mobile`
    margin: 12px;
  `}
`;
