import { useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';

import Benchmark from './components/benchmark/Benchmark';
import { Container } from './CompanyDetailStyles';
import CompanyDetailMenu from './components/menu/CompanyDetailMenu';
import CompanyDetailNews from './components/news/CompanyDetailNews';
import Summary from './components/summary/Summary';
import Timeline from './components/timeline/Timeline';

const CompanyDetail = () => {
  const [menuIdx, setMenuIdx] = useState(0);

  const handleMenu = (i) => {
    if (i === 0) amplitude.track('companydetail_view', { category: 'summary' });
    if (i === 1) amplitude.track('companydetail_view', { category: 'timeline' });
    if (i === 2) amplitude.track('companydetail_view', { category: 'benchmark' });
    if (i === 3) amplitude.track('companydetail_view', { category: 'news' });
    setMenuIdx(i);
  };

  return (
    <Container>
      <CompanyDetailMenu menuIdx={menuIdx} setMenuIdx={handleMenu} />

      {menuIdx === 0 ? <Summary /> : null}

      {menuIdx === 1 ? <Timeline /> : null}

      {menuIdx === 2 ? <Benchmark /> : null}

      {menuIdx === 3 ? <CompanyDetailNews /> : null}
    </Container>
  );
};

export default CompanyDetail;
